export enum UserPermissions {
  AABUS = 'AA-BUS', // PROVIDE EXPANDED PASSENGER SERVICE BUSINESS FUNCTIONS
  AABMAS = 'AABMAS', //
  ACSCTL = 'ACSCTL', // ACS CONTROL
  ACSDBA = 'ACSDBA', // ACSI Database Administrator
  ACSLED = 'ACSLED', // ACSI Lead Agent
  ACSOPS = 'ACSOPS', // ACSI Operations
  ACSPGR = 'ACSPGR', // ACSI Programmer
  ACSRBT = 'ACSRBT', //
  ACSSPV = 'ACSSPV', // ACSI SUPERVISOR
  AGSTRC = 'AGSTRC', // Agent Tracing - Force IcE Debug
  AIR375 = 'AIR375', // Allow phase 3.75 pricing/ticketing
  AKIOSK = 'AKIOSK', // IDENTIFICATION OF KIOSK TYPE DEVICE
  ALTNAM = 'ALTNAM', // SYNCRONIZE VCR NAMES AFTER PNR NAME CHANGE
  ALSCTL = 'ALSCTL', // Airline Solutions System Control
  ALUPTE = 'ALUPTE', // ABACUS AND INFINI CONNECTIVITY TAG
  AMRRPT = 'AMRRPT', // ALLOW ACCESS TO SYSTEM GENERATED REPORTS
  APLCTL = 'APLCTL', // EPR APPLICATIONS CONTROL AGENT
  APLRST = 'APLRST', // APPLICATIONS RESTRICT - EPR RESTRICTED TO APLCTL
  ARSPRT = 'ARSPRT', // GENERIC CONTROL FOR REQUESTING PRINTED ARS MANUALS
  ASRREV = 'ASRREV', // Agent Sales Report - Revenue Accounting
  ATACSP = 'ATACSP', // ALLOW ATAC SUPERVISORY ENTRIES
  ATACTL = 'ATACTL', // ALLOW ATAC CONTROL ENTRIES
  AUTHOR = 'AUTHOR', // ALLOW ACCESS AND CHANGE OF SAI COURSES AND LESSONS
  BAGCOD = 'BAGCOD', // ALLOW AGENT TO CODE AND CLOSE BAG MISHANDLING RCDS
  BAGCTS = 'BAGCTS', // ALLOW CONTROL OVER BMAS STATISTICAL COUNTS
  BAGTAG = 'BAGTAG', // Control Bag Tag Requests (limiting keyword)
  BAGZON = 'BAGZON', // ALLOW UPDATE OF BAGGAGE ZONE TABLES
  BRDACC = 'BRDACC', // DIRECT ACCESS USER WITH BROAD FUNCTIONAL CAPABILITIES
  BSRDSP = 'BSRDSP', // PRICING -- BANKERS SELLING RATE DISPLAY
  BTCURB = 'BTCURB', // SKYCAP BAGGAGE CHECK
  CAPCLR = 'CAPCLR', // REPLACE DUTY CODE 7 PASSENGER SELECTEE BY APPS PRODUCT
  CARSCR = 'CARSCR', // Cargo screener of physical search ETD or X-Ray
  CATSUP = 'CATSUP', // SUPPRESS CATEGORIES IN AN ELECTRONIC RULES DISPLAY
  CBSAGT = 'CBSAGT', // ALLOW CENTRAL BAGGAGE SERVICE AGENT ENTRIES
  CCSVCS = 'CCSVCS', // Restrict manual revalidation of a credit card
  CCVIEW = 'CCVIEW', // ALLOW AGENT TO VIEW CC DATA
  CDTCRD = 'CDTCRD', // CREDIT CARD AGENT
  CDTMGR = 'CDTMGR', // ALLOWS UPDATES TO VCL OPTION AND MH TABLES
  CENCRC = 'CENCRC', // CENTRAL REVENUE CONTROL AGENT  (aka CENTRAL RESERVATION CONTROL AGENT)
  CENCTL = 'CENCTL', // YIELD MANAGEMENT CONTROL
  CHGAAA = 'CHGAAA', // ALLOW CHANGING THE AAA CITY TO A HOST AIRLINE CITY
  CHGVCR = 'CHGVCR', // ALLOW AGENTS TO MODIFY VCR STATUS
  CLAIM = 'CLAIM¤', // CONTROL OA DIRECT ACCESS AGENTS FOR LMTACC ENTRY CLAIM
  CLAIMC = 'CLAIMC', // CLAIMED COUNTS
  CNXPTS = 'CNXPTS', // ALLOW ACCESS TO MULTI-HOST CONNECT POINT DATA BASE
  CODSHR = 'CODSHR', // CODESHARE ABILITY
  COMUTE = 'COMUTE', // COMMUTER ASSOCIATE AGENT
  CONSOL = 'CONSOL', // CONSOLIDATED CITIES
  CPUBLD = 'CPUBLD', // CPU BUILD
  CREATE = 'CREATE', // EPR CREATE AGENT
  CSADBF = 'CSADBF', // ALLOW CSA tool access for hotel bookings
  CUSTOM = 'CUSTOM', // CUSTOMS AGENTS
  CVRSAB = 'CVRSAB', // CONVERSATIONAL SABRE TERMINAL
  DBLAGT = 'DBLAGT', // EPR DOUBLE AGENT MAY CREATE BOTH AGENCY / ASSOC EPRS
  DBMAGT = 'DBMAGT', // DATA BASE MANAGEMENT AGENT
  DBUPTD = 'DBUPTD', // AIRPORT SECURITY 2
  DCCAGT = 'DCCAGT', // DIRECT CONNECT IDENTIFIER
  DETAIL = 'DETAIL', // ALLOW 4GD ENTRY TO RE-DETAIL SEAT MAPS
  DIENBR = 'DIENBR', // AGENT POSSESSES TICKETING DIE PLATE
  DISPLY = 'DISPLY', // ALLOW SPECIAL RESERVE SEAT ENTRIES
  DSCAGT = 'DSCAGT', // ALLOWS CREATE/UPDATE OF LMR -LINE MTNC RECORDS-
  DYNSKD = 'DYNSKD', // ALLOW DYNAMIC SCHEDULE CHANGE ENTRIES
  ENCODE = 'ENCODE', // CONTROL ADD/DEL CITIES FROM ENCODE TABLE
  EPRDSP = 'EPRDSP', // ALLOW DISPLAY OF ALL EPRS AND -H*CST- CAPABILITY
  EPRSVC = 'EPRSVC', // EMPLOYEE PROFILE RECORD (EPR) SERVICE
  EQUATE = 'EQUATE', // CONTROLS THE FLIGHT EQUATE/PSEUDO FLIGHT TBL UPDATES
  ERLYCK = 'ERLYCK', // EARLY CHECKIN
  ETRPRT = 'ETRPRT', // ALLOW RESET OF ELECTRONIC TICKET STATUS CODE TO OK
  ETVIEW = 'ETVIEW', // ELECTRONIC TICKET VIEW
  EXSCTN = 'EXSCTN', // ALLOW EXTRA SECTION AND CHARTER FLT UPDATES VIA  SKDCHG
  EXSNBP = 'EXSNBP', // EXTRA SECTION FOR COMMUTERS
  FASCTL = 'FASCTL', // ADMINISTRATIVE CONTROL OF FASTRAACK SYSTEM
  FAVUSR = 'FAVUSR', // FLY AWAY VACATION USER
  FFTDBM = 'FFTDBM', // ALLOW UPDATES TO FQTU/FQTR UPGRADE REQUEST CONTROL TBL
  FNLBRD = 'FNLBRD', // FINAL BOARDING
  FOXCTL = 'FOXCTL', // FOX (FOCUS) CONTROL
  FQSELL = 'FQSELL', // RETURN FARE QUOTE DISPLAY RESPONSE IN SELLING CURRENCY
  FREDDY = 'FREDDY', // ALLOW TFDEL ENTRY
  FSGAGT = 'FSGAGT', // FIELD SERVICE GROUP AGENT
  GDSDBM = 'GDSDBM', // GDS DATABASE
  GDSPLY = 'GDSPLY', // ALLOW ACCESS TO G*, G¤, GMM, GML ENTRIES ONLY
  GMSELL = 'GMSELL', // GROUP MANGEMENT SELL
  GMTIME = 'GMTIME', // GREENWICH MEAN TIME
  HDQAGT = 'HDQAGT', // HEADQUARTERS AGENT
  HLPDSK = 'HLPDSK', // PROVIDE HELP DESK CAPABILITIES FOR MULTI-HOSTS
  HODMIN = 'HODMIN', // SUPPRESS FREE TXT-HOTEL DIRECT CONNECT AVAIL DISPLAYS
  HODRTE = 'HODRTE', // RESTRICT HOTEL DCS DISPLAYS TO ONLY CRITICAL ELEMENTS
  HSSCTL = 'HSSCTL', // CONTROL ADDITION OF HSSPSB KEYWORD TO LNIATA
  HSTACC = 'HSTACC', // HOST TRAVEL ACCESS AUTHORITY
  IDCHNG = 'IDCHNG', // ID CHANGE
  INFSTP = 'INFSTP', // ALLOW AGENTS TO UPDATE STP LOCATION INDICATOR IN TJR
  INHNMC = 'INHNMC', // INHIBIT NAME CHANGE ON RETRIEVED PNRS
  INHVMO = 'INHVMO', // INHIBIT VMO ENTRY
  INSTRU = 'INSTRU', // ALLOW INSTRUCTORS ACCESS TO -HELD- SAI COURSE/LESSONS
  INVMGT = 'INVMGT', // ALLOW ENTRIES FOR CONTROLLING AND ADJUSTING INVENTORY
  IPSI01 = 'IPSI01', // IP SIGN IN VERSION 1
  ITCCTL = 'ITCCTL', // INTERLINE HOST ELECTRONIC TKT PROFILE TABLE
  KRAAT = 'KRAAT¤', // ALLOW UPDATE OF AIRLINE AGREEMENT TABLE
  LMTACC = 'LMTACC', // DIRECT ACCESS USERS WITH LIMITED CAPABILITIES
  MASTAR = 'MASTAR', // MASTER STAR
  MHBUS = 'MH-BUS', // ALLOW MINI ITIN PTR AT CTO WHEN DESIGNATED AS W*2M
  MHDAAA = 'MHDAAA', // ALLOW DIR ACC FROM MULTI-HOST TO AA W/OUT VAX
  MNTAGT = 'MNTAGT', // MONITOR AGENT
  MNTSET = 'MNTSET', // MONITOR SET
  MONEY = 'MONEY¤', // ALLOW UPDATE OF BANKERS BUYING RATE TABLES
  MONITR = 'MONITR', // ALLOW -MNT/C- ENTRY -AGENT SET MONITOR ACTIVITY-
  MRCHNT = 'MRCHNT', // RESTRICT MERCHANT FUNCTION TO KEYWORD HOLDERS
  MSGCT1 = 'MSGCT1', // MESSAGING GROUP CONTROL
  MSWAGT = 'MSWAGT', // ALLOW AGENT ACCESS TO SAAS MESSAGE SWITCHING
  MTTVNT = 'MTTVNT', // MARKET TARIFF TABLES/VIRTUAL NESTING TABLES
  MULRST = 'MULRST', // RESTRICT SIGN-IN TO ONE LOCATION
  MULSET = 'MULSET', // ALLOW SIGN-IN FROM ONE EPR INTO MULTIPLE LNIATA/S
  MULSKD = 'MULSKD', // MULTI SCHEDULE
  MUL375 = 'MUL375', // ALLOW AGENTS TO ACCESS 3.75 PRICING/TKTG FUNCTIONALITY
  NETFQD = 'NETFQD', // ACCESS TO NET FARES APPLICABLE TO AGENTS LOCATION
  NOBILL = 'NOBILL', // ALLOW STIN SUBSCRIBER ACCESS W/OUT CHARGE TO AGENCY
  NOCKIN = 'NOCKIN', // NO CHECK-IN
  NOPNRS = 'NOPNRS', // NO PNR UPDATES
  NOQUES = 'NOQUES', // INHIBIT ACCESS TO ALL QUEUES
  NSABRE = 'NSABRE', //
  OAACLR = 'OAACLR', // ALLOW OAALNIATA/CLEAR ENTRY TO TRASH AND REINIT AAA
  OB48HR = 'OB48HR', // OVERBOOK WITHIN 48 HOURS
  OBCINH = 'OBCINH', //
  OBCHNH = 'OBCHNH', // OVERBOOK BY CLASS INHIBITED
  OBCSHR = 'OBCSHR', // ALLOW OVERBOOK OF CODESHARE FLIGHTS
  OBUSER = 'OBUSER', // CONTROL OVERBOOKING ACTIVITY
  ONSNAP = 'ONSNAP', // NETEGRITY SECURITY FOR ONSNAP WEB APPLICATION
  OPSENG = 'OPSENG', // ALLOW UPDT OF SEAT ALGORITHM DATA AND PHYSICAL AIRCRAFT
  PERSTR = 'PERSTR', // RESTRICT UPDATE/DISPLAY OF STAR TO PERSONNEL USE
  PFKAGT = 'PFKAGT', // ALLOW UPDATE OF ANOTHER AGENT/S PERSONAL PF KEY DBSE
  PMMTRE = 'PMMTRE', // Gain access to PMMT entry
  PNLCTL = 'PNLCTL', // ALLOW UPDATE OF PNL/ADL CONTROL TABLE
  PRSCTL = 'PRSCTL', // RESTRICT PRE-RESERVED SEATS CONTROL TYPE ENTRIES
  PRSRST = 'PRSRST', // CONTROLS UPDATE OF PRS RESTRICTIONS TABLE
  PTAAGT = 'PTAAGT', // ALLOW DISPLAY OF PTA MCO NUMBERS FOR PREPAID TKT AGT
  PTARFD = 'PTARFD', // PREPAID TICKET ADVICE REFUND AGENT
  PTASTP = 'PTASTP', // ALLOW PREPAID TICKET AGENT STOP ACTION ENTRIES
  PTRAGT = 'PTRAGT', // ALLOW REMOVAL OF PRINTER MESSAGES FROM QUEUE
  PVTFAR = 'PVTFAR', // PRIVATE FARES
  QIKRES = 'QIKRES', // QIK RES PROJECT USER
  QMAINT = 'QMAINT', // ALLOW ACCESS TO QUEUE DATABASE ENTRIES
  QSTATS = 'QSTATS', // PULL QUEUE ANALYSIS STATS AND RESTRICT CITY ACCESS
  QUEMGR = 'QUEMGR', // ALLOW FOR CONTROL OF QUEUES
  QUESUB = 'QUESUB', // QUEUES PUBLISH AND SUBSCRIBE ROBOTICS
  QUEUES = 'QUEUES', // ALLOW FOR WORK DISTRIBUTION ON/FOR/TO QUEUES
  REACOM = 'REACOM', // ALLOW DYNAMIC SCHEDULE CHANGE RE-ACCOMMODATION ENTRIES
  REQROB = 'REQROB', // REQUIRED TO INDICATE ROBOTICS
  RESTRX = 'RESTRX', // ALLOW RES AGENTS TO DELETE PQF ITEMS FROM A PNR
  REVHST = 'REVHST', // CONTROL REVERSE HOST SYSTEM
  RMTCHK = 'RMTCHK', // IDENTIFY CHECK IN REQUEST IS FROM A SELF BOARD LOCATION
  ROBAP1 = 'ROBAP1', // ROBOTIC APPLICATION 1
  ROBAP2 = 'ROBAP2', // ROBOTIC APPLICATION 2
  ROBEXC = 'ROBEXC', // ROBOTIC MACHINE ID EXCEPTION
  ROTTBL = 'ROTTBL', // CONTROL ADD/CHG/DEL TTY-RO ROUTING TABLE ENTRIES
  RSSTAR = 'RSSTAR', // ALLOW DISPLAY/CHANGE OF RESERVATIONS RESTRICTED STARS
  RSTACC = 'RSTACC', // IDENTIFIES NONREV CAPABILITY
  SABHST = 'SABHST', // DIRECT ACCESS USER OF A MULTI-HOST PARTITION IN SABRE
  SACCTL = 'SACCTL', // ALLOW SPCL ASSIST COORDNTRS TO PRE-RES BLKHEAD SEATS
  SAICTL = 'SAICTL', // ALLOW UPDATE/CREATE TO SAI FOCUS INDEX
  SEATMP = 'SEATMP', // ALLOW MASTER MAP UPDATE ENTRIES
  SEATMQ = 'SEATMQ', // ALLOW UPDATE OF Q SEAT BLOCKS ONLY
  SELECT = 'SELECT', // BOARDING CONTROL FUNCTIONS
  SERCLR = 'SERCLR', // AIRPORT SECURITY
  SERCTY = 'SERCTY', // ALLOW ACCESS TO CITY USED TO SCREEN INTER PASSENGERS
  SERQUE = 'SERQUE', // ACCESS TO QUEUES OF SUSPECT INTERNATIONAL PASSENGERS
  SIRMSG = 'SIRMSG', // CREATE SIGN-IN RESPONSE
  SNDOUS = 'SNDOUS', // ALLOW OUS/OUB ENTRIES
  SOCCTL = 'SOCCTL', // ALLOW ASSIGNMENT OF SOCFIT KEYWORD - DPLY FLT IN TRBL
  SOCFIT = 'SOCFIT', // SYSTEMS OPERATIONS CENTER - DISPLAY FLIGHT IN TROUBLE
  SOCPSS = 'SOCPSS', // SYSTEMS OPERATIONS CONTROL (SOC) PASSENGER SERVICE SYSTEM (PSS)
  SPLOAA = 'SPLOAA', // LLOW -OAA- ENTRY FOR SAME LNIA AS AGENT
  SPLOAM = 'SPLOAM', // ALLOW -OAM- ENTRY FOR SAME LNIA AS AGENT
  SPURGE = 'SPURGE', // STAR PURGE
  SSDTRM = 'SSDTRM', // FILTER ACS GENERATED MASKS FOR DATABAHN
  STNMNT = 'STNMNT', // ALLOW UPDATE TO STATION TABLE
  STOLEN = 'STOLEN', // AUTOMATIC STOLEN TICKET AGENT
  STPLOS = 'STPLOS', // ALLOW 4G ENTRY - CONTROL BOARDING PASS ISSUANCE INHIBT
  SUBOPT = 'SUBOPT', // ALLOW HST AGNT TO BECOME A SUBSCRIBER W/UNIQUE OPTIONS
  SUPVSR = 'SUPVSR', // EPR SUPERVISOR AGENT
  SYSMSG = 'SYSMSG', // SYSTEM SIGN IN MESSAGE
  TBMCTL = 'TBMCTL', // TICKET-BY-MAIL CONTROL
  TMPACC = 'TMPACC', // TEMP ACCESS FOR HOST AGENTS TO PERFORM CRSAGT TASKS
  TMPCRS = 'TMPCRS', // ALLOWS CRS KWDS TO BE ADDED TO HOST EPRS TEMPORARILY
  TRAACK = 'TRAACK', // PERSON AUTHORIZED TO ENTER FASTRAACK UPDATE MODE
  TRACSA = 'TRACSA', // ALLOW ADMINISTRATION OF TRACS SYSTEM
  TREASU = 'TREASU', // ALLOW BANK DEPOSIT DISPLAY ENTRIES
  TREXBR = 'TREXBR', // Override Automated Exchange and Refunds functionality
  TREXB3 = 'TREXB3', // OVERRIDE T-REX PHASE 3 FUNCTIONALITY
  TREXEX = 'TREXEX', // ALLOW WEB CLIENT TO CONTINUE USING WS ENTRIES
  TTSCTL = 'TTSCTL', // CONTROL UPDATE/CREATE OF REFER. DATA - AUTO REFER SYS
  UNBLOK = 'UNBLOK', // ALLOW UNBLOCKING OF Z BLOCKED SEATS
  UNISTR = 'UNISTR', // ALLOW CREATION OF UNIVERSAL STAR RECORDS
  UNVPAR = 'UNVPAR', // ALLOW ACCESS TO ANY APPROVED PARTITION ONCE SIGNED-IN
  UNVSET = 'UNVSET', // ALLOW AGENT TO SIGN-IN AT ANY MULTI-HOST SET
  UPDBID = 'UPDBID', // UPDATE BID PRICES
  VCNRST = 'VCNRST', // VCN INVENTORY DISPLAY RESTRICTION
  VERIFY = 'VERIFY', // EPR VERIFY AGENT
  VIPACC = 'VIPACC', // ALLOW ACCESS TO VIPC INVENTORY ENTRY
  VMFRST = 'VMFRST', // ALLOW VMIF UPDATE TO NON CODESHARE FLIGHTS
  VNAUIR = 'VNAUIR', // ALLOW UPDATE OF AVAILABILITY AUTHORIZATION LEVELS
  VIPMGR = 'VIPMGR', // CONTROL ENTRIES -G*VP- AND -G*PPFLTNBR/VP-
  VOIDAA = 'VOIDAA', // ALLOW AGENT TO VOID TICKETS
  WAIVER = 'WAIVER', // AUTHORIZE TVL AGTS TO BYPASS PRICING RULES - PHASE 3.5
  WCCAGR = 'WCCAGR', // COMMAND CENTER AGREEMENTS
  WCCCSR = 'WCCCSR', // ALLOW WCC CODESHARE FUNCTION ACCESS
  WCCMAP = 'WCCMAP', // COMMAND CENTER MAPS
  WCCMTT = 'WCCMTT', // ALLOW WEB CONTROL CENTER MARKET TARRIFF TABLE UPDATES
  WCCRIM = 'WCCRIM', // COMMAND CENTER REVENUE INTEGRITY MANAGER
  XFRRBK = 'XFRRBK', // TRANSFER REVENUE BOOKINGS
  ZOENTS = 'ZOENTS', //
  TIME24 = '24TIME', // ACCEPT AND DISPLAY 24 HOUR CLOCK TIME
}
