import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { asyncScheduler, of, timer } from 'rxjs';
import { catchError, exhaustMap, mergeMap, observeOn, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { CallerContext } from '../../../dtos/response/caller-context-response/caller-context';
import { CallerContextResponse } from '../../../dtos/response/caller-context-response/caller-context-response';
import { RootState } from '../../../state/state';
import { enterZone, leaveZone } from '../../../utils/custom-schedulers';
import { CallerContextService } from '../caller-context.service';
import {
  setActiveCallerId,
  setCallerContextErrorMessage,
  setCallers,
  startCallerPolling,
  stopCallerPolling,
} from './caller-context-service.actions';
import { getActiveCallerId, getAllCallerEntities } from './caller-context-service.selectors';

@Injectable()
export class CallerContextServiceEffects {
  constructor(
    /**
     * An injectable observable stream provided by NGRX that emits all actions that are dispatched within RAIN
     */
    private actions$: Actions,
    private callerContextService: CallerContextService,
    private store: Store<RootState>,
    private ngZone: NgZone
  ) {}

  /**
   * This effect creates a recurring timer (no delay, runs every 5 seconds) that loads callers via the callerContextService
   * it uses a passed in scheduler so that we can control the timing during unit tests
   */
  //  getCallers$ = createEffect(() => (scheduler: SchedulerLike = asyncScheduler) =>
  pollCallerContext$ = createEffect(() => {
    /**
     * ms delay before starting the first operation
     */
    const initialDelay = 0;
    /**
     * ms between subsequent operations
     */
    const pollingPeriod = 5000;
    /**
     * how many active inner subscriptions can exist at one time
     */
    const concurrencyLimit = 1;
    // -- for info on leaveZone and enterZone see custom-schedulers.ts --
    // subscribe to all outputs from the actions$ observable
    return this.actions$.pipe(
      // filter for startCallerPolling action, only actions of this type will continue past this rxjs operator
      ofType(startCallerPolling),
      // take the action output by the ofType filter, pass it into a fat arrow function, and only run 1 concurrent operation
      mergeMap(
        (action) =>
          // create RXJS timer with no delay, 5000 millisecond loop, and a custom scheduler that leaves the angular zone
          // if we run a timer operator (or timeout, or setInterval) inside the angular zone, it blows up E2E tests
          timer(initialDelay, pollingPeriod, leaveZone(this.ngZone, asyncScheduler)).pipe(
            // observeOn re-emits all of the observables from above BUT it takes a scheduler so we use this operator along with our
            // custom enterZone scheduler to re-enter the angular zone to do our actual operations
            // running operations outside of the angular zone impacts error handling so we re-enter the zone as soon as possible
            observeOn(enterZone(this.ngZone, asyncScheduler)),
            // takeUntil will kill the observable when the observable it is listening to is triggered
            // in this case it is the stopCallerPolling action being dispatched
            takeUntil(this.actions$.pipe(ofType(stopCallerPolling))),
            // exhaustMap prevents additional requests from being made if the previous request is not complete
            exhaustMap(() =>
              // get the callers from the service and subscribe to the result
              this.callerContextService.getCallers().pipe(
                // get the activeCallerId and all of the caller context entities from the store
                withLatestFrom(this.store.select(getActiveCallerId), this.store.select(getAllCallerEntities)),
                // take in the result of the service call, and the two pieces of state from withLatestFrom
                switchMap(([result, activeCallerId, callerEntities]) =>
                  this.handleGetCallersResponse(result, activeCallerId, callerEntities)
                ),
                catchError((error) => {
                  return of(setCallerContextErrorMessage('An uncaught error occurred.'));
                })
              )
            )
          ),
        // limit the concurrency of the mergeMap
        concurrencyLimit
      )
    );
  });

  private handleGetCallersResponse(
    result: CallerContextResponse,
    activeCallerId: string,
    callerEntities: Dictionary<CallerContext>
  ): TypedAction<string>[] {
    // an action array to be returned by the switch map, all of the actions in this array are essentially
    // "dispatched" just like they would be if you called this.store.dispatch(someAction)
    const resultActions: TypedAction<string>[] = [];
    if (result.success) {
      // the local caller context model maintains a piece of state (dismissed) that the API does not
      // merge the dismissed state of each of the local state callers with the new callers from the service
      // if a match is not found the default state is used (false)
      const mergedCallers = result.callers?.map((caller) => ({
        ...caller,
        dismissed: callerEntities[caller.id]?.dismissed ? callerEntities[caller.id].dismissed : false,
      }));
      // add setCallers(mergedCallers) to the actions array, this will set the callers in the store via the reducer
      // if there are no mergedCallers (the map function above bailed out because callers was null), use empty array
      resultActions.push(setCallers(mergedCallers ?? []));
      // we have merged caller AND we don't have an active caller
      // OR the active caller is not in the mergedCallers array (or is but is dismissed)
      if (
        mergedCallers?.length &&
        (!activeCallerId || !mergedCallers.some((caller) => caller.id === activeCallerId && !caller.dismissed))
      ) {
        // set the active caller via an action to the first NON DISMISSED caller
        resultActions.push(setActiveCallerId(mergedCallers.find((caller) => !caller.dismissed)?.id ?? null));
      }
      // Clear any error messages that may have been set by a previous run after we get a successful response
      resultActions.push(setCallerContextErrorMessage(null));
    } else {
      // In the event of an error we simply set the message in the store so subscribers can handle it
      resultActions.push(setCallerContextErrorMessage(result.errorMessage));
    }
    return resultActions;
  }
}
