import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FlightShoppingRequest } from '../../../dtos/request/shopping-request/flight-shopping-request';
import { ShoppingRequest } from '../../../dtos/request/shopping-request/shopping-request';
import { ShoppingSegment } from '../../../dtos/request/shopping-request/shopping-segment';
import { TripRequest } from '../../../dtos/request/shopping-request/trip-request';
import { UserData } from '../../../dtos/response/login-response/user-data';
import { UserPermissions } from '../../../dtos/response/login-response/user-permissions';
import { Passenger } from '../../../dtos/response/reservation-response/passenger';
import { Segment } from '../../../dtos/response/reservation-response/segment';
import { FlightShoppingResponseFull } from '../../../dtos/response/shopping-response/flight-shopping-response-full';
import { Status } from '../../../models/status';
import { getUser } from '../../login-service/state/login-service.selector';
import { getPassengers } from '../../reservation-service/state/reservation-service.selectors';
import { getSdcEligibleSegments } from '../../sdc-eligibility-service/state/sdc-eligibility-service.selectors';
import { flightShoppingServiceFeatureKey, FlightShoppingServiceState } from './flight-shopping-service.state';

export const getFlightShoppingServiceState = createFeatureSelector<FlightShoppingServiceState>(flightShoppingServiceFeatureKey);

export const getFlightShoppingStatus = createSelector(getFlightShoppingServiceState, (state): Status => state.status);

export const getFlightShoppingResponse = createSelector(
  getFlightShoppingServiceState,
  (state): FlightShoppingResponseFull | null => state.results
);

export const getFlightShoppingSortedFilteredResponse = createSelector(
  getFlightShoppingServiceState,
  (state): ShoppingSegment[][] | undefined => state.results?.sortedFilteredFlightShoppingResults
);

export const getCurrentShoppingIndex = createSelector(getFlightShoppingServiceState, (state): number => state.currentShoppingIndex);

export const getSelectedFlightShoppingResult = createSelector(
  getFlightShoppingServiceState,
  (state): ShoppingSegment[] => state.selectedResult
);

export const sdcEligibleSegmentsContainArnk = createSelector(getSdcEligibleSegments, (sdcEligibleSegments: Segment[]): boolean => {
  for (let i = 0; i < sdcEligibleSegments.length - 1; i++) {
    if (sdcEligibleSegments[i].arrivalAirport !== sdcEligibleSegments[i + 1].departureAirport) {
      return true; // Return true as soon as a discrepancy among arrival/departure airports is found.
    }
  }
  return false;
});

/**
 * Get the Trip Request list portion of the Flight Shopping request for SDC based off of SDc eligible segments
 */
export const getTripRequests = createSelector(
  getSdcEligibleSegments,
  sdcEligibleSegmentsContainArnk,
  (sdcEligibleSegments: Segment[], hasArnk: boolean): TripRequest[] => {
    const dateOfTrip = new Date(sdcEligibleSegments[0]?.departureDateTime.split('T')[0]);
    const tripRequests: TripRequest[] = [];

    if (hasArnk) {
      // Search point to point. If an ARNK exists and you search origin to destination, you will not get any results
      // since the shopping API is not going to consider ARNK options.
      sdcEligibleSegments.forEach((segment) => {
        tripRequests.push({
          departureCity: segment.departureAirport,
          arrivalCity: segment.arrivalAirport,
          nearbyDepartureAirportsRadius: 55,
          nearbyArrivalAirportsRadius: 55,
          preferredEarliestDepDate: dateOfTrip,
          preferredLatestDepDate: dateOfTrip,
        });
      });
    } else {
      // Search origin to destination.
      tripRequests.push({
        departureCity: sdcEligibleSegments[0]?.departureAirport,
        arrivalCity: sdcEligibleSegments[sdcEligibleSegments.length - 1]?.arrivalAirport,
        nearbyDepartureAirportsRadius: 55,
        nearbyArrivalAirportsRadius: 55,
        preferredEarliestDepDate: dateOfTrip,
        preferredLatestDepDate: dateOfTrip,
      });
    }

    return tripRequests;
  }
);

/**
 * Get the SDC Flight Shopping request
 */
export const getSdcShoppingRequestRevenue = createSelector(
  getTripRequests,
  getSdcEligibleSegments,
  getPassengers,
  getUser,
  (tripRequests: TripRequest[], sdcEligibleSegments: Segment[], passengers: Passenger[], user: UserData): FlightShoppingRequest => {
    const shoppingRequest: ShoppingRequest = {
      shoppingMethod: 'standard',
      requestType: '',
      preferredCabin: 'coach',
      preferredCurrency: 'USD',
      preferNonStop: true,
      requirePassengerTypeMatch: false,
      accountCodeMatchRequired: false,
      checkAvailability: true,
      passengers: [
        {
          type: 'ADT',
        },
      ],
      tripRequests,
    };
    const request: FlightShoppingRequest = {
      shoppingRequest,
      sdcFilterSortInfo: {
        sdcEligibleSegments,
        numberOfPassengers: passengers?.length,
        hasOverbookEpr: user.permissions.includes(UserPermissions.OBCINH),
      },
    };
    return request;
  }
);
