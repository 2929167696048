import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FlightAvailabilityResponse } from '../../../dtos/response/flight-availability-response/flight-availability-response';
import { SEAT_AREA } from '../../../dtos/response/reservation-response/seat-area';
import { Segment } from '../../../dtos/response/reservation-response/segment';
import { FlightChangeForm } from '../../../models/flight-change-form/flight-change-form';
import { FlightChangeFormRow } from '../../../models/flight-change-form/flight-change-form-row';
import { FlightChangeOptions } from '../../../models/flight-change-form/flight-change-option';
import { SelectedFlightOption } from '../../../models/flight-change-form/selected-flight-option';
import { getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist, getInvoluntaryChangeSegments } from '../../reservation-service/state/reservation-service.selectors';
import {
  flightAvailabilityAdapter,
  flightAvailabilityServiceFeatureKey,
  FlightAvailabilityServiceState
} from './flight-availability-service.state';

const getFlightAvailabilityServiceState = createFeatureSelector<FlightAvailabilityServiceState>(flightAvailabilityServiceFeatureKey);

const { selectEntities: selectFlightAvailabilityEntities, selectAll: selectAllFlightAvailabilityResponses } =
  flightAvailabilityAdapter.getSelectors();

export const getFlightAvailabilityState = createSelector(getFlightAvailabilityServiceState, (state) => state);

export const getAllFlightAvailabilityEntities = createSelector(
  getFlightAvailabilityServiceState,
  (state): Dictionary<FlightAvailabilityResponse> => selectFlightAvailabilityEntities(state.flightAvailability)
);

export const getAllFlightAvailabilityResponses = createSelector(getFlightAvailabilityServiceState, (state): FlightAvailabilityResponse[] =>
  selectAllFlightAvailabilityResponses(state.flightAvailability)
);

/**
 * Get departure airport code
 */
export const getDepartureAirportCode = createSelector(getFlightAvailabilityServiceState, (state): string => state.departureAirportCode);

/**
 * Get arrival airport code
 */
export const getArrivalAirportCode = createSelector(getFlightAvailabilityServiceState, (state): string => state.arrivalAirportCode);

/**
 * Get all changed flights regardless of change type
 */
export const getFlightChangeFormFlights = createSelector(getFlightAvailabilityServiceState, (state): FlightChangeFormRow[] => {
  return state.changedFlights?.flights ?? [];
});

/**
 * Get changed flights with changeType === CHANGE
 */
export const getChangedFlights = createSelector(getFlightAvailabilityServiceState, (state): FlightChangeFormRow[] => {
  if (state.changedFlights) {
    return state.changedFlights.flights.filter((flight) => flight.changeType === FlightChangeOptions.CHANGE);
  } else {
    return [];
  }
});

/**
 * Get changed flights with changeType === CANCEL
 */
export const getCancelledFlights = createSelector(getFlightAvailabilityServiceState, (state): FlightChangeFormRow[] => {
  if (state.changedFlights) {
    return state.changedFlights.flights.filter((flight) => flight.changeType === FlightChangeOptions.CANCEL);
  } else {
    return [];
  }
});

/**
 * Gets a list of classes of service for the CHANGE/CANCEL flights (i.e. ['J', 'Y'])
 */
export const getChangedCancelledFlightsClassesOfService = createSelector(
  getChangedFlights, getCancelledFlights,
  (changedFlights: FlightChangeFormRow[], cancelledFlights: FlightChangeFormRow[]): string[] =>
    changedFlights.map((flight) => flight.classOfService).concat(cancelledFlights.map((flight) => flight.classOfService))
);

export const getChangedCancelledFlightsFirstClassClassesOfService = createSelector(
  getChangedCancelledFlightsClassesOfService,
  (classesOfService: string[]): string[] => {
    const firstClassCabin = ['J', 'C', 'D', 'I', 'U', 'E'];
    return classesOfService.filter((classOfService) => firstClassCabin.includes(classOfService)) ?? [];
  }
);

export const getChangedCancelledFlightsInFirstClass = createSelector(
  getChangedCancelledFlightsFirstClassClassesOfService,
  (classesOfService: string[]): boolean => {
    return classesOfService.length > 0;
  }
);

export const getConnectionToNonstopSearch = createSelector(
  getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist,
  getCancelledFlights,
  getDepartureAirportCode,
  getArrivalAirportCode,
  (allSegments: Segment[],
    cancelledFlights: FlightChangeFormRow[],
    departureAirportCode: string,
    arrivalAirportCode: string,): boolean => {
    const changeOfRouting = !allSegments.find((segment) =>
      segment.departureAirport === departureAirportCode && segment.arrivalAirport === arrivalAirportCode);
    return cancelledFlights.length > 0 && changeOfRouting;
  }
);

/**
 * Get changed flights with changeType === CHANGE FLIGHT
 */
export const getFlightChangeForm = createSelector(getFlightAvailabilityServiceState, (state): FlightChangeForm | undefined => {
  return state.changedFlights ?? undefined;
});

/**
 * Get all schedule change flights from FlightChangeForm
 */
export const getAllScheduleChangeFlights = createSelector(getFlightAvailabilityServiceState, (state): FlightChangeFormRow[] => {
  if (state.changedFlights) {
    return state.changedFlights?.flights?.filter(
      (flight) =>
        flight.changeType === FlightChangeOptions.KEEP ||
        flight.changeType === FlightChangeOptions.CHANGE ||
        flight.changeType === FlightChangeOptions.CANCEL
    ) ?? [];
  } else {
    return [];
  }
});

/**
 * Get all irrop flights from FlightChangeForm
 */
export const getAllIrropChangedFlights = createSelector(getFlightAvailabilityServiceState, (state): FlightChangeFormRow[] => {
  if (state.changedFlights) {
    return state.changedFlights?.flights?.filter(
      (flight) =>
        flight.changeType === FlightChangeOptions.IGNORE ||
        flight.changeType === FlightChangeOptions.KEEP ||
        flight.changeType === FlightChangeOptions.CHANGE ||
        flight.changeType === FlightChangeOptions.CANCEL
    ) ?? [];
  } else {
    return [];
  }
});

/**
 * Get current flight availability index
 */
export const getCurrentFlightAvailabilityIndex = createSelector(
  getFlightAvailabilityServiceState,
  (state): number => state.currentFlightAvailabilityIndex
);

/**
 * Check if the current flight availability segment is the last segment
 */
export const isLastFlightAvailabilitySegment = createSelector(
  getCurrentFlightAvailabilityIndex,
  getChangedFlights,
  (currentFlightAvailabilityIndex: number, changedFlights: FlightChangeFormRow[]): boolean => {
    if (changedFlights) {
      return currentFlightAvailabilityIndex + 1 >= changedFlights.length;
    } else {
      return true;
    }
  }
);

/**
 * Retrieves all selected options for each segment
 */
export const getAllSelectedAvailabilityOptions = createSelector(
  getAllFlightAvailabilityResponses,
  (options: FlightAvailabilityResponse[]): SelectedFlightOption[] => {
    return options.map((option) => {
      return {
        id: option.id,
        selectedOption: (option.availabilityOptions ?? []).find((opt) => opt.selected) ?? null,
      };
    });
  }
);

export const getCurrentFlightAvailabilityResponse = createSelector(
  getAllFlightAvailabilityResponses,
  getCurrentFlightAvailabilityIndex,
  (options: FlightAvailabilityResponse[], index: number) => {
    return options[index];
  }
);

export const getSelectedAvailabilityOption = createSelector(getCurrentFlightAvailabilityResponse, (options: FlightAvailabilityResponse) => {
  return options ? (options.availabilityOptions ?? []).find((avail) => avail.selected) : undefined;
});

export const getFlightAvailabilityStatus = createSelector(getFlightAvailabilityServiceState, (state) => state.status);

export const getFlightAvailabilityError = createSelector(getFlightAvailabilityServiceState, (state) => state.error);

/**
 * Get the number of changed flights
 */
export const getChangedFlightCount = createSelector(getFlightAvailabilityServiceState, (state): number => {
  if (state.changedFlights) {
    return state.changedFlights.flights.filter((flight) => flight.changeType === 2).length;
  } else {
    return 0;
  }
});

/**
 * Get the number of kept flights
 */
export const getKeptFlightCount = createSelector(getFlightAvailabilityServiceState, (state): number => {
  if (state.changedFlights) {
    return state.changedFlights.flights.filter((flight) => flight.changeType === 1).length;
  } else {
    return 0;
  }
});

/**
 * Get the number of cancelled flights
 */
export const getCancelledFlightCount = createSelector(getFlightAvailabilityServiceState, (state): number => {
  if (state.changedFlights) {
    return state.changedFlights.flights.filter((flight) => flight.changeType === 3).length;
  } else {
    return 0;
  }
});

/**
 * Get the number of flights in the flow
 */
export const getFlightCount = createSelector(getFlightAvailabilityServiceState, (state): number => {
  if (state.changedFlights) {
    return state.changedFlights.flights.length;
  } else {
    return 0;
  }
});

/**
 * Get the count of how many results to display for the current change flight
 * Every changed flight will start with 12 results
 * The results increase by 12 every time the 'display more flights' button is clicked
 */
export const getCurrentChangedFlightResultCount = createSelector(getFlightAvailabilityServiceState, (state): number => {
  if (state.changedFlights) {
    return state.changedFlights.flights.filter((flight) => flight.changeType === 2)[state.currentFlightAvailabilityIndex].resultCount;
  } else {
    return 12;
  }
});

export const getOriginalFlightClassOfService = createSelector(
  getAllFlightAvailabilityResponses,
  getChangedFlights,
  (options: FlightAvailabilityResponse[], changedFlights: FlightChangeFormRow[]): string => {
    return changedFlights?.filter((changedFlight) =>
      changedFlight.departureAirportCode + changedFlight.arrivalAirportCode === options[0]?.id)[0]?.classOfService ?? undefined;
  }
);

export const getChangedFlightClassOfService = createSelector(
  getChangedFlights,
  getDepartureAirportCode,
  getArrivalAirportCode,
  (changedFlights: FlightChangeFormRow[], departureAirport: string, arrivalAirport: string): string | undefined => {
    return changedFlights.find((flight) =>
      flight.departureAirportCode === departureAirport && flight.arrivalAirportCode === arrivalAirport)?.classOfService ?? undefined;
  }
);

export const getChangedFlightCabin = createSelector(
  getChangedFlights,
  getDepartureAirportCode,
  getArrivalAirportCode,
  (changedFlights: FlightChangeFormRow[], departureAirport: string, arrivalAirport: string): SEAT_AREA | undefined => {
    const cabin = changedFlights.find((flight) =>
      flight.departureAirportCode === departureAirport && flight.arrivalAirportCode === arrivalAirport)?.cabin;
    if (cabin === 'First cabin') {
      return SEAT_AREA.FIRST_CABIN;
    }
    // Default to main cabin
    return SEAT_AREA.MAIN_CABIN;
  }
);

/**
 * Gets a list of distinct airlines that the guests are booked on for the flight availability request.
 * This has both marketing and operating airlines.
 */
export const getFlightAvailabilityRequestAirlines = createSelector(
  getInvoluntaryChangeSegments,
  getFlightChangeFormFlights,
  (segments: Segment[], changedFlights: FlightChangeFormRow[]): (string | undefined)[] =>
    changedFlights.map((changedFlight, index) => {
      if (changedFlight.changeType === FlightChangeOptions.CHANGE || changedFlight.changeType === FlightChangeOptions.CANCEL) {
        return segments[index];
      }
      return null;
    })
      .filter((segment) => !!segment)
      .flatMap((segment) => [segment?.marketedByAirlineCode, segment?.operatedByAirlineCode])
      .filter((value, index, self) => self.indexOf(value) === index)
);

export const getSeatMapViewSegment = createSelector(getFlightAvailabilityServiceState, (state) => state.seatMapViewSegment);

export const getSeatMapViewSegmentForSameDayConfirm = createSelector(getFlightAvailabilityServiceState,
    (state) => state.seatMapViewSegmentForSameDayConfirm);
