import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { FlightService } from '../flight.service';
import { loadFlightStatus, loadFlightStatusComplete } from './flight-service.actions';
import { getFlightStatus } from './flight-service.selectors';
import { FlightServiceState } from './flight-service.state';
import { DatePipe } from '@angular/common';
import { setSegmentFlightLegInformation } from '../../reservation-service/state/reservation-service.actions';
import { getRoutedConfirmationCode } from '../../reservation-service/state/reservation-service.selectors';
import { FlightInformationResponse } from 'src/app/dtos/response/flight-status-response/flight-information-response';

@Injectable()
export class FlightServiceEffects {
  constructor(
    private actions$: Actions,
    private flightService: FlightService,
    private flightServiceStore: Store<FlightServiceState>,
    private datePipe: DatePipe
  ) {}

  getFlightStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFlightStatus),
      mergeMap((action) => {
        const departureDate = this.datePipe.transform(action.departureDateTime, 'yyyy-MM-dd');
        return observableOf(action).pipe(
          withLatestFrom(
            this.flightServiceStore.select(getFlightStatus(action.flightNumber + departureDate)),
            this.flightServiceStore.select(getRoutedConfirmationCode)
          )
        );
      }),
      mergeMap(([action, cachedFlightStatusResponse, confirmationCode]) => {
        if (cachedFlightStatusResponse) {
          return observableOf(loadFlightStatusComplete(cachedFlightStatusResponse));
        }
        return this.flightService.getStatus(action.carrierCode, action.flightNumber, action.departureDateTime).pipe(
          map((flightStatusResponse) => {
            this.updateSegmentFlightLegInformation(flightStatusResponse, confirmationCode);
            return loadFlightStatusComplete(flightStatusResponse);
          })
        );
      })
    )
  );

  private updateSegmentFlightLegInformation(flightStatusResponse: FlightInformationResponse, confirmationCode: string) {
    this.flightServiceStore.dispatch(
      setSegmentFlightLegInformation(confirmationCode, flightStatusResponse?.flightInformation?.flightInformation)
    );
  }
}
