import { createAction } from '@ngrx/store';
import { mileagePlanProfileSearchFeatureKey } from './mileage-plan-profile-search.state';

export const initializeMileagePlanProfileSearchState = createAction(`[${mileagePlanProfileSearchFeatureKey}] Initialize`);

export const setMileagePlanProfileSearchMileagePlanNumber = createAction(
  `[${mileagePlanProfileSearchFeatureKey}] Set mileage plan profile search MP number property`,
  (mileagePlanNumber: string) => ({
    mileagePlanNumber,
  })
);
