import { createAction } from '@ngrx/store';
import { CareKitRequest } from '../../../dtos/request/care-kit-request/care-kit-request';
import { Compensation } from '../../../dtos/response/compensation-response/compensation';
import { compensationServiceFeatureKey } from './compensation-service.state';

export const initializeCompensationServiceState = createAction(`[${compensationServiceFeatureKey}] Initialize`);

export const loadCompensation = createAction(`[${compensationServiceFeatureKey}] Load Compensation`);
export const setCompensations = createAction(`[${compensationServiceFeatureKey}] Set Compensation`, (compensation: Compensation[]) => ({
  compensation,
}));
export const submitCareKit = createAction(
  `[${compensationServiceFeatureKey}] Submit Care Kit`, (request: CareKitRequest, compensationType: string) => ({
    request,
    compensationType
}));
export const submitCareKitComplete = createAction(
  `[${compensationServiceFeatureKey}] Submit Care Kit Complete`, (success: boolean, errorMessage?: string) => ({
    success,
    errorMessage
}));

export const compensateGuestWithDifferentInfo = createAction(`[${compensationServiceFeatureKey}] Compensate guest with different information`);

export const resetCompensationSuccess = createAction(`[${compensationServiceFeatureKey}] Reset compensation success`);
