import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoginAttemptStatus } from '../../dtos/response/login-response/login-attempt-status';
import { login } from '../../services/login-service/state/login-service.actions';
import { getIsLoggedIn, getLoginLoadingStatus, getLoginStatus } from '../../services/login-service/state/login-service.selector';
import { LoginServiceState } from '../../services/login-service/state/login-service.state';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  formModel: UntypedFormGroup;
  username: UntypedFormControl;
  password: UntypedFormControl;
  loginErrorMessage: string;
  isAuthorized = true;
  contactCenterMessage: string;
  loading$: Observable<boolean>;
  version: string;
  private subscriptions: Subscription[];

  constructor(fb: UntypedFormBuilder, private router: Router, private loginServiceStore: Store<LoginServiceState>) {
    this.formModel = fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      rememberusername: [''],
    });
    this.username = this.formModel.controls.username as UntypedFormControl;
    this.password = this.formModel.controls.password as UntypedFormControl;
    this.subscriptions = [];
    this.loading$ = this.loginServiceStore.pipe(select(getLoginLoadingStatus));
  }

  ngOnInit() {
    if (localStorage.getItem('userID')) {
      this.formModel.controls.rememberusername.setValue(true);
      this.username.setValue(localStorage.getItem('userID'));
    }
    this.subscriptions.push(
      this.loginServiceStore.pipe(select(getIsLoggedIn)).subscribe((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          this.router.navigate(['/home', 'dashboard']);
        }
      }),
      this.loginServiceStore.pipe(select(getLoginStatus)).subscribe((status: LoginAttemptStatus) => {
        this.translateLoginStatus(status);
      })
    );
    this.version = environment.rainVersion;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  onSubmit() {
    this.loginErrorMessage = null;
    this.formModel.markAsTouched();
    Object.keys(this.formModel.controls)
      .map((controlName) => this.formModel.controls[controlName])
      .forEach((control) => control.markAsTouched());

    if (!this.formModel.valid) {
      return;
    }
    this.loginServiceStore.dispatch(login(this.formModel.value.username, this.formModel.value.password));
    this.rememberUser();
  }

  rememberUser() {
    if (this.formModel.controls.rememberusername.value) {
      localStorage.setItem('userID', this.username.value);
    } else {
      localStorage.removeItem('userID');
    }
  }

  private translateLoginStatus(status: LoginAttemptStatus) {
    switch (status) {
      case LoginAttemptStatus.BAD_CREDENTIALS:
        this.loginErrorMessage = 'Invalid credentials.';
        break;
      case LoginAttemptStatus.INSUFFICIENT_PRIVILEGES:
        this.loginErrorMessage = 'Insufficient privileges.';
        break;
      case LoginAttemptStatus.TIMEOUT:
        this.loginErrorMessage = 'Sign in attempt timed out.';
        break;
      case LoginAttemptStatus.SYSTEM_FAILURE:
        this.loginErrorMessage = 'An unknown error occurred. If the issue continues, help us investigate by';
        this.contactCenterMessage = 'submitting a detailed report through Contact Center Technology.';
        break;
      default:
        this.loginErrorMessage = null;
    }
  }
}
