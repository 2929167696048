import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CallerContext } from '../../../dtos/response/caller-context-response/caller-context';
import { Status } from '../../../models/status';
import { RootState } from '../../../state/state';

/**
 * The string name of the caller context "slice" of state
 */
export const callerContextFeatureKey = 'callerContext';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  callerContext: CallerContextState;
}

/**
 * The state that is used in relation to caller context, it is an extension of root state
 * these values are available and should be accessed from the root state model
 */
export interface CallerContextState {
  callers: CallerContextEntity;
  activeCallerId: string | null;
  status: Status;
  errorMessage?: string;
}

/**
 * Interface for storing CallerContext objects as an ordered array of ids and an unordered dictionary
 */
export interface CallerContextEntity extends EntityState<CallerContext> {}

/**
 * NGRX provided adapter for interfacing with entities (modifying and selecting), this adapter is for the caller context entity
 */
export const callerContextAdapter: EntityAdapter<CallerContext> = createEntityAdapter<CallerContext>();

/**
 * Initial state of the caller context entity as defined by the caller context adapter
 */
export const initialCallersState: CallerContextEntity = callerContextAdapter.getInitialState({});

/**
 * Initial state of the caller context state, used when caller context state is bootstrapped via the reducer in an app module
 * also used when state is reset
 */
export const initialCallerContextState: CallerContextState = {
  callers: initialCallersState,
  activeCallerId: null,
  status: Status.STABLE,
};
