import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeeRequest } from '../../../dtos/request/fee-request';
import { FeeInfo } from '../../../dtos/response/bags-and-ancillaries-response/fee-info';
import { PassengerInfo } from '../../../dtos/response/bags-and-ancillaries-response/passenger-info';
import { FeeResponse } from '../../../dtos/response/fee-response/fee-response';
import { AlaskaBaggageFeeCodes } from '../../../models/alaska-baggage-fee-codes';
import { Status } from '../../../models/status';
import {
  getConfirmedSegments,
  getPassengers,
  getRoutedConfirmationCode,
} from '../../reservation-service/state/reservation-service.selectors';
import { feeAdapter, feeFeatureKey, FeeState } from './fee.state';

const getFeeState = createFeatureSelector<FeeState>(feeFeatureKey);

const { selectEntities, selectAll } = feeAdapter.getSelectors();

export const getAllFeeEntities = createSelector(getFeeState, (state) => selectEntities(state.feeResults));

export const getAllFees = createSelector(getFeeState, (state) => selectAll(state.feeResults));

export const getFeeStatus = createSelector(getFeeState, (state): Status => state.feeStatus);

export const getFeeError = createSelector(getFeeState, (state): string | null => state.feeError);

// Define a selector to get the SDC fee for a given ID
export const getSdcFee = (id: string) =>
  createSelector(getAllFeeEntities, (entities: Dictionary<FeeResponse>): number | undefined => {
    // Use optional chaining to access the passengers array and nullish coalescing to provide a default empty array
    const passengers = entities[id]?.response?.passengers ?? [];
    // Check if there is at least one passenger with a defined SDC fee
    if (passengers.some((pax) => pax.sdcFee)) {
      // If the condition is met, calculate the maximum SDC fee among all passengers
      return passengers.reduce((max, current) => Math.max(max, current.sdcFee.saleTotal), 0);
    }
    // If the condition is not met, return undefined
    return undefined;
  });

export const getHasSdcFees = createSelector(
  getAllFees,
  (fees: FeeResponse[]): boolean => fees?.filter((fee) => fee.response?.passengers?.some((pax) => pax.sdcFee))?.length > 0
);

export const getSdcFeeWaiverReason = (id: string) =>
  createSelector(getAllFeeEntities, (entities: Dictionary<FeeResponse>): string | undefined =>
    id ? (((entities[id] ?? {}).response ?? {}).passengers ?? []).find((pax) => pax.sdcFee)?.sdcFee.waiverReason ?? undefined : undefined
  );

export const getSdcFeeOriginalAmount = (id: string) =>
  createSelector(getAllFeeEntities, (entities: Dictionary<FeeResponse>): number | undefined =>
    id
      ? ((((entities[id] ?? {}).response ?? {}).allAvailableItems ?? []).find((item) => item.feeCode === 'SDC') ?? {}).originalAmount ??
        undefined
      : undefined
  );

export const getFeeRequest = createSelector(
  getRoutedConfirmationCode,
  getConfirmedSegments,
  getPassengers,
  (confirmationCode, confirmedSegments, passengers): FeeRequest => {
    // remove 0's from passenger id
    const passengerNameNumbers = passengers?.map((passenger) => Number(passenger.id).toString().replace('.0', '.')) ?? [];
    const request: FeeRequest = {
      confirmationCode,
      departureCity: confirmedSegments[0]?.departureAirport ?? '',
      passengerNameNumbers,
      flightDate: confirmedSegments[0]?.departureDateTime.split('T')[0] ?? '',
    };
    return request;
  }
);

/**
 * Return the bagsAndAncillaryFeesStatus
 */
export const getBagsAndAncillaryFeesStatus = createSelector(getFeeState, (state): Status => state.bagsAndAncillaryFeesStatus);

/**
 * Get whether the request for bags and ancillary fees is finished
 */
export const getIsLoadedBagsAndAncillaryFees = createSelector(
  getFeeState,
  (state): boolean => state.bagsAndAncillaryFeesStatus === Status.STABLE
);

/**
 * Get the list of passenger fees from the bags and ancillary fees request
 */
export const getBagsAndAncillaryFeesPassengersList = createSelector(
  getFeeState,
  (state): PassengerInfo[] => state.bagsAndAncillaryFees?.response?.feesByPassengerRS?.passengers ?? []
);

/**
 * Get the fees for the current selected passenger
 * Used for dynamic baggage information
 */
export const getSelectedBagAndAncillaryFeePassengerBagFees = createSelector(
  getFeeState,
  (state): FeeInfo[] =>
    state.selectedBagAndAncillaryFeePassenger?.fees?.filter(
      (fee) =>
        fee.feeCode === AlaskaBaggageFeeCodes.Standard ||
        fee.feeCode === AlaskaBaggageFeeCodes.StandardFree ||
        fee.feeCode === AlaskaBaggageFeeCodes.Oversized ||
        fee.feeCode === AlaskaBaggageFeeCodes.OversizedFree
    ) ?? []
);

/**
 * Get the most significant carrier returned from the bags and ancillary fees request
 */
export const getBagAndAncillaryFeeMostSignificantCarrier = createSelector(
  getFeeState,
  (state): string => state.bagsAndAncillaryFees?.response?.feesByPassengerRS?.mostSignificantCarrier ?? 'Cannot be fetched'
);

/**
 * True if an error was returned from the bags and ancillary fees request
 */
export const getHasBagsAndAncillaryFeesError = createSelector(getFeeState, (state): boolean => state.bagsAndAncillaryFeesError != null);

/**
 * Returns an error returned from the bags and ancillary fees request
 */
export const getBagsAndAncillaryFeesError = createSelector(getFeeState, (state): string => state.bagsAndAncillaryFeesError ?? '');
