import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { loadBaggageDetailsComplete, loadBaggageJourneyByConfirmationCode } from './baggage-service.actions';
import { BaggageService } from '../baggage-service';

@Injectable()
export class BaggageServiceEffects {
  constructor(private actions$: Actions, private baggageService: BaggageService) {}

  loadBaggageJourney$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBaggageJourneyByConfirmationCode),
      mergeMap((action) => {
        return this.baggageService.getBaggageJourney(action.request).pipe(
          map((baggageJourneyResponse) => {
            return loadBaggageDetailsComplete(baggageJourneyResponse);
          })
        );
      }),
      catchError(() => {
        return of(loadBaggageDetailsComplete({ baggageJourneyDetailsList: [], error: 'UNKNOWN_ERROR' }));
      })
    )
  );
}
