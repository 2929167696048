import { Action, createReducer, on } from '@ngrx/store';
import { setInvoluntaryChangeActionType } from './involuntary-change.actions';
import { initialInvoluntaryChangeState } from './involuntary-change.state';


const featureReducer = createReducer(
  initialInvoluntaryChangeState,
  on(setInvoluntaryChangeActionType, (state, { actionType }) => ({ ...state,  actionType })),
);

export function involuntaryChangeReducer(state = initialInvoluntaryChangeState, action: Action) {
  return featureReducer(state, action);
}
