import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AddMileagePlanResponse } from 'src/app/dtos/response/add-mileage-plan-response';
import { MileagePlanProfileLookupResponse } from 'src/app/dtos/response/mileage-plan-profile-lookup-response/mileage-plan-profile-lookup-response';
import { RemoveMileagePlanResponse } from 'src/app/dtos/response/remove-mileage-plan-response';
import { AirlineCode } from 'src/app/models/airlines/airline-code';
import { Airlines } from 'src/app/models/airlines/airlines';
import { MessageKey } from 'src/app/models/message/message-key';
import { addStackedMessage } from 'src/app/services/message-service/state/message.actions';
import { RootState } from 'src/app/state/state';
import { MileagePlanProfileLookupStatus } from '../../../dtos/response/mileage-plan-profile-lookup-response/mileage-plan-profile-lookup-status';
import { MileagePlanEnrollmentResult } from 'src/app/dtos/response/mileage-plan-response/mileage-plan-enrollment-result';

@Injectable({
  providedIn: 'root',
})
export class MileagePlanMessageConverter {
  constructor(private store: Store<RootState>) {}

  /**
   * Converts a Mileage Plan profile lookup response into a list of message keys.
   */
  public convertMileagePlanLookupMessage(response: MileagePlanProfileLookupResponse, isAutoFill = false): MessageKey[] {
    // Early return for success case to reduce nesting
    if (response.status === MileagePlanProfileLookupStatus.SUCCESS) {
      return [];
    }
    // When autofill is true and the response is a partial failure with a successful account lookup, return an empty array
    // For autofill this is considered a success case
    if (
      isAutoFill &&
      response.status === MileagePlanProfileLookupStatus.PARTIAL_FAILURE &&
      response?.response?.accountLookupResponse?.success
    ) {
      return [];
    }

    // Map status and autoFill flag to message keys
    const statusToMessageKeyMap = {
      [MileagePlanProfileLookupStatus.NO_DATA_RETURNED]: isAutoFill ? MessageKey.AUTO_FILL_MP_NOT_FOUND : MessageKey.MP_LOOKUP_NOT_FOUND,
      [MileagePlanProfileLookupStatus.TIMEOUT]: isAutoFill ? MessageKey.AUTO_FILL_MP_TIMEOUT : MessageKey.MP_LOOKUP_TIMEOUT,
      [MileagePlanProfileLookupStatus.PARTIAL_FAILURE]: this.getPartialFailureMessageKey(response, isAutoFill),
    };

    const defaultMessageKey = isAutoFill ? MessageKey.AUTO_FILL_MP_FULL_FAILURE : MessageKey.UNKNOWN_WITH_LINK;

    // Get message key based on response status or use default
    const messageKey = statusToMessageKeyMap[response.status] || defaultMessageKey;

    return messageKey ? [messageKey] : [];
  }

  /**
   * Determines the appropriate message key for partial failures.
   */
  private getPartialFailureMessageKey(response: MileagePlanProfileLookupResponse, isAutoFill: boolean): MessageKey {
    // Auto Fill only cares about Account Lookup, check wheather Account lookup was successful.
    // Further error evaluation handled in MileagePlanAutoFillButtonComponent.
    if (isAutoFill && !response?.response?.accountLookupResponse?.success) {
      return MessageKey.AUTO_FILL_MP_FULL_FAILURE;
    } else if (!isAutoFill) {
      return MessageKey.MP_LOOKUP_PARTIAL_FAILURE;
    }
    return null;
  }

  /**
   * Given a mileage plan add response, evaluates the response and dispatches the appropriate message to state.
   */
  public convertAddMileagePlanMessage(response: AddMileagePlanResponse) {
    const airline =
      response?.frequentFlyerCarrier === AirlineCode.AS
        ? 'Alaska Airlines'
        : Airlines.get(response?.frequentFlyerCarrier)?.airline ?? 'Unknown';
    const error = response?.errorMessage ?? null;
    switch (true) {
      case error?.toLowerCase().includes('request timed out'):
        this.store.dispatch(addStackedMessage(MessageKey.ADD_MP_TIMEOUT));
        break;
      case error?.toLowerCase().includes('password decryption error'):
        this.store.dispatch(addStackedMessage(MessageKey.PASSWORD_DECRYPTION_ERROR));
        break;
      case error?.toLowerCase().includes('does not exist for this airline'):
        this.store.dispatch(addStackedMessage(MessageKey.ADD_MP_DOES_NOT_EXIST_FOR_AIRLINE, [response?.frequentFlyerNumber, airline]));
        break;
      case error?.toLowerCase().includes('data exists for this passenger'):
        this.store.dispatch(addStackedMessage(MessageKey.ADD_MP_DATA_EXISTS_FOR_PASSENGER));
        break;
      case error?.toLowerCase().includes('name does not match'):
        this.store.dispatch(addStackedMessage(MessageKey.ADD_MP_NAME_MISMATCH, [response?.frequentFlyerNumber]));
        break;
      case error?.toLowerCase().includes('agreement does not exist'):
        this.store.dispatch(addStackedMessage(MessageKey.ADD_MP_NO_AGREEMENT, [airline]));
        break;
      case error?.toLowerCase().includes('simultaneous changes'):
        this.store.dispatch(addStackedMessage(MessageKey.ADD_MP_SIMULATENOUS_CHANGES));
        break;
      default:
        this.store.dispatch(addStackedMessage(MessageKey.UNKNOWN_WITH_LINK));
        break;
    }
  }

  public convertRemoveMileagePlanMessage(response: RemoveMileagePlanResponse) {
    const error = response?.errorMessage ?? null;
    switch (true) {
      case error?.toLowerCase().includes('request timed out'):
        this.store.dispatch(addStackedMessage(MessageKey.REMOVE_MP_TIMEOUT));
        break;
      case error?.toLowerCase().includes('password decryption error'):
        this.store.dispatch(addStackedMessage(MessageKey.PASSWORD_DECRYPTION_ERROR));
        break;
      case error?.toLowerCase().includes('not found in context'):
        this.store.dispatch(addStackedMessage(MessageKey.REMOVE_MP_NOT_FOUND_IN_CONTEXT));
        break;
      case error?.toLowerCase().includes('simultaneous changes'):
        this.store.dispatch(addStackedMessage(MessageKey.REMOVE_MP_SIMULATENOUS_CHANGES));
        break;
      default:
        this.store.dispatch(addStackedMessage(MessageKey.UNKNOWN_WITH_LINK));
        break;
    }
  }

  public convertMileagePlanEnrollmentMessage(result: MileagePlanEnrollmentResult) {
    const error = result?.exceptionContent ?? null;
    if(error?.toLowerCase().includes('mileage plan enrollment service timed out')){
      this.store.dispatch(addStackedMessage(MessageKey.MILEAGE_PLAN_ENROLLMENT_TIMEOUT));
    }
    else{
      this.store.dispatch(addStackedMessage(MessageKey.UNKNOWN_WITH_LINK));
    }
  }
}
