import { Action, createReducer, on } from '@ngrx/store';
import { Status } from '../../../models/status';
import {
  initializeMileagePlanProfileServiceState,
  mileagePlanProfileSearch,
  mileagePlanProfileSearchFailure,
  mileagePlanProfileSearchSuccess,
} from './mileage-plan-profile-service.actions';
import { initialMileagePlanProfileServiceState, mileagePlanProfileLookupAdapter } from './mileage-plan-profile-service.state';

/**
 * Handles all state changes, there is no way to change the state without a reducer AND the reducer never
 * modifies state, it clones the state from the store, changes that cloned state in some way, and then replaces
 * the entire old state with the cloned and modified state.
 */
const featureReducer = createReducer(
  initialMileagePlanProfileServiceState,
  /**
   * Set status to loading
   */
  on(mileagePlanProfileSearch, (state) => ({
    ...state,
    mileagePlanProfileLookupCRUDStatus: Status.LOADING,
    mileagePlanProfileLookupStatus: null,
  })),
  /**
   * Add the mileage plan profile search response and set the status to stable
   */
  on(mileagePlanProfileSearchSuccess, (state, { response }) => ({
    ...state,
    mileagePlanProfileLookupResponse: mileagePlanProfileLookupAdapter.setAll([response.response], state.mileagePlanProfileLookupResponse),
    mileagePlanProfileLookupCRUDStatus: Status.STABLE,
    mileagePlanProfileLookupStatus: response.status,
    mileagePlanProfileLookupMappedMessageKeys: response.mappedMessageKeys,
  })),
  /**
   * Add the error status for the mileage plan profile search and set the status to stable
   */
  on(mileagePlanProfileSearchFailure, (state, { response }) => ({
    ...state,
    mileagePlanProfileLookupCRUDStatus: Status.STABLE,
    mileagePlanProfileLookupStatus: response.status,
    mileagePlanProfileLookupMappedMessageKeys: response.mappedMessageKeys,
  })),
  /**
   * Set the state to the initial value defined in the ticket-service.state file
   */
  on(initializeMileagePlanProfileServiceState, (state) => ({ ...state, ...initialMileagePlanProfileServiceState }))
);

/**
 * Typescript safe way to export the reducer so that it can be imported in modules
 */
export function mileagePlanProfileServiceReducer(state = initialMileagePlanProfileServiceState, action: Action) {
  return featureReducer(state, action);
}
