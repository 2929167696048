import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { FlightAvailabilityRequest } from '../../dtos/request/flight-availability-request/flight-availability-request';
import { AvailabilityOption } from '../../dtos/response/flight-availability-response/availability-option';
import { FlightAvailabilityResponse } from '../../dtos/response/flight-availability-response/flight-availability-response';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
export interface FlightAvailabilityServiceAPI {
  getFlightAvailability(request: FlightAvailabilityRequest): Observable<FlightAvailabilityResponse>;
}

@Injectable()
export class FlightAvailabilityService implements FlightAvailabilityServiceAPI {
  constructor(private http: HttpClient) {}

  getFlightAvailability(request: FlightAvailabilityRequest): Observable<FlightAvailabilityResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    const id = request.flightSegment.departureAirportCode + request.flightSegment.arrivalAirportCode;
    return this.http.post<AvailabilityOption[]>('api/flight/availability', request, options).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((availabilityOptions) => {
        return {
          id,
          availabilityOptions: availabilityOptions ?? [],
          success: true,
        };
      }),
      catchError((err: HttpErrorResponse) => {
        if (timeoutError.statusText === err.statusText) {
          return of({ id, success: false, error: 'Timeout' });
        } else if (err.status === 500) {
          return of({ id, success: false, error: 'System failure' });
        } else {
          return of({ id, success: false, error: 'Uncaught' });
        }
      })
    );
  }
}
