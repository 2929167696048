import { createAction } from '@ngrx/store';
import { messageStateFeatureKey } from './message.state';
import { Message } from 'src/app/models/message/message';
import { MessageKey } from 'src/app/models/message/message-key';
import { MessageType } from 'src/app/models/message/message-type';
import { RichMessage } from 'src/app/models/message/rich-message';
import { MessageStatus } from 'src/app/models/message/message-status';
import { MessageFilters } from 'src/app/models/message/message-filters';
import { MessageDetail } from '../../../models/message/MessageDetail';

export const initializeMessageState = createAction(`[${messageStateFeatureKey}] Initialize`);

export const addStackedMessage = createAction(
  `[${messageStateFeatureKey}] Add Stacked Message`,
  (key: MessageKey, messageArgs?: any[], messageFilters?: MessageFilters) => ({
    key,
    messageArgs,
    messageFilters
  })
);

export const addMessage = createAction(
  `[${messageStateFeatureKey}] Add Message`,
  (key: MessageKey, messageArgs?: any[]) => ({
    key,
    messageArgs
  })
);

/**
 * Add a stacked message if it won't duplicate an existing message on the stack
 */
export const addGlobalMessage = createAction(
  `[${messageStateFeatureKey}] Add Global Message`,
  (key: MessageKey, messageArgs?: any[], messageFilters?: MessageFilters) => {
    return {
      key,
      messageArgs,
      messageFilters
    };
  }
);

export const addStackedMessageNoDuplicate = createAction(
  `[${messageStateFeatureKey}] Add Message (No Duplicates)`,
  (key: MessageKey, messageArgs?: any[], messageFilters?: MessageFilters, messageDetails?: MessageDetail) => {
    return {
      key,
      messageArgs,
      messageFilters,
      messageDetails
    };
  }
);

export const addGlobalMessageSuccess = createAction(
  `[${messageStateFeatureKey}] Add Global Message Success`,
  (message: RichMessage) => ({
    message,
  })
);

export const addGlobalMessageFailure = createAction(
  `[${messageStateFeatureKey}] Add Global Message Failure`,
  (error: MessageStatus) => ({
    error,
  })
);

export const addStackedMessageSuccess = createAction(
  `[${messageStateFeatureKey}] Add Stacked Message Success`,
  (message: RichMessage) => ({
    message,
  })
);

export const addStackedMessageFailure = createAction(
  `[${messageStateFeatureKey}] Add Stacked Message Failure`,
  (error: MessageStatus) => ({
    error,
  })
);

export const addMessageSuccess = createAction(
  `[${messageStateFeatureKey}] Add Message Success`,
  (message: RichMessage) => ({
    message,
  })
);

export const addMessageFailure = createAction(
  `[${messageStateFeatureKey}] Add Message Failure`,
  (error: MessageStatus) => ({
    error,
  })
);

export const addMultipleJetStreamMessagesNoDuplicate = createAction(
  `[${messageStateFeatureKey}] Add Multiple JetStream Messages (No Duplicates)`,
  (keys: MessageKey[], messageArgs?: any[], messageFilters?: MessageFilters) => {
    return { keys, messageArgs, messageFilters };
  }
);

export const addGlobalJetStreamMessage = createAction(
  `[${messageStateFeatureKey}] Add global JetStream Message`,
  (key: MessageKey, messageArgs?: any[], messageFilters?: MessageFilters) => {
    return {
      key,
      messageArgs,
      messageFilters
    };
  }
);

export const removeGlobalJetStreamMessage = createAction(
  `[${messageStateFeatureKey}] Remove global JetStream Message`,
  (key: MessageKey) => ({
    key
  })
);

export const addJetStreamMessage = createAction(
  `[${messageStateFeatureKey}] Add JetStream Message`,
  (key: MessageKey, messageArgs?: any[], messageFilters?: MessageFilters) => {
    return {
      key,
      messageArgs,
      messageFilters
    };
  }
);

export const addJetStreamShortMessage = createAction(
  `[${messageStateFeatureKey}] Add JetStream Short Message`,
  (key: MessageKey, messageArgs?: any[], messageFilters?: MessageFilters) => {
    return {
      key,
      messageArgs,
      messageFilters
    };
  }
);

export const addGlobalJetStreamMessageComplete = createAction(
  `[${messageStateFeatureKey}] Add global JetStream Message Complete`,
  (messages: RichMessage[]) => ({
    messages,
  })
);

export const addJetStreamMessageComplete = createAction(
  `[${messageStateFeatureKey}] Add JetStream Message Complete`,
  (messages: RichMessage[]) => ({
    messages,
  })
);

export const addJetStreamShortMessageComplete = createAction(
  `[${messageStateFeatureKey}] Add JetStream Short Message Complete`,
  (messages: RichMessage[]) => ({
    messages,
  })
);

export const setCurrentGlobalMessageIndex = createAction(
  `[${messageStateFeatureKey}] Set Current Global Message Index`,
  (index: number) => ({
    index,
  })
);

export const setCurrentStackedLongMessageIndex = createAction(
  `[${messageStateFeatureKey}] Set Current Stacked Long Message Index`,
  (index: number) => ({
    index,
  })
);

export const setCurrentShortMessageIndex = createAction(
  `[${messageStateFeatureKey}] Set Current Short Message Index`,
  (index: number) => ({
    index,
  })
);

export const resetStackedLongMessages = createAction(`[${messageStateFeatureKey}] Reset Stacked Long Messages`);

export const resetCurrentStackedLongMessageIndex = createAction(`[${messageStateFeatureKey}] Reset Current Stacked Long Message Index`);

export const resetShortMessages = createAction(`[${messageStateFeatureKey}] Reset Short Messages`);

export const resetLongMessage = createAction(`[${messageStateFeatureKey}] Reset Long Message`);

export const resetJetStreamMessagesForPassenger = createAction(
  `[${messageStateFeatureKey}] Reset JetStream Messages for Passenger`,
  (passengerId: string) => ({
    passengerId,
  })
);

export const resetJetStreamMessagesForNonFiltered = createAction(`[${messageStateFeatureKey}] Reset JetStream Messages for non filtered messages`);

export const resetJetStreamShortMessages = createAction(`[${messageStateFeatureKey}] Reset JetStream Short Messages`);

export const resetGlobalJetStreamMessages = createAction(`[${messageStateFeatureKey}] Reset Global JetStream Messages`);

export const removeGlobalMessage = createAction(
  `[${messageStateFeatureKey}] Remove Global Message`,
  (message: Message) => ({
    message,
  })
);

export const removeStackedLongMessage = createAction(
  `[${messageStateFeatureKey}] Remove Stacked Long Message`,
  (message: Message) => ({
    message,
  })
);

export const removeShortMessage = createAction(
  `[${messageStateFeatureKey}] Remove Short Message`,
  (message: Message) => ({
    message,
  })
);

export const removeStackedLongMessagesByType = createAction(
  `[${messageStateFeatureKey}] Remove Stacked Long Messages By Type`,
  (messageType: MessageType) => ({
    messageType
  })
);

export const removeShortMessagesByType = createAction(
  `[${messageStateFeatureKey}] Remove Short Messages By Type`,
  (messageType: MessageType) => ({
    messageType
  })
);

export const removeMessageByKey = createAction(
  `[${messageStateFeatureKey}] Remove Message By Key`,
  (messageKey: MessageKey, segmentIndex?: number, passengerId?: string) => ({ messageKey, segmentIndex, passengerId })
);

export const removeMessagesByKeys = createAction(
  `[${messageStateFeatureKey}] Remove Messages By Keys`,
  (messageKeys: MessageKey[], segmentIndex?: number, passengerId?: string) => ({ messageKeys, segmentIndex, passengerId })
);

export const removeSegmentMessages = createAction(`[${messageStateFeatureKey}] Remove Segment Messages`, (segmentIndex: number) => ({
  segmentIndex,
}));
