import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CharacterLimitPipe } from './character-limit.pipe';
import { FilterPipe } from './filter.pipe';
import { SentenceCasePipe } from './sentence-case.pipe';
import { SortByFunctionPipe } from './sort-by-function.pipe';
import { SortByPipe } from './sort-by.pipe';

@NgModule({
  declarations: [SentenceCasePipe, CharacterLimitPipe, SortByPipe, SortByFunctionPipe, FilterPipe],
  imports: [CommonModule],
  exports: [SentenceCasePipe, CharacterLimitPipe, SortByPipe, SortByFunctionPipe, FilterPipe],
})
export class PipesModule {}
