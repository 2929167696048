/**
 * A partial list of SDC Rules that the eligibility service returns. Due to the dynamic nature
 * of that list this is not an exhaustive set of errors that can be returned. Only add values
 * to this list if you are overriding the message or have another need.
 */
export enum SdcRule {
  /**
   * No group reservations are allowed
   */
  NO_GROUPS = 'NO_GROUPS',

  /**
   * The reservation must contain at least one segment departing within the SDC window.
   */
  NO_ELIGIBLE_SEGMENT = 'NO_ELIGIBLE_SEGMENT',

  /**
   * The reservation must not be booked on a guest pass
   */
  NO_GUEST_PASSES = 'NO_GUEST_PASSES',

  /**
   * The reservation must not have any ineligible SSRs:
   * AVIH, DEPU, DEPA, MEDA, PNUT, PETC, STCR, TWOV, UMNR, OTHS with certain remarks
   */
  NO_INELIGIBLE_SSRS = 'NO_INELIGIBLE_SSRS',

  /**
   * All eligible flights must be booked in the Alaska Airlines Airgroup
   */
  ALL_AIRGROUP = 'ALL_AIRGROUP',

  /**
   * Reservation does not have a valid phone field
   *    Will fail reval/reissue without it
   */
  MISSING_PHONE_FIELD = 'MISSING_PHONE_FIELD',
}
