import { Action, createReducer, on } from '@ngrx/store';
import { Status } from '../../../models/status';
import {
  initializeOsiServiceState,
  addTcpOsi,
  addTcpOsiFullSuccess,
  addTcpOsiFullFailure,
  addTcpOsiPartialSuccess
} from './osi-service.actions';
import { initialOsiServiceState } from './osi-service.state';

const featureReducer = createReducer(
  initialOsiServiceState,
  on(initializeOsiServiceState, (state) => ({ ...state, ...initialOsiServiceState })),

  on(addTcpOsi, (state) => ({
    ...state,
    addOsiStatus: Status.UPDATING,
  })),
  /**
   * On addOsisFullSuccess, update osi status and the add osi
   */
  on(addTcpOsiFullSuccess, (state, { addOsiSuccess, addOsiStatus }) => ({
    ...state,
    addedOsiStatus: addOsiStatus,
    addOsiSuccess,
    addOsiStatus: Status.STABLE,
  })),
  /**
   * On addOsisPartialSuccess, update the add osi status and add the success/failure models
   */
  on(addTcpOsiPartialSuccess, (state, { addOsiFailure, addOsiSuccess, addOsiStatus }) => ({
    ...state,
    addedOsiStatus: addOsiStatus,
    addOsiFailure,
    addOsiSuccess,
    addOsiStatus: Status.STABLE,
  })),
  /**
   * On addOsisFullFailure, update the add osi status and add the failed SSRs
   */
  on(addTcpOsiFullFailure, (state, { addOsiStatus, addOsiFailure }) => ({
    ...state,
    addedOsiStatus: addOsiStatus,
    addOsiFailure,
    addOsiStatus: Status.STABLE,
  })),
);

export function osiServiceReducer(state = initialOsiServiceState, action: Action) {
  return featureReducer(state, action);
}
