import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReservationSearchHistoryState, reservationSearchHistoryAdapter, reservationSearchHistoryFeatureKey } from './reservation-search-history.state';

export const getReservationSearchHistoryState = createFeatureSelector<ReservationSearchHistoryState>(reservationSearchHistoryFeatureKey);

const { selectIds: confirmationCodes, selectEntities: reservationEntities } = reservationSearchHistoryAdapter.getSelectors();

export const getReservationsFromSearchHistory = createSelector(
    getReservationSearchHistoryState,
    reservationEntities
);

export const getConfirmationCodes = createSelector(
    getReservationSearchHistoryState,
    confirmationCodes
);

export const getPassengerNamesFromPnrHistory = (confirmationCode: string) => createSelector(
    getReservationsFromSearchHistory,
    reservations => reservations[confirmationCode].passengers.map(passenger => `${passenger.firstName} ${passenger.lastName}`)
);
