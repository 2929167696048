import { OthsKey } from 'src/app/models/ssr/oths-key';
import { SSRCode } from 'src/app/models/ssr/ssr-code';
import { SsrConfiguration } from 'src/app/models/ssr/ssr-configuration';
import { SsrFormOption } from 'src/app/models/ssr/ssr-form-option';
import { SsrHelpLink } from 'src/app/models/ssr/ssr-help-link';

export class SsrConfigurations {
  public static getSsrHelpLinks(ssrOption: string): SsrHelpLink[] {
    return this.getSsrConfiguration(ssrOption)?.helpLinks ?? ([] as SsrHelpLink[]);
  }

  public static getSsrHelpMessage(ssrOption: string): string | null {
    return this.getSsrConfiguration(ssrOption)?.helpMessage;
  }

  public static isRemarkEntryRequired(ssrOption: string): boolean {
    return this.getSsrConfiguration(ssrOption)?.isRemarkEntryRequired;
  }

  public static getSsrRemarkFormLabel(ssrOption: string): string {
    return this.getSsrConfiguration(ssrOption)?.ssrRemarkFormLabel ?? '';
  }

  public static getSsrFormOptions(ssrOption: string): SsrFormOption[] {
    return this.getSsrConfiguration(ssrOption)?.ssrFormOptions ?? ([] as SsrFormOption[]);
  }

  public static getSsrRoute(ssrOption: string): string {
    return this.getSsrConfiguration(ssrOption)?.route;
  }

  public static getSsrCode(ssrOption?: string): SSRCode {
    return this.getSsrConfiguration(ssrOption ?? '')?.ssrCode;
  }

  public static getSsrOptionFromRoute(route: string): string {
    return this.getSsrConfigurationFromRoute(route)?.ssrOption;
  }

  public static getSsrUrlFragment(ssrOption: string): string {
    return this.getSsrConfiguration(ssrOption)?.urlFragment.toLowerCase();
  }

  public static getSsrConfigurations(): SsrConfiguration[] {
    return this.ssrConfigurationData.sort((ssrFirst, ssrNext) => (ssrFirst.controlValue < ssrNext.controlValue ? -1 : 1));
  }

  public static getSsrConfigurationsWithHelpLinks(): SsrConfiguration[] {
    return this.getSsrConfigurations().filter((items) => items.helpLinks !== null);
  }

  private static getSsrConfiguration(ssrOption: string): SsrConfiguration {
    return this.ssrConfigurationData.find((items) => items.ssrOption === ssrOption) ?? ({} as SsrConfiguration);
  }

  private static getSsrConfigurationFromRoute(route: string): SsrConfiguration {
    return this.ssrConfigurationData.find((items) => items.route === route) ?? ({} as SsrConfiguration);
  }

  private static getHelpLink(id: string): SsrHelpLink {
    return this.helpLinksData.find((link) => link.id === id) ?? ({} as SsrHelpLink);
  }

  /*
        These are Help Message that are displayed on the SSR entry pages
        When new SSRs are added, either reuse an existing Help Message already found here
         or and a new Help Message as needed.
    */
  private static helpMessageAdviseToContactOtherAirline =
    'Advise guests traveling and/or connecting to another airline to confirm Special Service Request with the operating carrier directly.';
  private static helpMessageAddServiceAnimal =
    'If guest is traveling with a service animal please add appropriate special service request.';
  private static helpMessageMeetAndAssistPriority =
    'Meet and Assist SSR requests, are treated as courtesy requests for assistance in the airport. These requests are the very lowest priority.';
  private static helpMessageDisabledPersonNeedAssistanceNote =
    'The DPNA Service is provided for adults (age 18 and above). Adults with disabilities shall not use the UMNR Services for assistance.';
  private static helpMessageSecondSeat =
    "If a guest is adding a second seat with two PNRs, use IMAGE to add a 'To Complete Party' to both records.";
  private static helpMessageCabinBaggage =
    'If a guest is adding cabin baggage with two PNRs, use IMAGE to add a To Complete Party to both records.';
  private static helpMessageForeignLanguage =
    'Note: AS/QX do not offer translation services. However, we do provide escort assistance (MAAS) to individuals who need help to & from the aircraft and transport between gates.';
  private static helpMessagePersonalPortableOxygen = 'Portable Oxygen Concentrator model name is required. Add to remarks.';
  private static helpMessageArmedOfficerOrGuard =
    'Remember to link reservations using To complete party (TCP) in IMAGE after adding the Special Service Request.';
  private static helpMessagePetS =
    'Pets traveling as Pet in Cabin (PETC) must be stowed and fit under the seat during taxi, takeoff, and landing. Pets must remain completely enclosed in the kennel for the entire duration of the flight, including all appendages like the head and tail.<br><br>Animal in Hold (AVIH): Soft-sided and collapsible cabin kennels are not allowed in the aircraft hold as they do not comply with U.S. Department of Agriculture (USDA) regulations.';
  private static helpMessageTicketing = '';

  /*
   * This is a unique list of Help Links that are displayed on the SSR entry pages
   * When new SSRs are added, either reuse an existing Help Link already in this list
   * or and a new Help Link as needed.
   */
  private static helpLinksData: SsrHelpLink[] = [
    { id: 'airport-non-entry', text: 'Airport non-level entry information (DOT requirement)', href: 'https://www.alaskaair.com/content/travel-info/accessible-services/airport-accessibility', },
    { id: 'wheelchair-dimensions-mobility', text: 'Wheelchair dimensions/Mobility aides', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16182', },
    { id: 'junior-jetsetter-teen-assist', text: 'Junior Jetsetter/Teen assist', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16173', },
    { id: 'blind-deaf-impaired', text: 'Blind/Low Vision & Deaf/Hard of Hearing and/or Speech Impaired - CCM', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16185', },
    { id: 'acaa-services', text: 'ACAA Services - CCM', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16176', },
    { id: 'trained-service-animals', text: 'Trained Service Animals - CCM', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16419', },
    { id: 'service-animal-travel', text: 'Traveling with service animals', href: 'https://www.alaskaair.com/content/travel-info/accessible-services/specialservices-support-animals/', },
    { id: 'service-animal-changes', text: 'Service Animal Changes', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16176', },
    { id: 'cognitive-disabilities', text: 'Cognitive Disabilities - CCM', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16186', },
    { id: 'ccm-resources', text: 'Resources - CCM', href: 'https://alaskaair.sharepoint.com/sites/reservations', },
    { id: 'second-seat', text: 'Second seat (guest of size)', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16184', },
    { id: 'cabin-baggage', text: 'Cabin seat baggage', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16140', },
    { id: 'foreign-language', text: 'Voiance Language Line', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16314', },
    { id: 'medical-sitting', text: 'Medical Sitting', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16178#DOCUMENTATION', },
    { id: 'portable-oxygen-concentrator', text: 'Portable Oxygen Concentrator', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16180', },
    { id: 'medical-portable-electronic-devices', text: 'Medical Portable Electronic Devices (MPEDs)', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16177', },
    { id: 'guard-officer-or-prisoner-detainee', text: 'Guard / Officer or Prisoner / Detainee', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16175', },
    { id: 'ammunition-and-firearms', text: 'Ammunition and Firearms', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16145', },
    { id: 'fam-ffdo', text: 'FAM / FFDO', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16174', },
    { id: 'travel-on-oal', text: 'Travel on OAL', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16397', },
    { id: 'group-notifications-ccm', text: 'Group Notifications - CCM', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16534', },
    { id: 'international-inadmissible-passenger', text: 'International Inadmissible Passenger', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16252', },
    { id: 'group-notifications-ccm', text: 'Group Notifications - CCM', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16534', },
    { id: 'baggage-quick-reference', text: 'Baggage Quick Reference', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16165', },
    { id: 'sports-equipment-requirements', text: 'Sports Equipment Requirements', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16151#bic', },
    { id: 'checked-bag-restrictions', text: 'Checked Bag Restrictions', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16147', },
    { id: 'dangerous-goods', text: 'Dangerous Goods', href: 'https://www.faa.gov/hazmat', },
    { id: 'pet-in-cabin', text: 'PETC - Pet in Cabin', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16136', },
    { id: 'accepted-animals', text: 'Accepted Animals', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16127', },
    { id: 'avih-petc-travel-requirements', text: 'AVIH/PETC - Travel Requirements', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16451', },
    { id: 'animals-traveling-internationally', text: 'Animals Traveling Internationally', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16244', },
    { id: 'animal-in-hold', text: 'AVIH - Animal in the Hold', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16128', },
    { id: 'trained-service-animals', text: 'Trained Service Animals', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16419', },
    { id: 'service-animal-changes', text: 'Service Animal Changes', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16176', },
    { id: 'traveling-with-service-animals', text: 'Traveling with service animals', href: 'https://www.alaskaair.com/content/travel-info/accessible-services/specialservices-support-animals/', },
	  { id: 'ticketing', text: 'Ticketing', href: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16320' },
  ];

  /*
   * This is a set of SSR Configuration data
   * When new SSRs are added, edit this configuration data to add:

   *    The following configurations are applicable to all SSR and used primarily within the ssr-component:
   *    -ssrCode - Code used for this SSR
   *    -route - Route associated with this SSR
   *    -controlValue - SSR name which is to be displayed in the SSR dropdown control
   *    -splitName - if a Split is applicable to this SSR then enter the name, using null if no split is defined

   *    The following configurations are applicable to the SSR w/ Remarks ('Plain Vanilla' SSRs) only:
   *    -helpMessage - Help Message associated with this SSR, use null if this is not applicable
   *    -helpLinks - Help Links associated with this SSR, use null if this is not applicable
   *    -isRemarkEntryRequired - true or false as to where the Remarks field is required for this SSR
   */
  private static ssrConfigurationData: SsrConfiguration[] = [
    {
      ssrOption: SSRCode.BLND,
      ssrCode: SSRCode.BLND,
      route: 'blnd',
      urlFragment: 'blnds',
      controlValue: 'BLIND/LOW VISION',
      splitName: null,
      helpMessage: SsrConfigurations.helpMessageAddServiceAnimal,
      helpLinks: [
        SsrConfigurations.getHelpLink('blind-deaf-impaired'),
        SsrConfigurations.getHelpLink('acaa-services'),
        SsrConfigurations.getHelpLink('trained-service-animals'),
        SsrConfigurations.getHelpLink('service-animal-travel'),
      ],
      isRemarkEntryRequired: false,
      ssrRemarkFormLabel: 'Remarks (35 character limit)',
      ssrFormOptions: null,
    },
    {
      ssrOption: SSRCode.DEAF,
      ssrCode: SSRCode.DEAF,
      route: 'deaf',
      urlFragment: 'deafs',
      controlValue: 'DEAF/HARD OF HEARING',
      splitName: null,
      helpMessage: SsrConfigurations.helpMessageAddServiceAnimal,
      helpLinks: [
        SsrConfigurations.getHelpLink('blind-deaf-impaired'),
        SsrConfigurations.getHelpLink('acaa-services'),
        SsrConfigurations.getHelpLink('trained-service-animals'),
        SsrConfigurations.getHelpLink('service-animal-travel'),
      ],
      isRemarkEntryRequired: false,
      ssrRemarkFormLabel: 'Remarks (35 character limit)',
      ssrFormOptions: null,
    },
    {
      ssrOption: SSRCode.MAAS,
      ssrCode: SSRCode.MAAS,
      route: 'maas',
      urlFragment: 'maass',
      controlValue: 'MEET AND ASSIST',
      splitName: null,
      helpMessage: SsrConfigurations.helpMessageMeetAndAssistPriority,
      helpLinks: [SsrConfigurations.getHelpLink('cognitive-disabilities'), SsrConfigurations.getHelpLink('acaa-services')],
      isRemarkEntryRequired: true,
      ssrRemarkFormLabel: 'Remarks (35 character limit)',
      ssrFormOptions: null,
    },
    {
      ssrOption: SSRCode.WCHR,
      ssrCode: SSRCode.WCHR,
      route: 'wheelchair',
      urlFragment: 'wheelchairs',
      controlValue: 'WHEELCHAIR',
      splitName: null,
      helpMessage: SsrConfigurations.helpMessageAdviseToContactOtherAirline,
      helpLinks: null,
      isRemarkEntryRequired: false,
      ssrRemarkFormLabel: null,
      ssrFormOptions: null,
    },
    {
      ssrOption: OthsKey.TEEN,
      ssrCode: SSRCode.OTHS,
      route: 'oths-teen',
      urlFragment: 'oths/teen',
      controlValue: 'OTHS (Teens 13-17)',
      splitName: null,
      helpMessage: null,
      helpLinks: null,
      isRemarkEntryRequired: false,
      ssrRemarkFormLabel: null,
      ssrFormOptions: null,
    },
    {
      ssrOption: SSRCode.UMNR,
      ssrCode: SSRCode.UMNR,
      route: 'umnr',
      urlFragment: 'umnrs',
      controlValue: 'JUNIOR JETSETTER',
      splitName: null,
      helpMessage: null,
      helpLinks: null,
      isRemarkEntryRequired: false,
      ssrRemarkFormLabel: null,
      ssrFormOptions: null,
    },
    {
      ssrOption: SSRCode.SVAN,
      ssrCode: SSRCode.SVAN,
      route: 'svan',
      urlFragment: 'svans',
      controlValue: 'SERVICE ANIMAL',
      splitName: null,
      helpMessage: null,
      helpLinks: [
        SsrConfigurations.getHelpLink('trained-service-animals'),
        SsrConfigurations.getHelpLink('service-animal-changes'),
        SsrConfigurations.getHelpLink('traveling-with-service-animals'),
      ],
      isRemarkEntryRequired: false,
      ssrRemarkFormLabel: null,
      ssrFormOptions: null,
    },
    {
      ssrOption: SSRCode.DPNA,
      ssrCode: SSRCode.DPNA,
      route: 'dpna',
      urlFragment: 'dpnas',
      controlValue: 'DISABLED PERSON NEEDS ASSISTANCE',
      splitName: null,
      helpMessage: SsrConfigurations.helpMessageDisabledPersonNeedAssistanceNote,
      helpLinks: [
        SsrConfigurations.getHelpLink('ccm-resources'),
        SsrConfigurations.getHelpLink('cognitive-disabilities'),
        SsrConfigurations.getHelpLink('acaa-services'),
      ],
      isRemarkEntryRequired: true,
      ssrRemarkFormLabel: 'Remarks (35 character limit)',
      ssrFormOptions: null,
    },
    {
      ssrOption: SSRCode.EXST,
      ssrCode: SSRCode.EXST,
      route: 'exst',
      urlFragment: 'exsts',
      controlValue: 'EXTRA SEAT',
      splitName: null,
      helpMessage: SsrConfigurations.helpMessageSecondSeat,
      helpLinks: [SsrConfigurations.getHelpLink('second-seat')],
      isRemarkEntryRequired: false,
      ssrRemarkFormLabel: 'Remarks (35 character limit)',
      ssrFormOptions: null,
    },
    {
      ssrOption: SSRCode.CBBG,
      ssrCode: SSRCode.CBBG,
      route: 'cbbg',
      urlFragment: 'cbbgs',
      controlValue: 'CABIN BAGGAGE',
      splitName: null,
      helpMessage: SsrConfigurations.helpMessageCabinBaggage,
      helpLinks: [SsrConfigurations.getHelpLink('cabin-baggage')],
      isRemarkEntryRequired: true,
      ssrRemarkFormLabel: 'Remarks (35 character limit)',
      ssrFormOptions: null,
    },
    {
      ssrOption: SSRCode.LANG,
      ssrCode: SSRCode.LANG,
      route: 'lang',
      urlFragment: 'langs',
      controlValue: 'FOREIGN LANGUAGE',
      splitName: null,
      helpMessage: SsrConfigurations.helpMessageForeignLanguage,
      helpLinks: [SsrConfigurations.getHelpLink('foreign-language')],
      isRemarkEntryRequired: false,
      ssrRemarkFormLabel: 'Remarks (35 character limit)',
      ssrFormOptions: null,
    },
    {
      ssrOption: SSRCode.MEDA,
      ssrCode: SSRCode.MEDA,
      route: 'meda',
      urlFragment: 'medas',
      controlValue: 'MEDICAL ASSISTANCE',
      splitName: null,
      helpMessage: null,
      helpLinks: [SsrConfigurations.getHelpLink('acaa-services'), SsrConfigurations.getHelpLink('medical-sitting')],
      isRemarkEntryRequired: true,
      ssrRemarkFormLabel: 'Remarks (35 character limit)',
      ssrFormOptions: null,
    },
    {
      ssrOption: SSRCode.PPOC,
      ssrCode: SSRCode.PPOC,
      route: 'ppoc',
      urlFragment: 'ppocs',
      controlValue: 'PERSONAL PORTABLE OXYGEN',
      splitName: null,
      helpMessage: SsrConfigurations.helpMessagePersonalPortableOxygen,
      helpLinks: [
        SsrConfigurations.getHelpLink('portable-oxygen-concentrator'),
        SsrConfigurations.getHelpLink('medical-portable-electronic-devices'),
      ],
      isRemarkEntryRequired: true,
      ssrRemarkFormLabel: 'Remarks (35 character limit)',
      ssrFormOptions: null,
    },
    {
      ssrOption: OthsKey.ARMD,
      ssrCode: SSRCode.OTHS,
      route: 'oths-armd',
      urlFragment: 'oths/armds',
      controlValue: 'OTHS (Armed Officer)',
      splitName: null,
      helpMessage: SsrConfigurations.helpMessageArmedOfficerOrGuard,
      helpLinks: [
        SsrConfigurations.getHelpLink('guard-officer-or-prisoner-detainee'),
        SsrConfigurations.getHelpLink('ammunition-and-firearms'),
        SsrConfigurations.getHelpLink('fam-ffdo'),
      ],
      isRemarkEntryRequired: true,
      ssrRemarkFormLabel: 'Means of restraint (35 character limit)',
      ssrFormOptions: [
        { name: 'prisonerRisk', label: 'Prisoner risk', values: ['High', 'Low'] } as SsrFormOption,
        { name: 'escortType', label: 'Escort type', values: ['Armed', 'Unarmed'] } as SsrFormOption,
      ],
    },
    {
      ssrOption: SSRCode.GRPF,
      ssrCode: SSRCode.GRPF,
      route: 'grpf',
      urlFragment: 'grpfs',
      controlValue: 'OA GROUP FARE INFO',
      splitName: null,
      helpMessage: '',
      helpLinks: [SsrConfigurations.getHelpLink('travel-on-oal'), SsrConfigurations.getHelpLink('group-notifications-ccm')],
      isRemarkEntryRequired: false,
      ssrRemarkFormLabel: 'Remarks (35 character limit)',
      ssrFormOptions: null,
    },
    {
      ssrOption: SSRCode.GRPS,
      ssrCode: SSRCode.GRPS,
      route: 'grps',
      urlFragment: 'grpss',
      controlValue: 'OA PARTY TRAVELING TOGETHER',
      splitName: null,
      helpMessage: '',
      helpLinks: [SsrConfigurations.getHelpLink('travel-on-oal'), SsrConfigurations.getHelpLink('group-notifications-ccm')],
      isRemarkEntryRequired: false,
      ssrRemarkFormLabel: 'Remarks (35 character limit)',
      ssrFormOptions: null,
    },
    {
      ssrOption: SSRCode.DEPA,
      ssrCode: SSRCode.DEPA,
      route: 'depa',
      urlFragment: 'depas',
      controlValue: 'OA ACCOMPANIED DEPORTEE',
      splitName: null,
      helpMessage: '',
      helpLinks: [SsrConfigurations.getHelpLink('guard-officer-or-prisoner-detainee')],
      isRemarkEntryRequired: false,
      ssrRemarkFormLabel: 'Remarks (35 character limit)',
      ssrFormOptions: null,
    },
    {
      ssrOption: SSRCode.DEPU,
      ssrCode: SSRCode.DEPU,
      route: 'depu',
      urlFragment: 'depus',
      controlValue: 'OA UNACCOMPANIED DEPORTEE',
      splitName: null,
      helpMessage: '',
      helpLinks: [
        SsrConfigurations.getHelpLink('guard-officer-or-prisoner-detainee'),
        SsrConfigurations.getHelpLink('international-inadmissible-passenger'),
      ],
      isRemarkEntryRequired: false,
      ssrRemarkFormLabel: 'Remarks (35 character limit)',
      ssrFormOptions: null,
    },
    {
      ssrOption: SSRCode.BIKE,
      ssrCode: SSRCode.BIKE,
      route: 'bike',
      urlFragment: 'bikes',
      controlValue: 'OA BICYCLE',
      splitName: null,
      helpMessage: '',
      helpLinks: [SsrConfigurations.getHelpLink('baggage-quick-reference'), SsrConfigurations.getHelpLink('sports-equipment-requirements')],
      isRemarkEntryRequired: false,
      ssrRemarkFormLabel: 'Remarks (35 character limit)',
      ssrFormOptions: null,
    },
    {
      ssrOption: SSRCode.BULK,
      ssrCode: SSRCode.BULK,
      route: 'bulk',
      urlFragment: 'bulks',
      controlValue: 'OA BULKY BAGGAGE',
      splitName: null,
      helpMessage: null,
      helpLinks: null,
      isRemarkEntryRequired: true,
      ssrRemarkFormLabel: 'Remarks (35 character limit)',
      ssrFormOptions: null,
    },
    {
      ssrOption: SSRCode.FRAG,
      ssrCode: SSRCode.FRAG,
      route: 'frag',
      urlFragment: 'frags',
      controlValue: 'FRAGILE BAGGAGE',
      splitName: null,
      helpMessage: null,
      helpLinks: [
        SsrConfigurations.getHelpLink('baggage-quick-reference'),
        SsrConfigurations.getHelpLink('checked-bag-restrictions'),
        SsrConfigurations.getHelpLink('dangerous-goods'),
      ],
      isRemarkEntryRequired: false,
      ssrRemarkFormLabel: 'Remarks (35 character limit)',
      ssrFormOptions: null,
    },
    {
      ssrOption: SSRCode.SPEQ,
      ssrCode: SSRCode.SPEQ,
      route: 'speq',
      urlFragment: 'speqs',
      controlValue: 'OA SPECIAL SPORTS EQUIPMENT',
      splitName: null,
      helpMessage: null,
      helpLinks: [SsrConfigurations.getHelpLink('sports-equipment-requirements')],
      isRemarkEntryRequired: false,
      ssrRemarkFormLabel: 'Remarks (35 character limit)',
      ssrFormOptions: null,
    },
    {
      ssrOption: 'PETS',
      ssrCode: SSRCode.PETC,
      route: 'pets',
      urlFragment: 'pets',
      controlValue: 'PETS',
      splitName: 'RAIN_1023562_SSR_AVIH',
      helpMessage: SsrConfigurations.helpMessagePetS,
      helpLinks: [
        SsrConfigurations.getHelpLink('accepted-animals'),
        SsrConfigurations.getHelpLink('animals-traveling-internationally'),
        SsrConfigurations.getHelpLink('avih-petc-travel-requirements'),
        SsrConfigurations.getHelpLink('animal-in-hold'),
        SsrConfigurations.getHelpLink('pet-in-cabin'),
      ],
      isRemarkEntryRequired: false,
      ssrRemarkFormLabel: 'Remarks (35 character limit)',
      ssrFormOptions: null,
    },
    {
      ssrOption: SSRCode.TKTL,
      ssrCode: SSRCode.TKTL,
      route: 'tktl',
      urlFragment: 'tktls',
      controlValue: 'OA TICKET TIME LIMIT',
      splitName: null,
      helpMessage: SsrConfigurations.helpMessageTicketing,
      helpLinks: [SsrConfigurations.getHelpLink('ticketing')],
      isRemarkEntryRequired: false,
      ssrRemarkFormLabel: 'Remarks (35 character limit)',
      ssrFormOptions: null,
    },
  ];
}
