import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appSpaceOrEnterKeySelector]',
})
export class SpaceOrEnterKeySelectorDirective {
  @HostListener('keydown.space', ['$event'])
  @HostListener('keydown.enter', ['$event'])
  keydownSpaceOrEnter(event: any) {
    event.preventDefault();
    event.target.click();
  }
}
