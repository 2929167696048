<div *ngIf="authenticating; else error" class="auth-container">
  <h5>Authenticating</h5>
  <div class="loading-dot-wrapper">
    <app-loading-dots dotColor="white"></app-loading-dots>
  </div>
</div>
<ng-template #error>
  <div class="auth-container">
    <h5>An error occurred while authenticating. If the issue persists report it to Contact Center Technology.</h5>
  </div>
</ng-template>
