import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { bagsAndAncillariesWaivers } from '../../../dtos/response/bags-and-ancillaries-response/bag-and-ancillaries-waivers';
import { FeeInfo } from '../../../dtos/response/bags-and-ancillaries-response/fee-info';
import { Store, select } from '@ngrx/store';
import { RootState } from 'src/app/state/state';
import { Subject, takeUntil } from 'rxjs';
import { getBagAndAncillaryFeeMostSignificantCarrier } from 'src/app/services/fee-service/state/fee.selectors';
import { parseSize, parseWeight } from 'src/app/utils/baggage-parser';

@Component({
  selector: 'app-dynamic-baggage-row',
  templateUrl: './dynamic-baggage-row.component.html',
  styleUrls: ['./dynamic-baggage-row.component.scss'],
})
export class DynamicBaggageRowComponent implements OnInit, OnDestroy {
  @Input() fee: FeeInfo;
  @Input() bagCount: number;
  isOneworldWaiver = false;
  mostSignificantCarrier: string;
  private onDestroy$ = new Subject<void>();

  constructor(private store: Store<RootState>) { }

  ngOnInit(): void {
    this.store.pipe(select(getBagAndAncillaryFeeMostSignificantCarrier), takeUntil(this.onDestroy$)).subscribe((carrier) => {
      this.mostSignificantCarrier = carrier;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  /**
   * Pulls the weight values out of the description and formats them
   * Ex: Up to 50 lb/23 kg Up to 62 li/158 lcm
   */
  parseWeight(details: string): string {
    return parseWeight(details);
  }

  /**
   * Pulls the size values out of the description and formats them
   * Ex: Up to 50 lb/23 kg Up to 62 li/158 lcm
   */
  parseSize(details: string): string {
    return parseSize(details);
  }

  /**
   * Map waiver reasons coming out of the AirportAncillaryService/FDWS to formatted messages
   */
  getFormattedWaiverReason(waiverCode: string): string {
    switch (waiverCode) {
      // Cardholder waivers
      case bagsAndAncillariesWaivers.VisaSignaturePlatinum:
      case bagsAndAncillariesWaivers.VisaSmallBusiness:
      case bagsAndAncillariesWaivers.WorldEliteMastercard:
        return 'Alaska Airlines primary cardholder benefit is applied and waives 1 checked bag fee per guest in the same confirmation code (maximum 7 guests).';

      // Other airline waivers
      case bagsAndAncillariesWaivers.OAL:
      case bagsAndAncillariesWaivers.DeltaBaggageAgreement:
        return 'Another airline\'s checked baggage policy is applied.';

      // AS MP waivers
      case bagsAndAncillariesWaivers.MVP:
        return 'MVP Elite member benefit applied and waives the fees for up to 2 checked bags per guest in the same confirmation code.';
      case bagsAndAncillariesWaivers.Gold:
        return 'Gold Elite member benefit applied and waives the fees for up to 2 checked bags per guest in the same confirmation code.';
      case bagsAndAncillariesWaivers.Gold75K:
        return 'Gold75K Elite member benefit applied and waives the fees for up to 3 checked bags per guest in the same confirmation code.';
      case bagsAndAncillariesWaivers.Gold100K:
        return 'Gold100K Elite member benefit applied and waives the fees for up to 3 checked bags per guest in the same confirmation code.';

      // oneworld MP waivers
      case bagsAndAncillariesWaivers.OneworldRuby:
        // This flag add the oneworld naming with bolding
        this.isOneworldWaiver = true;
        return 'Ruby Elite member benefit applied and waives the fees for up to 2 checked bags. Companions of Ruby members are subject to Alaska\'s standard baggage fees.';
      case bagsAndAncillariesWaivers.OneworldSapphire:
        // This flag add the oneworld naming with bolding
        this.isOneworldWaiver = true;
        return 'Sapphire Elite member benefit applied and waives the fees for up to 2 checked bags. Companions of Sapphire members are subject to Alaska\'s standard baggage fees.';
      case bagsAndAncillariesWaivers.OneworldEmerald:
        // This flag add the oneworld naming with bolding
        this.isOneworldWaiver = true;
        return 'Emerald Elite member benefit applied and waives the fees for up to 3 checked bags. Companions of Emerald members are subject to Alaska\'s standard baggage fees.';

      // First class waivers
      case bagsAndAncillariesWaivers.FirstClass:
        return 'First Class benefit applied and waives the fees for up to 2 checked bags per guest in the same confirmation code.';

      // Intra AK waivers
      case bagsAndAncillariesWaivers.IntraAlaska:
        return 'Intra-Alaska benefit applied. All travel on the itinerary is within the State of Alaska and waives the fees for up to 4 checked bags in the same confirmation code.';
      case bagsAndAncillariesWaivers.Club49:
        return 'Club49 member benefit applied and waives the fees for up to 2 checked bags per guest in the same confirmation code.';

      // Military waivers
      case bagsAndAncillariesWaivers.Military:
        return 'US military waiver applied and waives the fees for up to 5 checked bags. At the airport on day of departure, check-in at the ticket counter with a valid US military ID.';

      // Manual agent waivers
      case bagsAndAncillariesWaivers.AgentWaiver:
        return 'Agent applied a checked baggage wavier.';

      // AA waivers
      case bagsAndAncillariesWaivers.AAExecutive:
        return 'American Airlines Executive Elite member benefit is applied.';
      case bagsAndAncillariesWaivers.AAConciergeKey:
        return 'American Airlines ConciergeKey Elite member benefit is applied.';

      // Default waiver message
      default:
        if (waiverCode) {
          return 'Baggage waiver reason unavailable. Visit Checked Baggage CCM page to learn more.';
        } else if (!waiverCode && this.mostSignificantCarrier === 'Alaska Airlines') {
          return 'Alaska Airlines standard checked baggage policy is applied.';
        } else {
          return `Another airline's checked baggage policy is applied.`;
        }
    }
  }
}
