import { RootState } from '../state';

export const mileagePlanProfileSearchFeatureKey = 'mileagePlanProfileSearchState';

export interface State extends RootState {
  mileagePlanProfileSearch: MileagePlanProfileSearchState;
}

export interface MileagePlanProfileSearchState {
  mileagePlanNumber: string | null;
}

export const initialMileagePlanProfileSearchState: MileagePlanProfileSearchState = {
  mileagePlanNumber: null,
};
