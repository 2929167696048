import { Passenger } from '../../../dtos/response/reservation-response/passenger';
import { RootState } from '../../../state/state';

export const seatMapFeatureKey = 'seatMapFeature';

export interface State extends RootState {
  seatMapMessages: SeatMapState;
}

export interface SeatMapState {
  currentSegmentIndex: number | null;
  currentSegmentHashId: string | null;
  selectedPassenger: Passenger | null;
}

export const initialSeatMapState: SeatMapState = {
  currentSegmentIndex: null,
  currentSegmentHashId: null,
  selectedPassenger: null
};
