import { TierStatus } from '../models/tier-status';

export function getTierStatusFromText(tierStatusName: string): TierStatus {
  switch (tierStatusName) {
    // MileagePlanUI returns 'Ruby', 'Sapphire', and 'Emerald'
    case 'MVP Gold 100K':
    case 'Alaska Gold 100K':
      return TierStatus.GOLD_100K;
    case 'MVP Gold 75K':
    case 'Alaska Gold 75K':
      return TierStatus.GOLD_75K;
    case 'Gold':
    case 'Alaska Gold':
      return TierStatus.GOLD;
    case 'MVP':
    case 'Alaska MVP':
      return TierStatus.MVP;
    case 'EMD':
    case 'Oneworld Emerald':
    case 'Emerald':
      return TierStatus.EMD;
    case 'SPH':
    case 'Oneworld Sapphire':
    case 'Sapphire':
      return TierStatus.SPH;
    case 'RBY':
    case 'Oneworld Ruby':
    case 'Ruby':
      return TierStatus.RBY;
    default:
      return TierStatus.STANDARD;
  }
}

export function mapMileagePlanLookupTierStatus(tierStatus: string): string {
  const tierStatusMap = {
    MVP: 'MVP',
    'MVP Gold': 'Gold',
    'MVP Gold 75K': 'MVP Gold 75K',
    'MVP Gold 100K': 'MVP Gold 100K'
  };

  return tierStatusMap[tierStatus] || '';
}

export function mapMileagePlanLookupOneWorldTierStatus(oneWorldTier: string): string {
  const oneWorldTierMap = {
    RUBY: TierStatus.RBY,
    SAPPHIRE: TierStatus.SPH,
    EMERALD: TierStatus.EMD
  };

  return oneWorldTierMap[oneWorldTier.toUpperCase()] || '';
}

export function mapPassengerLoyaltyToSeatMapRequestLoyalty(tierStatus: string): string {
  switch (tierStatus) {
    case 'MVP':
    case 'Gold':
      return tierStatus;
    case 'MVP Gold 75K':
      return 'Gold75K';
    case 'MVP Gold 100K':
      return 'Gold100K';
    case 'EMD':
    case 'SPH':
    case 'RBY':
      return 'AA_' + tierStatus;
    default:
      return 'Unknown';
  }
}
