import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { RootState } from '../../../state/state';
import { removeReservationFromSearchHistory } from '../../../services/reservation-search-history-service/state/reservation-search-history.actions';
import { Subject, takeUntil } from 'rxjs';
import { getConfirmationCodes } from '../../../services/reservation-search-history-service/state/reservation-search-history.selectors';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recent-confirmation-code-tile',
  templateUrl: './recent-confirmation-code-tile.component.html',
  styleUrls: ['./recent-confirmation-code-tile.component.scss'],
})
export class RecentConfirmationCodeTileComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() confirmationCode: string;
  @Input() isActive: boolean;
  @Input() passengerNames: string;
  @Input() tileClasses: string;
  confirmationCodes: string[] | number[];
  onlySingleConfirmationCode = false;
  isHovered = false;
  private onDestroy$ = new Subject<void>();

  constructor(private store: Store<RootState>, private router: Router) {}

  ngOnInit(): void {
    this.store.pipe(select(getConfirmationCodes), takeUntil(this.onDestroy$)).subscribe((confirmationCodes) => {
      this.confirmationCodes = confirmationCodes;
      this.onlySingleConfirmationCode = confirmationCodes?.length === 1 ? true : false;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  onHover() {
    this.isHovered = true;
  }

  onLeave() {
    this.isHovered = false;
  }

  removeConfirmationCode() {
    // Confirmation codes is in a reversed order.
    // If you close the first confirmation code and it is active,
    // you should be redirected to the last confirmation code you looked up.
    if (this.confirmationCode === this.confirmationCodes[this.confirmationCodes.length - 1] && this.isActive) {
      this.router.navigate(['reservation', this.confirmationCodes[this.confirmationCodes.length - 2]]);
    }
    // If you close the last/middle confirmation code and it is active,
    // you should be redirected to the last confirmation code you looked up.
    else if (this.isActive) {
      this.router.navigate(['reservation', this.confirmationCodes[this.confirmationCodes.length - 1]]);
    }
    this.store.dispatch(removeReservationFromSearchHistory(this.confirmationCode));
  }
}
