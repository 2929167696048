<div [hidden]="!message" class="message-bar {{ message?.type }}" data-testid="stacked-long-message-bar">
  <div class="d-flex justify-content-between message-bar-container">
    <div class="d-flex message-container">
      <div *ngIf="messageCount > 1" class="previous" (click)="previous(global ? currentGlobalIndex : currentLongMessageIndex)">
        <auro-icon class="arrow-icon"
          category="interface"
          name="chevron-left"
          customColor
          [ngClass]="{ 'error': isErrorMessage }"
          [attr.customSize]="true"
          style="height: 32px; width: 32px; margin-top: -6px;"></auro-icon>
      </div>
      <div class="count" *ngIf="messageCount > 1">{{ global ? currentGlobalIndex + 1 : currentLongMessageIndex + 1 }} / {{ messageCount }}</div>
      <div *ngIf="messageCount > 1" class="next" (click)="next(global ? currentGlobalIndex : currentLongMessageIndex)">
        <auro-icon class="arrow-icon"
          category="interface"
          name="chevron-right"
          customColor
          [ngClass]="{ 'error': isErrorMessage }"
          [attr.customSize]="true"
          style="height: 32px; width: 32px; margin-top: -6px; margin-left: -10px;"></auro-icon>
      </div>
      <div class="text">
        <div class="text-table">
          <div class="text-table-cell">
            <span #content class="message-content"></span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="message?.closable" class="close {{ message?.type }}" (click)="closeBar(message)">
      <span class="close-icon" [inlineSVG]="'/assets/icon-x.svg'"></span>
    </div>
  </div>
</div>
