import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Passenger } from '../../../dtos/response/reservation-response/passenger';
import { Segment } from '../../../dtos/response/reservation-response/segment';
import {
  getAllSegments,
  getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodes,
  getPassengerSeatRowAndLetter,
  getPassengers,
  getPassengersAndExtraSeatPassengers,
  isChangedSeat,
} from '../../../services/reservation-service/state/reservation-service.selectors';
import { seatMapFeatureKey, SeatMapState } from './seat-map.state';
import { AssignSeatsRequestSegment } from '../../../dtos/request/assign-seats/assign-seats-request-segment';
import { SeatAssignment } from '../../../dtos/request/assign-seats/assign-seats-request-seats';
import { PassengerSeat } from '../../../dtos/response/reservation-response/passenger-seat';

const getSeatMapMessageState = createFeatureSelector<SeatMapState>(seatMapFeatureKey);
export const getCurrentSegmentIndex = createSelector(getSeatMapMessageState, (state) => state?.currentSegmentIndex);
export const getCurrentSegmentHash = createSelector(getSeatMapMessageState, (state) => state?.currentSegmentHashId);

/**
 * Get selected passenger stored in state
 */
export const getSelectedPassenger = createSelector(getSeatMapMessageState, (state): Passenger | null => state.selectedPassenger);
export const getSelectedPassengerHashId = createSelector(getSelectedPassenger, (passenger): string | null => passenger?.hashId ?? null);
export const getSelectedPassengerId = createSelector(getSelectedPassenger, (passenger): string | null => passenger?.id ?? null);

/**
 * Get the reservation segment data for the curren seat map segment
 */
export const getCurrentSeatMapSegment = createSelector(
  getCurrentSegmentIndex,
  getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodes,
  (currentSegmentIndex: number, segments: Segment[]) => {
    return !segments || segments[currentSegmentIndex] === undefined || segments[currentSegmentIndex] === null
      ? null
      : segments[currentSegmentIndex];
  }
);

/**
 * Returns true if the current seat map segment is main cabin, false otherwise
 */
export const getIsCurrentSeatMapMainCabin = createSelector(
  getAllSegments,
  getCurrentSegmentHash,
  (segments: Segment[], segmentHashId: string): boolean =>
    segments
      ?.find((segment) => segment.hashId === segmentHashId)
      ?.seatArea?.toLowerCase()
      .includes('main') ?? false
);

export const getHasSegmentSeatChanges = createSelector(
  getPassengers,
  getCurrentSegmentHash,
  (passengers: Passenger[], segmentHashId: string): boolean => {
    return passengers.some((pax) => {
      const currentSeat = pax.seats.find((seat) => seat.segmentHashId === segmentHashId);
      const originalSeat = pax.originalSeats.find((seat) => seat.segmentHashId === segmentHashId);
      return isChangedSeat(currentSeat, originalSeat);
    });
  }
);

export const getSeatAssignmentDiff = createSelector(
  getHasSegmentSeatChanges,
  getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodes,
  getCurrentSegmentHash,
  getCurrentSegmentIndex,
  getPassengersAndExtraSeatPassengers,
  (
    hasSeatChanges: boolean,
    segments: Segment[],
    segmentHashId: string,
    segmentIndex: number,
    passengers: Passenger[]
  ): AssignSeatsRequestSegment[] | null => {
    if (!hasSeatChanges) {
      return null;
    }
    const currentSegment = segments.find((segment) => segment.hashId === segmentHashId);
    const passengerSeatChanges: SeatAssignment[] = [];
    passengers.forEach((pax) => {
      const currentSeat = pax.seats.find((seat) => seat.segmentHashId === segmentHashId);
      let originalSeat = pax.originalSeats.find((seat) => seat.segmentHashId === segmentHashId);
      if (!originalSeat) {
        originalSeat = pax?.originalSeats[segmentIndex];
      }
      if (currentSeat && originalSeat && isChangedSeat(currentSeat, originalSeat)) {
        passengerSeatChanges.push({
          id: pax.id,
          oldSeat: getPassengerSeatRowAndLetter(originalSeat).replace('-', ''),
          seat: getPassengerSeatRowAndLetter(currentSeat).replace('-', ''),
        });
      }
    });
    return [
      {
        flightNumber: currentSegment?.operatingAirlineFlightNumber,
        passengers: passengerSeatChanges,
        sequence: currentSegment?.sequence,
        changeOfGauge: currentSegment?.changeOfGauge,
      },
    ];
  }
);

/**
 * Gets the current seat text for a provided passenger and segment. (i.e. 16A)
 * Includes any extra seat passenger seats (i.e. '16A 16B')
 * If there is no seat assigned, returns 'Unchanged'
 */
export const getPassengerCurrentSeatText = (passengerHashId: string, segmentHashId: string) =>
  createSelector(getPassengers, getCurrentSegmentIndex, (passengers: Passenger[], segmentIndex: number): string => {
    let seatText = '-';
    const passenger: Passenger | undefined = passengers.find((pax) => pax.hashId === passengerHashId);
    const currentSeat: PassengerSeat | undefined = passenger?.seats.find((seat) => seat.segmentHashId === segmentHashId);
    let originalSeat: PassengerSeat | undefined = passenger?.originalSeats.find((seat) => seat.segmentHashId === segmentHashId);
    if (!originalSeat) {
      originalSeat = passenger?.originalSeats[segmentIndex];
    }
    if (passenger && currentSeat && originalSeat) {
      seatText = seatText.concat(getPassengerSeatRowAndLetter(currentSeat)).replace('-', '');
      passenger.extraSeatRefs?.forEach((extraSeatRef) => {
        const extraSeatRefCurrentSeat = extraSeatRef.seats.find((seat) => seat.segmentHashId === segmentHashId);
        if (extraSeatRefCurrentSeat) {
          seatText = seatText.concat(' ' + getPassengerSeatRowAndLetter(extraSeatRefCurrentSeat)).replace('-', '');
        }
      });
    }
    if (originalSeat && currentSeat && getPassengerSeatRowAndLetter(currentSeat) === getPassengerSeatRowAndLetter(originalSeat)) {
      return 'Unchanged';
    }
    return seatText;
  });

/**
 * Gets the original seat text for a provided passenger and segment. (i.e. '16A')
 * Includes any extra seat passenger seats (i.e. '16A 16B')
 * If there is no seat assigned, returns '-'
 */
export const getPassengerOriginalSeatText = (passengerHashId: string, segmentHashId: string) =>
  createSelector(getPassengers, (passengers: Passenger[]): string => {
    let seatText = '-';
    const passenger: Passenger | undefined = passengers.find((pax) => pax.hashId === passengerHashId);
    const originalSeat: PassengerSeat | undefined = passenger?.originalSeats.find((seat) => seat.segmentHashId === segmentHashId);
    if (passenger && originalSeat) {
      if (getPassengerSeatRowAndLetter(originalSeat) === '-') {
        return '-';
      }
      seatText = seatText.concat(getPassengerSeatRowAndLetter(originalSeat)).replace('-', '');
      passenger.extraSeatRefs?.forEach((extraSeatRef) => {
        const extraSeatRefOriginalSeat = extraSeatRef.originalSeats.find((seat) => seat.segmentHashId === segmentHashId);
        if (extraSeatRefOriginalSeat) {
          seatText = seatText.concat(' ' + getPassengerSeatRowAndLetter(extraSeatRefOriginalSeat)).replace('-', '');
        }
      });
    }
    return seatText;
  });
