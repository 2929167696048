import { AdvisoriesResponse } from 'src/app/dtos/response/advisories-response/advisories-response';
import { RootState } from '../../../state/state';
import { AdvisoriesLookupStatus } from 'src/app/dtos/response/advisories-response/advisories-lookup-status';

export const advisoriesServiceFeatureKey = 'advisoriesService';

export interface State extends RootState {
  advisoriesService: AdvisoriesServiceState;
}

export interface AdvisoriesServiceState {
    advisories: AdvisoriesResponse | null;
}

export const initialAdvisoriesServiceState: AdvisoriesServiceState = {
  advisories: {
    advisories: null,
    lookupStatus: AdvisoriesLookupStatus.UNKNOWN
  },
};
