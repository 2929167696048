/**
 * This enum is intended to provide split functionality in the mock ticket service getVcr endpoint
 * allowing the mock service to handle different types of responses
 */
export enum GetVcrType {
  /**
   * The getVcr is being called for a reservation, this means the mock service will attempt to find the reservation
   * in seatMapSession.reservation
   */
  RESERVATION,
  /**
   * The getVcr is being called for a manual association, this means the mock service will build coupons based on ticket numbers
   */
  MANUAL_ASSOCIATION,
}
