import { ActionCode } from './action-codes';

export const scheduleChangeActionCodes: ReadonlyArray<ActionCode> = [
  ActionCode.SC,
  ActionCode.WK,
  ActionCode.TK,
  ActionCode.UN,
  ActionCode.UC,
  ActionCode.HK,
];
