export enum ItineraryErrorCode {
  SYSTEM_FAILURE = 'system failure',
  CHANGE_ITINERARY = 'change itinerary',
  INVALID_DEPARTURE_DATE = 'invalid departure date',
  INVALID_CONNECTION_TIME = 'invalid connection time',
  RETRIEVE_FULL_TICKETS = 'retrieve full tickets',
  GENERATE_REQUEST = 'generate request',
  RETRIEVE_RESERVATION = 'retrieve reservation',
  REVALIDATE_TICKET = 'revalidate ticket',
  REISSUE_TICKET = 'reissue ticket',
  RETRIEVE_COUPONS = 'retrieve coupons',
  ASSOCIATE_NEW_TICKETS = 'associate new tickets',
  TIMEOUT = 'timeout',
  PASSWORD_DECRYPTION_ERROR = 'password decryption error',
  ADD_OSI = 'add osi'
}
