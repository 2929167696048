import { resultMemoize } from '@ngrx/store';
import { AnyFn } from '@ngrx/store/src/selector';

/**
 * Given two arrays of simple types (numbers, string, enums, etc NOT objects), check if the lengths match
 * and if so check if each element of the first array appears in the second array.
 * While this function is generic and will work for comparing array equality in general it is intended to be used as a custom
 * memoizer for NGRX selectors.
 */
export function areArraysEqual(a: any[], b: any[]): boolean {
  return (a && b && a.length === b.length && a.every((fromA) => b.includes(fromA))) ?? false;
}

/**
 * Provide memoization of arrays with non-object data types
 */
export const arrayMemoize = (projectorFn: AnyFn) => resultMemoize(projectorFn, areArraysEqual);
