import { EventEmitter, Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from '../../models/auth.config';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * emits when a new token is received
   */
  onTokenReceived: EventEmitter<void> = new EventEmitter<void>();
  onError: EventEmitter<void> = new EventEmitter<void>();

  constructor(private oAuthService: OAuthService) {}

  /**
   * configures OAuthService and triggers authentication process
   */
  onInit(): void {
    this.subscribeToEvents();
    this.oAuthService.configure(authConfig);
    this.oAuthService.setStorage(localStorage); // this is deprecated but the documented method was not working
    this.oAuthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      if (!this.hasValidAccessToken()) {
        this.oAuthService.initCodeFlow();
      }
    });
    this.oAuthService.setupAutomaticSilentRefresh();
  }

  /**
   * returns the current access_token
   */
  public getAccessToken(): string {
    return this.oAuthService.getAccessToken();
  }

  /**
   * checks if the current access_token is valid
   */
  public hasValidAccessToken(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

  /**
   * subscribe to OAuthService events and then translate and emit them from the Auth Service
   */
   private subscribeToEvents() {
    this.oAuthService.events.subscribe((event) => {
      if (event.type === 'token_received') {
        this.onTokenReceived.emit();
      }
      if (event.type.includes('_error')) {
        this.onError.emit();
      }
    });
  }
}
