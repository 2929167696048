import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FlightAvailabilityResponse } from '../../../dtos/response/flight-availability-response/flight-availability-response';
import { FlightSegment } from '../../../dtos/response/flight-availability-response/flight-segment';
import { ShoppingSegment } from '../../../dtos/request/shopping-request/shopping-segment';
import { FlightChangeForm } from '../../../models/flight-change-form/flight-change-form';
import { Status } from '../../../models/status';
import { RootState } from '../../../state/state';

/**
 * The string name of the flight availability service "slice" of state
 */
export const flightAvailabilityServiceFeatureKey = 'flightAvailabilityService';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  flightAvailabilityService: FlightAvailabilityServiceState;
}

/**
 * The state that is used in relation to flight availability service, it is an extension of root state
 * these values are available in and should be accessed from the root state model
 */
export interface FlightAvailabilityServiceState {
  flightAvailability: FlightAvailabilityEntity;
  departureAirportCode: string;
  arrivalAirportCode: string;
  changedFlights: FlightChangeForm | undefined;
  currentFlightAvailabilityIndex: number;
  status: Status;
  error: string;
  seatMapViewSegment: FlightSegment | undefined;
  seatMapViewSegmentForSameDayConfirm: ShoppingSegment | undefined;
}

/**
 * Interface for storing flight availability search result objects as an ordered array of ids and an unordered dictionary
 */
export interface FlightAvailabilityEntity extends EntityState<FlightAvailabilityResponse> {}

/**
 * NGRX provided adapter for interfacing with entities (modifying and selecting), this adapter is for the flight availability entity
 */
export const flightAvailabilityAdapter: EntityAdapter<FlightAvailabilityResponse> = createEntityAdapter<FlightAvailabilityResponse>({});

/**
 * Initial state of the search results entity as defined by the search results adapter
 */
export const initialFlightAvailabilityState: FlightAvailabilityEntity = flightAvailabilityAdapter.getInitialState({});

/**
 * Initial state of the flight availability service slice of state, used when flight availability service state is
 * bootstrapped via the reducer in an app module also used when state is reset
 */
export const initialFlightAvailabilityServiceState: FlightAvailabilityServiceState = {
  flightAvailability: initialFlightAvailabilityState,
  departureAirportCode: '',
  arrivalAirportCode: '',
  changedFlights: undefined,
  currentFlightAvailabilityIndex: 0,
  status: Status.STABLE,
  error: '',
  seatMapViewSegment: undefined,
  seatMapViewSegmentForSameDayConfirm: undefined
};
