import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { getHasReviewedSummary, getPaymentReceived } from '../models/state/model.selectors';
import { getTotalDue } from '../shared/purchase/state/purchase.selectors';
import { TicketValidationResult } from '../models/ticket-validation-result';
import { RootState } from '../state/state';
import { TicketValidationUseCase } from '../use-cases/ticket-validation.use-case';

@Injectable({
  providedIn: 'root',
})
export class PaymentGuard  {
  constructor(private store: Store<RootState>, private ticketValidationUseCase: TicketValidationUseCase) {}

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.ticketValidationUseCase.canPurchaseTickets().pipe(
      withLatestFrom(
        this.store.pipe(select(getTotalDue)),
        this.store.pipe(select(getPaymentReceived)),
        this.store.pipe(select(getHasReviewedSummary))
      ),
      map(
        ([ticketValidationResult, totalDue, paymentReceived, hasReviewedSummary]) =>
          ticketValidationResult === TicketValidationResult.VALID && !paymentReceived && !!totalDue && hasReviewedSummary
      )
    );
  }
}
