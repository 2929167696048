import { ZipLookupResponse } from 'src/app/dtos/response/location-lookup-response/zip-lookup-response';
import { Status } from 'src/app/models/status';
import { RootState } from '../../../state/state';

export const zipLookupFeatureKey = 'zipLookup';

export interface State extends RootState {
  lookupState: ZipLookupState;
}

export interface ZipLookupState {
  zipLookupResponse?: ZipLookupResponse | null;
  status: Status;
}

export const initialzipLookupEntityState: ZipLookupState = {
  zipLookupResponse: null,
  status: Status.STABLE
};
