import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { PipesModule } from '../../pipes/pipes.module';
import { UXModule } from '../ux/ux.module';
import { PassengerAlaskaBankCardComponent } from './passenger-alaska-bank-card/passenger-alaska-bank-card.component';
import { PassengerCouponStatusComponent } from './passenger-coupon-status/passenger-coupon-status.component';
import { PassengerCreditCardIconComponent } from './passenger-credit-card-icon/passenger-credit-card-icon.component';
import { PassengerLoyaltyIconComponent } from './passenger-loyalty-icon/passenger-loyalty-icon.component';
import { PassengerLoyaltyNumberComponent } from './passenger-loyalty-number/passenger-loyalty-number.component';
import { PassengerNameComponent } from './passenger-name/passenger-name.component';
import { PassengerSsrActionComponent } from './passenger-ssr-action/passenger-ssr-action.component';
import { PassengerSsrCodeComponent } from './passenger-ssr-code/passenger-ssr-code.component';
import { PassengerSsrComponent } from './passenger-ssr/passenger-ssr.component';

@NgModule({
  declarations: [
    PassengerLoyaltyNumberComponent,
    PassengerLoyaltyIconComponent,
    PassengerNameComponent,
    PassengerCouponStatusComponent,
    PassengerSsrCodeComponent,
    PassengerSsrActionComponent,
    PassengerSsrComponent,
    PassengerAlaskaBankCardComponent,
    PassengerCreditCardIconComponent,
  ],
  imports: [CommonModule, InlineSVGModule, UXModule, RouterModule, PipesModule],
  exports: [
    PassengerLoyaltyNumberComponent,
    PassengerLoyaltyIconComponent,
    PassengerNameComponent,
    PassengerCouponStatusComponent,
    PassengerSsrCodeComponent,
    PassengerSsrActionComponent,
    PassengerSsrComponent,
    PassengerAlaskaBankCardComponent,
    PassengerCreditCardIconComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class UXPassengersModule {}
