import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EMDTicketDetail } from '../../../dtos/response/emd-ticket-detail-response/emd-ticket-detail';
import { GDSTicketsCloudModelsResponsesTicketDetail } from '../../../dtos/response/gds-ticket-cloud-response/gDSTicketsCloudModelsResponsesTicketDetail';
import { TicketDetailsError } from '../../../dtos/response/ticket-detail-response/ticket-detail-error';
import { TicketSearchError } from '../../../dtos/response/ticket-search-response/ticket-search-error';
import { CouponStatusData } from '../../../dtos/response/vcr-response/coupon-status-data';
import { VcrStatus } from '../../../dtos/response/vcr-response/vcr-status';
import { Status } from '../../../models/status';
import { TicketSearchResultRow } from '../../../models/ticket-search-result-row';
import { RootState } from '../../../state/state';

/**
 * The string name of the ticket service "slice" of state
 */
export const ticketServiceFeatureKey = 'ticketService';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  ticketService: TicketServiceState;
}

/**
 * The state that is used in relation to ticket service, it is an extension of root state
 * these values are available in and should be accessed from the root state model
 */
export interface TicketServiceState {
  coupons: VcrCouponEntity;
  status: VcrStatus;
  vcrLoading: boolean;
  searchResults: TicketSearchResultEntity;
  searchStatus: Status;
  searchError: TicketSearchError;
  searchStartTime: number;
  EMDs: EMDEntity;
  ticketDetails: TicketDetailsEntity;
  ticketDetailsError: TicketDetailsError;
  ticketDetailsStatus: Status;
  ancillaryTicketDetails: AncillaryTicketDetailsEntity;
  ancillaryTicketDetailsError: TicketDetailsError;
  ancillaryTicketDetailsStatus: Status;
}

/**
 * Interface for storing coupon objects as an ordered array of ids and an unordered dictionary
 */
export interface VcrCouponEntity extends EntityState<CouponStatusData> {}

/**
 * NGRX provided adapter for interfacing with entities (modifying and selecting), this adapter is for the coupon entity
 */
export const couponAdapter: EntityAdapter<CouponStatusData> = createEntityAdapter<CouponStatusData>({
  selectId: (response) => response.ticketNumber + response.couponNumber,
});

/**
 * Initial state of the coupon entity as defined by the coupon adapter
 */
export const initialCouponState: VcrCouponEntity = couponAdapter.getInitialState({});

/**
 * Interface for storing ticket search result objects as an ordered array of ids and an unordered dictionary
 */
export interface TicketSearchResultEntity extends EntityState<TicketSearchResultRow> {}

/**
 * NGRX provided adapter for interfacing with entities (modifying and selecting), this adapter is for the ticket search result entity
 */
export const searchResultsAdapter: EntityAdapter<TicketSearchResultRow> = createEntityAdapter<TicketSearchResultRow>({
  selectId: (ticketSearchResult) => ticketSearchResult.eTicketNumber,
});

/**
 * Initial state of the search results entity as defined by the search results adapter
 */
export const initialSearchResultsState: TicketSearchResultEntity = searchResultsAdapter.getInitialState({});

/**
 * Interface for storing EMD ticket detail objects as an ordered array of ids and an unordered dictionary
 */
export interface EMDEntity extends EntityState<EMDTicketDetail> {}

/**
 * NGRX provided adapter for interfacing with entities (modifying and selecting), this adapter is for the EMD entity
 */
export const EMDAdapter: EntityAdapter<EMDTicketDetail> = createEntityAdapter<EMDTicketDetail>({
  selectId: (EMDTicketDetails) => EMDTicketDetails.ticketNumber,
});

/**
 * Initial state of the emd entity as defined by the emd adapter
 */
export const initialEMDState: EMDEntity = EMDAdapter.getInitialState({});

/**
 * Interface for storing ticket detail result objects as an ordered array of ids and an unordered dictionary
 */
export interface TicketDetailsEntity extends EntityState<GDSTicketsCloudModelsResponsesTicketDetail> {}

/**
 * Interface for storing ticket detail result objects as an ordered array of ids and an unordered dictionary
 */
export interface AncillaryTicketDetailsEntity extends EntityState<GDSTicketsCloudModelsResponsesTicketDetail> {}

/**
 * NGRX provided adapter for interfacing with entities (modifying and selecting), this adapter is for the ticket detail entity
 */
// eslint-disable-next-line max-len
export const ticketDetailsAdapter: EntityAdapter<GDSTicketsCloudModelsResponsesTicketDetail> = createEntityAdapter<GDSTicketsCloudModelsResponsesTicketDetail>(
  {
    selectId: (ticketDetails) => ticketDetails.ticket.number,
  }
);

/**
 * NGRX provided adapter for interfacing with entities (modifying and selecting), this adapter is for the ticket detail entity
 */
// eslint-disable-next-line max-len
export const ancillaryTicketDetailsAdapter: EntityAdapter<GDSTicketsCloudModelsResponsesTicketDetail> = createEntityAdapter<GDSTicketsCloudModelsResponsesTicketDetail>(
  {
    selectId: (ancillaryTicketDetails) => ancillaryTicketDetails.emd?.number ?? ancillaryTicketDetails.ticket?.number,
  }
);

/**
 * Initial state of the ticket detail results entity as defined by the search results adapter
 */
export const initialTicketDetailsState: TicketDetailsEntity = ticketDetailsAdapter.getInitialState({});
export const initialAncillaryTicketDetailsState: TicketDetailsEntity = ancillaryTicketDetailsAdapter.getInitialState({});

/**
 * Initial state of the ticket service slice of state, used when ticket service state is bootstrapped via the reducer in an app module
 * also used when state is reset
 */
export const initialTicketServiceState: TicketServiceState = {
  coupons: initialCouponState,
  status: null,
  vcrLoading: false,
  searchResults: initialSearchResultsState,
  searchStatus: Status.STABLE,
  searchError: null,
  searchStartTime: null,
  EMDs: initialEMDState,
  ticketDetails: initialTicketDetailsState,
  ticketDetailsError: null,
  ticketDetailsStatus: Status.STABLE,
  ancillaryTicketDetails: initialAncillaryTicketDetailsState,
  ancillaryTicketDetailsError: null,
  ancillaryTicketDetailsStatus: Status.STABLE
};
