<div>
  <div>
    <div class="subheader-big">Carry-on policy (domestic and international)</div>
    <div class="subheader-text">Additional exceptions may apply. Visit
      <a class="blue-link"
        href="https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16141"
        target="_blank"
        rel="noopener noreferrer">Carry-on Baggage</a>
      CCM page to learn more.
    </div>
  </div>
  <div class="subheader-small">Alaska Airlines flights</div>
  <div class="text">
    Each guest is limited to one personal item, such as a purse or briefcase and one carry-on bag that
    measures up to 22” (L) x 14" (W) x 9” (H) or 45 linear inches, including wheels and handles. At least
    one of the items should be stowed under the seat in front of you, and you'll also need to be able to lift
    your own bag into the overhead bin.
  </div>
  <div class="subheader-small">Horizon Air / SkyWest flights</div>
  <div class="text">
    Each guest is limited to one personal item, such as a purse or briefcase and one carry-on bag. Select
    aircraft operated by Horizon Air or SkyWest airlines may have smaller overhead storage and under
    seat space based on the aircraft type. Larger carry-on items may be checked to your destination and
    picked up at baggage claim or placed on a planeside cart before boarding.<br><br>
  </div>
  <div class="text">
    For aircraft with planeside baggage service, the maximum dimensions allowed is 22” (L) x 14" (W) x
    9” (H) or 45 linear inches, including wheels and handles.<br><br>
  </div>
  <div class="text">
    <em>Other airline flights:</em> Contact the other airline to learn more about their carry-on rules.<br><br><br>
  </div>
  <div>
    <div class="subheader-big">Checked bag policy (domestic and international)</div>
    <div class="subheader-text">Seasonal baggage limits and additional exceptions may apply. Visit
      <a class="blue-link"
        href="https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16146"
        target="_blank"
        rel="noopener noreferrer">Checked Baggage</a>
      CCM page to learn more.
    </div>
  </div>
  <div class="subheader-small">Alaska Airlines flights</div>
  <div class="text">
    The first checked bag fee is $30, and the second checked bag fee is $40. The first two checked
    bags can each weigh up to 50 lbs. and have a maximum dimension of 62 linear inches.<br><br>
  </div>
  <div class="text">
    For tickets purchased on/after <i>January 2, 2024</i>: The first checked bag fee is $35, and the second checked bag fee is $45. The first two checked bags can each weigh up to 50 lbs. and have a maximum dimension of 62 linear inches.<br><br>
  </div>
  <div class="text">
    The third checked bag and each additional checked bag fee is based on the date of ticketing. Visit
    <a class="blue-link"
      href="https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16146"
      target="_blank"
      rel="noopener noreferrer">Checked Baggage</a>
    CCM page to learn more.<br><br>
  </div>
  <div class="text">
    Alaska Airlines credit card holders, US military personnel, Mileage Plan™ and <strong>one</strong>world<sup>®</sup> elite members
    <a class="blue-link"
      href="https://www.alaskaair.com/content/travel-info/baggage/baggage-fee-waivers-exceptions"
      target="_blank"
      rel="noopener noreferrer">may qualify for exceptions.<br><br>
    </a>
  </div>
  <div class="text">
    <em>Other airline flights:</em> Contact the other airline to learn more about their checked bag rules.
  </div>
</div>
