<div [formGroup]="form" class="floating-label">
  <input
    #inputElement
    class="floating-input {{ inputName }}"
    type="{{ inputType }}"
    value="{{ value }}"
    placeholder=" "
    [maxlength]="maxlength"
    [ngStyle]="styles"
    [formControlName]="inputName"
    [class.error]="invalid"
    [readonly]="readonly"
    autocomplete="{{ autocomplete }}"
  />
  <label [class.error]="invalid">{{ label }}</label>
</div>
<div *ngIf="form?.controls[inputName]?.errors?.patternErrorMessage && invalid" class="validation-error">
  {{ form?.controls[inputName]?.errors?.patternErrorMessage }}
</div>
<div *ngIf="form?.controls[inputName]?.errors?.minlength && invalid" class="validation-error">
  Minimum {{ form?.controls[inputName]?.errors?.minlength?.requiredLength }} characters, current:
  {{ form?.controls[inputName]?.errors?.minlength?.actualLength }}
</div>
<div *ngIf="form?.controls[inputName]?.errors?.maxlength && invalid" class="validation-error">
  Maximum {{ form?.controls[inputName]?.errors?.maxlength?.requiredLength }} characters, current:
  {{ form?.controls[inputName]?.errors?.maxlength?.actualLength }}
</div>
<div *ngIf="form?.controls[inputName]?.errors?.required && invalid" class="validation-error">
  {{ invalidErrorMessage }}
</div>
