import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { DrawerComponent } from './drawer/drawer.component';
import { SmallDrawerComponent } from './small-drawer/small-drawer.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [DrawerComponent, SmallDrawerComponent],
  providers: [],
  exports: [DrawerComponent, SmallDrawerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DrawersModule {}
