import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { getMileagePlanProfileSearchMileagePlanNumber } from '../../../state/mileage-plan-profile-search/mileage-plan-profile-search.selectors';
import { RootState } from '../../../state/state';
import { MileagePlanProfileService } from '../mileage-plan-profile.service';
import {
  mileagePlanProfileSearch,
  mileagePlanProfileSearchFailure,
  mileagePlanProfileSearchSuccess,
} from './mileage-plan-profile-service.actions';
import { MileagePlanProfileLookupStatus } from '../../../dtos/response/mileage-plan-profile-lookup-response/mileage-plan-profile-lookup-status';
import { MileagePlanMessageConverter } from '../../../utils/message-converters/mileage-plan/mileage-plan-message-converter';
import { MileagePlanProfileLookupResponse } from '../../../dtos/response/mileage-plan-profile-lookup-response/mileage-plan-profile-lookup-response';
import { resetJetStreamMessagesForNonFiltered } from '../../message-service/state/message.actions';

@Injectable()
export class MileagePlanProfileServiceEffects {
  constructor(
    private actions$: Actions,
    private mileagePlanProfileService: MileagePlanProfileService,
    private store: Store<RootState>,
    private mileagePlanMessageConverter: MileagePlanMessageConverter
  ) {}

  mileagePlanProfileSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mileagePlanProfileSearch),
      withLatestFrom(this.store.select(getMileagePlanProfileSearchMileagePlanNumber)),
      mergeMap(([action, mileagePlanNumber]) =>
        this.mileagePlanProfileService.mileagePlanProfileLookup(mileagePlanNumber).pipe(
          map((result) => {
            this.store.dispatch(resetJetStreamMessagesForNonFiltered());
            result.mappedMessageKeys = this.mileagePlanMessageConverter.convertMileagePlanLookupMessage(result);
            return result.status === MileagePlanProfileLookupStatus.SUCCESS ||
              result.status === MileagePlanProfileLookupStatus.PARTIAL_FAILURE
              ? mileagePlanProfileSearchSuccess(result)
              : mileagePlanProfileSearchFailure(result);
          })
        )
      ),
      catchError(() => {
        const response: MileagePlanProfileLookupResponse = { status: MileagePlanProfileLookupStatus.UNCAUGHT };
        response.mappedMessageKeys = this.mileagePlanMessageConverter.convertMileagePlanLookupMessage(response);
        return of(mileagePlanProfileSearchFailure(response));
      })
    )
  );
}
