import { createAction } from '@ngrx/store';
import { ItineraryResponse } from '../../../dtos/response/itinerary-response/itinerary-response';
import { Passenger } from '../../../dtos/response/reservation-response/passenger';
import { itineraryFeatureKey } from './itinerary.state';

/**
 * Submits a revalidation request
 */
export const submitReval = createAction(`[${itineraryFeatureKey}] Submit schedule change reval`);

/**
 * Submits a revalidation request for SDC
 */
 export const submitSdcReval = createAction(`[${itineraryFeatureKey}] Submit SDC reval`);

/**
 * Submits a revalidation request when the user is in the reissue flow but the request
 * is eligible for schedule change reval
 */
export const submitRevalForScheduleChangeReissue = createAction(
  `[${itineraryFeatureKey}] Submit schedule change reval in place of reissue`
);

/**
 * Submits a revalidation request when the user is in the reissue flow but the request
 * is eligible for irrop reval
 */
export const submitRevalForIrropReissue = createAction(`[${itineraryFeatureKey}] Submit irrop reval in place of reissue`);

/**
 * Selects an availability option for the given segment
 */
export const submitScheduleChangeReissueAcceptFlights = createAction(
  `[${itineraryFeatureKey}] Submit schedule change reissue with assigned flights`
);

/**
 * Submits schedule change reissue with new flights
 */
export const submitScheduleChangeReissue = createAction(
  `[${itineraryFeatureKey}] Submit schedule change reissue`,
  (confirmationCode: string, passengers: Passenger[], ticketNumbers: string[], callerName: string, primaryPassengerLastName: string) => ({
    confirmationCode,
    passengers,
    ticketNumbers,
    callerName,
    primaryPassengerLastName,
  })
);

/**
 * Submits the IRROP reissue with new flights
 */
export const submitIrropReissue = createAction(`[${itineraryFeatureKey}] Submit IRROP reissue`);

/**
 * The reissue request has been submitted and completed (may contain failures)
 */
export const reissueComplete = createAction(`[${itineraryFeatureKey}] Reissue complete`, (response: ItineraryResponse) => ({
  response,
}));

/**
 * The reval request has been submitted and completed (may contain failures)
 */
export const revalComplete = createAction(`[${itineraryFeatureKey}] Reval complete`, (response: ItineraryResponse) => ({
  response,
}));

/**
 * The action to end the reval process used in the logFailure effect
 */
export const revalFailureLogged = createAction(`[${itineraryFeatureKey}] Reval end`);

/**
 * The action to end the reissue process used in the logFailure effect
 */
export const reissueFailureLogged = createAction(`[${itineraryFeatureKey}] Reissue end`);

/**
 * Logs a reval failure when applicable
 */
export const logRevalFailure = createAction(`[${itineraryFeatureKey}] Log reval failure`);

export const reissueMetricsLogged = createAction(`[${itineraryFeatureKey}] Reissue metrics logged`);

export const logReissueMetrics = createAction(`[${itineraryFeatureKey}] Log reissue metrics`);

/**
 * Initialize the flightAvailability collection in the store
 */
export const initializeItineraryState = createAction(`[${itineraryFeatureKey}] Initialize`);
