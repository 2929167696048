import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AutofocusDirective } from './autofocus.directive';
import { CallerNameDirective } from './caller-name.directive';
import { NonProdDirective } from './non-prod.directive';
import { PhoneMaskDirective } from './phone-mask.directive';
import { PhoneMaskSpaceDirective } from './phone-mask-space.directive';
import { SpaceOrEnterKeySelectorDirective } from './space-or-enter-selector.directive';

@NgModule({
  declarations: [
    AutofocusDirective,
    CallerNameDirective,
    NonProdDirective,
    PhoneMaskDirective,
    PhoneMaskSpaceDirective,
    SpaceOrEnterKeySelectorDirective
  ],
  imports: [CommonModule],
  exports: [
    AutofocusDirective,
    CallerNameDirective,
    NonProdDirective,
    PhoneMaskDirective,
    PhoneMaskSpaceDirective,
    SpaceOrEnterKeySelectorDirective
  ],
})
export class DirectivesModule {}
