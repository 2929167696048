import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer as observableDefer, Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { TravelAdvisoryResponse } from '../../dtos/response/travel-advisory-response/travel-advisory-response';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { TravelAdvisories } from '../../dtos/response/travel-advisory-response/travel-advisories';

export interface TravelAdvisoriesServiceAPI {
  getTravelAdvisories(): Observable<TravelAdvisoryResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class TravelAdvisoriesService {
  constructor(private http: HttpClient) {}

  getTravelAdvisories(): Observable<TravelAdvisoryResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    return this.http.get<TravelAdvisories>(`api/advisories`, options).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((travelAdvisories) => {
        return { success: true, travelAdvisories };
      }),
      catchError((err: HttpErrorResponse) => {
        return observableOf({ success: false, errorMessage: err?.error?.exceptionContent });
      })
    );
  }
}
