import { routerReducer } from '@ngrx/router-store';
import { Action, ActionReducerMap, createReducer, MetaReducer, on } from '@ngrx/store';
import { modelReducer } from '../models/state/model.reducer';
import { RootState } from './/state';
import { initializeAppState, setCallerName } from './actions';
import { initialAppState } from './state';

export const rootReducers: ActionReducerMap<RootState> = {
  model: modelReducer,
  app: appReducer,
  router: routerReducer,
};

export const rootMetaReducers: MetaReducer<RootState>[] = [];

const featureReducer = createReducer(
  initialAppState,
  on(setCallerName, (state, { callerName }) => {
    return {
      ...state,
      callerName: callerName?.trim().toUpperCase() || '',
    };
  }),
  on(initializeAppState, (state) => ({ ...state, ...initialAppState }))
);

export function appReducer(state = initialAppState, action: Action) {
  return featureReducer(state, action);
}
