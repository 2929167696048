import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ReservationCreationResponse } from '../../../dtos/response/reservation-creation-response/reservation-creation-response';
import { ReservationResponse } from '../../../dtos/response/reservation-response/reservation-response';
import { Status } from '../../../models/status';
import { RootState } from '../../../state/state';
import { ReservationCancelResponse } from 'src/app/dtos/response/reservation-response/reservation-cancel-response';
import { AddDeleteRemarkStatus } from 'src/app/dtos/response/remarks-response/add-delete-remark-status';

export const reservationServiceFeatureKey = 'reservationService';

export interface State extends RootState {
  reservationService: ReservationServiceState;
}

export interface ReservationServiceState {
  reservation: ReservationEntity;
  reservationLookupCrudStatus: Status;
  initialReservationLookupCrudStatus: Status;
  placeholderReservation: ReservationCreationResponse | null;
  associationRevalError: boolean;
  selectedInvoluntaryChangeOption: string;
  reservationCancelResponse: ReservationCancelResponse;
  deleteRemarkStatus: AddDeleteRemarkStatus;
  addRemarkStatus: AddDeleteRemarkStatus;
}

export interface ReservationEntity extends EntityState<ReservationResponse> {}

export const reservationAdapter: EntityAdapter<ReservationResponse> = createEntityAdapter<ReservationResponse>({
  selectId: (response) => response.confirmationCode,
});

export const initialReservationState: ReservationEntity = reservationAdapter.getInitialState({});

export const initialReservationCancelResponseState: ReservationCancelResponse = {
  confirmationCode: '', status: Status.STABLE, statusCode: 200, content: ''
};

export const initialReservationServiceState: ReservationServiceState = {
  reservation: initialReservationState,
  reservationLookupCrudStatus: Status.STABLE,
  initialReservationLookupCrudStatus: Status.STABLE,
  placeholderReservation: null,
  associationRevalError: false,
  selectedInvoluntaryChangeOption: '',
  reservationCancelResponse: initialReservationCancelResponseState,
  deleteRemarkStatus: AddDeleteRemarkStatus.NONE,
  addRemarkStatus: AddDeleteRemarkStatus.NONE,
};
