import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ReservationLookupStatus } from '../dtos/response/reservation-response/reservation-lookup-status';
import { getPaymentReceived } from '../models/state/model.selectors';
import { RootState } from '../state/state';
import {
  getRoutedConfirmationCode,
  getRoutedReservation,
  getReservationLookupCrudStatus,
  getRoutedReservationStatus,
} from '../services/reservation-service/state/reservation-service.selectors';
import { Reservation } from '../dtos/response/reservation-response/reservation';
import { Status } from '../models/status';
import { initialLoadReservation } from '../services/reservation-service/state/reservation-service.actions';

@Injectable({
  providedIn: 'root',
})
export class SeatMapGuard  {
  private confirmationCode: string;
  private reservation: Reservation;
  private paymentReceived: boolean;

  constructor(private store: Store<RootState>, private router: Router) {
    this.store.pipe(select(getRoutedConfirmationCode)).subscribe((confCode) => (this.confirmationCode = confCode));
    this.store.pipe(select(getRoutedReservation)).subscribe((res) => (this.reservation = res));
    this.store.pipe(select(getPaymentReceived)).subscribe((paymentReceived) => (this.paymentReceived = paymentReceived));
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    const confirmationCode = next.params.confcode ? (next.params.confcode as string).toUpperCase() : null;
    if (!this.reservation || this.confirmationCode !== confirmationCode || this.paymentReceived) {
      const promise = new Promise<boolean>((resolve, reject) => {
        this.store.dispatch(initialLoadReservation(confirmationCode));

        this.store.pipe(select(getReservationLookupCrudStatus)).subscribe((crudStatus) => {
          if (crudStatus === Status.STABLE) {
            this.store.pipe(select(getRoutedReservationStatus( confirmationCode ))).subscribe((lookupStatus) => {
              if (lookupStatus !== null) {
                if (lookupStatus === ReservationLookupStatus.SUCCESS) {
                  resolve(true);
                } else {
                  this.router.navigate(['search', 'reservation', confirmationCode, lookupStatus]);
                  resolve(false);
                }
              }
            });
          }
        });
      });
      return promise;
    }
    return true;
  }
}
