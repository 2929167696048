export enum Format {
  BOLD = 'BOLD',
  IMPORTANT = 'IMPORTANT',
  ITALIC = 'ITALIC',
  EMPHASIZED = 'EMPHASIZED',
  MARKED = 'MARKED',
  SMALLER = 'SMALLER',
  DELETED = 'DELETED',
  INSERTED = 'INSERTED',
  SUBSCRIPT = 'SUBSCRIPT',
  SUPERSCRIPT = 'SUPERSCRIPT',
  AURO_ICON = 'AURO_ICON'
}
