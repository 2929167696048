import { createAction } from '@ngrx/store';
import { BagsAndAncillariesResponse } from '../../../dtos/response/bags-and-ancillaries-response/bags-and-ancillaries-response';
import { FeeResponse } from '../../../dtos/response/fee-response/fee-response';
import { Status } from '../../../models/status';
import { feeFeatureKey } from './fee.state';

export const initializeFeeState = createAction(`[${feeFeatureKey}] Initialize`);

export const loadFeeResults = createAction(`[${feeFeatureKey}] Load fee results`);

export const loadFeeResultsComplete = createAction(
  `[${feeFeatureKey}] Load fee results complete`,
  (success: boolean, response: FeeResponse | null) => ({
    success,
    response
  })
);

export const getAllBagsAndAncillaryFees = createAction(`[${feeFeatureKey}] Get all bags and ancillary fees`);

export const getAllBagsAndAncillaryFeesComplete = createAction(
  `[${feeFeatureKey}] Get all bags and ancillary fees complete`,
  (success: boolean, response: BagsAndAncillariesResponse | null) => ({
    success,
    response
  })
);

export const setSelectedBagAndAncillaryFeePassenger = createAction(
  `[${feeFeatureKey}] Set the selected bag and ancillary fee passenger`,
  (selectedPassengerNameNumber: string) => ({
    selectedPassengerNameNumber
  })
);

export const setGetBagsAndAncillaryFeesStatus = createAction(
  `[${feeFeatureKey}] Set the selected bag and ancillary fee status`,
  (status: Status) => ({
    status
  })
);

