import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TravelAdvisoriesServiceState, travelAdvisoriesServiceFeatureKey } from './travel-advisories-service.state';
import { TravelAdvisory } from '../../../dtos/response/travel-advisory-response/travel-advisory';

export const getTravelAdvisoriesServiceState = createFeatureSelector<TravelAdvisoriesServiceState>(travelAdvisoriesServiceFeatureKey);
/**
 * Gets the travel advisories
 */
export const getTravelAdvisories = createSelector(getTravelAdvisoriesServiceState, (state) => state.travelAdvisories?.entries ?? []);

/**
 * Returns a list of active travel advisories
 */
export const getActiveTravelAdvisories = createSelector(getTravelAdvisories, (travelAdvisories: TravelAdvisory[]): TravelAdvisory[] =>
  travelAdvisories?.filter((advisory) => advisory.is_Active)
);

/**
 * Returns the count of active travel advisories
 */
export const getActiveTravelAdvisoriesCount = createSelector(
  getActiveTravelAdvisories,
  (activeTravelAdvisories: TravelAdvisory[]): number => activeTravelAdvisories?.length ?? 0
);
