import { Passenger } from '../dtos/response/reservation-response/passenger';
import { Reservation } from '../dtos/response/reservation-response/reservation';
import { Segment } from '../dtos/response/reservation-response/segment';
import { SpecialServiceRequest } from '../dtos/response/reservation-response/special-service-request';
import { CouponStatusData } from '../dtos/response/vcr-response/coupon-status-data';
import { FirstClassCabin } from '../models/cabins/first-class-cabin';
import { MainCabin } from '../models/cabins/main-cabin';
import { PassengerSegmentCoupon } from '../models/passenger-segment-coupon';
import { CouponMapper } from '../services/ticket-service/coupon-mapper';

// Pattern to recognize FCAA OSIs, e.g. *-UPG*FEECOLLECTED-UPG25-SEA/LAX/1144/20APR
const fcaaOsiPattern = /^\*-UPG\*FEECOLLECTED-UPG\d+-[A-Za-z]{3}\/[A-Za-z]{3}\/\w+\/\d{1,2}[A-Za-z]{3}$/;

// Extract FCAA OSIs for a given passenger
const extractFCAAOSIs = (passenger: Passenger) => {
  return passenger.ssrs.filter((ssr) => ssr.type === 'OSI' && !!ssr.freeText.match(fcaaOsiPattern));
};

/**
 * Generate a FCCA OSI map, where the key is a hash made from extracted flight information
 * from a passenger's FCAA OSIs, e.g - SEA/LAX/1144/20APR
 * @param passenger Passenger information
 * @returns a map with FCAA flight inforamtion as key and corresponding osi details
 */
const getFCAAOSIsMap = (passenger: Passenger) => {
  const fcaaOsis = extractFCAAOSIs(passenger);
  const map = new Map<number, SpecialServiceRequest>();
  fcaaOsis.forEach((osi) => {
    const seperateFlightDetailsWithPassengerInfo = osi.freeText.split('-');
    map.set(hashCode(seperateFlightDetailsWithPassengerInfo[seperateFlightDetailsWithPassengerInfo.length - 1]), osi);
  });
  return map;
};

/**
 *
 * @param str string value
 * @returns hashcode for the given string
 */
const hashCode = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    /*eslint no-bitwise: ["error", { "int32Hint": true }] */
    hash = (Math.imul(31, hash) + str.charCodeAt(i)) | 0;
  }
  return hash;
};

/**
 * Detect and update the FCAA segments
 * Process details:
 *  1. For each Segment, extract passenger coupons with segment details where coupon status is 'OK'
 *  2. For each of those extracted value, check if there's a mismatch is class of service
 *  3. If there's a mismatch, extract FCAAOSIsMap for corresponding passenger
 *  4. Generate hash from segment details using the following sig.
 *        'departureAirpot', 'arrivalAirport', 'flight number', && 'modified departureDate, i.e. daymonth', Modified passenger id (e.g, 1.1)
 *  5. Check if the generated hash from segment exist in the FCAAOSIsMap
 *  6. If exists, then it's a FCAA Segment, otherwise not
 *  7. Update segments information for the corresponding reservation
 * @param coupons Coupon record for the reservation
 * @param reservation Reservation response
 */
export const updateFCAASegments = (coupons: CouponStatusData[], reservation: Reservation) => {
  const passengersSegmentCoupons: PassengerSegmentCoupon[] = getPassengersSegmentCoupons(coupons, reservation);
  const segments = reservation.allSegments;
  const passengers = reservation.passengers;

  segments.forEach((segment) => {
    const segmentCoupons = passengersSegmentCoupons.filter((coupon) => coupon?.segmentHashId === segment.hashId && coupon?.status === 'OK');
    const isfcaaSegments: boolean[] = [];
    segmentCoupons.forEach((data) => {
      const coupon = coupons.find((c) => c.couponNumber === data.couponNumber && c.ticketNumber === data.ticketNumber);
      const passenger = passengers.find((p) => p.hashId === data.passengerHashId);
      if (isCouponSegmentCoSMismatch(coupon, segment)) {
        const paxFCAAOsis = getFCAAOSIsMap(passenger);
        const segmentFcaaHash = generateFcaaSegmentHash(segment, passenger.id);
        segment.isAFCAASegment = paxFCAAOsis.has(segmentFcaaHash);
        isfcaaSegments.push(paxFCAAOsis.has(segmentFcaaHash));
      }
    });
    segment.isAFCAASegment = isfcaaSegments.length > 0 ? isfcaaSegments.every((isFCAA) => isFCAA) : false;
  });
};

// Extract coupon and corresponding segment details for a given passenger
const getPassengersSegmentCoupons = (coupons: CouponStatusData[], reservation: Reservation) => {
  const passengersSegmentCoupons: PassengerSegmentCoupon[] = [];
  if (coupons.length > 0) {
    reservation.allSegments.forEach((segment) => {
      reservation.passengers.forEach((pax) => {
        const passengerCoupons = CouponMapper.getPassengerCoupon(pax, segment, reservation, coupons);
        passengersSegmentCoupons.push(passengerCoupons);
      });
    });
  }
  return passengersSegmentCoupons;
};

// Generate hash from segment's flight information
const generateFcaaSegmentHash = (segment: Segment, passengerId: string) => {
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const segmentDepartureDate = new Date(segment.departureDateTime);
  const dateOfDeparture = segmentDepartureDate.getDate().toString();
  const modifiedDepartureDate =
    (dateOfDeparture.length > 1 ? dateOfDeparture : `0${dateOfDeparture}`) + months[segmentDepartureDate.getMonth()];
  const segmentSig = [segment.departureAirport, segment.arrivalAirport, segment.operatingAirlineFlightNumber, modifiedDepartureDate].join(
    '/'
  );
  return hashCode(segmentSig);
};

export const updateSegmentsHashIdForFcaaOsis = (segments: Segment[], passengers: Passenger[]) => {
  const fcaaOsis: SpecialServiceRequest[] = [];
  passengers.forEach((passenger) => {
    const fcaaOsisMap = getFCAAOSIsMap(passenger);
    segments.forEach((segment) => {
      const segmentHashFcaa = generateFcaaSegmentHash(segment, passenger.id);
      if (fcaaOsisMap.has(segmentHashFcaa)) {
        const fcaaOsi = fcaaOsisMap.get(segmentHashFcaa);
        fcaaOsis.push({ ...fcaaOsi, segmentHashId: segment.hashId, passengerHashId: passenger.hashId });
      }
    });
  });

  return fcaaOsis;
};

// Check if there's a mismatch between coupon class of service and segment's class of service
const isCouponSegmentCoSMismatch = (coupon: CouponStatusData, segment: Segment) =>
  MainCabin.includes(coupon.classOfService) &&
  FirstClassCabin.filter((fcc) => fcc !== 'U').includes(segment.serviceClassCode) &&
  coupon.classOfService !== segment.serviceClassCode;
