import { createSelector } from '@ngrx/store';
import { PassengerTransactionRequest } from '../../../dtos/request/purchase-request/passenger-transaction-request';
import { SegmentWaiver } from '../../../dtos/request/purchase-request/segment-waiver';
import { TransactionRequest } from '../../../dtos/request/purchase-request/transaction-request';
import { TransactionSegment } from '../../../dtos/request/purchase-request/transaction-segment';
import { ShoppingSegment } from '../../../dtos/request/shopping-request/shopping-segment';
import { ActionCode } from '../../../dtos/response/action-code/action-codes';
import { GDSTicketsCloudModelsResponsesTicketDetail } from '../../../dtos/response/gds-ticket-cloud-response/gDSTicketsCloudModelsResponsesTicketDetail';
import { UserData } from '../../../dtos/response/login-response/user-data';
import { Passenger } from '../../../dtos/response/reservation-response/passenger';
import { Segment } from '../../../dtos/response/reservation-response/segment';
import { PaymentType } from '../../../models/payment-info/payment-type';
import { getSelectedFlightShoppingResult } from '../../flight-shopping-service/state/flight-shopping-service.selectors';
import { getUser } from '../../login-service/state/login-service.selector';
import { getPassengers, getRoutedConfirmationCode } from '../../reservation-service/state/reservation-service.selectors';
import { getSdcEligibleSegments } from '../../sdc-eligibility-service/state/sdc-eligibility-service.selectors';
import { getMostRecentlyIssuedTicketDetailsForGuests } from '../../ticket-service/state/ticket-service.selectors';

/**
 * Build the request to log an SDC auto waiver to the DAP
 */
export const getLogSdcAutoWaiverRequest = ( waiverReason: string, originalAmount: number ) => createSelector(
  getUser,
  getRoutedConfirmationCode,
  getSelectedFlightShoppingResult,
  getSdcEligibleSegments,
  getPassengers,
  getMostRecentlyIssuedTicketDetailsForGuests,
  (
    user: UserData,
    confirmationCode: string,
    selectedSdcSegments: ShoppingSegment[],
    sdcEligibleSegments: Segment[],
    passengers: Passenger[],
    mostRecentTickets: GDSTicketsCloudModelsResponsesTicketDetail[]
  ): TransactionRequest => {
    const transactionSegments: TransactionSegment[] = [];
    const transactionSegmentWaivers: SegmentWaiver[] = [];

    // Map the new SDC segment(s) to the transaction segment model
    selectedSdcSegments.forEach((selectedSegment) => {
      transactionSegments.push({
        departureAirport: selectedSegment.departureAirportCode,
        arrivalAirport: selectedSegment.arrivalAirportCode,
        actionCode: ActionCode.NN,
        carrierCode: selectedSegment.publishingCarrierCode,
        departureDate: selectedSegment.departureDateTime?.toString()?.split('T')[0],
        serviceClassCode: sdcEligibleSegments.filter(
          (eligibleSeg) =>
            eligibleSeg.departureAirport === selectedSegment.departureAirportCode &&
            eligibleSeg.arrivalAirport === selectedSegment.arrivalAirportCode
        )[0].serviceClassCode,
        flightNumber: selectedSegment.publishingFlightNumber.toString(),
      });

      let segRefIndex = 0;
      // Map the waiver information to the transaction waiver model
      transactionSegments.forEach((seg) => {
        transactionSegmentWaivers.push({
          waiverReason, // Why the waiver is being applied
          isAutoWaiver: true,
          segmentRefIndex: segRefIndex, // Index of the transaction segment that the waiver is being applied to
          pricingInfo: {
            basePrice: originalAmount, // The base amount being waived (For SDC this will be the same as the total)
            totalPrice: originalAmount, // The total amount that is being waived
          },
        });
        segRefIndex += 1;
      });
    });

    const transactionPassengers: PassengerTransactionRequest[] = [];

    passengers.forEach((passenger) => {
      // Grab the most recent ticket for the current passenger
      const matchingTicket = mostRecentTickets.filter(
        (ticket) =>
          ticket.ticket?.customer?.traveler?.firstName === passenger.firstName &&
          ticket.ticket?.customer?.traveler?.lastName === passenger.lastName
      )[0].ticket;

      // Map the passenger to the transaction passenger model
      transactionPassengers.push({
        firstName: passenger.firstName,
        id: passenger.id,
        lastName: passenger.lastName,
        loyalty: {
          airlineCode: passenger.loyalty?.airlineCode,
          status: passenger.loyalty?.status,
          number: passenger.loyalty?.number,
          tierStatus: passenger.loyalty?.tierStatus,
          id: passenger.loyalty?.id,
        },
        segmentWaivers: transactionSegmentWaivers,
        segmentPurchases: [],
        ticketNumber: matchingTicket?.number,
        ticketNumberIssueDate: matchingTicket?.details?.localIssueDateTime?.value,
      });
    });

    const request: TransactionRequest = {
      confirmationCode,
      passengers: transactionPassengers,
      segments: transactionSegments,
      sabreId: user.username,
      paymentType: PaymentType.SAME_DAY_CONFIRM,
    };
    return request;
  }
);
