import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { FlightNumberComponent } from '../ux-flights/flight-number/flight-number.component';
import { ContentWithTitleComponent } from './components/content-with-title/content-with-title.component';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import { FooterBarComponent } from './components/footer-bar/footer-bar.component';
import { LoadingDotsComponent } from './components/loading-dots/loading-dots.component';
import { ModalComponent } from './components/modal/modal.component';
import { NavTabsComponent } from './components/nav-tabs/nav-tabs.component';
import { OnPageAdvisoryComponent } from './components/on-page-advisory/on-page-advisory.component';
import { OverflowTextComponent } from './components/overflow-text/overflow-text.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PcBenefitsPopoverComponent } from './components/pc-benefits-popover/pc-benefits-popover.component';
import { ReservationRefreshComponent } from './components/reservation-refresh/reservation-refresh.component';
import { SubtotalComponent } from './components/subtotal/subtotal.component';
import { TitleBarV2Component } from './components/title-bar-v2/title-bar-v2.component';
import { TitleBarComponent } from './components/title-bar/title-bar.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { BetaComponent } from './components/beta/beta.component';
import { MileagePlanAutoFillButtonComponent } from './components/mileage-plan-auto-fill-button/mileage-plan-auto-fill-button.component';
import { FormInputModule } from '../form-input/form-input.module';
import { ToastComponent } from './components/toast/toast.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, RouterModule, FormsModule, FormInputModule, InlineSVGModule, PipesModule, DirectivesModule],
  declarations: [
    FooterBarComponent,
    TitleBarComponent,
    CopyToClipboardComponent,
    LoadingDotsComponent,
    SubtotalComponent,
    TitleBarV2Component,
    NavTabsComponent,
    ReservationRefreshComponent,
    FlightNumberComponent,
    ModalComponent,
    PcBenefitsPopoverComponent,
    OverflowTextComponent,
    OnPageAdvisoryComponent,
    ContentWithTitleComponent,
    PaginatorComponent,
    NavBarComponent,
    DropdownComponent,
    BetaComponent,
    MileagePlanAutoFillButtonComponent,
    ToastComponent
  ],
  exports: [
    FooterBarComponent,
    TitleBarComponent,
    CopyToClipboardComponent,
    LoadingDotsComponent,
    SubtotalComponent,
    TitleBarV2Component,
    NavTabsComponent,
    ReservationRefreshComponent,
    FlightNumberComponent,
    ModalComponent,
    PcBenefitsPopoverComponent,
    OverflowTextComponent,
    OnPageAdvisoryComponent,
    ContentWithTitleComponent,
    PaginatorComponent,
    NavBarComponent,
    DropdownComponent,
    BetaComponent,
    MileagePlanAutoFillButtonComponent,
    ToastComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UXModule {}
