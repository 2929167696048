<ng-container *ngFor="let resCategory of resourceCenterCategory | keyvalue:originalOrder">
    <h3 class="resource-center-links-category-header">{{ resCategory.key }}</h3>
    <ng-container *ngFor="let resLinkItems of resCategory.value | keyvalue:originalOrder">
        <a class="resource-links-row-container" [href]="resLinkItems.value['url']" target="_blank" rel="noopener noreferrer">
            <div class="resource-link-display-text" [id]="resLinkItems.value['id']">
              {{ resLinkItems.value['linkText']}}
            </div>
            <span class="external-link-icon" [inlineSVG]="'/assets/icon-external-link.svg'"></span>
        </a>
    </ng-container>
</ng-container>
