import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { BagsAndAncillariesResponse } from '../../../dtos/response/bags-and-ancillaries-response/bags-and-ancillaries-response';
import { PassengerInfo } from '../../../dtos/response/bags-and-ancillaries-response/passenger-info';
import { FeeResponse } from '../../../dtos/response/fee-response/fee-response';
import { Status } from '../../../models/status';
import { RootState } from '../../../state/state';

export const feeFeatureKey = 'fee';

export interface State extends RootState {
  fee: FeeState;
}

export interface FeeState {
  feeResults: FeeEntity;
  feeStatus: Status;
  feeError: string | null;
  bagsAndAncillaryFees: BagsAndAncillariesResponse | null;
  bagsAndAncillaryFeesStatus: Status;
  bagsAndAncillaryFeesError: string | null;
  selectedBagAndAncillaryFeePassenger: PassengerInfo | null;
}

export interface FeeEntity extends EntityState<FeeResponse> {}

export const feeAdapter: EntityAdapter<FeeResponse> = createEntityAdapter<FeeResponse>({
  selectId: (response) => (response.response ?? {}).id ?? '',
});

export const initialFeeEntityState: FeeEntity = feeAdapter.getInitialState({});


export const initialFeeState: FeeState = {
  feeResults: initialFeeEntityState,
  feeStatus: Status.STABLE,
  feeError: null,
  bagsAndAncillaryFees: null,
  bagsAndAncillaryFeesStatus: Status.STABLE,
  bagsAndAncillaryFeesError: null,
  selectedBagAndAncillaryFeePassenger: null,
};
