/* eslint-disable no-unused-expressions */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { HttpStatusCode } from '../../../dtos/http-status-code';
import { SecureFlightInfoResponse } from '../../../dtos/response/secure-flight-info/secure-flight-info-response';
import { RemoveSsrStatus } from '../../../dtos/response/ssr-response/remove-ssr-status';
import { MessageKey } from '../../../models/message/message-key';
import { Status } from '../../../models/status';
import { RootState } from '../../../state/state';
import { getUser } from '../../login-service/state/login-service.selector';
import { loadReservation } from '../../reservation-service/state/reservation-service.actions';
import { getRoutedConfirmationCode } from '../../reservation-service/state/reservation-service.selectors';
import { SsrService } from '../../ssr-service/ssr.service';
import { ContactTracingService } from '../contact-tracing.service';
import {
  contactTracingInfoRemovalComplete,
  contactTracingInfoUpdateComplete,
  removeContactTracingAddressInfo,
  removeContactTracingEmailInfo,
  removeContactTracingPhoneInfo,
  submitContactTracingAddressInfo,
  submitContactTracingEmailInfo,
  submitContactTracingPhonesInfo,
  updateContactTracingInfoStatus,
} from './contact-tracing.actions';
import { addStackedMessage } from '../../message-service/state/message.actions';

@Injectable()
export class ContactTracingEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private contactTracingService: ContactTracingService,
    private ssrService: SsrService,
  ) {}

  addContactTracingEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitContactTracingEmailInfo),
      withLatestFrom(this.store.select(getRoutedConfirmationCode)),
      mergeMap(([{ request }, confirmationCode]) => {
        return this.contactTracingService.addContactTracingEmail(request, confirmationCode).pipe(
          map((response) => {
            this.store.dispatch(updateContactTracingInfoStatus(request.passengerHashId, Status.STABLE));
            this.store.dispatch(loadReservation(confirmationCode));
            this.contactTracingService.refreshReservation(confirmationCode);
            return contactTracingInfoUpdateComplete(response, request.passengerHashId);
          }),
          catchError((err: HttpErrorResponse) => {
            err.error?.actionResult?.messages?.join().includes('CHECK ITINERARY')
              ? this.store.dispatch(
                  addStackedMessage(MessageKey.CONTACT_TRACING_INFO_NO_ITIN, [err.statusText])
                )
              : this.store.dispatch(
                  addStackedMessage(MessageKey.CONTACT_TRACING_INFO_FAILURE, [err.statusText])
                );
            this.store.dispatch(updateContactTracingInfoStatus(request.passengerHashId, Status.STABLE));
            return of(
              contactTracingInfoUpdateComplete(
                {
                  status: err.status,
                },
                request.passengerHashId
              )
            );
          })
        );
      })
    )
  );

  addContactTracingPhones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitContactTracingPhonesInfo),
      withLatestFrom(this.store.select(getRoutedConfirmationCode)),
      mergeMap(([{ request }, confirmationCode]) => {
        return this.contactTracingService.addContactTracingPhones(request, confirmationCode).pipe(
          map((response) => {
            this.store.dispatch(updateContactTracingInfoStatus(request.passengerHashId, Status.STABLE));
            this.store.dispatch(loadReservation(confirmationCode));
            this.contactTracingService.refreshReservation(confirmationCode);
            return contactTracingInfoUpdateComplete(response, request.passengerHashId);
          }),
          catchError((err: HttpErrorResponse) => {
            err.error?.actionResult?.messages?.join().includes('CHECK ITINERARY')
              ? this.store.dispatch(
                  addStackedMessage(MessageKey.CONTACT_TRACING_INFO_NO_ITIN, [err.statusText])
                )
              : this.store.dispatch(
                  addStackedMessage(MessageKey.CONTACT_TRACING_INFO_FAILURE, [err.statusText])
                );
            this.store.dispatch(updateContactTracingInfoStatus(request.passengerHashId, Status.STABLE));
            return of(
              contactTracingInfoUpdateComplete(
                {
                  status: err.status,
                },
                request.passengerHashId
              )
            );
          })
        );
      })
    )
  );

  addContactTracingAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitContactTracingAddressInfo),
      withLatestFrom(this.store.select(getRoutedConfirmationCode)),
      mergeMap(([{ request }, confirmationCode]) => {
        return this.contactTracingService.addContactTracingDestinationAddress(request, confirmationCode).pipe(
          map((response) => {
            this.store.dispatch(updateContactTracingInfoStatus(request.passengerHashId, Status.STABLE));
            this.store.dispatch(loadReservation(confirmationCode));
            this.contactTracingService.refreshReservation(confirmationCode);
            return contactTracingInfoUpdateComplete(response, request.passengerHashId);
          }),
          catchError((err: HttpErrorResponse) => {
            err.error?.actionResult?.messages?.join().includes('CHECK ITINERARY')
              ? this.store.dispatch(
                  addStackedMessage(MessageKey.CONTACT_TRACING_INFO_NO_ITIN, [err.statusText])
                )
              : this.store.dispatch(
                  addStackedMessage(MessageKey.CONTACT_TRACING_INFO_FAILURE, [err.statusText])
                );
            this.store.dispatch(updateContactTracingInfoStatus(request.passengerHashId, Status.STABLE));
            return of(
              contactTracingInfoUpdateComplete(
                {
                  status: err.status,
                },
                request.passengerHashId
              )
            );
          })
        );
      })
    )
  );

  removeContactTracingInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeContactTracingEmailInfo, removeContactTracingPhoneInfo, removeContactTracingAddressInfo),
      withLatestFrom(this.store.select(getUser)),
      mergeMap(([action, user]) => {
        return this.ssrService.removeSsr(action.confirmationCode, action.ssrId).pipe(
          map((result) => {
            this.store.dispatch(updateContactTracingInfoStatus(action.passengerHashId, Status.STABLE));
            this.store.dispatch(loadReservation(action.confirmationCode));
            const ssrRemoveResult: SecureFlightInfoResponse = { status: HttpStatusCode.OK };
            switch (result.status) {
              case RemoveSsrStatus.SUCCESS:
                break;
              case RemoveSsrStatus.TIMEOUT:
                ssrRemoveResult.status = HttpStatusCode.REQUEST_TIMEOUT;
                break;
              default:
                ssrRemoveResult.status = HttpStatusCode.INTERNAL_SERVER_ERROR;
                break;
            }
            return contactTracingInfoRemovalComplete(ssrRemoveResult);
          })
        );
      }),
      catchError(() => of(contactTracingInfoRemovalComplete({ status: HttpStatusCode.INTERNAL_SERVER_ERROR })))
    )
  );
}
