import { Action, createReducer, on } from '@ngrx/store';
import { initialMileagePlanCompanionServiceState } from './mileage-plan-companion-service.state';
import { Status } from 'src/app/models/status';
import { addMileagePlanCompanion,
  addMileagePlanCompanionFailure,
  addMileagePlanCompanionSuccess,
  editMileagePlanCompanion,
  editMileagePlanCompanionFailure,
  editMileagePlanCompanionSuccess,
  initializeMileagePlanCompanionServiceState,
  removeMileagePlanCompanion,
  removeMileagePlanCompanionFailure,
  removeMileagePlanCompanionSuccess
} from './mileage-plan-companion-service.actions';

/**
 * Handles all state changes, there is no way to change the state without a reducer AND the reducer never
 * modifies state, it clones the state from the store, changes that cloned state in some way, and then replaces
 * the entire old state with the cloned and modified state.
 */
const featureReducer = createReducer(
  initialMileagePlanCompanionServiceState,
  /**
   * Set status to loading on addMileagePlanCompanion
   */
  on(addMileagePlanCompanion, (state) => ({
    ...state,
    status: Status.LOADING,
    error: null,
  })),
  /**
   * Set status to loading on editMileagePlanCompanion
   */
  on(editMileagePlanCompanion, (state) => ({
    ...state,
    status: Status.LOADING,
    error: null,
  })),
  /**
   * Set status to loading on removeMileagePlanCompanion
   */
  on(removeMileagePlanCompanion, (state) => ({
    ...state,
    status: Status.LOADING,
    error: null,
  })),
  /**
   * Add the add mileage plan companion response and set the status to stable
   */
  on(addMileagePlanCompanionSuccess, (state, { response }) => ({
    ...state,
    mileagePlanAddCompanionResponse: response,
    status: Status.STABLE,
    error: null,
  })),
  /**
   * Add the edit mileage plan companion response and set the status to stable
   */
  on(editMileagePlanCompanionSuccess, (state, { response }) => ({
    ...state,
    mileagePlanEditCompanionResponse: response,
    status: Status.STABLE,
    error: null,
  })),
  /**
   * Add the remove mileage plan companion response and set the status to stable
   */
  on(removeMileagePlanCompanionSuccess, (state, { response }) => ({
    ...state,
    mileagePlanRemoveCompanionResponse: response,
    status: Status.STABLE,
    error: null,
  })),
  /**
   * Add the error status for the add mileage plan companion and set the status to stable
   */
  on(addMileagePlanCompanionFailure, (state, { error }) => ({
    ...state,
    mileagePlanAddCompanionResponse: { status: error },
    status: Status.STABLE,
    error,
  })),
  /**
   * Add the error status for the edit mileage plan companion and set the status to stable
   */
  on(editMileagePlanCompanionFailure, (state, { error }) => ({
    ...state,
    mileagePlanEditCompanionResponse: { status: error },
    status: Status.STABLE,
    error,
  })),
  /**
   * Add the error status for the remove mileage plan companion and set the status to stable
   */
  on(removeMileagePlanCompanionFailure, (state, { error }) => ({
    ...state,
    mileagePlanRemoveCompanionResponse: { status: error },
    status: Status.STABLE,
    error,
  })),
  /**
   * Set the state to the initial value defined in the mileage-plan-companion-service.state file
   */
  on(initializeMileagePlanCompanionServiceState, (state) => ({ ...state, ...initialMileagePlanCompanionServiceState }))
);

/**
 * Typescript safe way to export the reducer so that it can be imported in modules
 */
export function mileagePlanCompanionServiceReducer(state = initialMileagePlanCompanionServiceState, action: Action) {
  return featureReducer(state, action);
}
