import { Action, createReducer, on } from '@ngrx/store';
import { Status } from '../../../models/status';
import { initializeZipLookupState, lookupCityStateComplete, lookupCityStateResults } from './zip-lookup.actions';
import { initialzipLookupEntityState } from './zip-lookup.state';

const featureReducer = createReducer(
  initialzipLookupEntityState,

  on(initializeZipLookupState, (state) => ({ ...state, ...initialzipLookupEntityState })),

  on(lookupCityStateResults, (state) => ({
    ...state,
    status: Status.UPDATING,
  })),

  on(lookupCityStateComplete, (state, { response }) => ({
    ...state,
    zipLookupResponse: response,
    status: Status.STABLE
  })),
);

export function zipLookupServiceReducer(state = initialzipLookupEntityState, action: Action) {
  return featureReducer(state, action);
}
