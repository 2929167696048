import { RootState } from '../state';

export const couponRecordSearchFeatureKey = 'couponRecordSearchState';

export interface State extends RootState {
  couponRecordSearch: CouponRecordSearchState;
}

export interface CouponRecordSearchState {
  lastName: string;
  firstName: string;
  startDate: string;
  endDate: string;
  departureCity: string;
  airlineCode: string;
  ticketNumber: string;
}

export const initialCouponRecordSearchState: CouponRecordSearchState = {
  lastName: null,
  firstName: null,
  startDate: null,
  endDate: null,
  departureCity: null,
  airlineCode: null,
  ticketNumber: null,
};
