import { Injectable } from '@angular/core';
import { SavePassengerResponse } from '../../../dtos/response/save-passenger-response/save-passenger-response';
import { MessageKey } from '../../../models/message/message-key';
import { PassengerDataSlice } from '../../../dtos/response/save-passenger-response/passenger-data-slice';
import { RemovePassengerDataSlice } from 'src/app/dtos/request/save-passenger-request/remove-passenger-data-slice';
import { HttpStatusCode } from '../../../dtos/http-status-code';

@Injectable({
  providedIn: 'root',
})
export class SaveGuestMessageConverter {
  /**
   * Given a response when removing/saving guest information, evaluates the response and convert it into a message bar with content object.
   */
  public convertSaveGuestErrorMessage(response: SavePassengerResponse, removeDataSlice?: RemovePassengerDataSlice) {
    response.mappedMessageKeys = [];
    this.convertTimeoutFailures(response, removeDataSlice);
    this.convertPasswordDecryptionFailure(response);
    this.convertBadRequestFailures(response);
    this.convertIndividualFailures(response, removeDataSlice);
    this.convertUnknownFailures(response, removeDataSlice);
    return response;
  }

  private convertTimeoutFailures(response: SavePassengerResponse, removeDataSlice?: RemovePassengerDataSlice) {
    // Timeout
    if (response && response.mappedMessageKeys && response.exceptionContent?.toLowerCase().includes('timeout')) {
      // For lapInfant remove timeout, we'll map to a different message key.
      if (removeDataSlice && removeDataSlice === RemovePassengerDataSlice.LapInfant) {
        response.mappedMessageKeys.push(MessageKey.LAP_INFANT_REMOVE_TIMEOUT);
      } else if (!removeDataSlice){
        response.mappedMessageKeys.push(MessageKey.GUEST_SAVE_TIMEOUT);
      }
      else if (removeDataSlice){
        response.mappedMessageKeys.push(MessageKey.GUEST_REMOVE_TIMEOUT);
      }
    }
  }

  private convertPasswordDecryptionFailure(response: SavePassengerResponse) {
    // Password Decryption Error
    if (response &&
        response.mappedMessageKeys &&
        response.exceptionContent?.toLowerCase().includes('password decryption error')) {
      response.mappedMessageKeys.push(MessageKey.PASSWORD_DECRYPTION_ERROR);
    }
  }

  private convertBadRequestFailures(response: SavePassengerResponse) {
    if (response.statusCode === HttpStatusCode.BAD_REQUEST) {
      if (response?.exceptionContent?.includes('internationalDocumentRequest')) {
        response.mappedMessageKeys?.push(MessageKey.INTERNATIONAL_DOCS_BAD_REQUEST);
      }
    }
  }

  private convertIndividualFailures(response: SavePassengerResponse, removeDataSlice?: RemovePassengerDataSlice) {
    if (response?.response?.failedList && response.response.failedList.length > 0) {
      const failures = response.response?.failedList;
      failures?.forEach((failure) => {
        const requestType = failure.requestType;
        const failureMessage = failure.failureMessage;
        this.convertMileagePlanFailure(requestType, failureMessage, response);
        this.convertBioInfoFailure(requestType, failureMessage, response);
        this.convertKTNFailure(requestType, failureMessage, response);
        this.convertRedressFailure(requestType, failureMessage, response);
        this.convertInternationalDocFailure(requestType, response);
        this.convertEmergencyContactFailure(requestType, response);
        this.convertContactTracingFailure(requestType, failureMessage, response);
        this.convertLapInfantFailure(requestType, response);
      });
    }
    this.convertContactTracingRemoveFailures(response, removeDataSlice);
  }

  private convertUnknownFailures(response: SavePassengerResponse, removeDataSlice?: RemovePassengerDataSlice) {
    // If we've identified an error and made it this far without mapping a key, we'll map the unknown error key.
    if (response.statusCode !== 200 && response.mappedMessageKeys?.length === 0) {
      if (removeDataSlice && removeDataSlice === RemovePassengerDataSlice.LapInfant) {
        response.mappedMessageKeys.push(MessageKey.LAP_INFANT_REMOVE_UNKNOWN_WITH_LINK);
      } else if (!removeDataSlice || removeDataSlice !== RemovePassengerDataSlice.ContactTracing){
        response.mappedMessageKeys.push(MessageKey.UNKNOWN_WITH_LINK);
      }
    }
  }

  private convertMileagePlanFailure(requestType: PassengerDataSlice, failureMessage: string | undefined, response: SavePassengerResponse) {
    switch (true) {
      case requestType === PassengerDataSlice.MileagePlan && failureMessage?.toLowerCase().includes('does not exist for this airline'):
        response.mappedMessageKeys?.push(MessageKey.MILEAGE_PLAN_DOES_NOT_EXIST);
        break;
      case requestType === PassengerDataSlice.MileagePlan && failureMessage?.toLowerCase().includes('data exists for this passenger'):
        response.mappedMessageKeys?.push(MessageKey.MILEAGE_PLAN_DATA_EXISTS);
        break;
      case requestType === PassengerDataSlice.MileagePlan && failureMessage?.toLowerCase().includes('name does not match'):
        response.mappedMessageKeys?.push(MessageKey.MILEAGE_PLAN_NAME_MISMATCH);
        break;
      case requestType === PassengerDataSlice.MileagePlan && failureMessage?.toLowerCase().includes('agreement does not exist'):
        response.mappedMessageKeys?.push(MessageKey.MILEAGE_PLAN_NO_AGREEMENT);
        break;
      case requestType === PassengerDataSlice.MileagePlan && failureMessage?.toLowerCase().includes('simultaneous changes'):
        response.mappedMessageKeys?.push(MessageKey.MILEAGE_PLAN_SIMULTANEOUS_CHANGES);
        break;
      case requestType === PassengerDataSlice.MileagePlan && failureMessage?.toLowerCase().includes('past date pnr'):
        response.mappedMessageKeys?.push(MessageKey.MILEAGE_PLAN_PAST_DATE_PNR);
        break;
      case requestType === PassengerDataSlice.MileagePlan && failureMessage?.toLowerCase().includes('time limit is prior to current time'):
        response.mappedMessageKeys?.push(MessageKey.MILEAGE_PLAN_TKT_TIME_LIMIT);
        break;
      case requestType === PassengerDataSlice.MileagePlan && failureMessage?.toLowerCase().includes('incorrect tkt/time limit'):
        response.mappedMessageKeys?.push(MessageKey.MILEAGE_PLAN_TKT_TIME_LIMIT);
        break;
      case requestType === PassengerDataSlice.MileagePlan && failureMessage?.toLowerCase().includes('invld tac date'):
        response.mappedMessageKeys?.push(MessageKey.MILEAGE_PLAN_INVLD_TAC_DATE);
        break;
      case requestType === PassengerDataSlice.MileagePlan && failureMessage?.toLowerCase().includes('processing error need address'):
        response.mappedMessageKeys?.push(MessageKey.MILEAGE_PLAN_NEED_ADDRESS);
        break;
      case requestType === PassengerDataSlice.MileagePlan &&
        failureMessage?.toLowerCase().includes('ticket/timelimit must precede travel date'):
        response.mappedMessageKeys?.push(MessageKey.MILEAGE_PLAN_TKT_TIME_LIMIT);
        break;
      case requestType === PassengerDataSlice.MileagePlan && failureMessage?.toLowerCase().includes('update reservation processing error'):
        response.mappedMessageKeys?.push(MessageKey.MILEAGE_PLAN_UPDATE_RESERVATION_PROCESSING_ERROR);
        break;
      case requestType === PassengerDataSlice.MileagePlan && failureMessage?.toLowerCase().includes('service error'):
        response.mappedMessageKeys?.push(MessageKey.MILEAGE_PLAN_SERVICE_ERROR);
        break;
      case requestType === PassengerDataSlice.MileagePlan:
        // Generic mileage plan failure
        response.mappedMessageKeys?.push(MessageKey.MILEAGE_PLAN_GENERIC);
        break;
      case requestType === PassengerDataSlice.MileagePlanRemove:
        response.mappedMessageKeys?.push(MessageKey.MILEAGE_PLAN_REMOVE);
        break;
      default:
        break;
    }
  }

  private convertBioInfoFailure(requestType: PassengerDataSlice, failureMessage: string | undefined, response: SavePassengerResponse) {
    switch (true) {
      case requestType === PassengerDataSlice.BioInfo && failureMessage?.toLowerCase().includes('check itinerary'):
        response.mappedMessageKeys?.push(MessageKey.BIO_INFO_CHECK_ITIN);
        break;
      case requestType === PassengerDataSlice.BioInfo &&
        failureMessage?.toLowerCase().includes('dob already collected, db is not required'):
        response.mappedMessageKeys?.push(MessageKey.BIO_INFO_DOB_ALREADY_COLLECTED);
        break;
      case requestType === PassengerDataSlice.BioInfo &&
        failureMessage?.toLowerCase().includes('name number') &&
        failureMessage?.toLowerCase().includes('invalid'):
        response.mappedMessageKeys?.push(MessageKey.BIO_INFO_INVALID_NAME_NUMBER);
        break;
      case requestType === PassengerDataSlice.BioInfo && failureMessage?.toLowerCase().includes('simultaneous changes'):
        response.mappedMessageKeys?.push(MessageKey.BIO_INFO_SIMULTANEOUS_CHANGES);
        break;
      case requestType === PassengerDataSlice.BioInfo && failureMessage?.toLowerCase().includes('time limit is prior to current time'):
        response.mappedMessageKeys?.push(MessageKey.BIO_INFO_TIME_LIMIT);
        break;
      case requestType === PassengerDataSlice.BioInfo:
        // Generic bio info failure
        response.mappedMessageKeys?.push(MessageKey.BIO_INFO_GENERIC);
        break;
      case requestType === PassengerDataSlice.BioInfoRemove:
        response.mappedMessageKeys?.push(MessageKey.BIO_INFO_REMOVE);
        break;
      default:
        break;
    }
  }

  private convertKTNFailure(requestType: PassengerDataSlice, failureMessage: string | undefined, response: SavePassengerResponse) {
    switch (true) {
      case requestType === PassengerDataSlice.KnownTraveler && failureMessage?.toLowerCase().includes('check itinerary'):
        response.mappedMessageKeys?.push(MessageKey.KTN_CHECK_ITIN);
        break;
      case requestType === PassengerDataSlice.KnownTraveler && failureMessage?.toLowerCase().includes('pnr has been updated'):
        response.mappedMessageKeys?.push(MessageKey.KTN_PNR_UPDATED);
        break;
      case requestType === PassengerDataSlice.KnownTraveler && failureMessage?.toLowerCase().includes('invalid country code'):
        response.mappedMessageKeys?.push(MessageKey.KTN_INVALID_COUNTRY_CODE);
        break;
      case requestType === PassengerDataSlice.KnownTraveler && failureMessage?.toLowerCase().includes('invld tac date'):
        response.mappedMessageKeys?.push(MessageKey.KTN_INVALID_TAC_DATE);
        break;
      case requestType === PassengerDataSlice.KnownTraveler &&
        failureMessage?.toLowerCase().includes('et validations successful, pnr save timeout'):
        response.mappedMessageKeys?.push(MessageKey.KTN_PNR_SAVE_TIMEOUT);
        break;
      case requestType === PassengerDataSlice.KnownTraveler:
        // Generic known traveler failure
        response.mappedMessageKeys?.push(MessageKey.KTN_GENERIC);
        break;
      case requestType === PassengerDataSlice.KnownTravelerRemove:
        response.mappedMessageKeys?.push(MessageKey.KTN_REMOVE);
        break;
      default:
        break;
    }
  }

  private convertRedressFailure(requestType: PassengerDataSlice, failureMessage: string | undefined, response: SavePassengerResponse) {
    switch (true) {
      case requestType === PassengerDataSlice.Redress && failureMessage?.toLowerCase().includes('check itinerary'):
        response.mappedMessageKeys?.push(MessageKey.REDRESS_CHECK_ITIN);
        break;
      case requestType === PassengerDataSlice.Redress:
        // Generic redress failure
        response.mappedMessageKeys?.push(MessageKey.REDRESS_GENERIC);
        break;
      case requestType === PassengerDataSlice.RedressRemove:
        response.mappedMessageKeys?.push(MessageKey.REDRESS_REMOVE);
        break;
      default:
        break;
    }
  }

  private convertInternationalDocFailure(requestType: PassengerDataSlice, response: SavePassengerResponse) {
    switch (true) {
      case requestType === PassengerDataSlice.InternationalDocument:
        // Generic international document failure
        response.mappedMessageKeys?.push(MessageKey.INTERNATIONAL_DOCS_GENERIC);
        break;
      case requestType === PassengerDataSlice.InternationalDocumentRemove:
        response.mappedMessageKeys?.push(MessageKey.INTERNATIONAL_DOCS_REMOVE);
        break;
      case requestType === PassengerDataSlice.InternationalDocumentCountryOfResidenceRemove:
        response.mappedMessageKeys?.push(MessageKey.INTERNATIONAL_DOCS_COUNTRY_OF_RESIDENCE_REMOVE);
        break;
      default:
        break;
    }
  }

  private convertEmergencyContactFailure(requestType: PassengerDataSlice, response: SavePassengerResponse) {
    switch (true) {
      case requestType === PassengerDataSlice.EmergencyContact:
        // Generic emergency contact failure
        response.mappedMessageKeys?.push(MessageKey.EMERGENCY_CONTACT_GENERIC);
        break;
      case requestType === PassengerDataSlice.EmergencyContactRemove:
        response.mappedMessageKeys?.push(MessageKey.EMERGENCY_CONTACT_REMOVE);
        break;
      default:
        break;
    }
  }

  private convertContactTracingFailure(
    requestType: PassengerDataSlice,
    failureMessage: string | undefined,
    response: SavePassengerResponse
  ) {
    if (requestType === PassengerDataSlice.ContactTracingEmail ||
        requestType === PassengerDataSlice.ContactTracingPhone ||
        requestType === PassengerDataSlice.ContactTracingAddress
    ) {
      if (failureMessage?.toLowerCase().includes('timeout')) {
        response.mappedMessageKeys?.push(MessageKey.CONTACT_TRACING_TIMEOUT);
      } else if (failureMessage?.toLowerCase().includes('pnr has been updated')) {
        response.mappedMessageKeys?.push(MessageKey.CONTACT_TRACING_PNR_UPDATED);
      } else if (failureMessage?.toLowerCase().includes('invld tac date')) {
        response.mappedMessageKeys?.push(MessageKey.CONTACT_TRACING_TAC_DATE);
      } else {
        switch (requestType) {
          case PassengerDataSlice.ContactTracingEmail:
            // Generic contact tracing add email failure
            response.mappedMessageKeys?.push(MessageKey.CONTACT_TRACING_ADD_EMAIL_GENERIC);
            break;
          case PassengerDataSlice.ContactTracingPhone:
            // Generic contact tracing add phone number failure
            response.mappedMessageKeys?.push(MessageKey.CONTACT_TRACING_ADD_PHONE_GENERIC);
            break;
          case PassengerDataSlice.ContactTracingAddress:
            // Generic contact tracing add address failure
            response.mappedMessageKeys?.push(MessageKey.CONTACT_TRACING_ADD_ADDRESS_GENERIC);
            break;
        }
      }
    }
  }

  /**
   * Converts the response for contact tracing remove failures.
   */
  private convertContactTracingRemoveFailures(response: SavePassengerResponse, removeDataSlice?: RemovePassengerDataSlice) {
    if (removeDataSlice && removeDataSlice === RemovePassengerDataSlice.ContactTracing) {
      // Check for TAC, this will cause all sorts of errors when editing the reservation
      //  it should be shown above other messages so the agent can resolve it
      if (response.exceptionContent?.toLocaleLowerCase()?.includes('invld tac date') ||
      response.response?.failedList?.some(failedRemove => failedRemove.failureMessage?.toLocaleLowerCase()?.includes('invld tac date'))) {
        response.mappedMessageKeys?.push(MessageKey.REMOVE_CONTACT_TRACING_TAC_ERROR);
      }
      // Check for simultaneous changes
      else if (response.exceptionContent?.toLocaleLowerCase()?.includes('simultaneous changes') ||
          response.response?.failedList?.some(failedRemove => failedRemove.failureMessage?.toLocaleLowerCase()?.includes('simultaneous changes'))) {
        response.mappedMessageKeys?.push(MessageKey.REMOVE_CONTACT_TRACING_SIMULTANEOUS_CHANGES);
      }
      // Check for partial failure
      else if (response.response?.failedList && response.response.failedList.length > 0 && response.response.successList?.length > 0) {
        // Return generic partial failure message
        response.mappedMessageKeys?.push(MessageKey.REMOVE_CONTACT_TRACING_PARTIAL_FAILURE);
      }
      // Check for full failure
      else if ((response.response?.failedList && response.response.failedList.length > 0 && response.response.successList?.length <= 0)
        || response.statusCode !== HttpStatusCode.OK) {
        // Do not check if the error is a timeout, as we want to show the timeout message
        if (!response.exceptionContent?.toLowerCase().includes('timeout')) {
          response.mappedMessageKeys?.push(MessageKey.REMOVE_CONTACT_TRACING_FULL_FAILURE);
        }
      }
    }
  }

  private convertLapInfantFailure(requestType: PassengerDataSlice, response: SavePassengerResponse) {
    if (requestType === PassengerDataSlice.LapInfantRemove) {
      response.mappedMessageKeys?.push(MessageKey.LAP_INFANT_REMOVE_FAILURE);
    }
  }
}
