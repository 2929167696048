import { createAction } from '@ngrx/store';
import { waiverServiceFeatureKey } from './waiver-service.state';

export const initializeWaiverServiceState = createAction(`[${waiverServiceFeatureKey}] Initialize`);

/**
 * Logs an SDC auto waiver to the DAP
 */
export const logSdcAutoWaiver = createAction(
  `[${waiverServiceFeatureKey}] Log SDC auto-waiver`,
  (waiverReason: string, originalAmount: number) => ({
    waiverReason,
    originalAmount,
  })
);

/**
 * Logs an SDC auto waiver to the DAP
 */
export const logSdcAutoWaiverComplete = createAction(`[${waiverServiceFeatureKey}] Complete log SDC auto-waiver`);
