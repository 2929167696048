import { createAction } from '@ngrx/store';
import { AddWheelchairRequest } from '../../../dtos/request/ssr-requests/add-wheelchair-request';
import { SsrDetail } from '../../../dtos/request/ssr-requests/ssr-details';
import { AddSsrStatus } from '../../../dtos/response/ssr-response/add-ssr-status';
import { RemoveSsrStatus } from '../../../dtos/response/ssr-response/remove-ssr-status';
import { SSRCode } from '../../../models/ssr/ssr-code';
import { ssrServiceFeatureKey } from './ssr-service.state';
import { SsrType } from '../../../dtos/request/secure-flight-info/ssr-type';
import { LapInfantData } from 'src/app/models/passenger-forms/lap-infant-data';
import { AddPetRequest } from 'src/app/dtos/request/ssr-requests/add-pet-request';
import { BatchDeleteSsrResponse } from 'src/app/dtos/response/ssr-response/batch-delete-ssr-response';
import { MultiPetRequest } from 'src/app/dtos/request/ssr-requests/multi-pets-request';

export const initializeSsrServiceState = createAction(`[${ssrServiceFeatureKey}] Initialize`);

/**
 * Sets the selected option we chose from SSR drop down UMNR, WCHR, etc
 */
export const setSelectedSsrOption = createAction(
  `[${ssrServiceFeatureKey}] Set selected ssr option property`,
  (selectedSsrOption: string) => ({
    selectedSsrOption,
  })
);

/**
 * Sets ssr form validity
 */
export const setSsrFormValid = createAction(`[${ssrServiceFeatureKey}] Set ssr form valid property`, (ssrFormValid: boolean) => ({
  ssrFormValid,
}));

/**
 * Sets OTHS/TEEN ssr form validity
 */
export const setOthsTeenSsrFormValid = createAction(
  `[${ssrServiceFeatureKey}] Set OTHS/TEEN ssr form valid property`,
  (formValid: boolean) => ({
    formValid,
  })
);

/**
 * Sets ssr select flights form validity
 */
export const setSelectFlightsFormValid = createAction(
  `[${ssrServiceFeatureKey}] Set ssr form valid property`,
  (selectFlightsFormValid: boolean) => ({
    selectFlightsFormValid,
  })
);

/**
 * Sets ssr select guests form validity
 */
export const setSelectGuestsFormValid = createAction(
  `[${ssrServiceFeatureKey}] Set ssr form valid property`,
  (selectGuestsFormValid: boolean) => ({
    selectGuestsFormValid,
  })
);

/**
 * Add wheelchair ssrs to a reservation based on form inputs
 */
export const addWheelchairSsrs = createAction(
  `[${ssrServiceFeatureKey}] Add wheelchair SSRs to reservation`,
  (addWheelchairSsrsInput: AddWheelchairRequest, confirmationCode: string, ssrDetails: SsrDetail[]) => ({
    addWheelchairSsrsInput,
    confirmationCode,
    ssrDetails,
  })
);

/**
 * Add umnr ssrs to a reservation based on form inputs
 */
export const addUmnrSsrs = createAction(
  `[${ssrServiceFeatureKey}] Add umnr SSRs to reservation`,
  (confirmationCode: string, ssrDetails: SsrDetail[]) => ({
    confirmationCode,
    ssrDetails,
  })
);

/**
 * Add OTHS/TEEN ssrs to a reservation based on form inputs
 */
export const addOthsTeenSsrs = createAction(
  `[${ssrServiceFeatureKey}] Add oths/teen SSRs to reservation`,
  (confirmationCode: string, ssrDetails: SsrDetail[]) => ({
    confirmationCode,
    ssrDetails,
  })
);

/**
 * Add BLND ssrs to a reservation based on form inputs
 */
export const addSsrsWithRemark = createAction(
  `[${ssrServiceFeatureKey}] Add SSRs with remark to reservation`,
  (confirmationCode: string, ssrDetails: SsrDetail[], ssrSelectedOption: string, ssrCode: SSRCode) => ({
    confirmationCode,
    ssrDetails,
    ssrSelectedOption,
    ssrCode,
  })
);

/**
 * Add SVAN ssrs to a reservation based on form inputs
 */
export const addSvanSsrs = createAction(
  `[${ssrServiceFeatureKey}] Add SVAN SSRs to reservation`,
  (confirmationCode: string, ssrDetails: SsrDetail[]) => ({
    confirmationCode,
    ssrDetails,
  })
);

/**
 * Add Exst ssrs to a reservation based on form inputs
 */
export const addExstSsrs = createAction(
  `[${ssrServiceFeatureKey}] Add EXST SSRs to reservation`,
  (confirmationCode: string, ssrDetails: SsrDetail[]) => ({
    confirmationCode,
    ssrDetails,
  })
);

/**
 * Add CBBG ssrs to a reservation based on form inputs
 */
export const addCbbgSsrs = createAction(
  `[${ssrServiceFeatureKey}] Add CBBG SSRs to reservation`,
  (confirmationCode: string, ssrDetails: SsrDetail[]) => ({
    confirmationCode,
    ssrDetails,
  })
);

/**
 * Add the LapInfant
 * infantSsrIds, infantDocId - needed to update the lap infant for a segment(s)
 */
export const addNewLapInfant = createAction(
  `[${ssrServiceFeatureKey}] Add new lap infant`,
  (ssrType: SsrType, lapInfantInfos: LapInfantData, infantSsrIds: string[] | null = null, infantDocId: string | null = null) => ({
    ssrType,
    lapInfantInfos,
    infantSsrIds,
    infantDocId,
  })
);

/**
 * Update the LapInfant
 * infantSsrIds, infantDocId - needed to update the lap infant for a segment(s)
 */
export const updateLapInfant = createAction(
  `[${ssrServiceFeatureKey}] Update lap infant`,
  (ssrType: SsrType, lapInfantInfos: LapInfantData, infantSsrIds: string[] | null = null, infantDocId: string | null = null) => ({
    ssrType,
    lapInfantInfos,
    infantSsrIds,
    infantDocId,
  })
);

/**
 * Add PET (PETC, AVIH) ssrs to a reservation based on form inputs
 */
export const addPetsSsrs = createAction(
  `[${ssrServiceFeatureKey} Add Pets Ssr]`,
  (confirmationCode: string, addPetsReuqest: MultiPetRequest) => ({ confirmationCode, addPetsReuqest })
);

/**
 * All SSRs successfully added to reservation
 */
export const addSsrsFullSuccess = createAction(
  `[${ssrServiceFeatureKey}] All SSRs successfully added to reservation`,
  (successfullyAddedSsrs: SsrDetail[] | undefined, addSsrStatus: AddSsrStatus) => ({
    successfullyAddedSsrs,
    addSsrStatus,
  })
);

/**
 * Some SSRs successfully added to reservation
 */
export const addSsrsPartialSuccess = createAction(
  `[${ssrServiceFeatureKey}] Some SSRs successfully added to reservation`,
  (failedSsrs: SsrDetail[] | undefined, successfullyAddedSsrs: SsrDetail[] | undefined, addSsrStatus: AddSsrStatus) => ({
    failedSsrs,
    successfullyAddedSsrs,
    addSsrStatus,
  })
);

/**
 * Failed to add any ssrs to reservation
 */
export const addSsrsFullFailure = createAction(
  `[${ssrServiceFeatureKey}] Failed to add any SSRs to reservation`,
  (addSsrStatus: AddSsrStatus, failedSsrs: SsrDetail[] | undefined) => ({
    addSsrStatus,
    failedSsrs,
  })
);

/**
 * Some SSRs successfully added to reservation
 */
export const addSsrsComplete = createAction(
  `[${ssrServiceFeatureKey}] Add SSRs to reservation complete`,
  (failedSsrs: SsrDetail[], successfullyAddedSsrs: SsrDetail[], addSsrStatus: AddSsrStatus) => ({
    failedSsrs,
    successfullyAddedSsrs,
    addSsrStatus,
  })
);

/**
 * Remove ssr from a reservation based on ConfCode and SsrId
 */
export const removeSsr = createAction(
  `[${ssrServiceFeatureKey}] Remove an SSR from a reservation`,
  (confirmationCode: string, ssrCode: SSRCode, ssrId: string) => ({
    confirmationCode,
    ssrCode,
    ssrId,
  })
);

/**
 * Remove ssr from a reservation based on ConfCode and SsrId
 */
export const removeSsrs = createAction(
  `[${ssrServiceFeatureKey}] Remove multiple SSR from a reservation`,
  (confirmationCode: string, ssrCode: SSRCode, ssrIds: string[]) => ({
    confirmationCode,
    ssrCode,
    ssrIds,
  })
);

/**
 * Batch delete ssrs from a reservation based on ConfCode and SsrIds
 */
export const batchDeleteSsrs = createAction(
  `[${ssrServiceFeatureKey}] Batch delete multiple SSRs from a reservation`,
  (confirmationCode: string, ssrIds: string[]) => ({
    confirmationCode,
    ssrIds,
  })
);

/**
 * Batch delete ssrs from reservation completed
 */
export const batchDeleteSsrsComplete = createAction(
  `[${ssrServiceFeatureKey}] Batch Delete Ssrs Complete`,
  (response: BatchDeleteSsrResponse) => ({
    response,
  })
);

/**
 * Batch delete ssrs from reservation failure
 */
export const batchDeleteSsrsFailure = createAction(
  `[${ssrServiceFeatureKey}] Batch Delete Ssrs Failure`,
  (response: string) => ({
    response,
  })
);

/**
 * Successfully removed ssr from reservation
 */
export const removeSsrSuccess = createAction(
  `[${ssrServiceFeatureKey}] Successfully removed ssr from reservation`,
  (removeSsrStatus: RemoveSsrStatus) => ({
    removeSsrStatus,
  })
);

/**
 * Failed to remove ssr from reservation
 */
export const removeSsrFailure = createAction(
  `[${ssrServiceFeatureKey}] Failed to remove ssr from reservation`,
  (removeSsrStatus: RemoveSsrStatus) => ({
    removeSsrStatus,
  })
);

/**
 * Sets the remove ssr status to whatever value is passed in
 */
export const setRemoveSsrStatus = createAction(`[${ssrServiceFeatureKey}] Set remove ssr status`, (removeSsrStatus: RemoveSsrStatus) => ({
  removeSsrStatus,
}));


/**
 * Sets the remove ssr id to whatever value is passed in
 */
export const setRemoveSsrId = createAction(`[${ssrServiceFeatureKey}] Set remove ssr id`, (removeSsrId: string) => ({
  removeSsrId,
}));
