import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, defer as observableDefer, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { DAPFlightInformation } from '../../dtos/response/flight-status-response/dap-flight-information';
import { FlightInformationResponse } from '../../dtos/response/flight-status-response/flight-information-response';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';

export interface FlightServiceAPI {
  getStatus(carrierCode: string, flightNumber: string, departureDateString: string): Observable<FlightInformationResponse>;
}

@Injectable()
export class FlightService implements FlightServiceAPI {
  constructor(private http: HttpClient, private datePipe: DatePipe) {}

  getStatus(carrierCode: string, flightNumber: string, departureDateString: string): Observable<FlightInformationResponse> {
    const departureDate = this.datePipe.transform(new Date(departureDateString), 'yyyy-MM-dd');
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    return this.http
      .get<DAPFlightInformation>(`api/flight/${carrierCode}/${flightNumber}/status?departureDate=${departureDate}`, options)
      .pipe(
        timeout({
          each: TimeoutLimit.SHORT,
          with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
        }),
        map((flightInformation) => {
          return { success: true, flightInformation, flightNumber, departureDate };
        }),
        catchError((err: HttpErrorResponse) => {
          return observableOf({ success: false, errorMessage: err.statusText, flightNumber, departureDate });
        })
      );
  }
}
