<div *ngIf="(selectedPassengerFeeBagFees$ | async)?.length > 0">
  <div class="app-table dynamic-baggage-table-container">
    <app-dynamic-baggage-header></app-dynamic-baggage-header>
    <div
      class="data-row-container"
      *ngFor="let fee of (selectedPassengerFeeBagFees$ | async) | slice : 0 : maxFeesToShow; let bagCount = index"
    >
      <app-dynamic-baggage-row [fee]="fee" [bagCount]="bagCount + 1"></app-dynamic-baggage-row>
    </div>
  </div>
  <div class="most-significant-carrier"><b>Longest journey carrier:</b> {{ mostSignificantCarrier$ | async }}</div>
</div>
