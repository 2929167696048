import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { RootState } from '../../../state/state';
import { ZipLookupService } from '../zip-lookup.service';
import { lookupCityStateComplete, lookupCityStateResults } from './zip-lookup.actions';

@Injectable()
export class ZipLookupEffects {
  constructor(private store: Store<RootState>, private actions$: Actions, private service: ZipLookupService) { }

  getLocationFromZipResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lookupCityStateResults),
      mergeMap((action) =>
        this.service.getCityStateByZip(action.country, action.zip).pipe(map((result) => lookupCityStateComplete(result.success, result)))
      ),
      catchError(() => of(lookupCityStateComplete(false)))
    )
  );
}
