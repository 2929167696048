import { createAction } from '@ngrx/store';
import { AirlineCode } from 'src/app/models/airlines/airline-code';
import { ReservationFromCouponsRequest } from '../../../dtos/request/reservation-from-coupons-request';
import { ActionCode } from '../../../dtos/response/action-code/action-codes';
import { ReservationCreationResponse } from '../../../dtos/response/reservation-creation-response/reservation-creation-response';
import { Loyalty } from '../../../dtos/response/reservation-response/loyalty';
import { PassengerSeat } from '../../../dtos/response/reservation-response/passenger-seat';
import { Reservation } from '../../../dtos/response/reservation-response/reservation';
import { ReservationResponse } from '../../../dtos/response/reservation-response/reservation-response';
import { PassengerSegmentCoupon } from '../../../models/passenger-segment-coupon';
import { reservationServiceFeatureKey } from './reservation-service.state';
import { CouponStatusData } from 'src/app/dtos/response/vcr-response/coupon-status-data';
import { ReservationCancelResponse } from 'src/app/dtos/response/reservation-response/reservation-cancel-response';
import { LapInfant } from '../../../dtos/response/reservation-response/lap-infant';
import { RemarksAddRemoveResponse } from 'src/app/dtos/response/reservation-response/remarks-add-remove-response';
import { AddDeleteRemarkStatus } from 'src/app/dtos/response/remarks-response/add-delete-remark-status';
import { FlightInformation } from 'src/app/dtos/response/flight-status-response/flight-information';
import { Ticket } from '../../../dtos/response/reservation-response/ticket';
import { AddRemarkRequest } from 'src/app/dtos/request/add-remark-request';
import { RemarksAddResponse } from 'src/app/dtos/response/reservation-response/remarks-add-response';

export const initializeReservationServiceState = createAction(`[${reservationServiceFeatureKey}] Initialize`);

export const loadReservation = createAction(`[${reservationServiceFeatureKey}] Load Reservation`, (confirmationCode: string) => ({
  confirmationCode,
}));

export const loadReservationFromRoute = createAction(`[${reservationServiceFeatureKey}] Load reservation from route`);

export const loadReservationForInitialBooking = createAction(
  `[${reservationServiceFeatureKey}] Load reservation from route with price quote for Initial Booking`
);

export const initialLoadReservation = createAction(
  `[${reservationServiceFeatureKey}] Initial Load Reservation`,
  (confirmationCode: string) => ({
    confirmationCode,
  })
);

export const loadReservationComplete = createAction(
  `[${reservationServiceFeatureKey}] Load Reservation Complete`,
  (reservationResponse: ReservationResponse) => ({
    reservationResponse,
  })
);

/**
 * Start the process of clearing a reservation stored in the reservation state
 */
export const removeReservation = createAction(`[${reservationServiceFeatureKey}] Remove Reservation`, (confirmationCode: string) => ({
  confirmationCode,
}));

/**
 * Start the process of cancelling a non-ticketed reservation
 */
export const cancelReservation = createAction(`[${reservationServiceFeatureKey}] Cancel Reservation`, (confirmationCode: string) => ({
  confirmationCode,
}));

export const cancelReservationComplete = createAction(
  `[${reservationServiceFeatureKey}] Cancel Reservation Complete`,
  (reservationCancelResponse: ReservationCancelResponse) => ({
    reservationCancelResponse,
  })
);

export const cancelReservationReset = createAction(`[${reservationServiceFeatureKey}] Reset Cancel Reservation State`);

export const addPassengerLoyalty = createAction(
  `[${reservationServiceFeatureKey}] Add passenger loyalty`,
  (
    confirmationCode: string,
    passengerHashId: string,
    frequentFlyerCarrier: AirlineCode,
    frequentFlyerNumber: string,
    mileagePlanDetails
  ) => ({
    confirmationCode,
    passengerHashId,
    mileagePlanDetails,
    frequentFlyerCarrier,
    frequentFlyerNumber,
  })
);

export const setPassengerLoyalty = createAction(
  `[${reservationServiceFeatureKey}] Set passenger loyalty`,
  (confirmationCode: string, passengerHashId: string, loyalty: Loyalty) => ({
    confirmationCode,
    passengerHashId,
    loyalty,
  })
);

export const setPassengerRedressNumber = createAction(
  `[${reservationServiceFeatureKey}] Set passenger redress number`,
  (confirmationCode: string, passengerHashId: string, redressNumber: string) => ({
    confirmationCode,
    passengerHashId,
    redressNumber,
  })
);

export const setPassengerKnownTravelerNumber = createAction(
  `[${reservationServiceFeatureKey}] Set passenger known traveler number`,
  (confirmationCode: string, passengerHashId: string, knownTraveler: string) => ({
    confirmationCode,
    passengerHashId,
    knownTraveler,
  })
);

export const syncPassengerLoyalty = createAction(
  `[${reservationServiceFeatureKey}] Sync passenger loyalty`,
  (confirmationCode: string, passengerHashId: string) => ({
    confirmationCode,
    passengerHashId,
  })
);

export const removePassengerLoyalty = createAction(
  `[${reservationServiceFeatureKey}] Remove passenger loyalty`,
  (confirmationCode: string, passengerHashId: string, loyaltyId: string) => ({
    confirmationCode,
    passengerHashId,
    loyaltyId,
  })
);

/**
 * Start the process of creating a reservation to be stored in the placeholder reservation property of the reservation state
 */
export const createPlaceHolderReservation = createAction(
  `[${reservationServiceFeatureKey}] Create placeholder reservation`,
  (reservationFromCoupons: ReservationFromCouponsRequest) => ({
    reservationFromCoupons,
  })
);

/**
 * Finalize the process of creating a placeholder reservation, handles success and failures
 */
export const createPlaceHolderReservationComplete = createAction(
  `[${reservationServiceFeatureKey}] Create placeholder reservation complete`,
  (placeholderReservation: ReservationCreationResponse) => ({
    placeholderReservation,
  })
);

export const setAssociationRevalError = createAction(
  `[${reservationServiceFeatureKey}] Set association revalidation error`,
  (associationRevalError: boolean) => ({
    associationRevalError,
  })
);

/**
 * Sets the selected option we chose from drop down SC/IRROP
 */
export const setSelectedInvoluntaryChangeOption = createAction(
  `[${reservationServiceFeatureKey}] Set selected involuntary change option property`,
  (selectedInvoluntaryChangeOption: string | null) => ({
    selectedInvoluntaryChangeOption,
  })
);

/**
 * Replace segments in currently loaded reservation
 */
export const replaceReservationSegmentsAndPassengers = createAction(
  `[${reservationServiceFeatureKey}] Replace reservation segments`,
  (reservation: Reservation) => ({
    reservation,
  })
);

/**
 * Trigger an effect to call the reservation service for new ticket numbers
 */
export const syncTicketNumbers = createAction(`[${reservationServiceFeatureKey}] Sync ticket numbers`, (confirmationCode: string) => ({
  confirmationCode,
}));

/**
 * Replace the ticket numbers in the store
 */
export const setTicketNumbers = createAction(
  `[${reservationServiceFeatureKey}] Set ticket numbers`,
  (confirmationCode: string, ticketNumbers: Ticket[]) => ({
    confirmationCode,
    ticketNumbers,
  })
);

/**
 * Sets the coupons for a given passenger
 */
export const setPassengerCoupons = createAction(
  `[${reservationServiceFeatureKey}] Set passenger coupons`,
  (confirmationCode: string, passengerHashId: string, coupons: PassengerSegmentCoupon[]) => ({
    confirmationCode,
    passengerHashId,
    coupons,
  })
);

/**
 * Sets the coupons for a given passenger that would previously had been stored in the passenger coupon map
 */
export const setPassengerCouponMapCoupons = createAction(
  `[${reservationServiceFeatureKey}] Set passenger coupon map coupons`,
  (confirmationCode: string, passengerHashId: string, coupons: PassengerSegmentCoupon[]) => ({
    confirmationCode,
    passengerHashId,
    coupons,
  })
);

/**
 * Sets a coupon for a given passenger at the specified index
 */
export const setPassengerCoupon = createAction(
  `[${reservationServiceFeatureKey}] Set passenger coupon`,
  (confirmationCode: string, passengerHashId: string, coupon: PassengerSegmentCoupon, index: number) => ({
    confirmationCode,
    passengerHashId,
    coupon,
    index,
  })
);

/**
 * Sets a seat for a given passenger at the specified index
 */
export const setPassengerSeat = createAction(
  `[${reservationServiceFeatureKey}] Set passenger seat`,
  (confirmationCode: string, passengerHashId: string, seat: PassengerSeat, index: number, segmentHashId: string | null) => ({
    confirmationCode,
    passengerHashId,
    seat,
    index,
    segmentHashId,
  })
);

/**
 * Sets the action code for a given segment based on hashId
 */
export const setSegmentActionCode = createAction(
  `[${reservationServiceFeatureKey}] Set segment action code`,
  (confirmationCode: string, segmentHashId: string, actionCode: ActionCode) => ({
    confirmationCode,
    segmentHashId,
    actionCode,
  })
);

/**
 * Update the 'originalSeats' property per passenger on a successful seat assignment to match the selected seats
 */
export const syncSeats = createAction(`[${reservationServiceFeatureKey}] Sync seats`, (confirmationCode: string) => ({
  confirmationCode,
}));

/**
 * Revert the 'seats' property per passenger to match the 'originalSeats'. This is for when the 'X' is clicked in the seat map
 * header and the user chooses not to save seats.
 */
export const revertSeats = createAction(`[${reservationServiceFeatureKey}] Revert seats`, (confirmationCode: string) => ({
  confirmationCode,
}));

export const setOriginalClassOfService = createAction(
  `[${reservationServiceFeatureKey}] Set segments' original class of services`,
  (confirmationCode: string, coupons: CouponStatusData[]) => ({
    confirmationCode,
    coupons,
  })
);

export const setPassengerLapInfant = createAction(
  `[${reservationServiceFeatureKey}] Set passenger lap infant`,
  (confirmationCode: string, passengerHashId: string, lapInfant: LapInfant) => ({
    confirmationCode,
    passengerHashId,
    lapInfant,
  })
);

export const removePassengerLapInfant = createAction(
  `[${reservationServiceFeatureKey}] Remove passenger lap infant`,
  (confirmationCode: string, passengerHashId: string) => ({
    confirmationCode,
    passengerHashId,
  })
);

export const detectFCAASegments = createAction(
  `[${reservationServiceFeatureKey}] Detect FCAA Segments`,
  (confirmationCode: string, coupons: CouponStatusData[]) => ({ confirmationCode, coupons })
);
export const setSegmentFlightLegInformation = createAction(
  `[${reservationServiceFeatureKey}] Set Segment Flight Leg Information`,
  (confirmationCode: string, flightInformation: FlightInformation | undefined) => ({ confirmationCode, flightInformation })
);

/**
 * Start the process of removing remarks from a reservation
 */
export const removeRemarks = createAction(`[${reservationServiceFeatureKey}] Remove Remarks`, (confirmationCode: string, remarkIds: number[]) => ({
  confirmationCode,
  remarkIds
}));

export const removeRemarksSucceeded = createAction(
  `[${reservationServiceFeatureKey}] Remove Remarks Succeeded`,
  (confirmationCode: string, remarkIds: number[], remarksRemoveResponse: RemarksAddRemoveResponse) => ({
    confirmationCode,
    remarkIds,
    remarksRemoveResponse,
  })
);

export const removeRemarksFailed = createAction(
  `[${reservationServiceFeatureKey}] Remove Remarks Failed`,
  (status: AddDeleteRemarkStatus, errorMessage: string) => ({
    status,
    errorMessage
  })
);

/**
 * Start the process of adding a remark to a reservation
 */
export const addRemark = createAction(`[${reservationServiceFeatureKey}] Add Remarks`, (confirmationCode: string, addRemarkRequest: AddRemarkRequest) => ({
  confirmationCode,
  addRemarkRequest
}));

export const addRemarkSucceeded = createAction(
  `[${reservationServiceFeatureKey}] Add Remark Succeeded`,
  (confirmationCode: string, remarksAddRemoveResponse: RemarksAddRemoveResponse) => ({
    confirmationCode, remarksAddRemoveResponse
  })
);

export const addRemarkFailed = createAction(
  `[${reservationServiceFeatureKey}] Add Remark Failed`,
  (status: AddDeleteRemarkStatus, errorMessage: string) => ({
    status,
    errorMessage
  })
);

/**
 * Sets the add remark status to whatever value is passed in
 */
export const clearAddRemarksStatus = createAction(`[${reservationServiceFeatureKey}] Set add remark status`);

/**
 * Sets the remove remark status to whatever value is passed in
 */
export const clearRemoveRemarksStatus = createAction(`[${reservationServiceFeatureKey}] Set remove remark status`);
