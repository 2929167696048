import { createAction } from '@ngrx/store';
import { travelAdvisoriesServiceFeatureKey } from './travel-advisories-service.state';
import { TravelAdvisoryResponse } from '../../../dtos/response/travel-advisory-response/travel-advisory-response';

export const initializeTravelAdvisoriesServiceState = createAction(`[${travelAdvisoriesServiceFeatureKey}] Initialize`);

export const getTravelAdvisories = createAction(`[${travelAdvisoriesServiceFeatureKey}] Get travel advisories`);

export const getTravelAdvisoriesComplete = createAction(
  `[${travelAdvisoriesServiceFeatureKey}] Get travel advisories complete`,
  (response: TravelAdvisoryResponse) => ({
    response,
  })
);
