import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take, withLatestFrom } from 'rxjs/operators';
import { ExtraSeatType } from '../dtos/response/reservation-response/extra-seat-type';
import { Passenger } from '../dtos/response/reservation-response/passenger';
import { PassengerProperty } from '../dtos/response/reservation-response/passenger-property';
import { Reservation } from '../dtos/response/reservation-response/reservation';
import { Segment } from '../dtos/response/reservation-response/segment';
import { PassengerSeatLocation } from '../dtos/response/seat-map-lookup-response/passenger-seat-location';
import { CouponStatusData } from '../dtos/response/vcr-response/coupon-status-data';
import { getCurrentSegmentHash } from '../features/seat-map/state/seat-map.selectors';
import { PassengerSegmentCoupon } from '../models/passenger-segment-coupon';
import { Seat } from '../models/seat-map/seat';
import { SSRCode } from '../models/ssr/ssr-code';
import { setPassengerCouponMapCoupons, setPassengerSeat } from '../services/reservation-service/state/reservation-service.actions';
import { getRoutedConfirmationCode } from '../services/reservation-service/state/reservation-service.selectors';
import { CouponMapper } from '../services/ticket-service/coupon-mapper';
import { RootState } from '../state/state';
import { PassengerSeat } from '../dtos/response/reservation-response/passenger-seat';

@Injectable({
  providedIn: 'root',
})
export class PassengerUseCase {
  constructor(private store: Store<RootState>) {}

  hasProperty(passenger: Passenger, property: PassengerProperty): boolean {
    return passenger?.properties?.includes(property) ?? false;
  }

  contiguousSeatCount(passenger: Passenger): number {
    return 1 + (passenger.extraSeatRefs?.length ?? 0);
  }

  hasSeatOnSegment(passenger: Passenger, segmentHashId: string): boolean {
    return !!passenger.seats.find((seat) => seat.segmentHashId === segmentHashId)?.row;
  }

  /**
   * Update the reservation to show the new seat info for the current passenger
   */
  assignSeatInReservationState(passenger: Passenger, seat: Seat | undefined, segmentIndex: number, extraSeatType?: ExtraSeatType): void {
    if (seat && seat.row && seat.letter) {
      const passengerSeat: PassengerSeat = {
        letter: seat.letter,
        upsellName: seat.upsellName,
        upsellPrice: seat.upsellPrice,
        upsellPriceBeforeTaxes: seat.upsellPriceBeforeTaxes,
        upsellTaxes: seat.upsellTaxes,
        row: seat.row,
        extraSeatType,
        isWindowSeat: seat.windowSeat,
        isAisleSeat: seat.aisleSeat,
        ancillaryDesignator: seat.ancillaryDesignator,
        primaryProperty: seat.primaryProperty
      };
      this.store
        .pipe(
          select(getRoutedConfirmationCode),
          withLatestFrom(this.store.pipe(select(getCurrentSegmentHash))),
          take(1)
        )
        .subscribe(([confirmationCode, segmentHashId]) =>
          this.store.dispatch(setPassengerSeat(confirmationCode, passenger.hashId, passengerSeat, segmentIndex, segmentHashId))
        );
    }
  }

  getExtraSeatRefByType(passenger: Passenger, extraSeatType: ExtraSeatType): Passenger[] {
    return passenger.extraSeatRefs?.filter((extraSeatRef) =>
      extraSeatRef.seats?.find((seat) => seat.extraSeatType === extraSeatType)) ?? [];
  }

  alaskaEliteTierStatus(passenger: Passenger): string {
    if (!passenger.loyalty) {
      return '';
    }
    return passenger.loyalty.airlineCode === 'AS' ? (passenger.loyalty.tierStatus || '') : '';
  }

  hasSSRs(passenger: Passenger): boolean {
    return passenger.ssrs?.some((ssr) => ssr != null) ?? false;
  }

  hasSSRsInSegment(passenger: Passenger): boolean {
    return passenger.ssrs?.length > 0;
  }

  hasSSRInSegment(passenger: Passenger, ssrCode: SSRCode): boolean {
    return this.hasSSRsInSegment(passenger) && passenger.ssrs.some((ssr) => ssr.serviceCode === ssrCode);
  }

  mapCoupons(reservation: Reservation, vcrCoupons: CouponStatusData[], filteredSegments: Segment[]): void {
    reservation?.passengers?.forEach((passenger) => {
      const passengerCoupons: PassengerSegmentCoupon[] = [];
      filteredSegments?.forEach((segment) => {
        // in order to fix below, need to subscribe to the getCoupons Selector and then use it here
        const passengerCoupon = CouponMapper.getPassengerCoupon(passenger, segment, reservation, vcrCoupons);
        if (passengerCoupon !== null) {
          passengerCoupon.segmentHashId = segment.hashId;
          passengerCoupons.push(passengerCoupon);
        }
      });
      this.store.dispatch(setPassengerCouponMapCoupons(reservation.confirmationCode, passenger.hashId, passengerCoupons));
    });
  }

  public getPassengerSeatLocation(passenger: Passenger, segmentIndex: number): PassengerSeatLocation {
    const passengerSeat = passenger.seats[segmentIndex];
    return {
      letter: passengerSeat.letter || '',
      passenger,
      row: passengerSeat.row,
      upsellPrice: passengerSeat.upsellPrice,
      primaryProperty: passengerSeat.primaryProperty
    };
  }
}
