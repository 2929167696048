import { RootState } from '../../../state/state';
import { PreOrderMealResponse } from 'src/app/dtos/response/pre-order-meal-response/pre-order-meal-response';

export const preorderMealServiceFeatureKey = 'preorderMealService';

export interface State extends RootState {
  preorderMealService: PreorderMealServiceState;
}

export interface PreorderMealServiceState {
  preOrderMeals: PreOrderMealResponse | null;
}

export const initialPreorderMealServiceState: PreorderMealServiceState = {
  preOrderMeals: null,
};
