import { Status } from '../../../models/status';
import { RootState } from '../../../state/state';
import { ReshopResponse } from 'src/app/dtos/response/reshop-response/reshop-response';

export const flightReshopServiceFeatureKey = 'flightReshopService';

export interface State extends RootState {
  flightReshopService: FlightReshopServiceState;
}

export interface FlightReshopServiceState {
  results: ReshopResponse | null;
  status: Status;
  error: string | null;
}

export const initialFlightReshopServiceState: FlightReshopServiceState = {
  results: null,
  status: Status.STABLE,
  error: null,
};
