export enum TimeoutLimit {
  /**
   * 30 seconds
   */
  SHORT = 30000,
  /**
   * 60 seconds, minimum for any request that
   * is required for RAIN to function properly
   */
  MEDIUM = 60000,
  /**
   * 90 seconds
   */
  LONG = 90000,
}
