import { Injectable } from '@angular/core';
import { Segment } from '../dtos/response/reservation-response/segment';
import { AirlineCode } from '../models/airlines/airline-code';
import { AirGroupAirlines } from '../models/airlines/airlines';

@Injectable({
  providedIn: 'root',
})
export class SegmentUseCase {
  public isSaverFare(segment: Segment): boolean {
    return segment.serviceClassCode?.toUpperCase() === 'X' && segment.marketedByAirlineCode?.toUpperCase() === 'AS';
  }

  public isAirGroupOperated(segment: Segment): boolean {
    return segment.operatedByAirlineCode === 'AS'
      ? true
      : AirGroupAirlines.has(AirlineCode[segment.marketedByAirlineCode as AirlineCode]) ||
          AirGroupAirlines.has(AirlineCode[segment.operatedByAirlineCode as AirlineCode]);
  }
}
