import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getRoutedReservation } from '../services/reservation-service/state/reservation-service.selectors';
import { RootState } from '../state/state';

@Injectable({
  providedIn: 'root',
})
export class InvoluntaryChangeGuard  {
  constructor(private store: Store<RootState>) {}

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(getRoutedReservation),
      map((reservation) => !!reservation)
    );
  }
}
