import { Injectable } from '@angular/core';
import { Seat } from 'src/app/models/seat-map/seat';
import { SeatCode } from '../../models/seat-map/seat-code';
import { SeatProperty } from '../../models/seat-map/seat-property';

@Injectable({
  providedIn: 'root',
})
export class SeatMapSeatUtil {
  /**
   * Returns true is the seat is on the seat map but is broken
   */
  isInoperable(seatData: Seat): boolean {
    return this.hasProperty(SeatProperty.Inoperative, seatData);
  }

  /**
   * Returns true is seat is a "windowless seat"
   */
  isWindowlessWindowSeat(seatData: Seat): boolean {
    return this.hasProperty(SeatProperty.Windowless, seatData);
  }

  /**
   * Checks if the seat is flagged as a premium class seat
   */
  isPremiumClass(seatData: Seat): boolean {
    return this.hasProperty(SeatProperty.PremiumClass, seatData) || this.hasProperty(SeatProperty.OtherAirlinePremium, seatData);
  }

  /**
   * Checks if a seat has a given property
   */
  hasProperty(property: SeatProperty, seat: Seat): boolean {
    if (!seat.properties && !seat.primaryProperty) {
      return false;
    }

    return seat.properties?.includes(property) || seat.primaryProperty === property;
  }

  /**
   * If the seat has an upsell price
   */
  hasUpsellPrice(seat: Seat): boolean {
    return seat.upsellPrice !== undefined && seat.upsellPrice !== null;
  }

  /**
   * Returns a seat code based on property
   */
  mapSeatPropertyToCode(property: SeatProperty): string {
    switch (property) {
      case SeatProperty.Inoperative:
        return SeatCode.INOPERATIVE;
      case SeatProperty.UnaccompaniedMinorRecommended:
        return SeatCode.UNACCOMPANIED_MINOR;
      case SeatProperty.AirportAssignOnly:
        return SeatCode.AIRPORT_CONTROL;
      case SeatProperty.PremiumClass:
      case SeatProperty.PreferredPlus:
      case SeatProperty.ExitRowSeat:
      case SeatProperty.OtherAirlinePremium:
        return SeatCode.PREMIUM;
      case SeatProperty.Accessible:
        return SeatCode.DISABLED;
      case SeatProperty.Preferential:
      case SeatProperty.MainPreferred:
        return SeatCode.PREFERRED;
      case SeatProperty.BlockedForInfant:
        return SeatCode.INFANT_BLOCKED;
      case SeatProperty.ZoneRestricted:
        return SeatCode.ZONE_RESTRICTED;
      case SeatProperty.RestrictedRecline:
        return SeatCode.UNDESIRABLE;
      default:
        return '';
    }
  }
}
