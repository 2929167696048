import { Airline } from './airline';
import { AirlineCode } from './airline-code';

/**
 * A map of all relevant Airlines
 */
export const Airlines = new Map<AirlineCode, Airline>([
  [
    AirlineCode.AA,
    {
      airline: 'American Airlines',
      airlineCode: AirlineCode.AA,
      ticketPrefix: '001',
      isMileagePlanPartner: true,
      isOneWorldPartner: true,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.AC,
    {
      airline: 'Air Canada',
      airlineCode: AirlineCode.AC,
      ticketPrefix: '014',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.AF,
    {
      airline: 'Air France',
      airlineCode: AirlineCode.AF,
      ticketPrefix: '057',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.AM,
    {
      airline: 'AeroMexico',
      airlineCode: AirlineCode.AM,
      ticketPrefix: '139',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.AT,
    {
      airline: 'Royal Air Maroc',
      airlineCode: AirlineCode.AT,
      ticketPrefix: '147',
      isMileagePlanPartner: true,
      isOneWorldPartner: true,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.AV,
    {
      airline: 'Avianca',
      airlineCode: AirlineCode.AV,
      ticketPrefix: '134',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.AY,
    {
      airline: 'Finnair',
      airlineCode: AirlineCode.AY,
      ticketPrefix: '105',
      isMileagePlanPartner: true,
      isOneWorldPartner: true,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.AZ,
    {
      airline: 'Alitalia',
      airlineCode: AirlineCode.AZ,
      ticketPrefix: '055',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.BA,
    {
      airline: 'British Airways',
      airlineCode: AirlineCode.BA,
      ticketPrefix: '125',
      isMileagePlanPartner: true,
      isOneWorldPartner: true,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.BF,
    {
      airline: 'French Bee',
      airlineCode: AirlineCode.BF,
      ticketPrefix: '396',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.BR,
    {
      airline: 'Eva Airways',
      airlineCode: AirlineCode.BR,
      ticketPrefix: '695',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.B6,
    {
      airline: 'JetBlue Airways',
      airlineCode: AirlineCode.B6,
      ticketPrefix: '279',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.CA,
    {
      airline: 'Air China Limited',
      airlineCode: AirlineCode.CA,
      ticketPrefix: '999',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.CI,
    {
      airline: 'China Airlines',
      airlineCode: AirlineCode.CI,
      ticketPrefix: '297',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.CM,
    {
      airline: 'Copa Airlines',
      airlineCode: AirlineCode.CM,
      ticketPrefix: '230',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.CX,
    {
      airline: 'Cathay Pacific',
      airlineCode: AirlineCode.CX,
      ticketPrefix: '160',
      isMileagePlanPartner: true,
      isOneWorldPartner: true,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.CZ,
    {
      airline: 'China Southern Airlines',
      airlineCode: AirlineCode.CZ,
      ticketPrefix: '784',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.DE,
    {
      airline: 'Condor',
      airlineCode: AirlineCode.DE,
      ticketPrefix: '881',
      isMileagePlanPartner: true,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.DL,
    {
      airline: 'Delta Airlines',
      airlineCode: AirlineCode.DL,
      ticketPrefix: '006',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.EI,
    {
      airline: 'Aer Lingus',
      airlineCode: AirlineCode.EI,
      ticketPrefix: '053',
      isMileagePlanPartner: true,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.EK,
    {
      airline: 'Emirates',
      airlineCode: AirlineCode.EK,
      ticketPrefix: '176',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.ET,
    {
      airline: 'Ethiopian Airlines',
      airlineCode: AirlineCode.ET,
      ticketPrefix: '071',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.FI,
    {
      airline: 'Icelandair',
      airlineCode: AirlineCode.FI,
      ticketPrefix: '108',
      isMileagePlanPartner: true,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.FJ,
    {
      airline: 'Fiji Airways',
      airlineCode: AirlineCode.FJ,
      ticketPrefix: '260',
      isMileagePlanPartner: true,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.FZ,
    {
      airline: 'Flydubai',
      airlineCode: AirlineCode.FZ,
      ticketPrefix: '141',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.HA,
    {
      airline: 'Hawaiian Airlines',
      airlineCode: AirlineCode.HA,
      ticketPrefix: '173',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.HR,
    {
      airline: 'Hahn Air Lines',
      airlineCode: AirlineCode.HR,
      ticketPrefix: '169',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: false,
      hasBaggageAgreement: false
    },
  ],
  [
    AirlineCode.HU,
    {
      airline: 'Hainan Airlines',
      airlineCode: AirlineCode.HU,
      ticketPrefix: '880',
      isMileagePlanPartner: true,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.HX,
    {
      airline: 'Hong Kong Airlines',
      airlineCode: AirlineCode.HX,
      ticketPrefix: '851',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.IB,
    {
      airline: 'Iberia',
      airlineCode: AirlineCode.IB,
      ticketPrefix: '075',
      isMileagePlanPartner: true,
      isOneWorldPartner: true,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.IG,
    {
      airline: 'Air Italy SPA',
      airlineCode: AirlineCode.IG,
      ticketPrefix: '191',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: false,
      hasBaggageAgreement: false
    },
  ],
  [
    AirlineCode.JJ,
    {
      airline: 'Latam Airlines Brasil',
      airlineCode: AirlineCode.JJ,
      ticketPrefix: '957',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.JL,
    {
      airline: 'Japan Airlines',
      airlineCode: AirlineCode.JL,
      ticketPrefix: '131',
      isMileagePlanPartner: true,
      isOneWorldPartner: true,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.KA,
    {
      airline: 'Cathay Dragon',
      airlineCode: AirlineCode.KA,
      ticketPrefix: '043',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.KE,
    {
      airline: 'Korean Air',
      airlineCode: AirlineCode.KE,
      ticketPrefix: '180',
      isMileagePlanPartner: true,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.KL,
    {
      airline: 'KLM Royal Dutch Airlines',
      airlineCode: AirlineCode.KL,
      ticketPrefix: '074',
      isMileagePlanPartner: true,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.KS,
    {
      airline: 'Penair',
      airlineCode: AirlineCode.KS,
      ticketPrefix: '339',
      isMileagePlanPartner: true,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.LA,
    {
      airline: 'Latam Airlines Group',
      airlineCode: AirlineCode.LA,
      ticketPrefix: '045',
      isMileagePlanPartner: true,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.LH,
    {
      airline: 'Lufthansa',
      airlineCode: AirlineCode.LH,
      ticketPrefix: '220',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.LO,
    {
      airline: 'Lot Polish Airlines',
      airlineCode: AirlineCode.LO,
      ticketPrefix: '080',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.LP,
    {
      airline: 'Latam Airlines Peru',
      airlineCode: AirlineCode.LP,
      ticketPrefix: '544',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.LR,
    {
      airline: 'Avianca Costa Rica',
      airlineCode: AirlineCode.LR,
      ticketPrefix: '133',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.LY,
    {
      airline: 'El Al Israel Airlines',
      airlineCode: AirlineCode.LY,
      ticketPrefix: '114',
      isMileagePlanPartner: true,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.MF,
    {
      airline: 'Xiamen Airlines',
      airlineCode: AirlineCode.MF,
      ticketPrefix: '731',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.MH,
    {
      airline: 'Malaysia Airlines',
      airlineCode: AirlineCode.MH,
      ticketPrefix: '232',
      isMileagePlanPartner: true,
      isOneWorldPartner: true,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.MT,
    {
      airline: 'Thomas Cook Airlines',
      airlineCode: AirlineCode.MT,
      ticketPrefix: '727',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: false,
      hasBaggageAgreement: false
    },
  ],
  [
    AirlineCode.MU,
    {
      airline: 'China Eastern Airlines',
      airlineCode: AirlineCode.MU,
      ticketPrefix: '781',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.MW,
    {
      airline: 'Mokulele Airlines',
      airlineCode: AirlineCode.MW,
      ticketPrefix: '415',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.NH,
    {
      airline: 'All Nippon Airways',
      airlineCode: AirlineCode.NH,
      ticketPrefix: '205',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.NZ,
    {
      airline: 'Air New Zealand',
      airlineCode: AirlineCode.NZ,
      ticketPrefix: '086',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.OO,
    {
      airline: 'Skywest Airlines',
      airlineCode: AirlineCode.OO,
      ticketPrefix: '302',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: true,
      isAirGroup: true,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.OZ,
    {
      airline: 'Asiana Airlines',
      airlineCode: AirlineCode.OZ,
      ticketPrefix: '988',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.PR,
    {
      airline: 'Philippine Airlines',
      airlineCode: AirlineCode.PR,
      ticketPrefix: '079',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.PS,
    {
      airline: 'Ukraine International Airlines',
      airlineCode: AirlineCode.PS,
      ticketPrefix: '566',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.PZ,
    {
      airline: 'Latam Airlines Paraguay',
      airlineCode: AirlineCode.PZ,
      ticketPrefix: '692',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.QF,
    {
      airline: 'Qantas Airways',
      airlineCode: AirlineCode.QF,
      ticketPrefix: '081',
      isMileagePlanPartner: true,
      isOneWorldPartner: true,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.QR,
    {
      airline: 'Qatar Airways',
      airlineCode: AirlineCode.QR,
      ticketPrefix: '157',
      isMileagePlanPartner: true,
      isOneWorldPartner: true,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.QX,
    {
      airline: 'Horizon Air',
      airlineCode: AirlineCode.QX,
      ticketPrefix: '481',
      isMileagePlanPartner: true,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: true,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.RJ,
    {
      airline: 'Royal Jordanian',
      airlineCode: AirlineCode.RJ,
      ticketPrefix: '512',
      isMileagePlanPartner: true,
      isOneWorldPartner: true,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.SA,
    {
      airline: 'South African Airways',
      airlineCode: AirlineCode.SA,
      ticketPrefix: '083',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.SK,
    {
      airline: 'Scandinavian Airlines',
      airlineCode: AirlineCode.SK,
      ticketPrefix: '117',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.SQ,
    {
      airline: 'Singapore Airlines',
      airlineCode: AirlineCode.SQ,
      ticketPrefix: '618',
      isMileagePlanPartner: true,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.SU,
    {
      airline: 'Aeroflot',
      airlineCode: AirlineCode.SU,
      ticketPrefix: '555',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.SV,
    {
      airline: 'Saudia Airlines',
      airlineCode: AirlineCode.SV,
      ticketPrefix: '065',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.SY,
    {
      airline: 'Sun Country',
      airlineCode: AirlineCode.SY,
      ticketPrefix: '337',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: false,
      hasBaggageAgreement: false
    },
  ],
  [
    AirlineCode.S4,
    {
      airline: 'Sata Azores Airlines',
      airlineCode: AirlineCode.S4,
      ticketPrefix: '331',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.S7,
    {
      airline: 'S7 Airlines (JSC Siberia Airlines)',
      airlineCode: AirlineCode.S7,
      ticketPrefix: '421',
      isMileagePlanPartner: true,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: false,
      hasBaggageAgreement: false
    },
  ],
  [
    AirlineCode.TA,
    {
      airline: 'Taca',
      airlineCode: AirlineCode.TA,
      ticketPrefix: '202',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.TK,
    {
      airline: 'Turkish Airlines',
      airlineCode: AirlineCode.TK,
      ticketPrefix: '235',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.TN,
    {
      airline: 'Air Tahiti',
      airlineCode: AirlineCode.TN,
      ticketPrefix: '244',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.TP,
    {
      airline: 'TAP Air Portugal',
      airlineCode: AirlineCode.TP,
      ticketPrefix: '047',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.T0,
    {
      airline: 'Avianca Peru SA',
      airlineCode: AirlineCode.T0,
      ticketPrefix: '530',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: false
    },
  ],
  [
    AirlineCode.UA,
    {
      airline: 'United Airlines',
      airlineCode: AirlineCode.UA,
      ticketPrefix: '016',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.UL,
    {
      airline: 'SriLankan Airlines',
      airlineCode: AirlineCode.UL,
      ticketPrefix: '603',
      isMileagePlanPartner: true,
      isOneWorldPartner: true,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.VA,
    {
      airline: 'Virgin Australia',
      airlineCode: AirlineCode.VA,
      ticketPrefix: '795',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.VS,
    {
      airline: 'Virgin Atlantic',
      airlineCode: AirlineCode.VS,
      ticketPrefix: '932',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: false,
      hasBaggageAgreement: false
    },
  ],
  [
    AirlineCode.VW,
    {
      airline: 'Trasportes Aeromar SA',
      airlineCode: AirlineCode.VW,
      ticketPrefix: '942',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.VX,
    {
      airline: 'Virgin America',
      airlineCode: AirlineCode.VX,
      ticketPrefix: '984',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.WP,
    {
      airline: 'Air Antwerp BV',
      airlineCode: AirlineCode.WP,
      ticketPrefix: '347',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: false,
      hasBaggageAgreement: false
    },
  ],
  [
    AirlineCode.WS,
    {
      airline: 'WestJet',
      airlineCode: AirlineCode.WS,
      ticketPrefix: '838',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.XL,
    {
      airline: 'Latam Airlines Ecuador',
      airlineCode: AirlineCode.XL,
      ticketPrefix: '462',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode.X4,
    {
      airline: 'Air Excursions',
      airlineCode: AirlineCode.X4,
      ticketPrefix: '042',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode['3M'],
    {
      airline: 'Silver Airways',
      airlineCode: AirlineCode['3M'],
      ticketPrefix: '449',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode['4C'],
    {
      airline: 'Latam Airlines Colombia',
      airlineCode: AirlineCode['4C'],
      ticketPrefix: '035',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode['4M'],
    {
      airline: 'Latam Airlines Argentina',
      airlineCode: AirlineCode['4M'],
      ticketPrefix: '469',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode['4N'],
    {
      airline: 'Air North Airlines',
      airlineCode: AirlineCode['4N'],
      ticketPrefix: '287',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode['7H'],
    {
      airline: 'Corvus Airlines',
      airlineCode: AirlineCode['7H'],
      ticketPrefix: '808',
      isMileagePlanPartner: true,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode['9K'],
    {
      airline: 'Cape Air',
      airlineCode: AirlineCode['9K'],
      ticketPrefix: '306',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode['9N'],
    {
      airline: 'Tropic Air',
      airlineCode: AirlineCode['9N'],
      ticketPrefix: '789',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
  [
    AirlineCode['9X'],
    {
      airline: 'Southern Airways',
      airlineCode: AirlineCode['9X'],
      ticketPrefix: '504',
      isMileagePlanPartner: false,
      isOneWorldPartner: false,
      isCpaPartner: false,
      isAirGroup: false,
      hasTicketingAgreement: true,
      hasBaggageAgreement: true
    },
  ],
]);

/**
 * A map of airlines that have a known ticket prefix with key's being 3 character ticket number prefixes NOT airline code
 */
export const AirlinesByTicketPrefix = new Map<string, Airline>(
  Array.from(Airlines.values()) // convert the values from the map into an array
    .filter((airline) => airline.ticketPrefix) // remove values from the array that do not have ticket prefixes
    .map((airline) => [airline.ticketPrefix, airline]) // put the remaining values into the map with the ticketPrefix as the key
);

/**
 * A map of airlines that have a known ticket prefix with key's being airline code
 */
export const AirlinesWithTicketPrefixByAirlineCode = new Map<string, Airline>(
  Array.from(Airlines.values()) // convert the values from the map into an array
    .filter((airline) => airline.ticketPrefix) // remove values from the array that do not have ticket prefixes
    .map((airline) => [airline.airlineCode, airline]) // put the remaining values into the map with the airline code as the key
);

/**
 * A map of OneWorld airlines that have a known ticket prefix with key's being 3 character ticket number prefixes NOT airline code
 */
export const OneWorldAirlinesByTicketPrefix = new Map<string, Airline>(
  Array.from(Airlines.values()) // convert the values from the map into an array
    .filter((airline) => airline.ticketPrefix) // remove values from the array that do not have ticket prefixes
    .filter((airline) => airline.isOneWorldPartner) // remove values from the array that aren't OneWorld partners
    .map((airline) => [airline.ticketPrefix, airline]) // put the remaining values into the map with the ticketPrefix as the key
);

/**
 * A map of airlines that are Alaska Airlines mileage plan partners
 */
export const MileagePlanPartnerAirlines = new Map<AirlineCode, Airline>(
  Array.from(Airlines.values()) // convert the values from the map into an array
    // remove values from the array that are not mileage plan partners
    .filter((airline) => airline.isMileagePlanPartner && airline.airlineCode !== AirlineCode.QX)
    .map((airline) => [airline.airlineCode, airline]) // put the remaining values into the map
);

/**
 * A map of airlines that are OneWorld partners
 */
export const OneWorldPartnerAirlines = new Map<AirlineCode, Airline>(
  Array.from(Airlines.values()) // convert the values from the map into an array
    .filter((airline) => airline.isOneWorldPartner) // remove values from the array that are not OneWorld partners
    .map((airline) => [airline.airlineCode, airline]) // put the remaining values into the map
);

/**
 * A map of airlines that are CPA (capacity purchase agreement) partners
 */
export const CpaPartnerAirlines = new Map<AirlineCode, Airline>(
  Array.from(Airlines.values()) // convert the values from the map into an array
    .filter((airline) => airline.isCpaPartner) // remove values from the array that are not CPA partners
    .map((airline) => [airline.airlineCode, airline]) // put the remaining values into the map
);

/**
 * A map of airlines that are under the Alaska Air Group umbrella
 */
export const AirGroupAirlines = new Map<AirlineCode, Airline>(
  Array.from(Airlines.values()) // convert the values from the map into an array
    .filter((airline) => airline.isAirGroup) // remove values from the array that are not airgroup airlines
    .map((airline) => [airline.airlineCode, airline]) // put the remaining values into the map
);

/**
 * A map of airlines that we have ticketing agreements with
 */
export const TicketingAndBaggageAgreementAirlines = new Map<AirlineCode, Airline>(
  Array.from(Airlines.values()) // convert the values from the map into an array
    // remove values that we do not have ticketing/baggage agreements with
    .filter((airline) => airline.hasTicketingAgreement && airline.hasBaggageAgreement)
    .map((airline) => [airline.airlineCode, airline]) // put the remaining values into the map
);
