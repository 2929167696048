/**
 * Represents a ticket designator attached to a ticket.
 *
 * NOTE: There are a LOT more ticket designators than this. This is just a small sample of them
 * to support features that are currently in development. This list will likely be expanded.
 */
export enum TicketDesignator {
  XPO1 = 'XPO1',
  XPE1 = 'XPE1',
  XPH1 = 'XPH1',
  WHL1 = 'WHL1',
  WHL2 = 'WHL2',
  WHL3 = 'WHL3',
  WHL4 = 'WHL4',
  WHL5 = 'WHL5',
  WHL6 = 'WHL6',
  WHL7 = 'WHL7',
  WHL8 = 'WHL8',
  WHL9 = 'WHL9',
  PKGE = 'PKGE',
  PKGT = 'PKGT',
  PKGP = 'PKGP',
  JTPK = 'JTPK',
  PAWD = 'PAWD',
  ASWD = 'ASWD',
  BRV1 = 'BRV1',
  EC9V = 'EC9V',
  EC99 = 'EC99',
}
