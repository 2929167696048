import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, defer, map, Observable, throwError as observableThrowError, of, timeout } from 'rxjs';
import { AddTcpRequest } from 'src/app/dtos/request/add-tcp-request';
import { AddOsiStatus } from 'src/app/dtos/response/osi-response/add-osi-status';
import { AddTcpDetailResponse } from 'src/app/dtos/response/osi-response/add-tcp-detail-response';
import { AddTcpOsiResponse } from 'src/app/dtos/response/osi-response/add-tcp-osi-response';
import { timeoutError } from 'src/app/models/timeout-error';
import { TimeoutLimit } from 'src/app/models/timeout-limit';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';

export interface OsiServiceAPI {
  addTcpOsi(request: AddTcpRequest, confirmationCode: string): Observable<AddTcpOsiResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class OsiService implements OsiServiceAPI {
  constructor(
    private http: HttpClient,
    private eventService: GlobalEventService) {}

  addTcpOsi(request: AddTcpRequest): Observable<AddTcpOsiResponse> {
    return this.http.post<AddTcpDetailResponse>(`api/reservation/${request.confirmationCode}/osi/tcps`, request).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => this.mapResponse(response)),
      catchError((err) => this.mapError(err))
    );
  }

  private mapResponse(response: AddTcpDetailResponse): AddTcpOsiResponse {
    if (response.osis.filter(tcp => !tcp.isSuccessful)?.length) {
      return {
        status: AddOsiStatus.PARTIAL_SUCCESS,
        failedToAddTcps: response.osis.filter(tcp => !tcp.isSuccessful),
        successfullyAddedTcps: response.osis.filter(tcp => tcp.isSuccessful),
      };
    }
    return {
      status: AddOsiStatus.SUCCESS,
      successfullyAddedTcps: response.osis,
    };
  }

  private mapError(err: any): Observable<AddTcpOsiResponse> {
    if (timeoutError.statusText === err.statusText) {
      this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
      return of({ status: AddOsiStatus.TIMEOUT, errorMessage: 'Timeout' });
    } else {
      return of({
        status: AddOsiStatus.SYSTEM_FAILURE,
        errorMessage: 'Request failed, none of the TCP OSIs were added to the reservation',
      });
    }
  }
}
