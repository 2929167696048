import { Action, createReducer, on } from '@ngrx/store';
import { initialAdvisoriesServiceState } from './advisories-service.state';
import { loadAdvisories, loadAdvisoriesComplete } from './advisories-service.actions';
import { AdvisoriesLookupStatus } from 'src/app/dtos/response/advisories-response/advisories-lookup-status';

const featureReducer = createReducer(
  initialAdvisoriesServiceState,

  on(loadAdvisories, (state) => ({
    ...state,
    advisories: {
      advisories: null,
      lookupStatus: AdvisoriesLookupStatus.LOADING
    }
  })),

  on(loadAdvisoriesComplete, (state, { advisoriesResponse }) => {
    return {
      ...state,
      advisories: advisoriesResponse
    };
  })
);

export function advisoryServiceReducer(state = initialAdvisoriesServiceState, action: Action) {
  return featureReducer(state, action);
}
