import { createAction } from '@ngrx/store';
import { InvoluntaryChangeActionType } from '../../../models/monitoring/InvoluntaryChangeActionType';
import { involuntaryChangeFeatureKey } from './involuntary-change.state';

export const initializeInvoluntaryChangeState = createAction(`[${involuntaryChangeFeatureKey}] Initialize`);

export const setInvoluntaryChangeActionType = createAction(
  `[${involuntaryChangeFeatureKey}] Set involuntary change action type`,
  (actionType: InvoluntaryChangeActionType) => ({
    actionType,
  })
);
