import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '../../environments/environment';
import { OperatingEnvironment } from '../models/operating-environment';

/**
 * This directive prevents the element it is applied to from being added to the DOM when RAIN is running in production mode
 */
@Directive({
  selector: '[appNonProd]',
})
export class NonProdDirective implements OnInit {
  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  ngOnInit(): void {
    if (environment.name !== OperatingEnvironment.Production) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
