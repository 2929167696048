import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, timeout } from 'rxjs/operators';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { defer as observableDefer, Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { BaggageJourneyResponse } from 'src/app/dtos/response/baggage-journey-response/baggage-journey-response';
import { BaggageJourneyDetails } from 'src/app/dtos/response/baggage-journey-response/BaggageJourneyDetails';
import { BaggageJourneyRequestByConfirmationCode } from '../../dtos/request/baggage-journey/baggage-journey-request-by-confirmation-code';

export interface BaggageServiceAPI {
  getBaggageJourney(request: BaggageJourneyRequestByConfirmationCode): Observable<BaggageJourneyResponse>;
}

@Injectable()
export class BaggageService implements BaggageServiceAPI {
  constructor(private http: HttpClient) {}

  getBaggageJourney(request: BaggageJourneyRequestByConfirmationCode): Observable<BaggageJourneyResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    return this.http.post<BaggageJourneyDetails[]>(`api/baggage-journey`, request, options).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((baggageJourneyList) => {
        return {
          baggageJourneyDetailsList: baggageJourneyList,
        };
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          return observableOf({
            baggageJourneyDetailsList: [],
            error: 'TIMEOUT'
          });
        }
        return observableOf({
          baggageJourneyDetailsList: [],
          error: err.error?.message || 'UNKNOWN_ERROR'
        });
      })
    );
  }
}
