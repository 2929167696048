import { Action, createReducer, on } from '@ngrx/store';
import { SdcEligibilityStatus } from '../../../dtos/response/sdc-eligibility-response/sdc-eligibility-status';
import { Status } from '../../../models/status';
import {
  getSdcEligibility,
  initializeSdcEligibilityServiceState,
  setGetSdcEligibilityFailure,
  setGetSdcEligibilitySuccess,
} from './sdc-eligibility-service.actions';
import { initialSdcEligibilityServiceState } from './sdc-eligibility-service.state';

/**
 * Handles all state changes, there is no way to change the state without a reducer AND the reducer never
 * modifies state, it clones the state from the store, changes that cloned state in some way, and then replaces
 * the entire old state with the cloned and modified state.
 */
const featureReducer = createReducer(
  initialSdcEligibilityServiceState,
  /**
   * Set statuses
   */
  on(getSdcEligibility, (state) => ({
    ...state,
    getSdcEligibleRequestStatus: Status.LOADING,
    getSdcEligibleResponseStatus: null,
    isSdcEligible: false,
    sdcIneligibleReasons: [],
    sdcEligibleSegments: [],
  })),
  /**
   * Sets state on getSdcEligibility request success
   */
  on(setGetSdcEligibilitySuccess, (state, { sdcEligibilityResponse }) => ({
    ...state,
    getSdcEligibleRequestStatus: Status.STABLE,
    getSdcEligibleResponseStatus: SdcEligibilityStatus.SUCCESS,
    isSdcEligible: sdcEligibilityResponse?.isEligible,
    sdcIneligibleReasons: sdcEligibilityResponse?.ineligibleReasons,
    sdcEligibleSegments: sdcEligibilityResponse?.eligibleSegments?.map((eligibleSegment) => eligibleSegment.sequence)
  })),
  /**
   * Sets state on getSdcEligibility request failure
   * Set isSdcEligible to false since we were unable to get a success response from the service
   */
  on(setGetSdcEligibilityFailure, (state, { getSdcEligibleResponseStatus }) => ({
    ...state,
    getSdcEligibleRequestStatus: Status.STABLE,
    isSdcEligible: false,
    getSdcEligibleResponseStatus,
  })),
  /**
   * Set the state to the initial value defined in the ticket-service.state file
   */
  on(initializeSdcEligibilityServiceState, (state) => ({ ...state, ...initialSdcEligibilityServiceState }))
);

/**
 * Typescript safe way to export the reducer so that it can be imported in modules
 */
export function sdcEligibilityServiceReducer(state = initialSdcEligibilityServiceState, action: Action) {
  return featureReducer(state, action);
}
