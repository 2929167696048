import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { LoginAttemptStatus } from '../../../dtos/response/login-response/login-attempt-status';
import { LoginService } from '../login.service';
import { login, loginComplete, logout } from './login-service.actions';

@Injectable()
export class LoginServiceEffects {
  constructor(
    private actions$: Actions,
    private loginService: LoginService,
  ) {}
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      exhaustMap((action) => {
        return this.loginService.login(action.username, action.password).pipe(
          map((loginResponse) => {
            if (loginResponse.status === LoginAttemptStatus.SUCCESS) {
              localStorage.setItem('user', JSON.stringify(loginResponse.user));
            }
            return loginComplete(loginResponse.user, loginResponse.status);
          })
        );
      })
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        tap(() => {
          // Clear session data
          localStorage.removeItem('user');
          localStorage.removeItem('session_expiration');
          localStorage.removeItem('access_token');
          localStorage.removeItem('PKCI_verifier');
          localStorage.removeItem('nonce');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('expires_at');
          localStorage.removeItem('access_token_stored_at');
          // Hard reset - do not use Angular router here
          this.hardReset();
        })
      ),
    { dispatch: false }
  );

/*
 * Do a hard navigation to reset application, do not use angular router here
 * This is in its own function so that it can easily be disabled during testing
 */
  public hardReset(): void {
    window.location.replace('/auth');
  }
}
