import { StringReplacementData } from '../models/string-replacement-data';

/**
 * Replaces all instances of a target character(s) with a designated replacement character(s).
 */
export function replaceCharacters(value: string, replacements: StringReplacementData[]) {
  if (value) {
    replacements.forEach((replacement) => {
      value = value.split(replacement.target).join(replacement.newVal);
    });
  }
  return value;
}
