import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ExecuteBlockedAction } from '../../models/blocked-action/action';
import { BlockedActionReason } from '../../models/blocked-action/blocked-action-reason';
import { BlockedAction } from '../../models/blocked-action/blocked-action';

export enum GlobalEvent {
  ACTION_BLOCKED = 1,
  AJAX_START,
  AJAX_END,
  PASSWORD_DECRYPTION_ERROR,
  RESERVATION_LOADED,
  COMPENSATION_LOADED,
  COMPENSATION_REQUESTED,
  RESET_PURCHASABILITY,
  EXIT_SEAT_MAP_FLOW,
  EXIT_MANUAL_ASSOCIATION_FLOW,
  EXIT_INITIAL_BOOKING_FLOW,
  EXIT_INVOLUNTARY_CHANGE_FLOW,
  EXIT_SSR_FLOW,
  EXIT_SAME_DAY_CONFIRM_FLOW,
  RESERVATION_RELOAD,
  SSR_FORM_SUBMIT,
  SSR_DELETE_FAILED,
  PET_SSR_DELETE_FAILED_ON_GENERAL_ERROR,
  PET_SSR_DELETE_FAILED_ON_PAST_DATED_PNR,
  PET_SSR_DELETE_FAILED_ON_SIMUTANEOUS_CHANGES,
  PET_SSR_DELETE_FAILED_ON_UNKNOWN_ERROR,
  SEAT_ASSIGNMENT_CHANGE,
  SEAT_ASSIGNMENT_CHANGES_PERSISTED,
  SEAT_MAP_PASSENGER_LIST_CHANGE,
}

export interface BroadcastEvent {
  key: GlobalEvent;
  data?: any;
}

/**
 * This service allows clients to publish and subscribe to events that are of general interest (i.e. useful in
 * many situations/components/services). The service also stores state based on events so that components that
 * are instantiated after the events have already fired will be able to find out what the state is upon their creation.
 */
@Injectable()
export class GlobalEventService {
  blockedActionReason: BlockedActionReason;

  private _subject = new Subject<BroadcastEvent>();
  private _pendingRequestCount = 0;

  broadcastAjax(key: GlobalEvent.AJAX_START | GlobalEvent.AJAX_END, error?: any) {
    switch (key) {
      case GlobalEvent.AJAX_START:
        this._pendingRequestCount++;
        this._subject.next({ key });
        break;
      case GlobalEvent.AJAX_END:
        if (this._pendingRequestCount > 0 && !--this._pendingRequestCount) {
          this._subject.next({ key, data: error });
        }
        break;
    }
  }

  broadcastActionBlocked(executeBlockedAction: ExecuteBlockedAction) {
    if (this.blockedActionReason) {
      this._subject.next({
        key: GlobalEvent.ACTION_BLOCKED,
        data: {
          reason: this.blockedActionReason,
          executeBlockedAction,
        } as BlockedAction,
      });
    }
  }

  broadcast(key: GlobalEvent, data: any = null) {
    switch (key) {
      case GlobalEvent.PASSWORD_DECRYPTION_ERROR:
      case GlobalEvent.RESERVATION_LOADED:
      case GlobalEvent.COMPENSATION_LOADED:
      case GlobalEvent.COMPENSATION_REQUESTED:
      case GlobalEvent.RESET_PURCHASABILITY:
      case GlobalEvent.EXIT_SEAT_MAP_FLOW:
      case GlobalEvent.EXIT_INVOLUNTARY_CHANGE_FLOW:
      case GlobalEvent.EXIT_MANUAL_ASSOCIATION_FLOW:
      case GlobalEvent.EXIT_INITIAL_BOOKING_FLOW:
      case GlobalEvent.EXIT_SSR_FLOW:
      case GlobalEvent.EXIT_SAME_DAY_CONFIRM_FLOW:
      case GlobalEvent.RESERVATION_RELOAD:
      case GlobalEvent.SSR_FORM_SUBMIT:
      case GlobalEvent.SSR_DELETE_FAILED:
      case GlobalEvent.PET_SSR_DELETE_FAILED_ON_GENERAL_ERROR:
      case GlobalEvent.PET_SSR_DELETE_FAILED_ON_PAST_DATED_PNR:
      case GlobalEvent.PET_SSR_DELETE_FAILED_ON_SIMUTANEOUS_CHANGES:
      case GlobalEvent.PET_SSR_DELETE_FAILED_ON_UNKNOWN_ERROR:
      case GlobalEvent.SEAT_ASSIGNMENT_CHANGE:
      case GlobalEvent.SEAT_ASSIGNMENT_CHANGES_PERSISTED:
      case GlobalEvent.SEAT_MAP_PASSENGER_LIST_CHANGE:
        this._subject.next({ key, data });
        break;
      default:
        break;
    }
  }

  on<T>(key: GlobalEvent): Observable<T> {
    return this._subject.asObservable().pipe(
      filter((event) => event.key === key),
      map((event) => event.data as T)
    );
  }
}
