import { SeatChangeEvent } from '../../../models/seat-map/seat-change-event';
import { RootState } from '../../../state/state';

export const seatMapDisplayServiceFeatureKey = 'seatMapDisplayService';

export interface State extends RootState {
  seatMapDisplayService: SeatMapDisplayServiceState;
}

export interface SeatMapDisplayServiceState {
  changeEvent: SeatChangeEvent | null;
}

export const initialSeatMapDisplayServiceState: SeatMapDisplayServiceState = {
  changeEvent: null
};
