import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PhoneFieldState, phoneFieldFeatureKey } from './phone-field.state';
import { PhoneFieldResponse } from '../../../dtos/response/phone-field-response/phone-field-response';
import { HttpStatusCode } from '../../../dtos/http-status-code';
import { getContactInfoForm } from 'src/app/shared/passenger-forms/state/passenger-forms.selectors';
import { CountryList } from 'src/app/models/countries/country-list';
import { BulkPhoneFieldRequest } from 'src/app/dtos/request/phone-field-request/bulk-phone-field-request';
import { PhoneFieldRequestType } from 'src/app/dtos/request/phone-field-request/phone-field-request-type';

const getPhoneFieldState = createFeatureSelector<PhoneFieldState>(phoneFieldFeatureKey);
export const getPhoneFieldResponse = createSelector(getPhoneFieldState, (state): PhoneFieldResponse | null => state.phoneFieldResponse);
export const getPhoneFieldResponseStatus = createSelector(
  getPhoneFieldResponse, (response): HttpStatusCode | null | undefined => response?.statusCode);

export const getSavePhoneFieldRequest = createSelector(getContactInfoForm, (contactInfoForm) => {
  const phoneCountryCode = contactInfoForm?.phoneNumber?.phoneCountryCode;
  const phoneExtension = CountryList.find((country) => country.code === phoneCountryCode)?.phoneExtension;
  const phoneNumber = contactInfoForm?.phoneNumber?.phoneNumber;
  const email = contactInfoForm?.emailAddress;
  const name = contactInfoForm?.name ?? '';
  const request: BulkPhoneFieldRequest = {
    bulkPhoneFieldRequest: [
      {
        requestType: PhoneFieldRequestType.Phone,
        // For a US Phone Number '555 555 5555' the entry will be '5555555555*USA 1'
        phoneField: phoneNumber?.replace(/\s/g, '') + '*' + phoneCountryCode + ' ' + phoneExtension,
      },
      {
        requestType: PhoneFieldRequestType.Email,
        phoneField: email,
      },
    ],
    receivedFrom: name,
  };
  return request;
});

 export const getPhoneFieldResponseMappedErrorKeys = createSelector(getPhoneFieldResponse, (response) => response?.mappedErrorKeys ?? []);

