import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMDTicketDetailError } from '../../../dtos/response/emd-ticket-detail-response/emd-ticket-detail-error';
import { TicketDetailsError } from '../../../dtos/response/ticket-detail-response/ticket-detail-error';
import { TicketSearchError } from '../../../dtos/response/ticket-search-response/ticket-search-error';
import { VcrStatus } from '../../../dtos/response/vcr-response/vcr-status';
import { RootState } from '../../../state/state';
import { TicketService } from '../ticket.service';
import {
  getAncillaryTicketDetails,
  getAncillaryTicketDetailsFailure,
  getAncillaryTicketDetailsSuccess,
  getEMDTicketDetails,
  getEMDTicketDetailsFailure,
  getEMDTicketDetailsSuccess,
  getTicketDetails,
  getTicketDetailsFailure,
  getTicketDetailsSuccess,
  loadVcr,
  loadVcrFailure,
  loadVcrSuccess,
  reloadVcrSuccess,
  searchByRoute,
  searchByRouteFailure,
  searchByRouteSuccess,
  searchByTicketNumber,
  searchByTicketNumberFailure,
  searchByTicketNumberSuccess,
} from './ticket-service.actions';
import { getAllEMDEntities, getVcrState } from './ticket-service.selectors';

@Injectable()
export class TicketServiceEffects {
  constructor(private actions$: Actions, private ticketService: TicketService, private store: Store<RootState>) {}

  /**
   * Triggered by loadVcr action, gets the vcr state from the store and if it is present and the status is success, returns it.
   * If there is no vcr status in the store, or the status is not success, call the ticketService.getVcr
   */
  getVcr$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadVcr),
      withLatestFrom(this.store.select(getVcrState)),
      mergeMap(([action, cachedVcrResponse]) => {
        if (cachedVcrResponse?.status === VcrStatus.SUCCESS && action.useCache) {
          return of(reloadVcrSuccess(cachedVcrResponse));
        }
        return this.ticketService
          .getVcr(action.ticketNumbers, action.background, action.vcrType)
          .pipe(map((vcrResponse) => loadVcrSuccess(vcrResponse)));
      }),
      catchError(() => of(loadVcrFailure()))
    )
  );

  /**
   * Triggered by searchByRoute action. Calls the ticketService.searchByRoute method, and depending on the result
   * return a searchByRouteSuccess or searchByRouteFailure action
   */
  searchByRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchByRoute),
      mergeMap((action) =>
        this.ticketService
          .searchByRoute(action.ticketSearchByRoute)
          .pipe(
            map((result) =>
              result.success
                ? searchByRouteSuccess(result.searchResults.map((searchResult) => ({ ...searchResult, selected: false, pinned: false })))
                : searchByRouteFailure(result.error)
            )
          )
      ),
      catchError(() => of(searchByRouteFailure(TicketSearchError.UNCAUGHT)))
    )
  );

  /**
   * Triggered by searchByTicketNumber action. Calls the ticketService.searchByTicketNumber method, and depending on the result
   * return a searchByTicketNumberSuccess or searchByTicketNumberFailure action
   */
  searchByTicketNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchByTicketNumber),
      mergeMap((action) =>
        this.ticketService
          .searchByTicketNumber(action.ticketSearchByTicketNumber)
          .pipe(
            map((result) =>
              result.success
                ? searchByTicketNumberSuccess(
                    result.searchResults.map((searchResult) => ({ ...searchResult, selected: false, pinned: false }))
                  )
                : searchByTicketNumberFailure(result.error)
            )
          )
      ),
      catchError(() => of(searchByTicketNumberFailure(TicketSearchError.UNCAUGHT)))
    )
  );

  /**
   * Triggered by getTicketDetails action. Calls the ticketService.getTicketDetails method, and depending on the result
   * return a getTicketDetailsSuccess or getTicketDetailsFailure action
   */
  getTicketDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTicketDetails),
      mergeMap((action) =>
        this.ticketService
          .getTicketDetails(action.ticketNumbers, action.background)
          .pipe(map((result) => (result.success ? getTicketDetailsSuccess(result.ticketDetails) : getTicketDetailsFailure(result.error))))
      ),
      catchError(() => of(getTicketDetailsFailure(TicketDetailsError.UNCAUGHT)))
    )
  );

  /**
   * Triggered by getAncillaryTicketDetails action. Calls the ticketService.getTicketDetails method, and depending on the result
   * return a getTicketDetailsSuccess or getTicketDetailsFailure action
   */
  getAncillaryTicketDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAncillaryTicketDetails),
      mergeMap((action) =>
        this.ticketService
          .getTicketDetails(action.ticketNumbers)
          .pipe(map((result) => (result.success
            ? getAncillaryTicketDetailsSuccess(result.ticketDetails)
            : getAncillaryTicketDetailsFailure(result.error))))
      ),
      catchError(() => of(getAncillaryTicketDetailsFailure(TicketDetailsError.UNCAUGHT)))
    )
  );

  /**
   * Gets EMD details from ticket service using ticket numbers
   */
  getEMDTicketDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEMDTicketDetails),
      // Get EMD Ticket Details from store as dictionary
      withLatestFrom(this.store.select(getAllEMDEntities)),
      // Combine the getEMDTicketDetails action and cached details into a single stream
      switchMap(([action, cachedEMDTicketDetails]) => {
        /** unique ticket numbers from the action */
        const uniqueTicketNumbers = [...new Set<string>(action.ticketNumbers)];
        /** array of actions to be dispatched by the effect */
        const resultActions: TypedAction<string>[] = [];
        /** cached EMD ticket details that had EMDs attached */
        const cachedResultsWithEMDs = uniqueTicketNumbers
          .map((ticketNumber) => cachedEMDTicketDetails?.[ticketNumber])
          .filter((EMDTicketDetails) => EMDTicketDetails?.emd);
        /** ticket number that did not have a cached EMD */
        const uncachedTicketNumbers = uniqueTicketNumbers.filter((ticketNumber) => !cachedEMDTicketDetails?.[ticketNumber]?.emd);
        // if there are cached results, add them to the results array
        if (cachedResultsWithEMDs.length) {
          resultActions.push(getEMDTicketDetailsSuccess(cachedResultsWithEMDs));
        }
        // if there are uncached ticket number, send the network request
        if (uncachedTicketNumbers.length) {
          return this.ticketService.getEMDTicketDetails(uncachedTicketNumbers).pipe(
            switchMap((EMDTicketDetailResponse) => {
              // if the response is successful, add success actions to results array, otherwise add failure actions
              // eslint-disable-next-line no-unused-expressions
              EMDTicketDetailResponse.success
                ? resultActions.push(getEMDTicketDetailsSuccess(EMDTicketDetailResponse.EMDTicketDetails))
                : resultActions.push(getEMDTicketDetailsFailure(uncachedTicketNumbers, EMDTicketDetailResponse.error));
              // return the actions array
              return resultActions;
            }),
            // a hard error is thown
            catchError(() => of(getEMDTicketDetailsFailure(uncachedTicketNumbers, EMDTicketDetailError.UNHANDLED)))
          );
        } else {
          // there were only cached tickets, return the actions array
          return resultActions;
        }
      })
    )
  );
}
