import { Injectable } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { TicketSearchByRoute } from '../dtos/request/ticket-search-request/ticket-search-by-route';
import { TicketSearchByTicketNumber } from '../dtos/request/ticket-search-request/ticket-search-by-ticket-number';
import { TicketSearchByTicketNumberRequest } from '../dtos/request/ticket-search-request/ticket-search-by-ticket-number-request';
import { AirlineCode } from '../models/airlines/airline-code';
import { AirlinesByTicketPrefix, AirlinesWithTicketPrefixByAirlineCode } from '../models/airlines/airlines';
import { StringReplacementData } from '../models/string-replacement-data';
import { dateLimit, DateLimitType } from '../shared/form-input/validators/dateLimit';
import { replaceCharacters } from '../utils/replace-characters';

@Injectable({
  providedIn: 'root',
})
export class CouponRecordSearchUseCase {
  private illegalNameChars: StringReplacementData[] = [{ target: `-`, newVal: ' ' }];

  /**
   * Uses the start date value to dynamically create an end date validator to enforce a max date range
   */
  public getDateValidators(startDateString: string, maxDateDiff: number): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    if (startDateString) {
      const minDate = new Date(new Date(startDateString).toLocaleString('en-US', { timeZone: 'Etc/UTC' }));
      const maxDate = new Date(new Date(startDateString).toLocaleString('en-US', { timeZone: 'Etc/UTC' }));
      maxDate.setDate(minDate.getDate() + maxDateDiff);
      validators.push(dateLimit(minDate, DateLimitType.MIN));
      validators.push(dateLimit(maxDate, DateLimitType.MAX));
    }
    return validators;
  }

  public getTicketPrefix(airlineCode: string): string {
    if (airlineCode === AirlineCode.AS) {
      return '027';
    }
    return AirlinesWithTicketPrefixByAirlineCode.has(airlineCode) ?
      AirlinesWithTicketPrefixByAirlineCode.get(airlineCode).ticketPrefix : '';
  }

  public getAirlineCode(ticketPrefix: string): string {
    if (ticketPrefix === '027') {
      return AirlineCode.AS;
    }

    const airlineCode = AirlinesByTicketPrefix.has(ticketPrefix) ?
    AirlinesByTicketPrefix.get(ticketPrefix).airlineCode : '';

    return airlineCode;
  }

  public grabTicketNumber(formValues: TicketSearchByTicketNumber): TicketSearchByTicketNumberRequest {
    const result = { ...formValues };
    const ticketNumbers: string[] = [result.ticketNumber.toString()];
    return { ticketNumbers };
  }

  /**
   * Removes illegal characters and strips blank values from the form values object
   */
  public getNormalizedByRouteFormData(formValues: TicketSearchByRoute): TicketSearchByRoute {
    // shallow copy to prevent read-only property issue
    const result = { ...formValues };
    result.lastName = replaceCharacters(result.lastName, this.illegalNameChars);
    result.firstName = replaceCharacters(result.firstName, this.illegalNameChars);
    return this.stripBlankValues(formValues);
  }

  /**
   * recursively remove blank/null/undefined values from an object
   */
  private stripBlankValues(formValues: TicketSearchByRoute): TicketSearchByRoute {
    /* eslint-disable */
    for (const prop in formValues) {
      // Have to convert to keyof since any is too broad,
      // then convert value to any since it starts as TicketSearchByRoute and then can be the nested object TicketSearchRoute
      const typedProp = prop as keyof TicketSearchByRoute;
      const typedValue = formValues[typedProp] as any;
      if (typeof formValues[typedProp] === 'object' && typedValue !== null) {
        this.stripBlankValues(typedValue);
        if (Object.keys(typedValue).length === 0) {
          delete formValues[typedProp];
        }
      } else if (typedValue === '' || typedValue === null || typedValue === undefined) {
        delete formValues[typedProp];
      }
    }
    /* eslint-enable */
    return formValues;
  }
}
