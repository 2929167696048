import { Action, createReducer, on } from '@ngrx/store';
import { Status } from '../../../models/status';
import {
  initializeFlightReshopServiceState,
  loadFlightReshopResults,
  loadFlightReshopResultsComplete,
} from './flight-reshop-service.actions';
import { initialFlightReshopServiceState } from './flight-reshop-service.state';

const featureReducer = createReducer(
  initialFlightReshopServiceState,
  on(initializeFlightReshopServiceState, (state) => ({ ...state, ...initialFlightReshopServiceState })),
  on(loadFlightReshopResults, (state) => ({
    ...state,
    results: null,
    status: Status.UPDATING,
    error: null,
  })),
  on(loadFlightReshopResultsComplete, (state, { response, error }) => ({
    ...state,
    results: response ?? null,
    status: Status.STABLE,
    error,
  }))
);

export function flightReshopServiceReducer(state = initialFlightReshopServiceState, action: Action) {
  return featureReducer(state, action);
}
