import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer as observableDefer, Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { SdcEligibility } from '../../dtos/response/sdc-eligibility-response/sdc-eligibility';
import { SdcEligibilityResponse } from '../../dtos/response/sdc-eligibility-response/sdc-eligibility-response';
import { SdcEligibilityStatus } from '../../dtos/response/sdc-eligibility-response/sdc-eligibility-status';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';

export interface SdcEligibilityServiceAPI {
  getSdcEligibility(confirmationCode: string): Observable<SdcEligibilityResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class SdcEligibilityService implements SdcEligibilityServiceAPI {
  constructor(
    private http: HttpClient,
    private eventService: GlobalEventService
  ) { }

  /**
   * Gets the SDC (Same Day Confirm) eligibility for a reservation via the Airport Rebook Service
   */
  getSdcEligibility(confirmationCode: string, background: boolean = true): Observable<SdcEligibilityResponse> {
    const options = {
      headers: new HttpHeaders({ background: background.toString() }),
    };
    return this.http.get<SdcEligibility>(`api/sdc-eligibility/${confirmationCode.toUpperCase()}`, options).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((response) => {
        return { sdcEligibilityResponse: response, status: SdcEligibilityStatus.SUCCESS };
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END);
          return observableOf({ sdcEligibilityResponse: null, status: SdcEligibilityStatus.TIMEOUT });
        }
        return observableOf({ sdcEligibilityResponse: null, status: SdcEligibilityStatus.SYSTEM_FAILURE });
      })
    );
  }
}
