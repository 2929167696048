import { Action, createReducer, on } from '@ngrx/store';
import { Status } from '../../../models/status';
import {
  flightAvailabilityFailure,
  flightAvailabilitySuccess,
  initializeFlightAvailabilityOptions,
  initializeFlightAvailabilityServiceState,
  loadFlightAvailability,
  setArrivalAirportCode,
  setChangedFlights,
  setCurrentFlightAvailabilityIndex,
  setDepartureAirportCode,
  setFlightAvailabilityStabilityStatus,
  setSeatMapViewSegment,
  setSeatMapViewSegmentForSameDayConfirm,
  setSelectedAvailabilityOption,
} from './flight-availability-service.actions';
import {
  flightAvailabilityAdapter,
  initialFlightAvailabilityServiceState,
  initialFlightAvailabilityState,
} from './flight-availability-service.state';

const featureReducer = createReducer(
  initialFlightAvailabilityServiceState,
  on(loadFlightAvailability, (state) => ({
    ...state,
    status: Status.LOADING,
    error: null,
  })),
  // Store the departure airport code
  on(setDepartureAirportCode, (state, { departureAirportCode }) => ({ ...state, departureAirportCode })),
  // Store the arrival airport code
  on(setArrivalAirportCode, (state, { arrivalAirportCode }) => ({ ...state, arrivalAirportCode })),
  // Store the changed flights options from flight change form
  on(setChangedFlights, (state, { changedFlights }) => ({ ...state, changedFlights })),
  // Store the current flight availability index
  on(setCurrentFlightAvailabilityIndex, (state, { currentFlightAvailabilityIndex }) => ({ ...state, currentFlightAvailabilityIndex })),
  // Reset the state of the FlightAvailabilityService slice to it's initial state
  on(initializeFlightAvailabilityServiceState, (state) => ({
    ...state,
    flightAvailability: initialFlightAvailabilityState,
    changedFlights: null,
    arrivalAirportCode: null,
    departureAirportCode: null,
    status: Status.STABLE,
    error: null,
    currentFlightAvailabilityIndex: 0,
  })),
  // Reset the state of the FlightAvailability options
  on(initializeFlightAvailabilityOptions, (state) => ({
    ...state,
    flightAvailability: initialFlightAvailabilityState,
    arrivalAirportCode: null,
    departureAirportCode: null,
  })),
  on(flightAvailabilitySuccess, (state, { response }) => ({
    ...state,
    flightAvailability: flightAvailabilityAdapter.setOne(response, state.flightAvailability),
    status: Status.STABLE,
  })),
  on(flightAvailabilityFailure, (state, { error }) => ({
    ...state,
    error,
    status: Status.STABLE,
  })),
  on(setFlightAvailabilityStabilityStatus, (state, { status }) => ({ ...state, status })),
  // Sets the user selected availability option for a given segment and deselects any other option
  on(setSelectedAvailabilityOption, (state, action) => {
    const availId: string = state.flightAvailability.ids[action.currentAvailabilityIndex] as string;

    if (!state.flightAvailability.entities[availId]) {
      // We're still loading, bail out for now.
      return state;
    }

    const newOptionState = state.flightAvailability.entities[availId].availabilityOptions
      .map((availabilityOption) => ({ ...availabilityOption })) // Make a mutable copy of the availability optionas array
      .map((availabilityOption) => {
        return {
          ...availabilityOption,
          // This will set only the desired option to selected: true
          selected: availabilityOption.hashId === action.availabilityOptionId,
        };
      });

    return {
      ...state,
      flightAvailability: flightAvailabilityAdapter.updateOne(
        {
          id: availId,
          changes: {
            availabilityOptions: newOptionState,
          },
        },
        state.flightAvailability
      ),
    };
  }),
  on(setSeatMapViewSegment, (state, { seatMapViewSegment }) => ({ ...state, seatMapViewSegment })),
  on(setSeatMapViewSegmentForSameDayConfirm,
      (state, { seatMapViewSegmentForSameDayConfirm }) => ({ ...state, seatMapViewSegmentForSameDayConfirm })),
  on(initializeFlightAvailabilityServiceState, (state) => ({ ...state, ...initialFlightAvailabilityServiceState }))
);

export function flightAvailabilityServiceReducer(state = initialFlightAvailabilityServiceState, action: Action) {
  return featureReducer(state, action);
}
