import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  @Input() label: string;
  @Input() invalid: boolean;
  @Input() styles: any;
  @Input() inputName: string;
  @Input() form: UntypedFormGroup;
  @Input() value: string;
  @Input() maxlength?: number;
  @Input() readonly = false;
  @Input() autocomplete = 'on';
  @Input() inputType = 'text';
  @Input() invalidErrorMessage: string;

  @ViewChild('inputElement', { static: false }) inputElement: ElementRef;

  getInputElement(): HTMLInputElement {
    return this.inputElement.nativeElement;
  }
}
