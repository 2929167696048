<div *ngIf="messages && messages.length > 0"
  class="message-bar">
  <alaska-alert buttonLabel="Next"
    type="warning"
    [noAction]="undefined"
    [noIcon]="undefined"
    [noCloseBtn]="true"
    [stacked]="true"
    [customizedContent]="false"
    [fluid]="true">
    <div>
      <div class="msgContent"
        *ngFor="let message of messages; let messageIndex = index">
        <p class="title"><strong>{{message.emphasisText}}</strong></p>
        <p [innerHTML]="innerHtml[messageIndex]"></p>
      </div>
    </div>
  </alaska-alert>
</div>
