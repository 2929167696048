import { RootState } from '../../../state/state';
import { TravelAdvisories } from '../../../dtos/response/travel-advisory-response/travel-advisories';

/**
 * The string name of the travel advisories service "slice" of state
 */
export const travelAdvisoriesServiceFeatureKey = 'travelAdvisoriesService';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  travelAdvisoriesService: TravelAdvisoriesServiceState;
}

/**
 * The state that is used in relation to travel advisories service, it is an extension of root state
 * these values are available in and should be accessed from the root state model
 */
export interface TravelAdvisoriesServiceState {
  travelAdvisories: TravelAdvisories;
  getTravelAdvisoriesError: string;
  getTravelAdvisoriesSuccess: boolean;
}

/**
 * Initial state of the travel advisories service slice of state, used when travel advisories service state is
 * bootstrapped via the reducer in an app module also used when state is reset
 */
export const initialTravelAdvisoriesServiceState: TravelAdvisoriesServiceState = {
  travelAdvisories: {},
  getTravelAdvisoriesError: '',
  getTravelAdvisoriesSuccess: false,
};
