import { Action, createReducer, on } from '@ngrx/store';
import {
  initializeCouponRecordSearchState,
  setCouponRecordSearchAirlineCode,
  setCouponRecordSearchDepartureCity,
  setCouponRecordSearchEndDate,
  setCouponRecordSearchFirstName,
  setCouponRecordSearchLastName,
  setCouponRecordSearchStartDate,
  setCouponRecordSearchTicketNumber,
} from './coupon-record-search.actions';
import { initialCouponRecordSearchState } from './coupon-record-search.state';

const featureReducer = createReducer(
  initialCouponRecordSearchState,
  on(setCouponRecordSearchFirstName, (state, { firstName }) => ({ ...state, firstName })),
  on(setCouponRecordSearchLastName, (state, { lastName }) => ({ ...state, lastName })),
  on(setCouponRecordSearchStartDate, (state, { startDate }) => ({ ...state, startDate })),
  on(setCouponRecordSearchEndDate, (state, { endDate }) => ({ ...state, endDate })),
  on(setCouponRecordSearchDepartureCity, (state, { departureCity }) => ({ ...state, departureCity })),
  on(setCouponRecordSearchAirlineCode, (state, { airlineCode }) => ({ ...state, airlineCode })),
  on(setCouponRecordSearchTicketNumber, (state, { ticketNumber }) => ({ ...state, ticketNumber })),
  on(initializeCouponRecordSearchState, (state) => ({ ...state, ...initialCouponRecordSearchState }))
);

export function couponRecordSearchReducer(state = initialCouponRecordSearchState, action: Action) {
  return featureReducer(state, action);
}
