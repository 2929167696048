import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Tax } from '../dtos/response/reservation-response/tax';
import { ChargePricing } from '../models/line-item-charge/charge-pricing';
import { LineItemCharge } from '../models/line-item-charge/line-item-charge';
import { LineItemChargeType } from '../models/line-item-charge/line-item-charge-type';
import { removeLineItemCharge, resetLineItemCharges, setLineItemCharge } from '../shared/purchase/state/purchase.actions';
import { RootState } from '../state/state';
import { take } from 'rxjs';
import {
  getPassengerOriginalSeatPrice,
  isSeatsPrimaryPropertyMismatch,
} from '../services/reservation-service/state/reservation-service.selectors';
import { getCurrentSegmentHash } from '../features/seat-map/state/seat-map.selectors';

@Injectable({
  providedIn: 'root',
})
export class ChargeUseCase {
  constructor(private store: Store<RootState>) {}

  /**
   * Add or remove a line item charge from the store
   * @param chargeType the type of charge, this value is used in the NGRX id
   * @param passengerId the sabre id or the passenger, used in the NGRX id
   * @param passengerHashId the hashed passenger ID generated by the RAIN API, used in NGRX id
   * @param segmentIndex the segment the charge is associated with
   * @param chargePricing the optional pricing details for the charge, if this value is present
   * line item charge will be added, if it is missing a line item charge will be removed
   */
  processCharge(
    chargeType: LineItemChargeType | undefined,
    passengerId: string,
    passengerHashId: string,
    segmentIndex: number,
    chargePricing?: ChargePricing | null
  ): void {
    if (chargePricing) {
      this.addCharge(
        chargeType,
        chargePricing.basePrice,
        passengerId,
        passengerHashId,
        segmentIndex,
        chargePricing.totalPrice,
        chargePricing.taxes
      );
    } else {
      this.store.dispatch(removeLineItemCharge(chargeType + passengerHashId + segmentIndex));
    }
  }

  private addCharge(
    chargeType: LineItemChargeType | undefined,
    basePrice: number,
    passengerId: string,
    passengerHashId: string,
    segmentIndex: number,
    totalPrice: number,
    taxes: Tax[]
  ): void {
    let lineItemCharge: LineItemCharge | null = null;

    lineItemCharge = this.createSeatCharge(basePrice, passengerId, passengerHashId, segmentIndex, totalPrice, taxes, chargeType);
    if (lineItemCharge) {
      this.store.dispatch(setLineItemCharge(lineItemCharge));
    } else {
      this.store.dispatch(resetLineItemCharges());
    }
  }

  private createSeatCharge(
    basePrice: number,
    passengerId: string,
    passengerHashId: string,
    segmentIndex: number,
    totalPrice: number,
    taxes: Tax[],
    chargeType: LineItemChargeType | undefined
  ): LineItemCharge | null {
    let result: LineItemCharge | null = null;
    let prereservedPrice;
    let isSeatPrimaryPropertyMismatch = false;
    this.store.pipe(select(getCurrentSegmentHash), take(1)).subscribe((segmentHashId) => {
      this.store
        .pipe(select(getPassengerOriginalSeatPrice(passengerHashId, segmentHashId)), take(1))
        .subscribe((price) => (prereservedPrice = price));
      this.store
        .pipe(select(isSeatsPrimaryPropertyMismatch(passengerHashId, segmentHashId || '')), take(1))
        .subscribe((val) => (isSeatPrimaryPropertyMismatch = val));
    });

    const isUpgradingFromPreExistingPremiumClassOrExitRow = totalPrice > 0 && prereservedPrice > 0 && totalPrice > prereservedPrice;

    let amountDueForSegment = isUpgradingFromPreExistingPremiumClassOrExitRow
      ? totalPrice
      : totalPrice - prereservedPrice;

    if (amountDueForSegment < 0) {
      amountDueForSegment = isSeatPrimaryPropertyMismatch ? totalPrice : 0;
    }

    if (amountDueForSegment) {
      result = {
        chargeType,
        basePrice,
        passengerId,
        passengerHashId,
        segmentIndex,
        totalPrice: amountDueForSegment,
        taxes,
      };
    }

    return result;
  }
}
