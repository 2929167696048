import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer as observableDefer, Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { CardOnFile } from '../../dtos/response/card-on-file-response/card-on-file';
import { CardsOnFileResponse } from '../../dtos/response/card-on-file-response/cards-on-file-response';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';

export interface CardOnFileServiceAPI {
  getCardsOnFileForMileagePlanNumbers(mileagePlanNumbers: string[]): Observable<CardsOnFileResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class CardOnFileService implements CardOnFileServiceAPI {
  constructor(private http: HttpClient) {}

  /**
   * Retrieves a list of cards on file for a list of mileage plan numbers
   * Not in PCI scope - only returns last four, name, and credit card type
   */
  getCardsOnFileForMileagePlanNumbers(mileagePlanNumbers: string[]): Observable<CardsOnFileResponse> {
    const body = mileagePlanNumbers ?? [];
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };

    return this.http.post<CardOnFile[]>(`api/card-on-file/lookup`, body, options).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((response) => {
        return {
          success: true,
          cardsOnFile: response
        };
      }),
      catchError((err: HttpErrorResponse) => {
        return observableOf({
          success: false,
        });
      })
    );
  }
}
