import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer as observableDefer, Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { HttpStatusCode } from '../../dtos/http-status-code';
import { ItineraryChangeRequest } from '../../dtos/request/itinerary-request/itinerary-change-request';
import { ItineraryErrorCode } from '../../dtos/response/itinerary-response/itinerary-error-code';
import { ItineraryResponse } from '../../dtos/response/itinerary-response/itinerary-response';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';

export interface ItineraryServiceAPI {
  changeItineraryAndRevalidateTicket(request: ItineraryChangeRequest): Observable<ItineraryResponse>;
  changeItineraryAndReissueTicket(request: ItineraryChangeRequest): Observable<ItineraryResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class ItineraryService implements ItineraryServiceAPI {
  constructor(
    private http: HttpClient,
    private eventService: GlobalEventService,
  ) {}

  changeItineraryAndRevalidateTicket(request: ItineraryChangeRequest): Observable<ItineraryResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'false',
      }),
    };
    return this.http.put<ItineraryResponse>(`api/itinerary/revalidate`, request, options).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((itineraryResponse) => itineraryResponse),
      catchError((err: HttpErrorResponse) => {
        if(timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return observableOf({
            statusCode: HttpStatusCode.GATEWAY_TIMEOUT,
            errorCode: ItineraryErrorCode.TIMEOUT,
            exceptionContent: 'timeout'
          });
        }
        if(err.error?.exceptionContent.toLowerCase().includes('password decryption error')) {
          return observableOf({
            statusCode: err.status,
            errorCode: ItineraryErrorCode.PASSWORD_DECRYPTION_ERROR,
            exceptionContent: err.error?.exceptionContent
          } as ItineraryResponse);
        }
        return observableOf({
          statusCode: err.status,
          errorCode: err.error?.errorCode ?? ItineraryErrorCode.SYSTEM_FAILURE,
          exceptionContent: err.error?.exceptionContent,
          reservation: err.error?.reservation
        } as ItineraryResponse);
      })
    );
  }

  changeItineraryAndReissueTicket(request: ItineraryChangeRequest): Observable<ItineraryResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'false',
      }),
    };
    return this.http.put<ItineraryResponse>(`api/itinerary/reissue`, request, options).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((itineraryResponse) => itineraryResponse),
      catchError((err: HttpErrorResponse) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return observableOf({ statusCode: HttpStatusCode.GATEWAY_TIMEOUT, errorCode: ItineraryErrorCode.TIMEOUT });
        }
        if (err.error?.exceptionContent.toLowerCase().includes('password decryption error')) {
          return observableOf({
            statusCode: err.status,
            errorCode: ItineraryErrorCode.PASSWORD_DECRYPTION_ERROR,
            exceptionContent: err.error?.exceptionContent
          } as ItineraryResponse);
        }
        else {
          return observableOf({
            statusCode: err.status,
            errorCode: err.error?.errorCode ?? ItineraryErrorCode.SYSTEM_FAILURE,
            exceptionContent: err.error?.exceptionContent,
            newTicketNumbers: err.error?.newTicketNumbers,
            failedToReissueTicketNumbers: err.error?.failedToReissueTicketNumbers,
            reservation: err.error?.reservation
          } as ItineraryResponse);
        }
      })
    );
  }
}
