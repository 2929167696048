import { MileagePlanAddCompanionResponse } from 'src/app/dtos/response/mileage-plan-companion-response/mileage-plan-add-companion-response';
import { MileagePlanEditCompanionResponse } from 'src/app/dtos/response/mileage-plan-companion-response/mileage-plan-edit-companion-response';
import { MileagePlanCompanionStatus } from 'src/app/dtos/response/mileage-plan-companion-response/mileage-plan-companion-status';
import { Status } from 'src/app/models/status';
import { RootState } from 'src/app/state/state';
import { MileagePlanRemoveCompanionResponse } from 'src/app/dtos/response/mileage-plan-companion-response/mileage-plan-remove-companion-response';

/**
 * The string name of the mileage plan profile service "slice" of state
 */
export const mileagePlanCompanionServiceFeatureKey = 'mileagePlanCompanionService';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  mileagePlanCompanionService: MileagePlanCompanionServiceState;
}

/**
 * The state that is used in relation to mileage plan companion service, it is an extension of root state
 * these values are available in and should be accessed from the root state model
 */
export interface MileagePlanCompanionServiceState {
  mileagePlanAddCompanionResponse: MileagePlanAddCompanionResponse | null;
  mileagePlanEditCompanionResponse: MileagePlanEditCompanionResponse | null;
  mileagePlanRemoveCompanionResponse: MileagePlanRemoveCompanionResponse | null;
  status: Status;
  error: MileagePlanCompanionStatus | null;
}

export const initialMileagePlanCompanionServiceState: MileagePlanCompanionServiceState = {
  mileagePlanAddCompanionResponse: null,
  mileagePlanEditCompanionResponse: null,
  mileagePlanRemoveCompanionResponse: null,
  status: Status.STABLE,
  error: null
};
