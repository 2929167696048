import { createAction } from '@ngrx/store';
import { voluntaryChangeFeatureKey } from './voluntary-change.state';
import { Passenger } from '../../../dtos/response/reservation-response/passenger';
import { Segment } from '../../../dtos/response/reservation-response/segment';
import { SegmentAndAction } from '../../../models/voluntary-change/segment-and-action';
import { FlightSearchCriteria } from '../../../models/voluntary-change/flight-search-criteria';
import { SelectedFlightResult } from '../../../models/voluntary-change/selected-flight-result';

export const initializeVoluntaryChangeState = createAction(`[${voluntaryChangeFeatureKey}] Initialize`);

export const resetVoluntaryChangeState = createAction(
  `[${voluntaryChangeFeatureKey}] Reset Voluntary Change State`,
  (passengers: Passenger[], segments: Segment[]) => ({
    passengers,
    segments,
  })
);

export const setSelectedGuests = createAction(`[${voluntaryChangeFeatureKey}] Set Selected Passengers`, (guests: Passenger[]) => ({
  guests,
}));

export const setSelectedFlights = createAction(
  `[${voluntaryChangeFeatureKey}] Set Selected Flights`,
  (selectedFlights: SegmentAndAction[]) => ({
    selectedFlights,
  })
);

export const updateFlightChangeAction = createAction(
  `[${voluntaryChangeFeatureKey}] Update Flight Change Action`,
  (segment: Segment | null, action: string | null) => ({
    segment,
    action,
  })
);

export const setSelectedCabinType = createAction(`[${voluntaryChangeFeatureKey}] Set Selected Cabin Type`, (selectedCabinType: string) => ({
  selectedCabinType,
}));

export const setFlightSearchCriteria = createAction(
  `[${voluntaryChangeFeatureKey}] Set Flight Search Criteria`,
  (flightSearchCriteria: FlightSearchCriteria[]) => ({
    flightSearchCriteria,
  })
);

export const updateFlightSearchCriteria = createAction(
  `[${voluntaryChangeFeatureKey}] Update Flight Search Criteria`,
  (flightSearchCriteria: FlightSearchCriteria) => ({
    flightSearchCriteria,
  })
);

export const setSelectedFlightResult = createAction(
  `[${voluntaryChangeFeatureKey}] Set Selected Flight Result`,
  (selectedFlightResult: SelectedFlightResult | null) => ({
    selectedFlightResult,
  })
);
