import { SdcEligibilityStatus } from '../../../dtos/response/sdc-eligibility-response/sdc-eligibility-status';
import { SdcIneligibleReason } from '../../../dtos/response/sdc-eligibility-response/sdc-ineligible-reason';
import { Status } from '../../../models/status';
import { RootState } from '../../../state/state';

export const sdcEligibilityServiceFeatureKey = 'sdcEligibilityService';

export interface State extends RootState {
  sdcEligibilityService: SdcEligibilityServiceState;
}

export interface SdcEligibilityServiceState {
  // List of reasons on why a reservation is not SDC eligible
  sdcIneligibleReasons: SdcIneligibleReason[];
  // List of sequence IDs of SDC eligible segments
  sdcEligibleSegments: string[];
  // Boolean - true if the resevation can have a Same Day Confirm (SDC)
  isSdcEligible: boolean;
  // Response tatus of the get SDC eligibility request: got a response from the service/ timed out/ coule not reach the service
  getSdcEligibleResponseStatus: SdcEligibilityStatus;
  // Request status of the get SDC eligibility request: stable (request complete)/ loading (request still processing)
  getSdcEligibleRequestStatus: Status;
}

export const initialSdcEligibilityServiceState: SdcEligibilityServiceState = {
  sdcIneligibleReasons: [],
  sdcEligibleSegments: [],
  isSdcEligible: false,
  getSdcEligibleResponseStatus: SdcEligibilityStatus.UNCAUGHT,
  getSdcEligibleRequestStatus: Status.STABLE,
};
