import { Action, createReducer, on } from '@ngrx/store';
import { initializeItineraryState, reissueComplete, revalComplete } from './itinerary.actions';
import { initialItineraryState } from './itinerary.state';

const featureReducer = createReducer(
  initialItineraryState,
  on(initializeItineraryState, (state) => ({ ...state, ...initialItineraryState })),
  on(revalComplete, (state, { response }) => ({...state, revalResponse: response})),
  on(reissueComplete, (state, { response }) => ({...state, reissueResponse: response}))
);

export function itineraryReducer(state = initialItineraryState, action: Action) {
  return featureReducer(state, action);
}
