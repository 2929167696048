import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, takeUntil, withLatestFrom } from 'rxjs';
import { PassengerInfo } from '../../../dtos/response/bags-and-ancillaries-response/passenger-info';
import { ReservationLookupStatus } from '../../../dtos/response/reservation-response/reservation-lookup-status';
import { setGetBagsAndAncillaryFeesStatus, setSelectedBagAndAncillaryFeePassenger } from '../../../services/fee-service/state/fee.actions';
import {
  getBagsAndAncillaryFeesError,
  getBagsAndAncillaryFeesPassengersList,
  getBagsAndAncillaryFeesStatus,
  getHasBagsAndAncillaryFeesError,
  getIsLoadedBagsAndAncillaryFees,
  getSelectedBagAndAncillaryFeePassengerBagFees,
} from '../../../services/fee-service/state/fee.selectors';
import {
  getHasItinerary,
  getIsGroupReservation,
  getPassengersCount,
  getRoutedReservationSuccessStatus,
} from '../../../services/reservation-service/state/reservation-service.selectors';
import { RootState } from '../../../state/state';
import { addJetStreamShortMessage, resetJetStreamShortMessages } from '../../../services/message-service/state/message.actions';
import { MessageKey } from '../../../models/message/message-key';
import { parseSize, parseWeight } from 'src/app/utils/baggage-parser';
import { Status } from 'src/app/models/status';

@Component({
  selector: 'app-dynamic-baggage',
  templateUrl: './dynamic-baggage.component.html',
  styleUrls: ['./dynamic-baggage.component.scss'],
})
export class DynamicBaggageComponent implements OnInit, OnDestroy {
  passengerFees$: Observable<PassengerInfo[]>;
  bagsAndAncillaryFeesStatus$: Observable<Status>;
  dynamicBaggageContentLoaded: boolean;
  showAdvisory: boolean;
  hasItinerary: boolean;
  isGroupReservation: boolean;
  passengerCount: number;
  hasBaggageError: boolean;
  baggageError: string;
  allBagsHaveNoData = false;
  noIdentifiedFees = false;
  Status = Status;
  private onDestroy$ = new Subject<void>();

  constructor(private store: Store<RootState>) {}

  ngOnInit(): void {
    this.store
      .pipe(
        select(getRoutedReservationSuccessStatus),
        withLatestFrom(
          this.store.pipe(select(getHasItinerary)),
          this.store.pipe(select(getIsGroupReservation)),
          this.store.pipe(select(getPassengersCount))
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(([routedReservationStatus, hasItinerary, isGroupReservation, passengerCount]) => {
        if (routedReservationStatus === ReservationLookupStatus.SUCCESS) {
          this.hasItinerary = hasItinerary;
          this.isGroupReservation = isGroupReservation;
          this.passengerCount = passengerCount;
          this.handleBaggageAdvisory();
        }
      });
    this.store.pipe(select(getHasBagsAndAncillaryFeesError), takeUntil(this.onDestroy$)).subscribe((hasBaggageError) => {
      this.hasBaggageError = hasBaggageError;
      if (hasBaggageError) {
        this.store.pipe(select(getBagsAndAncillaryFeesError), takeUntil(this.onDestroy$)).subscribe((error) => {
          this.baggageError = error;
        });
      }
      this.handleBaggageAdvisory();
    });
    this.store.pipe(select(getIsLoadedBagsAndAncillaryFees), takeUntil(this.onDestroy$)).subscribe((isLoaded) => {
      if (!isLoaded) {
        this.showAdvisory = false;
      }
      this.dynamicBaggageContentLoaded = isLoaded;
    });
    this.store.pipe(select(getSelectedBagAndAncillaryFeePassengerBagFees), takeUntil(this.onDestroy$)).subscribe((fees) => {
      if (fees?.length > 0) {
        this.allBagsHaveNoData = fees.every(
          (fee) => parseWeight(fee.description?.details) === 'No data' || parseSize(fee.description?.details) === 'No data'
        );
        if (this.allBagsHaveNoData) {
          this.handleBaggageAdvisory();
        }
      } else if (this.dynamicBaggageContentLoaded) {
        this.noIdentifiedFees = true;
        this.handleBaggageAdvisory();
      }
    });

    this.bagsAndAncillaryFeesStatus$ = this.store.pipe(select(getBagsAndAncillaryFeesStatus));
    this.passengerFees$ = this.store.pipe(select(getBagsAndAncillaryFeesPassengersList));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.store.dispatch(setGetBagsAndAncillaryFeesStatus(null));
  }

  onSelect(selectedAction: any) {
    this.store.dispatch(setSelectedBagAndAncillaryFeePassenger(selectedAction.target.value));
  }

  handleBaggageAdvisory() {
    this.store.dispatch(resetJetStreamShortMessages());
    if (!this.hasItinerary) {
      this.showAdvisory = true;
      this.store.dispatch(addJetStreamShortMessage(MessageKey.BAGGAGE_NO_ITIN));
    } else if (this.isGroupReservation) {
      this.showAdvisory = true;
      this.store.dispatch(addJetStreamShortMessage(MessageKey.BAGGAGE_GROUPS));
    } else if (this.passengerCount >= 9) {
      this.showAdvisory = true;
      this.store.dispatch(addJetStreamShortMessage(MessageKey.BAGGAGE_GUEST_COUNT));
    } else if (this.hasBaggageError && !!this.baggageError && this.baggageError.includes('WeightBasedCarrier')) {
      this.showAdvisory = true;
      this.store.dispatch(addJetStreamShortMessage(MessageKey.BAGGAGE_WEIGHT_BASED_CARRIER));
    } else if (this.hasBaggageError || this.allBagsHaveNoData || this.noIdentifiedFees) {
      this.showAdvisory = true;
      this.store.dispatch(addJetStreamShortMessage(MessageKey.BAGGAGE_GENERIC));
    } else {
      this.showAdvisory = false;
    }
  }
}
