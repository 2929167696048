import { Country } from './country';
import { CountryCode } from './country-code';

export const CountryList: Country[] = [
  {
    name: 'United States',
    code: CountryCode.USA,
    phoneExtension: '1',
  },
  {
    name: 'Canada',
    code: CountryCode.CAN,
    phoneExtension: '1',
  },
  {
    name: 'Mexico',
    code: CountryCode.MEX,
    phoneExtension: '52',
  },
  {
    name: 'United Kingdom',
    code: CountryCode.GBR,
    phoneExtension: '44',
  },
  {
    name: 'Belize',
    code: CountryCode.BLZ,
    phoneExtension: '501',
  },
  {
    name: 'Costa Rica',
    code: CountryCode.CRI,
    phoneExtension: '506',
  },
  {
    name: 'Afghanistan',
    code: CountryCode.AFG,
    phoneExtension: '93',
  },
  {
    name: 'Aland Islands',
    code: CountryCode.ALA,
    phoneExtension: '358',
  },
  {
    name: 'Albania',
    code: CountryCode.ALB,
    phoneExtension: '355',
  },
  {
    name: 'Algeria',
    code: CountryCode.DZA,
    phoneExtension: '213',
  },
  {
    name: 'American Samoa',
    code: CountryCode.ASM,
    phoneExtension: '1',
  },
  {
    name: 'Andorra',
    code: CountryCode.AND,
    phoneExtension: '376',
  },
  {
    name: 'Angola',
    code: CountryCode.AGO,
    phoneExtension: '244',
  },
  {
    name: 'Anguilla',
    code: CountryCode.AIA,
    phoneExtension: '1',
  },
  {
    name: 'Antarctica',
    code: CountryCode.ATA,
    phoneExtension: '672',
  },
  {
    name: 'Antigua and Barbuda',
    code: CountryCode.ATG,
    phoneExtension: '1',
  },
  {
    name: 'Argentina',
    code: CountryCode.ARG,
    phoneExtension: '54',
  },
  {
    name: 'Armenia',
    code: CountryCode.ARM,
    phoneExtension: '374',
  },
  {
    name: 'Aruba',
    code: CountryCode.ABW,
    phoneExtension: '297',
  },
  {
    name: 'Australia',
    code: CountryCode.AUS,
    phoneExtension: '61',
  },
  {
    name: 'Austria',
    code: CountryCode.AUT,
    phoneExtension: '43',
  },
  {
    name: 'Azerbaijan',
    code: CountryCode.AZE,
    phoneExtension: '994',
  },
  {
    name: 'Bahamas',
    code: CountryCode.BHS,
    phoneExtension: '1',
  },
  {
    name: 'Bahrain',
    code: CountryCode.BHR,
    phoneExtension: '973',
  },
  {
    name: 'Bangladesh',
    code: CountryCode.BGD,
    phoneExtension: '880',
  },
  {
    name: 'Barbados',
    code: CountryCode.BRB,
    phoneExtension: '1',
  },
  {
    name: 'Belarus',
    code: CountryCode.BLR,
    phoneExtension: '375',
  },
  {
    name: 'Belgium',
    code: CountryCode.BEL,
    phoneExtension: '32',
  },
  {
    name: 'Benin',
    code: CountryCode.BEN,
    phoneExtension: '229',
  },
  {
    name: 'Bermuda',
    code: CountryCode.BMU,
    phoneExtension: '1',
  },
  {
    name: 'Bhutan',
    code: CountryCode.BTN,
    phoneExtension: '975',
  },
  {
    name: 'Bolivia',
    code: CountryCode.BOL,
    phoneExtension: '591',
  },
  {
    name: 'Bonaire, Saint Eustatius and Saba',
    code: CountryCode.BES,
    phoneExtension: '599',
  },
  {
    name: 'Bosnia and Herzegovina',
    code: CountryCode.BIH,
    phoneExtension: '387',
  },
  {
    name: 'Botswana',
    code: CountryCode.BWA,
    phoneExtension: '267',
  },
  {
    name: 'Bouvet Island',
    code: CountryCode.BVT,
    phoneExtension: '47',
  },
  {
    name: 'Brazil',
    code: CountryCode.BRA,
    phoneExtension: '55',
  },
  {
    name: 'British Indian Ocean Territory',
    code: CountryCode.IOT,
    phoneExtension: '246',
  },
  {
    name: 'British Virgin Islands',
    code: CountryCode.VGB,
    phoneExtension: '1',
  },
  {
    name: 'Brunei',
    code: CountryCode.BRN,
    phoneExtension: '673',
  },
  {
    name: 'Bulgaria',
    code: CountryCode.BGR,
    phoneExtension: '359',
  },
  {
    name: 'Burkina Faso',
    code: CountryCode.BFA,
    phoneExtension: '226',
  },
  {
    name: 'Burundi',
    code: CountryCode.BDI,
    phoneExtension: '257',
  },
  {
    name: 'Cambodia',
    code: CountryCode.KHM,
    phoneExtension: '855',
  },
  {
    name: 'Cameroon',
    code: CountryCode.CMR,
    phoneExtension: '237',
  },
  {
    name: 'Cape Verde',
    code: CountryCode.CPV,
    phoneExtension: '238',
  },
  {
    name: 'Cayman Islands',
    code: CountryCode.CYM,
    phoneExtension: '1',
  },
  {
    name: 'Central African Republic',
    code: CountryCode.CAF,
    phoneExtension: '236',
  },
  {
    name: 'Chad',
    code: CountryCode.TCD,
    phoneExtension: '235',
  },
  {
    name: 'Chile',
    code: CountryCode.CHL,
    phoneExtension: '56',
  },
  {
    name: 'China',
    code: CountryCode.CHN,
    phoneExtension: '86',
  },
  {
    name: 'Christmas Island',
    code: CountryCode.CXR,
    phoneExtension: '61',
  },
  {
    name: 'Cocos Islands',
    code: CountryCode.CCK,
    phoneExtension: '61',
  },
  {
    name: 'Colombia',
    code: CountryCode.COL,
    phoneExtension: '57',
  },
  {
    name: 'Comoros',
    code: CountryCode.COM,
    phoneExtension: '269',
  },
  {
    name: 'Cook Islands',
    code: CountryCode.COK,
    phoneExtension: '682',
  },
  {
    name: 'Croatia',
    code: CountryCode.HRV,
    phoneExtension: '385',
  },
  {
    name: 'Cuba',
    code: CountryCode.CUB,
    phoneExtension: '53',
  },
  {
    name: 'Curacao',
    code: CountryCode.CUW,
    phoneExtension: '599',
  },
  {
    name: 'Cyprus',
    code: CountryCode.CYP,
    phoneExtension: '357',
  },
  {
    name: 'Czech Republic',
    code: CountryCode.CZE,
    phoneExtension: '420',
  },
  {
    name: 'Democratic Republic of the Congo',
    code: CountryCode.COD,
    phoneExtension: '243',
  },
  {
    name: 'Denmark',
    code: CountryCode.DNK,
    phoneExtension: '45',
  },
  {
    name: 'Djibouti',
    code: CountryCode.DJI,
    phoneExtension: '253',
  },
  {
    name: 'Dominica',
    code: CountryCode.DMA,
    phoneExtension: '1',
  },
  {
    name: 'Dominican Republic',
    code: CountryCode.DOM,
    phoneExtension: '1',
  },
  {
    name: 'East Timor',
    code: CountryCode.TLS,
    phoneExtension: '670',
  },
  {
    name: 'Ecuador',
    code: CountryCode.ECU,
    phoneExtension: '593',
  },
  {
    name: 'Egypt',
    code: CountryCode.EGY,
    phoneExtension: '20',
  },
  {
    name: 'El Salvador',
    code: CountryCode.SLV,
    phoneExtension: '503',
  },
  {
    name: 'Equatorial Guinea',
    code: CountryCode.GNQ,
    phoneExtension: '240',
  },
  {
    name: 'Eritrea',
    code: CountryCode.ERI,
    phoneExtension: '291',
  },
  {
    name: 'Estonia',
    code: CountryCode.EST,
    phoneExtension: '372',
  },
  {
    name: 'Ethiopia',
    code: CountryCode.ETH,
    phoneExtension: '251',
  },
  {
    name: 'Falkland Islands',
    code: CountryCode.FLK,
    phoneExtension: '500',
  },
  {
    name: 'Faroe Islands',
    code: CountryCode.FRO,
    phoneExtension: '298',
  },
  {
    name: 'Fiji',
    code: CountryCode.FJI,
    phoneExtension: '679',
  },
  {
    name: 'Finland',
    code: CountryCode.FIN,
    phoneExtension: '358',
  },
  {
    name: 'France',
    code: CountryCode.FRA,
    phoneExtension: '33',
  },
  {
    name: 'French Guiana',
    code: CountryCode.GUF,
    phoneExtension: '594',
  },
  {
    name: 'French Polynesia',
    code: CountryCode.PYF,
    phoneExtension: '689',
  },
  {
    name: 'French Southern Territories',
    code: CountryCode.ATF,
    phoneExtension: '262',
  },
  {
    name: 'Gabon',
    code: CountryCode.GAB,
    phoneExtension: '241',
  },
  {
    name: 'Gambia',
    code: CountryCode.GMB,
    phoneExtension: '220',
  },
  {
    name: 'Georgia',
    code: CountryCode.GEO,
    phoneExtension: '995',
  },
  {
    name: 'Germany',
    code: CountryCode.DEU,
    phoneExtension: '49',
  },
  {
    name: 'Ghana',
    code: CountryCode.GHA,
    phoneExtension: '233',
  },
  {
    name: 'Gibraltar',
    code: CountryCode.GIB,
    phoneExtension: '350',
  },
  {
    name: 'Greece',
    code: CountryCode.GRC,
    phoneExtension: '30',
  },
  {
    name: 'Greenland',
    code: CountryCode.GRL,
    phoneExtension: '299',
  },
  {
    name: 'Grenada',
    code: CountryCode.GRD,
    phoneExtension: '1',
  },
  {
    name: 'Guadeloupe',
    code: CountryCode.GLP,
    phoneExtension: '590',
  },
  {
    name: 'Guam',
    code: CountryCode.GUM,
    phoneExtension: '1',
  },
  {
    name: 'Guatemala',
    code: CountryCode.GTM,
    phoneExtension: '502',
  },
  {
    name: 'Guernsey',
    code: CountryCode.GGY,
    phoneExtension: '44',
  },
  {
    name: 'Guinea',
    code: CountryCode.GIN,
    phoneExtension: '224',
  },
  {
    name: 'Guinea-Bissau',
    code: CountryCode.GNB,
    phoneExtension: '245',
  },
  {
    name: 'Guyana',
    code: CountryCode.GUY,
    phoneExtension: '592',
  },
  {
    name: 'Haiti',
    code: CountryCode.HTI,
    phoneExtension: '509',
  },
  {
    name: 'Honduras',
    code: CountryCode.HND,
    phoneExtension: '504',
  },
  {
    name: 'Hong Kong',
    code: CountryCode.HKG,
    phoneExtension: '852',
  },
  {
    name: 'Hungary',
    code: CountryCode.HUN,
    phoneExtension: '36',
  },
  {
    name: 'Iceland',
    code: CountryCode.ISL,
    phoneExtension: '354',
  },
  {
    name: 'India',
    code: CountryCode.IND,
    phoneExtension: '91',
  },
  {
    name: 'Indonesia',
    code: CountryCode.IDN,
    phoneExtension: '62',
  },
  {
    name: 'Iran',
    code: CountryCode.IRN,
    phoneExtension: '98',
  },
  {
    name: 'Iraq',
    code: CountryCode.IRQ,
    phoneExtension: '964',
  },
  {
    name: 'Ireland',
    code: CountryCode.IRL,
    phoneExtension: '353',
  },
  {
    name: 'Isle of Man',
    code: CountryCode.IMN,
    phoneExtension: '44',
  },
  {
    name: 'Israel',
    code: CountryCode.ISR,
    phoneExtension: '972',
  },
  {
    name: 'Italy',
    code: CountryCode.ITA,
    phoneExtension: '39',
  },
  {
    name: 'Ivory Coast',
    code: CountryCode.CIV,
    phoneExtension: '225',
  },
  {
    name: 'Jamaica',
    code: CountryCode.JAM,
    phoneExtension: '1',
  },
  {
    name: 'Japan',
    code: CountryCode.JPN,
    phoneExtension: '81',
  },
  {
    name: 'Jersey',
    code: CountryCode.JEY,
    phoneExtension: '44',
  },
  {
    name: 'Jordan',
    code: CountryCode.JOR,
    phoneExtension: '962',
  },
  {
    name: 'Kazakhstan',
    code: CountryCode.KAZ,
    phoneExtension: '7',
  },
  {
    name: 'Kenya',
    code: CountryCode.KEN,
    phoneExtension: '254',
  },
  {
    name: 'Kiribati',
    code: CountryCode.KIR,
    phoneExtension: '686',
  },
  {
    name: 'Kosovo',
    code: CountryCode.XXK,
    phoneExtension: '383',
  },
  {
    name: 'Kuwait',
    code: CountryCode.KWT,
    phoneExtension: '965',
  },
  {
    name: 'Kyrgyzstan',
    code: CountryCode.KGZ,
    phoneExtension: '996',
  },
  {
    name: 'Laos',
    code: CountryCode.LAO,
    phoneExtension: '856',
  },
  {
    name: 'Latvia',
    code: CountryCode.LVA,
    phoneExtension: '371',
  },
  {
    name: 'Lebanon',
    code: CountryCode.LBN,
    phoneExtension: '961',
  },
  {
    name: 'Lesotho',
    code: CountryCode.LSO,
    phoneExtension: '266',
  },
  {
    name: 'Liberia',
    code: CountryCode.LBR,
    phoneExtension: '231',
  },
  {
    name: 'Libya',
    code: CountryCode.LBY,
    phoneExtension: '218',
  },
  {
    name: 'Liechtenstein',
    code: CountryCode.LIE,
    phoneExtension: '423',
  },
  {
    name: 'Lithuania',
    code: CountryCode.LTU,
    phoneExtension: '370',
  },
  {
    name: 'Luxembourg',
    code: CountryCode.LUX,
    phoneExtension: '352',
  },
  {
    name: 'Macao',
    code: CountryCode.MAC,
    phoneExtension: '853',
  },
  {
    name: 'Macedonia',
    code: CountryCode.MKD,
    phoneExtension: '389',
  },
  {
    name: 'Madagascar',
    code: CountryCode.MDG,
    phoneExtension: '261',
  },
  {
    name: 'Malawi',
    code: CountryCode.MWI,
    phoneExtension: '265',
  },
  {
    name: 'Malaysia',
    code: CountryCode.MYS,
    phoneExtension: '60',
  },
  {
    name: 'Maldives',
    code: CountryCode.MDV,
    phoneExtension: '960',
  },
  {
    name: 'Mali',
    code: CountryCode.MLI,
    phoneExtension: '223',
  },
  {
    name: 'Malta',
    code: CountryCode.MLT,
    phoneExtension: '356',
  },
  {
    name: 'Marshall Islands',
    code: CountryCode.MHL,
    phoneExtension: '692',
  },
  {
    name: 'Martinique',
    code: CountryCode.MTQ,
    phoneExtension: '596',
  },
  {
    name: 'Mauritania',
    code: CountryCode.MRT,
    phoneExtension: '222',
  },
  {
    name: 'Mauritius',
    code: CountryCode.MUS,
    phoneExtension: '230',
  },
  {
    name: 'Mayotte',
    code: CountryCode.MYT,
    phoneExtension: '262',
  },
  {
    name: 'Micronesia',
    code: CountryCode.FSM,
    phoneExtension: '691',
  },
  {
    name: 'Moldova',
    code: CountryCode.MDA,
    phoneExtension: '373',
  },
  {
    name: 'Monaco',
    code: CountryCode.MCO,
    phoneExtension: '377',
  },
  {
    name: 'Mongolia',
    code: CountryCode.MNG,
    phoneExtension: '976',
  },
  {
    name: 'Montenegro',
    code: CountryCode.MNE,
    phoneExtension: '382',
  },
  {
    name: 'Montserrat',
    code: CountryCode.MSR,
    phoneExtension: '1',
  },
  {
    name: 'Morocco',
    code: CountryCode.MAR,
    phoneExtension: '212',
  },
  {
    name: 'Mozambique',
    code: CountryCode.MOZ,
    phoneExtension: '258',
  },
  {
    name: 'Myanmar',
    code: CountryCode.MMR,
    phoneExtension: '95',
  },
  {
    name: 'Namibia',
    code: CountryCode.NAM,
    phoneExtension: '264',
  },
  {
    name: 'Nauru',
    code: CountryCode.NRU,
    phoneExtension: '674',
  },
  {
    name: 'Nepal',
    code: CountryCode.NPL,
    phoneExtension: '977',
  },
  {
    name: 'Netherlands',
    code: CountryCode.NLD,
    phoneExtension: '31',
  },
  {
    name: 'Netherlands Antilles',
    code: CountryCode.ANT,
    phoneExtension: '599',
  },
  {
    name: 'New Caledonia',
    code: CountryCode.NCL,
    phoneExtension: '687',
  },
  {
    name: 'New Zealand',
    code: CountryCode.NZL,
    phoneExtension: '64',
  },
  {
    name: 'Nicaragua',
    code: CountryCode.NIC,
    phoneExtension: '505',
  },
  {
    name: 'Niger',
    code: CountryCode.NER,
    phoneExtension: '227',
  },
  {
    name: 'Nigeria',
    code: CountryCode.NGA,
    phoneExtension: '234',
  },
  {
    name: 'Niue',
    code: CountryCode.NIU,
    phoneExtension: '683',
  },
  {
    name: 'Norfolk Island',
    code: CountryCode.NFK,
    phoneExtension: '672',
  },
  {
    name: 'North Korea',
    code: CountryCode.PRK,
    phoneExtension: '850',
  },
  {
    name: 'Northern Mariana Islands',
    code: CountryCode.MNP,
    phoneExtension: '1',
  },
  {
    name: 'Norway',
    code: CountryCode.NOR,
    phoneExtension: '47',
  },
  {
    name: 'Oman',
    code: CountryCode.OMN,
    phoneExtension: '968',
  },
  {
    name: 'Pakistan',
    code: CountryCode.PAK,
    phoneExtension: '92',
  },
  {
    name: 'Palau',
    code: CountryCode.PLW,
    phoneExtension: '680',
  },
  {
    name: 'Palestinian Territory',
    code: CountryCode.PSE,
    phoneExtension: '970',
  },
  {
    name: 'Panama',
    code: CountryCode.PAN,
    phoneExtension: '507',
  },
  {
    name: 'Papua New Guinea',
    code: CountryCode.PNG,
    phoneExtension: '675',
  },
  {
    name: 'Paraguay',
    code: CountryCode.PRY,
    phoneExtension: '595',
  },
  {
    name: 'Peru',
    code: CountryCode.PER,
    phoneExtension: '51',
  },
  {
    name: 'Philippines',
    code: CountryCode.PHL,
    phoneExtension: '63',
  },
  {
    name: 'Pitcairn',
    code: CountryCode.PCN,
    phoneExtension: '64',
  },
  {
    name: 'Poland',
    code: CountryCode.POL,
    phoneExtension: '48',
  },
  {
    name: 'Portugal',
    code: CountryCode.PRT,
    phoneExtension: '351',
  },
  {
    name: 'Puerto Rico',
    code: CountryCode.PRI,
    phoneExtension: '1',
  },
  {
    name: 'Qatar',
    code: CountryCode.QAT,
    phoneExtension: '974',
  },
  {
    name: 'Republic of the Congo',
    code: CountryCode.COG,
    phoneExtension: '242',
  },
  {
    name: 'Reunion',
    code: CountryCode.REU,
    phoneExtension: '262',
  },
  {
    name: 'Romania',
    code: CountryCode.ROU,
    phoneExtension: '40',
  },
  {
    name: 'Russia',
    code: CountryCode.RUS,
    phoneExtension: '7',
  },
  {
    name: 'Rwanda',
    code: CountryCode.RWA,
    phoneExtension: '250',
  },
  {
    name: 'Saint Barthelemy',
    code: CountryCode.BLM,
    phoneExtension: '590',
  },
  {
    name: 'Saint Helena',
    code: CountryCode.SHN,
    phoneExtension: '290',
  },
  {
    name: 'Saint Kitts and Nevis',
    code: CountryCode.KNA,
    phoneExtension: '1',
  },
  {
    name: 'Saint Lucia',
    code: CountryCode.LCA,
    phoneExtension: '1',
  },
  {
    name: 'Saint Martin',
    code: CountryCode.MAF,
    phoneExtension: '590',
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: CountryCode.SPM,
    phoneExtension: '508',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: CountryCode.VCT,
    phoneExtension: '1',
  },
  {
    name: 'Samoa',
    code: CountryCode.WSM,
    phoneExtension: '685',
  },
  {
    name: 'San Marino',
    code: CountryCode.SMR,
    phoneExtension: '378',
  },
  {
    name: 'Sao Tome and Principe',
    code: CountryCode.STP,
    phoneExtension: '239',
  },
  {
    name: 'Saudi Arabia',
    code: CountryCode.SAU,
    phoneExtension: '966',
  },
  {
    name: 'Senegal',
    code: CountryCode.SEN,
    phoneExtension: '221',
  },
  {
    name: 'Serbia',
    code: CountryCode.SRB,
    phoneExtension: '381',
  },
  {
    name: 'Seychelles',
    code: CountryCode.SYC,
    phoneExtension: '248',
  },
  {
    name: 'Sierra Leone',
    code: CountryCode.SLE,
    phoneExtension: '232',
  },
  {
    name: 'Singapore',
    code: CountryCode.SGP,
    phoneExtension: '65',
  },
  {
    name: 'Sint Maarten',
    code: CountryCode.SXM,
    phoneExtension: '1',
  },
  {
    name: 'Slovakia',
    code: CountryCode.SVK,
    phoneExtension: '421',
  },
  {
    name: 'Slovenia',
    code: CountryCode.SVN,
    phoneExtension: '386',
  },
  {
    name: 'Solomon Islands',
    code: CountryCode.SLB,
    phoneExtension: '677',
  },
  {
    name: 'Somalia',
    code: CountryCode.SOM,
    phoneExtension: '252',
  },
  {
    name: 'South Africa',
    code: CountryCode.ZAF,
    phoneExtension: '27',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: CountryCode.SGS,
    phoneExtension: '500',
  },
  {
    name: 'South Korea',
    code: CountryCode.KOR,
    phoneExtension: '82',
  },
  {
    name: 'South Sudan',
    code: CountryCode.SSD,
    phoneExtension: '211',
  },
  {
    name: 'Spain',
    code: CountryCode.ESP,
    phoneExtension: '34',
  },
  {
    name: 'Sri Lanka',
    code: CountryCode.LKA,
    phoneExtension: '94',
  },
  {
    name: 'Sudan',
    code: CountryCode.SDN,
    phoneExtension: '249',
  },
  {
    name: 'Suriname',
    code: CountryCode.SUR,
    phoneExtension: '597',
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: CountryCode.SJM,
    phoneExtension: '47',
  },
  {
    name: 'Swaziland',
    code: CountryCode.SWZ,
    phoneExtension: '268',
  },
  {
    name: 'Sweden',
    code: CountryCode.SWE,
    phoneExtension: '46',
  },
  {
    name: 'Switzerland',
    code: CountryCode.CHE,
    phoneExtension: '41',
  },
  {
    name: 'Syria',
    code: CountryCode.SYR,
    phoneExtension: '963',
  },
  {
    name: 'Taiwan',
    code: CountryCode.TWN,
    phoneExtension: '886',
  },
  {
    name: 'Tajikistan',
    code: CountryCode.TJK,
    phoneExtension: '992',
  },
  {
    name: 'Tanzania',
    code: CountryCode.TZA,
    phoneExtension: '255',
  },
  {
    name: 'Thailand',
    code: CountryCode.THA,
    phoneExtension: '66',
  },
  {
    name: 'Togo',
    code: CountryCode.TGO,
    phoneExtension: '228',
  },
  {
    name: 'Tokelau',
    code: CountryCode.TKL,
    phoneExtension: '690',
  },
  {
    name: 'Tonga',
    code: CountryCode.TON,
    phoneExtension: '676',
  },
  {
    name: 'Trinidad and Tobago',
    code: CountryCode.TTO,
    phoneExtension: '1',
  },
  {
    name: 'Tunisia',
    code: CountryCode.TUN,
    phoneExtension: '216',
  },
  {
    name: 'Turkey',
    code: CountryCode.TUR,
    phoneExtension: '90',
  },
  {
    name: 'Turkmenistan',
    code: CountryCode.TKM,
    phoneExtension: '993',
  },
  {
    name: 'Turks and Caicos Islands',
    code: CountryCode.TCA,
    phoneExtension: '1',
  },
  {
    name: 'Tuvalu',
    code: CountryCode.TUV,
    phoneExtension: '688',
  },
  {
    name: 'U.S. Virgin Islands',
    code: CountryCode.VIR,
    phoneExtension: '1',
  },
  {
    name: 'Uganda',
    code: CountryCode.UGA,
    phoneExtension: '256',
  },
  {
    name: 'Ukraine',
    code: CountryCode.UKR,
    phoneExtension: '380',
  },
  {
    name: 'United Arab Emirates',
    code: CountryCode.ARE,
    phoneExtension: '971',
  },
  {
    name: 'Uruguay',
    code: CountryCode.URY,
    phoneExtension: '598',
  },
  {
    name: 'Uzbekistan',
    code: CountryCode.UZB,
    phoneExtension: '998',
  },
  {
    name: 'Vanuatu',
    code: CountryCode.VUT,
    phoneExtension: '678',
  },
  {
    name: 'Vatican',
    code: CountryCode.VAT,
    phoneExtension: '379',
  },
  {
    name: 'Venezuela',
    code: CountryCode.VEN,
    phoneExtension: '58',
  },
  {
    name: 'Vietnam',
    code: CountryCode.VNM,
    phoneExtension: '84',
  },
  {
    name: 'Wallis and Futuna',
    code: CountryCode.WLF,
    phoneExtension: '681',
  },
  {
    name: 'Western Sahara',
    code: CountryCode.ESH,
    phoneExtension: '212',
  },
  {
    name: 'Yemen',
    code: CountryCode.YEM,
    phoneExtension: '967',
  },
  {
    name: 'Zambia',
    code: CountryCode.ZMB,
    phoneExtension: '260',
  },
  {
    name: 'Zimbabwe',
    code: CountryCode.ZWE,
    phoneExtension: '263',
  }
];
