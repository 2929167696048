import { createAction } from '@ngrx/store';
import { baggageServiceFeatureKey } from './baggage-service.state';
import { BaggageJourneyResponse } from 'src/app/dtos/response/baggage-journey-response/baggage-journey-response';
import { BaggageJourneyRequestByConfirmationCode } from '../../../dtos/request/baggage-journey/baggage-journey-request-by-confirmation-code';

export const initializeBaggageServiceState = createAction(`[${baggageServiceFeatureKey}] Initialize`);

export const resetBaggageJourneyState = createAction(`[${baggageServiceFeatureKey}] Reset Baggage Journey State`);

export const loadBaggageJourneyByConfirmationCode = createAction(
  `[${baggageServiceFeatureKey}] Load baggage journey details`,
  (request: BaggageJourneyRequestByConfirmationCode) => ({
    request,
  })
);

export const loadBaggageDetailsComplete = createAction(
  `[${baggageServiceFeatureKey}] Load Baggage Journey Complete`,
  (baggageJourneyResponse: BaggageJourneyResponse | null) => ({
    baggageJourneyResponse,
  })
);

export const setBaggageJourneyLastNames = createAction(
  `[${baggageServiceFeatureKey}] Set Baggage Journey Last Names`,
  (lastNames: string[] | null) => ({
    lastNames,
  })
);

export const setBaggageJourneyConfirmationCode = createAction(
  `[${baggageServiceFeatureKey}] Set Baggage Journey Confirmation Code`,
  (confirmationCode: string | null) => ({
    confirmationCode,
  })
);

export const setShowSearchForm = createAction(`[${baggageServiceFeatureKey}] Set Show Search Form`, (showSearchForm: boolean) => ({
  showSearchForm,
}));

export const setSelectedBaggageJourneyGuest = createAction(
  `[${baggageServiceFeatureKey}] Set Selected Baggage Journey Guest`,
  (selectedGuest: string | null) => ({
    selectedGuest,
  })
);
