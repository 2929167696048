import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
  canDeactivate(): boolean | Promise<boolean> | Observable<boolean>;
}

@Injectable()
export class CanDeactivateGuard  {
  canDeactivate(component: ComponentCanDeactivate): boolean | Promise<boolean> | Observable<boolean> {
    return component.canDeactivate();
  }
}
