export enum TicketDetailsError {
  /**
   * Service did not respond before the configured limit
   */
  TIMEOUT,
  /**
   * Service returned an error but no message
   */
  NO_MESSAGE,
  /**
   * Service returned a 404
   */
  NO_RESULTS,
  /**
   * An error was thrown that did not fit into the above scenarios
   */
  UNCAUGHT,
  /**
   * No tickets numbers were provided
   */
  NO_TICKETS,
}
