import { Action, createReducer, on } from '@ngrx/store';
import { initialTravelAdvisoriesServiceState } from './travel-advisories-service.state';
import { getTravelAdvisoriesComplete } from './travel-advisories-service.actions';

/**
 * Handles all state changes, there is no way to change the state without a reducer AND the reducer never
 * modifies state, it clones the state from the store, changes that cloned state in some way, and then replaces
 * the entire old state with the cloned and modified state.
 */
const featureReducer = createReducer(
  initialTravelAdvisoriesServiceState,
  /**
   * Add the mileage plan profile search response and set the status to stable
   */
  on(getTravelAdvisoriesComplete, (state, { response }) => ({
    ...state,
    travelAdvisories: response?.travelAdvisories,
    getTravelAdvisoriesSuccess: response?.success ?? false,
    getTravelAdvisoriesError: response?.errorMessage,
  })),
);

/**
 * Typescript safe way to export the reducer so that it can be imported in modules
 */
export function travelAdvisoriesServiceReducer(state = initialTravelAdvisoriesServiceState, action: Action) {
  return featureReducer(state, action);
}
