import { createAction } from '@ngrx/store';
import { ReservationTransactionResponse } from '../../../dtos/response/reservation-transaction-response/reservation-transaction-response';
import { reservationTransactionServiceFeatureKey } from './reservation-transaction-service.state';

export const initializeReservationTransactionServiceState = createAction(`[${reservationTransactionServiceFeatureKey}] Initialize`);

export const loadTransactions = createAction(
  `[${reservationTransactionServiceFeatureKey}] Load Transactions`,
  (confirmationCode: string, lastNames: string[]) => ({
    confirmationCode,
    lastNames
  })
);

export const loadTransactionComplete = createAction(
  `[${reservationTransactionServiceFeatureKey}] Load Transaction Complete`,
  (reservationTransactionResponse: ReservationTransactionResponse) => ({
    reservationTransactionResponse,
  })
);

export const loadTransactionFailure = createAction(`[${reservationTransactionServiceFeatureKey}] Load Transaction Failure`, () => {
  const errorTransactionResponse: ReservationTransactionResponse = {
    success: false,
    reservationTransaction: null,
    errorMessage: 'Error loading transactions',
  };
  return { errorTransactionResponse };
});
