import { Action, createReducer, on } from '@ngrx/store';
import { initialPreorderMealServiceState } from './preorder-meal-service.state';
import { clearPreOrderedMeals, loadPreOrderedMealsComplete } from './preorder-meal-service.actions';


const featureReducer = createReducer(
  initialPreorderMealServiceState,

  on(clearPreOrderedMeals, (state) => {
    return {
      ...state,
      preOrderMeals: null,
    };
  }),

  on(loadPreOrderedMealsComplete, (state, { preOrderMealResponse }) => {
    return {
      ...state,
      preOrderMeals: preOrderMealResponse,
    };
  })
);

/**
 * Typescript safe way to export the reducer so that it can be imported in modules
 */
export function preOrderMealServiceReducer(state = initialPreorderMealServiceState, action: Action) {
  return featureReducer(state, action);
}
