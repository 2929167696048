import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getRoutedConfirmationCode, getRoutedReservationLookupStatus } from '../../../services/reservation-service/state/reservation-service.selectors';
import { RootState } from '../../../state/state';

@Component({
  selector: 'app-baggage-drawer',
  templateUrl: './baggage-drawer.component.html',
  styleUrls: ['./baggage-drawer.component.scss']
})
export class BaggageDrawerComponent implements OnInit {
  routedConfirmationCode$: Observable<string>;
  routedReservationStatus$: Observable<string>;

  constructor(private store: Store<RootState>) { }

  ngOnInit(): void {
    this.routedConfirmationCode$ = this.store.pipe(select(getRoutedConfirmationCode));
    this.routedReservationStatus$ = this.store.pipe(select(getRoutedReservationLookupStatus));
  }
}
