import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ContactTracingInfoRequestStatus as ContactTracingInfo } from '../../../dtos/request/contact-tracing-request/contact-tracing-info-request-status';
import { RootState } from '../../../state/state';

/**
 * The string name of the contact tracing 'slice' of state
 */
export const contactTracingFeatureKey = 'contactTracing';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  contactTracing: ContactTracingState;
}

/**
 * The state that represents the contact tracing info
 */
export interface ContactTracingState {
  contactTracingInfo: ContactTracingInfoEntity;
}

export interface ContactTracingInfoEntity extends EntityState<ContactTracingInfo> {}
export const contactTracingInfoAdapter: EntityAdapter<ContactTracingInfo> =
  createEntityAdapter<ContactTracingInfo>({
    selectId: (info) => info.passengerHashId,
  });

/**
 * Initial state for the contact tracing info
 */
export const initialContactTracingState: ContactTracingState = {
  contactTracingInfo: contactTracingInfoAdapter.getInitialState(),
};
