import { Action, createReducer, on } from '@ngrx/store';
import { initializeMileagePlanProfileSearchState, setMileagePlanProfileSearchMileagePlanNumber } from './mileage-plan-profile-search.actions';
import { initialMileagePlanProfileSearchState } from './mileage-plan-profile-search.state';

const featureReducer = createReducer(
  initialMileagePlanProfileSearchState,
  on(setMileagePlanProfileSearchMileagePlanNumber, (state, { mileagePlanNumber }) => ({ ...state, mileagePlanNumber })),
  on(initializeMileagePlanProfileSearchState, (state) => ({ ...state, ...initialMileagePlanProfileSearchState }))
);

export function mileagePlanProfileSearchReducer(state = initialMileagePlanProfileSearchState, action: Action) {
  return featureReducer(state, action);
}
