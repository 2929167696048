import { Action, createReducer, on } from '@ngrx/store';
import { Status } from '../../../models/status';
import {
  getAllBagsAndAncillaryFees,
  getAllBagsAndAncillaryFeesComplete,
  initializeFeeState,
  loadFeeResults,
  loadFeeResultsComplete,
  setGetBagsAndAncillaryFeesStatus,
  setSelectedBagAndAncillaryFeePassenger,
} from './fee.actions';
import { feeAdapter, initialFeeState } from './fee.state';

const featureReducer = createReducer(
  initialFeeState,
  on(initializeFeeState, (state) => ({ ...state, ...initialFeeState })),
  on(loadFeeResults, (state) => ({
    ...state,
    feeStatus: Status.UPDATING,
  })),
  on(loadFeeResultsComplete, (state, { response }) => ({
    ...state,
    feeResults: feeAdapter.setOne(response, state.feeResults),
    feeStatus: Status.STABLE,
    feeError: response.error,
  })),
  on(getAllBagsAndAncillaryFees, (state) => ({
    ...state,
    bagsAndAncillaryFeesStatus: Status.UPDATING,
    bagsAndAncillaryFeesError: null,
    bagsAndAncillaryFees: null,
    selectedBagAndAncillaryFeePassenger: null
  })),
  on(getAllBagsAndAncillaryFeesComplete, (state, { response }) => ({
    ...state,
    bagsAndAncillaryFees: response,
    bagsAndAncillaryFeesStatus: Status.STABLE,
    bagsAndAncillaryFeesError: response.error,
    selectedBagAndAncillaryFeePassenger: response?.response?.feesByPassengerRS?.passengers[0]
  })),
  on(setSelectedBagAndAncillaryFeePassenger, (state, { selectedPassengerNameNumber }) => ({
    ...state,
    selectedBagAndAncillaryFeePassenger: state.bagsAndAncillaryFees?.response?.feesByPassengerRS?.passengers?.filter(
      (passenger) => passenger.paxNameNumber === selectedPassengerNameNumber
    )[0] ?? null,
  })),
  on(setGetBagsAndAncillaryFeesStatus, (state, { status }) => ({
    ...state,
    bagsAndAncillaryFeesStatus: status
  }))
);

export function feeServiceReducer(state = initialFeeState, action: Action) {
  return featureReducer(state, action);
}
