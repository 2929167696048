export enum AddDeleteRemarkStatus {
  SUCCESS = 1,
  SYSTEM_FAILURE,
  TIMEOUT,
  UNCAUGHT,
  FULL_FAILURE,
  PASSWORD_DECRYPTION_ERROR,
  SIMULATENOUS_CHANGES_ERROR,
  NONE,
}
