import { LoginAttemptStatus } from '../../../dtos/response/login-response/login-attempt-status';
import { UserData } from '../../../dtos/response/login-response/user-data';

export const loginServiceFeatureKey = 'loginService';

export interface LoginServiceState {
  user: UserData;
  status: LoginAttemptStatus | null;
  loading: boolean;
}

// Note: The logic in this line does not belong in the state. Do not emulate the following line
export const initialLoginServiceState: LoginServiceState = {
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') ?? '') : null,
  status: null,
  loading: false,
};
