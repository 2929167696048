import { InvoluntaryChangeActionType } from '../../../models/monitoring/InvoluntaryChangeActionType';
import { RootState } from '../../../state/state';

export const involuntaryChangeFeatureKey = 'involuntaryChange';

export interface State extends RootState {
  involuntaryChange: InvoluntaryChangeState;
}

export interface InvoluntaryChangeState {
  // Will be reissue or reval
  actionType: InvoluntaryChangeActionType;
}

export const initialInvoluntaryChangeState: InvoluntaryChangeState = {
  actionType: null,
};
