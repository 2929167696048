import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { getSelectedTicketSearchResultCount } from '../services/ticket-service/state/ticket-service.selectors';
import { RootState } from '../state/state';

@Injectable({
  providedIn: 'root',
})
export class ManualAssociationGuard  {
  constructor(private store: Store<RootState>) {}

  /**
   * Do not allow access to the manual-association routes if there are no selected tickets in the store
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(getSelectedTicketSearchResultCount).pipe(
      // take the first result emitted and kill the subscription, this prevents memory leaks as this method is called multiple times
      take(1),
      map((result) => (result ? true : false))
    );
  }
}
