import { Action, createReducer, on } from '@ngrx/store';
import { initialDrawerDisplaysState } from './drawer-displays.state';
import { initializeDrawerDisplaysState, setFlightInfoDrawerSegment } from './drawer-displays.actions';

/**
 * Handles all state changes, there is no way to change the state without a reducer AND the reducer never
 * modifies state, it clones the state from the store, changes that cloned state in some way, and then replaces
 * the entire old state with the cloned and modified state.
 */
const featureReducer = createReducer(
  initialDrawerDisplaysState,
  /**
   * Set the flight info drawer segment hashId
   */
  on(setFlightInfoDrawerSegment, (state, { flightInfoDrawerSegment }) => ({
    ...state,
    flightInfoDrawerSegment: { ...state.flightInfoDrawerSegment, segment: flightInfoDrawerSegment },
  })),

  /**
   * Set the state to the initial value defined in the state file
   */
  on(initializeDrawerDisplaysState, (state) => ({ ...state, ...initialDrawerDisplaysState }))
);

/**
 * Typescript safe way to export the reducer so that it can be imported in modules
 */
export function drawerDisplaysReducer(state = initialDrawerDisplaysState, action: Action) {
  return featureReducer(state, action);
}
