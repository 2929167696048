import { createAction } from '@ngrx/store';
import { FlightInformationResponse } from '../../../dtos/response/flight-status-response/flight-information-response';
import { flightServiceFeatureKey } from './flight-service.state';

export const initializeFlightServiceState = createAction(`[${flightServiceFeatureKey}] Initialize`);

export const loadFlightStatus = createAction(
  `[${flightServiceFeatureKey}] Load Flight Status`,
  (carrierCode: string, flightNumber: string, departureDateTime: string) => {
    return {
      carrierCode,
      flightNumber,
      departureDateTime,
    };
  }
);

export const loadFlightStatusComplete = createAction(
  `[${flightServiceFeatureKey}] Load Flight Status Complete`,
  (flightStatusResponse: FlightInformationResponse) => ({
    flightStatusResponse,
  })
);
