<app-dropdown 
    [hostComponent]="this" 
    [activeClassStyle]="'color: #054687;'" 
    [isAuroIconBased]="true" 
    [isUppercase]="true">
    <div 
        class="dropdown-option"
        id="researvationSearch"  
        data-max="6" 
        [attr.data-pattern]="'^[a-zA-Z]{6}$'"
        [attr.data-allowedchars]="'^[a-zA-Z]*$'" 
        [attr.data-label]="'Confirmation code'"
        data-executemethod="researvationSearch"
        data-testid="resSearch">
        <auro-icon 
            category="interface" 
            name="reservation-stroke" 
            style="width: 16px; height: 16px; color: #0074c8;"
            data-activeclass="width: 16px; height: 16px; color: #054687;"
            data-inactiveclass="width: 16px; height: 16px; color: #0074c8;" 
            customColor 
            customSize>
        </auro-icon>
        <span>Reservations</span>
    </div>
    <div 
        class="dropdown-option" 
        id="guestProfileSearch" 
        [attr.data-label]="mileagePlanLabel"
        data-max="50"
        [attr.data-pattern]="validators.mileagePlanNumberNonAlphaRegex.source"
        [attr.data-allowedchars]="'^[0-9]*$'"
        data-executemethod="guestProfileSearch"
        data-testid="guestProfileSearch">
        <auro-icon 
            category="in-flight" 
            name="passenger" 
            style="width: 16px; height: 16px; color: #0074c8;"
            data-activeclass="width: 16px; height: 16px; color: #054687;"
            data-inactiveclass="width: 16px; height: 16px; color: #0074c8;" 
            customColor 
            customSize></auro-icon>
        <span>Guests</span>
    </div>
    <div 
    class="dropdown-option" 
    id="ticketSearch" 
    [attr.data-label]="'Ticket number'"
    data-max="10"
    [attr.data-pattern]="'^[0-9]{10}$'"
    [attr.data-allowedchars]="'^[0-9]*$'"
    data-executemethod="ticketSearch"
    data-testid="ticketSearch"
    [attr.data-prefix]="prefix">
    <auro-icon 
        category="interface" 
        name="ticket-stroke" 
        style="width: 16px; height: 16px; color: #0074c8;"
        data-activeclass="width: 16px; height: 16px; color: #054687;"
        data-inactiveclass="width: 16px; height: 16px; color: #0074c8;" 
        customColor 
        customSize></auro-icon>
    <span>Tickets</span>
</div>
</app-dropdown>
