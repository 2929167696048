import { ShoppingSegment } from '../../../dtos/request/shopping-request/shopping-segment';
import { FlightShoppingResponseFull } from '../../../dtos/response/shopping-response/flight-shopping-response-full';
import { Status } from '../../../models/status';
import { RootState } from '../../../state/state';

export const flightShoppingServiceFeatureKey = 'flightShoppingService';

export interface State extends RootState {
  flightShoppingService: FlightShoppingServiceState;
}

export interface FlightShoppingServiceState {
  results: FlightShoppingResponseFull | null;
  sortedFilteredResults: ShoppingSegment[][];
  status: Status;
  error: string;
  currentShoppingIndex: number;
  selectedResult: ShoppingSegment[];
}

export const initialFlightShoppingServiceState: FlightShoppingServiceState = {
  results: null,
  sortedFilteredResults: [],
  status: Status.STABLE,
  error: '',
  currentShoppingIndex: 0,
  selectedResult: []
};
