import { createAction } from '@ngrx/store';
import { ZipLookupResponse } from 'src/app/dtos/response/location-lookup-response/zip-lookup-response';
import { zipLookupFeatureKey } from './zip-lookup.state';

export const initializeZipLookupState = createAction(`[${zipLookupFeatureKey}] Initialize`);

export const lookupCityStateResults = createAction(
  `[${zipLookupFeatureKey}] Lookup city state results`,
  (country: string, zip: string) =>({country, zip}));

export const lookupCityStateComplete = createAction(
  `[${zipLookupFeatureKey}] Lookup city state complete`,
  (success: boolean, response?: ZipLookupResponse) => ({
    success,
    response
  })
);
