import { Component, OnInit } from '@angular/core';
import { RichMessage } from '../../../models/message/rich-message';
import { Subject, takeUntil } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { RootState } from '../../../state/state';
import { getJetStreamShortMessages, getJetStreamShortMessagesInnerHtml } from '../../../services/message-service/state/message.selectors';

@Component({
  selector: 'app-jet-stream-short-message-bar',
  templateUrl: './jet-stream-short-message-bar.component.html',
  styleUrls: ['./jet-stream-short-message-bar.component.scss']
})
export class JetStreamShortMessageBarComponent implements OnInit {
  messages: RichMessage[];
  innerHtml: string[];
  private onDestroy$ = new Subject<void>();

  constructor(private store: Store<RootState>) {}

  ngOnInit(): void {
    this.store.pipe(select(getJetStreamShortMessages), takeUntil(this.onDestroy$)).subscribe((messages) => {
      this.messages = messages;
    });
    this.store.pipe(select(getJetStreamShortMessagesInnerHtml), takeUntil(this.onDestroy$)).subscribe((innerHtml) => {
      this.innerHtml = innerHtml;
    });
  }
}
