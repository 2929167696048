<div [ngClass]="{ 'global-search-bar-z-parent': fixedToTop }">
    <div class='global-search-bar' [ngClass]="{ 'global-search-bar-fixed': fixedToTop }">
        <div class="caller-context-content">
            <app-caller-context style="width: 100%;"></app-caller-context>
        </div>
        <div class="search-content">
            <app-search></app-search>
        </div>
    </div>
  </div>
