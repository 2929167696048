export enum AirlineCode {
  'AA' = 'AA',
  'AC' = 'AC',
  'AF' = 'AF',
  'AM' = 'AM',
  'AS' = 'AS',
  'AT' = 'AT',
  'AV' = 'AV',
  'AY' = 'AY',
  'AZ' = 'AZ',
  'BA' = 'BA',
  'BF' = 'BF',
  'BR' = 'BR',
  'B6' = 'B6',
  'CA' = 'CA',
  'CI' = 'CI',
  'CM' = 'CM',
  'CX' = 'CX',
  'CZ' = 'CZ',
  'DE' = 'DE',
  'DL' = 'DL',
  'EI' = 'EI',
  'EK' = 'EK',
  'ET' = 'ET',
  'FI' = 'FI',
  'FJ' = 'FJ',
  'FZ' = 'FZ',
  'HA' = 'HA',
  'HR' = 'HR',
  'HU' = 'HU',
  'HX' = 'HX',
  'IB' = 'IB',
  'IG' = 'IG',
  'JJ' = 'JJ',
  'JL' = 'JL',
  'KA' = 'KA',
  'KE' = 'KE',
  'KL' = 'KL',
  'KS' = 'KS',
  'LA' = 'LA',
  'LH' = 'LH',
  'LO' = 'LO',
  'LP' = 'LP',
  'LR' = 'LR',
  'LY' = 'LY',
  'MF' = 'MF',
  'MH' = 'MH',
  'MT' = 'MT',
  'MU' = 'MU',
  'MW' = 'MW',
  'NH' = 'NH',
  'NZ' = 'NZ',
  'OO' = 'OO',
  'OZ' = 'OZ',
  'PR' = 'PR',
  'PS' = 'PS',
  'PZ' = 'PZ',
  'QF' = 'QF',
  'QR' = 'QR',
  'QX' = 'QX',
  'RJ' = 'RJ',
  'SA' = 'SA',
  'SK' = 'SK',
  'SQ' = 'SQ',
  'SU' = 'SU',
  'SV' = 'SV',
  'SY' = 'SY',
  'S4' = 'S4',
  'S7' = 'S7',
  'TA' = 'TA',
  'TK' = 'TK',
  'TN' = 'TN',
  'TP' = 'TP',
  'T0' = 'T0',
  'UA' = 'UA',
  'UL' = 'UL',
  'VA' = 'VA',
  'VS' = 'VS',
  'VW' = 'VW',
  'VX' = 'VX',
  'WP' = 'WP',
  'WS' = 'WS',
  'XL' = 'XL',
  'X4' = 'X4',
  '3M' = '3M',
  '4C' = '4C',
  '4M' = '4M',
  '4N' = '4N',
  '7H' = '7H',
  '9K' = '9K',
  '9N' = '9N',
  '9X' = '9X',
}
