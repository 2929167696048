import { createAction } from '@ngrx/store';
import { FlightAvailabilityRequest } from '../../../dtos/request/flight-availability-request/flight-availability-request';
import { FlightAvailabilityResponse } from '../../../dtos/response/flight-availability-response/flight-availability-response';
import { FlightSegment } from '../../../dtos/response/flight-availability-response/flight-segment';
import { ShoppingSegment } from '../../../dtos/request/shopping-request/shopping-segment';
import { FlightChangeForm } from '../../../models/flight-change-form/flight-change-form';
import { Status } from '../../../models/status';
import { flightAvailabilityServiceFeatureKey } from './flight-availability-service.state';

export const initializeFlightAvailabilityServiceState = createAction(`[${flightAvailabilityServiceFeatureKey}] Initialize`);

export const loadFlightAvailability = createAction(
  `[${flightAvailabilityServiceFeatureKey}] Load Flight Availability`,
  (flightAvailabilityRequest: FlightAvailabilityRequest | null) => ({
    flightAvailabilityRequest,
  })
);

export const flightAvailabilitySuccess = createAction(
  `[${flightAvailabilityServiceFeatureKey}] Flight availability search success`,
  (response: FlightAvailabilityResponse) => ({
    response,
  })
);

export const flightAvailabilityFailure = createAction(
  `[${flightAvailabilityServiceFeatureKey}] Flight availability search failure`,
  (error: string) => ({
    error,
  })
);

export const setFlightAvailabilityStabilityStatus = createAction(
  `[${flightAvailabilityServiceFeatureKey}] Set Flight availability stability status`,
  (status: Status) => ({
    status,
  })
);
/**
 * Sets the departure airport code
 */
export const setDepartureAirportCode = createAction(
  `[${flightAvailabilityServiceFeatureKey}] Set departure airport code`,
  (departureAirportCode: string | undefined) => ({
    departureAirportCode,
  })
);

/**
 * Sets the arrival airport code
 */
export const setArrivalAirportCode = createAction(
  `[${flightAvailabilityServiceFeatureKey}] Set arrival airport code`,
  (arrivalAirportCode: string | undefined) => ({
    arrivalAirportCode,
  })
);

/**
 * Sets changed flights
 *
 */
export const setChangedFlights = createAction(
  `[${flightAvailabilityServiceFeatureKey}] Set changed flights`,
  (changedFlights: FlightChangeForm) => ({
    changedFlights,
  })
);

/**
 * Sets current flight availability index
 */
export const setCurrentFlightAvailabilityIndex = createAction(
  `[${flightAvailabilityServiceFeatureKey}] Set current flight availability index`,
  (currentFlightAvailabilityIndex: number) => ({
    currentFlightAvailabilityIndex,
  })
);

/**
 * Selects an availability option for the given segment
 */
export const setSelectedAvailabilityOption = createAction(
  `[${flightAvailabilityServiceFeatureKey}] Set selected availability option`,
  (availabilityOptionId: string, currentAvailabilityIndex: number) => ({
    availabilityOptionId,
    currentAvailabilityIndex,
  })
);

export const setSeatMapViewSegment = createAction(
  `[${flightAvailabilityServiceFeatureKey}] Set seat map view segment`,
  (seatMapViewSegment: FlightSegment | undefined) => ({ seatMapViewSegment })
);

export const setSeatMapViewSegmentForSameDayConfirm = createAction(
  `[${flightAvailabilityServiceFeatureKey}] Set seat map view segment for Same Day Confirm`,
  (seatMapViewSegmentForSameDayConfirm: ShoppingSegment | undefined) => ({ seatMapViewSegmentForSameDayConfirm })
);

/**
 * Initialize the flightAvailability collection in the store
 */
export const initializeFlightAvailabilityOptions = createAction(
  `[${flightAvailabilityServiceFeatureKey}] Initialize flight availability options`);
