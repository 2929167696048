import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FeeInfo } from '../../../dtos/response/bags-and-ancillaries-response/fee-info';
import { getBagAndAncillaryFeeMostSignificantCarrier, getSelectedBagAndAncillaryFeePassengerBagFees } from '../../../services/fee-service/state/fee.selectors';
import { RootState } from '../../../state/state';

@Component({
  selector: 'app-dynamic-baggage-table',
  templateUrl: './dynamic-baggage-table.component.html',
  styleUrls: ['./dynamic-baggage-table.component.scss'],
})
export class DynamicBaggageTableComponent implements OnInit {
  selectedPassengerFeeBagFees$: Observable<FeeInfo[]>;
  mostSignificantCarrier$: Observable<string>;
  maxFeesToShow = 3;

  constructor(private store: Store<RootState>) {}

  ngOnInit(): void {
    this.selectedPassengerFeeBagFees$ = this.store.pipe(select(getSelectedBagAndAncillaryFeePassengerBagFees));
    this.mostSignificantCarrier$ = this.store.pipe(select(getBagAndAncillaryFeeMostSignificantCarrier));
  }
}
