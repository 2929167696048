import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CallerContext } from '../../../dtos/response/caller-context-response/caller-context';
import { callerContextAdapter, callerContextFeatureKey, CallerContextState } from './caller-context-service.state';

/**
 * Get the entire caller context slice of state
 */
const getCallerContextState = createFeatureSelector<CallerContextState>(callerContextFeatureKey);
/**
 * Get adapter selection methods to allow access to the caller context entities in the store
 */
const { selectEntities, selectAll } = callerContextAdapter.getSelectors();

/**
 * Get all of the caller context objects as a dictionary accessible by id
 */
export const getAllCallerEntities = createSelector(getCallerContextState, (state) => selectEntities(state.callers));

/**
 * Get all the caller context objects as an array
 */
export const getAllCallers = createSelector(getCallerContextState, (state) => selectAll(state.callers));

/**
 * Get the active caller ID from the state
 */
export const getActiveCallerId = createSelector(getCallerContextState, (state) => state.activeCallerId);

/**
 * Get the active caller using the activeCallerId to get the caller from the dictionary
 */
export const getActiveCaller = createSelector(getActiveCallerId, getAllCallerEntities, (activeCallerId, callers) =>
  callers && activeCallerId && !callers[activeCallerId]?.dismissed ? callers[activeCallerId] : null
);

/**
 * Get all of the callers that are not dismissed
 */
export const getCallers = createSelector(getAllCallers, (allCallers) => allCallers?.filter((caller) => !caller.dismissed) ?? null);

/**
 * Get the number of callers that are not dismissed
 */
export const getCallerCount = createSelector(getCallers, (callers) => callers?.length ?? 0);

/**
 * Get the 0 based index of the active caller from the array of callers that are not dismissed
 */
export const getActiveCallerIndex = createSelector(
  getCallers,
  getActiveCaller,
  (callers: CallerContext[], activeCaller) => callers?.findIndex((caller) => caller.id === activeCaller?.id) ?? null
);

/**
 * Get the 1 based index by adding 1 to the result of the getActiveCallerIndex selector
 */
export const getActiveCallerIndexBaseOne = createSelector(getActiveCallerIndex, (activeCallerIndex) =>
  activeCallerIndex !== null && activeCallerIndex > -1 ? activeCallerIndex + 1 : null
);

/**
 * Get the caller ID of the next caller based on position in the caller array in relation to the current active caller
 */
export const getNextActiveCallerId = createSelector(getCallers, getActiveCallerIndex, (callers, currentIndex) =>
  currentIndex + 1 >= callers.length ? callers[0]?.id : callers[currentIndex + 1]?.id
);

/**
 * Get the caller ID of the previous caller based on position in the caller array in relation to the current active caller
 */
export const getPreviousActiveCallerId = createSelector(getCallers, getActiveCallerIndex, (callers, currentIndex) =>
  currentIndex === 0 ? callers[callers.length - 1]?.id : callers[currentIndex - 1]?.id
);

/**
 * Get the error message for the caller context
 */
export const getActiveCallerErrorMessage = createSelector(getCallerContextState, (state) => state.errorMessage);
