import { HttpClient, HttpErrorResponse, HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { defer, Observable, of, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { TicketAssociationRequest } from '../../dtos/request/ticket-association-request/ticket-association-request';
import { TicketSearchByRoute } from '../../dtos/request/ticket-search-request/ticket-search-by-route';
import { TicketSearchByTicketNumberRequest } from '../../dtos/request/ticket-search-request/ticket-search-by-ticket-number-request';
import { EMDTicketDetailError } from '../../dtos/response/emd-ticket-detail-response/emd-ticket-detail-error';
import { EMDTicketDetailResponse } from '../../dtos/response/emd-ticket-detail-response/emd-ticket-detail-response';
import { GDSTicketsCloudModelsResponsesTicketDetail } from '../../dtos/response/gds-ticket-cloud-response/gDSTicketsCloudModelsResponsesTicketDetail';
import { UserData } from '../../dtos/response/login-response/user-data';
import { ActionResult } from '../../dtos/response/reservation-transaction-response/action-result';
import { TicketAssociationResponseStatus } from '../../dtos/response/ticket-association-response/ticket-association-response-status';
import { TicketDetailsError } from '../../dtos/response/ticket-detail-response/ticket-detail-error';
import { TicketDetailsResponse } from '../../dtos/response/ticket-detail-response/ticket-detail-response';
import { TicketSearchError } from '../../dtos/response/ticket-search-response/ticket-search-error';
import { TicketSearchResponse } from '../../dtos/response/ticket-search-response/ticket-search-response';
import { TicketSearchResult } from '../../dtos/response/ticket-search-response/ticket-search-result';
import { VcrResponse } from '../../dtos/response/vcr-response/vcr-response';
import { VcrStatus } from '../../dtos/response/vcr-response/vcr-status';
import { GetVcrType } from '../../models/get-vcr-type';
import { Status } from '../../models/status';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { RootState } from '../../state/state';
import { getUser } from '../login-service/state/login-service.selector';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';

export interface TicketServiceAPI {
  /**
   * Get virtual coupon records for a given set of ticket numbers. VCR is used during the purchase process
   * and must be validated prior to creating EMDs
   */
  getVcr(ticketNumbers: string[], background: boolean, type: GetVcrType): Observable<VcrResponse>;
  /**
   * Get the EMD ticket detail based on ticket numbers. Does not return EMD ticket details that do not have a fee ticket number.
   */
  getEMDTicketDetails(ticketNumbers: string[]): Observable<EMDTicketDetailResponse>;
  /**
   * Get the full ticket detail based on ticket numbers
   */
  getTicketDetails(ticketNumbers: string[]): Observable<TicketDetailsResponse>;
  /**
   * Search for tickets based on parameters in TicketSearchByRoute interface
   */
  searchByRoute(ticketSearchByRoute: TicketSearchByRoute): Observable<TicketSearchResponse>;
  /**
   * Search for tickets based on a string array of ticket numbers
   */
  searchByTicketNumber(ticketSearchByTicketNumber: TicketSearchByTicketNumberRequest): Observable<TicketSearchResponse>;
  associateVcrTicket(
    ticketAssociationRequest: TicketAssociationRequest
  ): Observable<{ status: TicketAssociationResponseStatus; messages?: string[] }>;
}

@Injectable()
export class TicketService implements TicketServiceAPI {
  private user: UserData;

  constructor(
    private http: HttpClient,
    private store: Store<RootState>,

    private eventService: GlobalEventService
  ) {
    this.store.pipe(select(getUser)).subscribe((user) => (this.user = user));
  }

  public getVcr(ticketNumbers: string[], background: boolean, type: GetVcrType): Observable<VcrResponse> {
    const body = { ticketNumbers };
    const options = {
      headers: new HttpHeaders({
        background: background.toString(),
      }),
    };
    return this.http.post<VcrResponse>(`api/ticketing`, body, options).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => defer(() => throwError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((vcrData) => ({ ...vcrData, status: VcrStatus.SUCCESS })),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return of({ status: VcrStatus.TIMEOUT });
        } else {
          return of({ status: VcrStatus.SYSTEM_FAILURE });
        }
      })
    );
  }

  public getEMDTicketDetails(ticketNumbers: string[]): Observable<EMDTicketDetailResponse> {
    const body = { ticketNumbers };
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    return this.http.post<GDSTicketsCloudModelsResponsesTicketDetail[]>(`api/ticketing/full`, body, options).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => defer(() => throwError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((EMDTicketDetails) => ({
        success: true,
        EMDTicketDetails: EMDTicketDetails.filter((EMDTicketDetail) => EMDTicketDetail.emd?.number).map((EMDTicketDetail) => ({
          ...EMDTicketDetail,
          status: Status.STABLE,
          ticketNumber: EMDTicketDetail.emd.number,
        })),
      })),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          return of({ success: false, error: EMDTicketDetailError.TIMEOUT });
        } else {
          return of({ success: false, error: EMDTicketDetailError.UNHANDLED });
        }
      })
    );
  }

  public getTicketDetails(ticketNumbers: string[], background: string = 'true'): Observable<TicketDetailsResponse> {
    const body = { ticketNumbers };
    const options = {
      headers: new HttpHeaders({
        background,
      }),
    };
    return this.http.post<GDSTicketsCloudModelsResponsesTicketDetail[]>(`api/ticketing/full`, body, options).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => defer(() => throwError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((ticketDetails) => ({
        success: true,
        ticketDetails,
      })),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          return of({ success: false, error: TicketDetailsError.TIMEOUT });
        } else {
          return of({ success: false, error: TicketDetailsError.UNCAUGHT });
        }
      })
    );
  }

  public searchByRoute(ticketSearchByRoute: TicketSearchByRoute): Observable<TicketSearchResponse> {
    const body = ticketSearchByRoute;
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    return this.http.post<TicketSearchResult[]>(`api/ticketing/search/by-route`, body, options).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => defer(() => throwError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((searchResults) => ({ success: true, searchResults })),
      catchError((err) => {
        const errorMessage: string = err.error ? JSON.stringify(err.error) : 'no error message returned';
        return of({
          success: false,
          error: this.convertSearchErrorMessage(errorMessage.toLocaleLowerCase()),
        });
      })
    );
  }

  public searchByTicketNumber(ticketSearchByTicketNumber: TicketSearchByTicketNumberRequest): Observable<TicketSearchResponse> {
    const body = ticketSearchByTicketNumber;
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    return this.http.post<TicketSearchResult[]>(`api/ticketing/search/by-ticket-number`, body, options).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => defer(() => throwError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((searchResults) => ({ success: true, searchResults })),
      catchError((err: HttpErrorResponse) => {
        if (err.status !== HttpStatusCode.NotFound) {
          const errorMessage: string = err.error ? JSON.stringify(err.error) : 'no error message returned';
          return of({
            success: false,
            error: this.convertSearchErrorMessage(errorMessage.toLocaleLowerCase()),
          });
        } else {
          return of({ success: false });
        }
      })
    );
  }

  public associateVcrTicket(
    ticketAssociationRequest: TicketAssociationRequest
  ): Observable<{ status: TicketAssociationResponseStatus; messages?: string[] }> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    return this.http
      .put<{ hostResponseMessages: string[]; actionResult: ActionResult }>(`api/ticketing/associate`, ticketAssociationRequest, options)
      .pipe(
        timeout({
          each: TimeoutLimit.LONG,
          with: () => defer(() => throwError(() => new HttpErrorResponse(timeoutError))),
        }),
        map((response) => {
          return {
            status: TicketAssociationResponseStatus.SUCCESS,
            messages: response?.actionResult?.messages,
          };
        }),
        catchError((err) => {
          switch (true) {
            case timeoutError.statusText === err.statusText:
              return of({ status: TicketAssociationResponseStatus.TIMEOUT });
            case err.error.toLowerCase().includes('password decryption error'):
              return of({ status: TicketAssociationResponseStatus.PASSWORD_DECRYPTION_ERROR });
            case err.error.toLowerCase().includes('date of travel beyond vcr purge date'):
              return of({ status: TicketAssociationResponseStatus.DATE_BEYOND_TICKET_VALIDITY });
            default:
              return of({ status: TicketAssociationResponseStatus.SYSTEM_FAILURE });
          }
        })
      );
  }

  /**
   * Convert server errors from the vcr search feature into enum values
   */
  private convertSearchErrorMessage(errorMessage: string): TicketSearchError {
    switch (true) {
      case errorMessage.includes('timeout'):
        return TicketSearchError.TIMEOUT;
      case errorMessage.includes('internal server error'):
        return TicketSearchError.UNCAUGHT;
      case errorMessage.includes('vcr not found'):
        return TicketSearchError.NO_RESULTS;
      case errorMessage.includes('password decryption error'):
        return TicketSearchError.PASSWORD_DECRYPTION_ERROR;
      default:
        return TicketSearchError.NO_MESSAGE;
    }
  }
}
