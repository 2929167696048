import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer as observableDefer, Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { ReservationTransactionResponse } from '../../dtos/response/reservation-transaction-response/reservation-transaction-response';
import { ReservationTransaction } from '../../dtos/response/reservation-transaction-response/reservation-transaction';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';

export interface ReservationTransactionServiceAPI {
  getTransactions(confirmationCode: string, lastNames: string[]): Observable<ReservationTransactionResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class ReservationTransactionService implements ReservationTransactionServiceAPI {
  constructor(private readonly http: HttpClient) {}

  /**
   * Story 872588 - Fees we get back from this DAP call DOES NOT include transactions that
   * was made on AScom. We switched to using /full ticket lookup to get all fees info.
   * see ticket-service.ts.
   * This DAP call is still needed for waivers information.
   */
  getTransactions(confirmationCode: string, lastNames: string[]): Observable<ReservationTransactionResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    return this.http
      .get<ReservationTransaction>(`api/transactions/${confirmationCode}/?lastNames=${lastNames.join('&lastNames=')}`, options)
      .pipe(
        timeout({
          each: TimeoutLimit.SHORT,
          with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
        }),
        map((reservationTransaction) => {
          reservationTransaction.confirmationCode = confirmationCode;
          return { success: true, reservationTransaction };
        }),
        catchError((err: HttpErrorResponse) => {
          return observableOf({ success: false, errorMessage: err.statusText });
        })
      );
  }
}
