import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OsiService } from '../osi-service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {  addTcpOsi, addTcpOsiFullFailure, addTcpOsiFullSuccess, addTcpOsiPartialSuccess } from './osi-service.actions';
import { AddOsiStatus } from 'src/app/dtos/response/osi-response/add-osi-status';
import { of } from 'rxjs';

@Injectable()
export class OsiServiceEffects {
  constructor(private actions$: Actions, private osiService: OsiService) {}

  addTcpOsi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addTcpOsi),
      mergeMap((action) =>
        this.osiService.addTcpOsi(action.addTcpRequest).pipe(
          map((result) => {
            if (result.status === AddOsiStatus.SUCCESS) {
              return addTcpOsiFullSuccess(result.successfullyAddedTcps, AddOsiStatus.SUCCESS);
            } else if (result.status === AddOsiStatus.PARTIAL_SUCCESS) {
              return addTcpOsiPartialSuccess(result.failedToAddTcps, result.successfullyAddedTcps, AddOsiStatus.PARTIAL_SUCCESS);
            } else {
              return addTcpOsiFullFailure(result.failedToAddTcps, AddOsiStatus.SYSTEM_FAILURE);
            }
          })
        )
      ),
      catchError(() => of(addTcpOsiFullFailure([], AddOsiStatus.UNCAUGHT)))
    )
  );
}
