import { Action, createReducer, on } from '@ngrx/store';
import {
  compensateGuestWithDifferentInfo,
  initializeCompensationServiceState,
  resetCompensationSuccess,
  setCompensations,
  submitCareKit,
  submitCareKitComplete,
} from './compensation-service.actions';
import { compensationAdapter, initialCompensationServiceState } from './compensation-service.state';

const featureReducer = createReducer(
  initialCompensationServiceState,
  on(setCompensations, (state, { compensation }) => {
    return {
      ...state,
      compensation: compensationAdapter.setAll(compensation, state.compensation),
      loaded: true
    };
  }),
  on(submitCareKit, (state, { request, compensationType }) => {
    return {
      ...state,
      careKitRequest: request,
      careKitRequestCompensationType: compensationType,
    };
  }),
  on(submitCareKitComplete, (state, { success, errorMessage }) => {
    return {
      ...state,
      success,
      errorMessage,
    };
  }),
  on(resetCompensationSuccess, (state) => {
    return {
      ...state,
      success: null,
    };
  }),
  on(compensateGuestWithDifferentInfo, (state) => {
    return {
      ...state,
      ...initialCompensationServiceState
    };
  }),
  on(initializeCompensationServiceState, (state) => ({ ...state, ...initialCompensationServiceState }))
);

export function compensationServiceReducer(state = initialCompensationServiceState, action: Action) {
  return featureReducer(state, action);
}
