/**
 * Enumeration for itinerary change types (typically reissues). Must match
 * RainWebApi.DTOs.TicketReissue.TransactionType
 */
export enum ItineraryChangeType {
  /**
   * Not currently supported
   */
  Exchange = 0,

  /**
   * InvoluntaryReroute requires at least 1 Coupon be provided in ExchangeDocs
   */
  InvoluntaryReroute = 1,

  /**
   * Schedule change requires the entire old ticket to be reissued. Do not provide ExchangeDoc data.
   */
  ScheduleChange = 2,

  /**
   * To autoprice for taxes. Requires you specify all FareInfo.BaseAmounts. Will use provided BaseAmounts to calculate taxes.
   * Not currently supported.
   */
  ExchangeAutoPriceForTaxes = 3
}
