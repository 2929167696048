export enum SSRCode {
  AVIH = 'AVIH',
  BIKE = 'BIKE',
  BLND = 'BLND',
  BSCT = 'BSCT',
  BULK = 'BULK',
  CBBG = 'CBBG',
  CHLD = 'CHLD',
  DEAF = 'DEAF',
  DEPA = 'DEPA',
  DEPU = 'DEPU',
  DOCS = 'DOCS',
  DPNA = 'DPNA',
  ESAN = 'ESAN',
  EXST = 'EXST',
  FRAG = 'FRAG',
  GRPF = 'GRPF',
  GRPS = 'GRPS',
  INFT = 'INFT',
  LANG = 'LANG',
  MAAS = 'MAAS',
  MEDA = 'MEDA',
  OTHS = 'OTHS',
  PETC = 'PETC',
  PNUT = 'PNUT',
  PPOC = 'PPOC',
  SPEQ = 'SPEQ',
  STCR = 'STCR',
  SVAN = 'SVAN',
  TKTL = 'TKTL',
  TWOV = 'TWOV',
  UMNR = 'UMNR',
  WCBD = 'WCBD',
  WCBW = 'WCBW',
  WCHC = 'WCHC',
  WCHR = 'WCHR',
  WCHS = 'WCHS',
  WCLB = 'WCLB',
  WCMP = 'WCMP',
  WCOB = 'WCOB',
}
