import { createFeatureSelector, createSelector } from '@ngrx/store';
import { voluntaryChangeFeatureKey, VoluntaryChangeState } from './voluntary-change.state';
import { getMostRecentlyIssuedTicketDetailsForGuests } from '../../../services/ticket-service/state/ticket-service.selectors';
import { Passenger } from '../../../dtos/request/reshop-request/passenger';
import { NameNumberUtil } from '../../../utils/name-number-util';
import { Segment } from '../../../dtos/request/reshop-request/segment';
import { getRoutedConfirmationCode } from '../../../services/reservation-service/state/reservation-service.selectors';
import { FlightReshopRequest } from '../../../dtos/request/reshop-request/flight-reshop-request';

const getVoluntaryChangeState = createFeatureSelector<VoluntaryChangeState>(voluntaryChangeFeatureKey);

export const getSelectedGuests = createSelector(getVoluntaryChangeState, (state) => state?.selectedGuests);
export const getSelectedFlights = createSelector(getVoluntaryChangeState, (state) => state?.selectedFlights);
export const getSelectedFlightsValid = createSelector(getVoluntaryChangeState, (state) => {
  return state?.selectedFlights.some((sf) => sf.action === 'Change' || sf.action === null);
});
export const getSelectedCabinType = createSelector(getVoluntaryChangeState, (state) => state?.selectedCabinType);
export const getFlightSearchCriteria = createSelector(getVoluntaryChangeState, (state) => state?.flightSearchCriteria);
export const getFlightSearchCriteriaValid = createSelector(getVoluntaryChangeState, (state) => {
  return state?.flightSearchCriteria?.length > 0 && !!state?.selectedCabinType && state?.flightSearchCriteria.every((fsc) => fsc.isValid);
});
export const getSelectedFlightResult = createSelector(getVoluntaryChangeState, (state) => state?.selectedFlightResult);

/**
 * These selectors are used to build the flight search request for the flight reshop service.
 */
export const getFlightSearchRequestSegments = createSelector(
  getSelectedFlights,
  getFlightSearchCriteria,
  (selectedFlights, flightSearchCriteria): Segment[] => {
    const requestSegments: Segment[] = [];
    // For each selected flight, determine if it is a change or keep and build the request segment accordingly
    selectedFlights.forEach((selectedFlight) => {
      const flightSearchCriteriaForFlight = flightSearchCriteria.find((fsc) => fsc.id === selectedFlight.id);
      if (flightSearchCriteriaForFlight) {
        // If the flight has been selected for change
        const departureDate =
          flightSearchCriteriaForFlight.departureDate && flightSearchCriteriaForFlight.isValid
            ? new Date(flightSearchCriteriaForFlight.departureDate)?.toISOString()?.split('T')[0]
            : null;
        requestSegments.push({
          origin: [flightSearchCriteriaForFlight.departure],
          destination: [flightSearchCriteriaForFlight.arrival],
          keptFlightInformation: null,
          departureDate,
        });
      } else if (selectedFlight.action === 'Keep') {
        // If the flight has been selected for keep
        requestSegments.push({
          origin: [selectedFlight.segment.departureAirport],
          destination: [selectedFlight.segment.arrivalAirport],
          keptFlightInformation: {
            bookingCode: selectedFlight.segment.serviceClassCode,
            carrierFlightNumber: selectedFlight.segment.operatedByAirlineCode + selectedFlight.segment.operatingAirlineFlightNumber,
            departureDateTime: selectedFlight.segment.departureDateTime,
            flown: false, // *** NOTE: *** THIS MAY NEED TO BE UPDATED IN THE FUTURE!!! For MVP, we are only supporting unflown flights.
          },
          departureDate: new Date(selectedFlight.segment.departureDateTime).toISOString().split('T')[0],
        });
      }
    });
    return requestSegments;
  }
);

export const getFlightSearchRequest = createSelector(
  getSelectedGuests,
  getFlightSearchRequestSegments,
  getMostRecentlyIssuedTicketDetailsForGuests,
  getRoutedConfirmationCode,
  (selectedGuests, requestSegments, guestTickets, confirmationCode): FlightReshopRequest => {
    const passengers: Passenger[] = selectedGuests.map((guest) => {
      const ticketNumber = guestTickets.find((ticket) =>
        NameNumberUtil.IsEquivalent(ticket.ticket?.customer?.traveler?.nameNumber, guest.id)
      )?.ticket?.number;
      return {
        firstName: guest.firstName,
        lastName: guest.lastName,
        dateOfBirth: guest.secureFlightInfo?.dateOfBirth?.split('T')[0],
        ticketNumber,
      };
    });
    return {
      segments: requestSegments,
      passengers,
      recordLocator: confirmationCode,
      itaMarket: 'as_revenue_reshop',
    };
  }
);
