import { AbstractControl as UntypedFormControl, ValidatorFn } from '@angular/forms';
import { calculateOffsetDate, TimeType } from './calculateOffsetDate';

export enum DateLimitType {
  MIN,
  MAX,
}

/**
 * Enforces a max or min date for a datepicker input
 * @param date - the max date to enforce
 * @param limit - the type of limit, either upper or lower
 * @param offset - optional object containing the type and amount of the offset
 * @example
 * dateLimit('01-01-1990', DateLimitType.MAX, {type: TimeType.Year, amount: 1}) would enforce a max date of '01-01-1991'
 * dateLimit('01-01-1990', DateLimitType.MAX, {type: TimeType.Year, amount: -1}) would enforce a max date of '01-01-1989'
 * dateLimit('01-01-1990', DateLimitType.MIN, {type: TimeType.Year, amount: 1}) would enforce a min date of '01-01-1991'
 * dateLimit('01-01-1990', DateLimitType.MIN, {type: TimeType.Year, amount: -1}) would enforce a min date of '01-01-1989'
 */
export function dateLimit(date: Date, limit: DateLimitType, offset?: { type: TimeType; amount: number }): ValidatorFn | null {
  return (control: UntypedFormControl) => {
    let result: { dateLimitErrorMessage: string } | null = null;
    const inputDate = new Date(new Date(control.value).toLocaleString('en-US', { timeZone: 'Etc/UTC' }));
    // use new Date(mm/dd/yyyy) to strip time from date
    const dateLimitNoTime = new Date(`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`);
    // if the control value is a valid date
    if (!isNaN(inputDate.getTime())) {
      const dateToEnforce = offset?.type !== undefined ? calculateOffsetDate(dateLimitNoTime, offset) : dateLimitNoTime;
      const dateLimitString = `${dateToEnforce.getMonth() + 1}/${dateToEnforce.getDate()}/${dateToEnforce.getFullYear()}`;
      if (limit === DateLimitType.MAX && inputDate.getTime() > dateToEnforce.getTime()) {
        result = {
          dateLimitErrorMessage: `Max date: ${dateLimitString}`,
        };
      }

      if (
        limit === DateLimitType.MIN && (inputDate.getTime() !== dateToEnforce.getTime()) &&
        (inputDate.getTime() < dateToEnforce.getTime() || checkTwoDigitsJSDates(control.value, dateToEnforce))
      ) {
        result = {
          dateLimitErrorMessage: `Min date: ${dateLimitString}`,
        };
      }
    }
    return result;
  };
}

/**
 * Reason for checking dates with only two digits:
 *  If you use year higher or equal 0 and lower then 50 then 2000 will be added automatically.
      new Date('01 January 15 00:00:00 UTC')).getFullYear() // returns 2015
 *  If you use year higher or equal 50 and lower then 100 then 1900 will be added.
      new Date('01 January 50 00:00:00 UTC').getFullYear() // return 1950
 *  Only years equal to 100 or higher gets the correct year number.
      new Date('01 January 100 00:00:00 UTC').getFullYear() // returns 100
 * @param controlValue date passed from the form control
 * @param minDateRange date to validate against
 * @returns false when the date has only 2 digits
 */
function checkTwoDigitsJSDates(controlValue: string, minDateRange: Date) {
  if (controlValue) {
    const parsedDate = new Date(controlValue);
    return parsedDate.valueOf() < minDateRange.valueOf();
  }

  return false;
}
