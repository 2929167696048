export enum FlightChangeOptions {
  /**
   * Keep the flight that the guest has been assigned to
   */
  KEEP = 1,

  /**
   * Change the flight that the guest has been assigned to
   */
  CHANGE = 2,

  /**
   * Cancel the segment all together
   */
  CANCEL = 3,

  /**
   * Ignore this segment for reissue (e.g.: we're in an IRROP but this flight is not)
   */
  IGNORE = 4,
}
