import { Injectable } from '@angular/core';
import { SeatMap } from '../../../models/seat-map/seat-map';
import { Passenger } from '../../../dtos/response/reservation-response/passenger';
import { Reservation } from '../../../dtos/response/reservation-response/reservation';
import { Segment } from '../../../dtos/response/reservation-response/segment';
import { SeatMapUtil } from '../../seat-map/seat-map-util';
import { RootState } from '../../../state/state';
import { Store } from '@ngrx/store';
import { MessageKey } from '../../../models/message/message-key';
import { addStackedMessageNoDuplicate, removeMessageByKey } from '../../../services/message-service/state/message.actions';
import { MISSING_SEAT_PRICE } from '../../../models/seat-map/missing-seat-price';
import { PassengerUseCase } from '../../../use-cases/passenger.use-case';
import { SEAT_AREA } from '../../../dtos/response/reservation-response/seat-area';
import { SeatMapLookupStatus } from '../../../dtos/response/seat-map-lookup-response/seat-map-lookup-status';
import { SeatMapFailure } from '../../../models/seat-map-failure';
import { SSRCode } from '../../../models/ssr/ssr-code';
import { SeatMapLookupResponse } from '../../../dtos/response/seat-map-lookup-response/seat-map-lookup-response';

@Injectable({
  providedIn: 'root',
})
export class SeatMapMessageConverter {
  constructor(private store: Store<RootState>, private seatMapUtil: SeatMapUtil, private passengerUseCase: PassengerUseCase) {}

  /**
   * Dispatch seat map messages based on a successful seatmap lookup
   */
  public dispatchSeatMapMessages(
    seatMap: SeatMap,
    seatMapSegmentIndex: number,
    passengers: Passenger[],
    routedReservation: Reservation,
    filteredSegments: Segment[]
  ): void {
    if (routedReservation?.premiumEligibility?.isEligible && this.seatMapUtil.checkPremiumSeatsUnoccupied(seatMap)) {
      this.checkSegmentForMissingPremiumPrices(seatMap, seatMapSegmentIndex, passengers);
    }
    this.checkSegmentForNoContiguousSeats(routedReservation, seatMapSegmentIndex, seatMap.maxContiguousCount);

    this.checkSegmentForOtherAirline(routedReservation, seatMapSegmentIndex, seatMap, filteredSegments);
  }

  /**
   * Map seatmap lookup errors
   * Used for non-reservation based flights
   */
  public convertSeatMapErrorToMessage(result: SeatMapLookupResponse): SeatMapFailure {
    const seatMapFailure = new SeatMapFailure();
    switch (result.status) {
      case SeatMapLookupStatus.TIMEOUT:
        seatMapFailure.errorMessage = 'Service has timed out or is unavailable. Error will be automatically logged.';
        seatMapFailure.contactCenterMessage = null;
        break;
      case SeatMapLookupStatus.INVALID_DATES:
      case SeatMapLookupStatus.DOES_NOT_EXIST:
        seatMapFailure.errorMessage = result.errorMessage;
        seatMapFailure.contactCenterMessage = null;
        break;
      case SeatMapLookupStatus.UNSUPPORTED_CARRIER:
        seatMapFailure.errorMessage = 'Seat map is only available on supported carrier. Please contact partner airline directly.';
        seatMapFailure.contactCenterMessage = null;
        break;
      case SeatMapLookupStatus.INSIDE_CHECK_IN_WINDOW:
        seatMapFailure.errorMessage = 'Unable to load seat map during check-in window, advise guest to see a CSA at the airport.';
        seatMapFailure.contactCenterMessage = null;
        break;
      case SeatMapLookupStatus.SYSTEM_FAILURE:
        seatMapFailure.errorMessage = 'A system error occurred. Error will be automatically logged.';
        seatMapFailure.contactCenterMessage = null;
        break;
      default:
        seatMapFailure.errorMessage = 'An unknown error occurred. If the issue continues, help us investigate by';
        seatMapFailure.contactCenterMessage = 'submitting a detailed report through Contact Center Technology.';
        break;
    }
    return seatMapFailure;
  }

  /**
   *
   * @param passenger Passenger - check if has extra seats without ssr
   * @param segmentIndex the 0 based index for the impacted segment
   *
   * If the passenger has extra seats without ssrs, will dispatch an action to display
   * an advisory text to add the ssr.
   */
  public checkForExtraSeatsAdvisoryTextsForSelectedPassenger(passenger: Passenger, segmentIndex: number) {
    const isExtraSeatSSRsAvailable =
      passenger?.ssrs?.length > 0
        ? passenger.ssrs.some((ssr) => ssr.serviceCode === SSRCode.EXST || ssr.serviceCode === SSRCode.CBBG)
        : false;
    if (passenger) {
      if (passenger.extraSeatRefs?.length > 0 && (passenger.ssrs.length === 0 || !isExtraSeatSSRsAvailable)) {
        this.store.dispatch(addStackedMessageNoDuplicate(MessageKey.EXTRA_SEAT_NO_SSRS, [], { segmentIndex, passengerId: passenger.id }));
      }
      if (passenger.extraSeatRefs?.length > 0 && passenger.ssrs.length > 0 && isExtraSeatSSRsAvailable) {
        this.store.dispatch(removeMessageByKey(MessageKey.EXTRA_SEAT_NO_SSRS, segmentIndex, passenger.id));
      }
    }
  }

  /**
   * Maps seatmap lookup statuses to message keys
   */
  public mapSeatMapMessages(seatMapSegmentIndex: number, status: SeatMapLookupStatus, message: string) {
    let key: MessageKey;

    switch (status) {
      case SeatMapLookupStatus.INSIDE_CHECK_IN_WINDOW:
      case SeatMapLookupStatus.ALREADY_CHECKED_IN:
        key = MessageKey.INSIDE_CHECK_IN_WINDOW;
        break;
      case SeatMapLookupStatus.NO_SEGMENTS:
        key = MessageKey.NO_SEGMENTS;
        break;
      case SeatMapLookupStatus.NO_PREMIUM_PRICES:
        key = MessageKey.NO_PREMIUM_PRICES;
        break;
      case SeatMapLookupStatus.OTHER_AIRLINE:
        key = MessageKey.OTHER_AIRLINE;
        break;
      case SeatMapLookupStatus.SUCCESS:
        // We don't show a message for success
        break;
      case SeatMapLookupStatus.TIMEOUT:
        key = MessageKey.SEAT_MAP_LOAD_TIMEOUT;
        break;
      case SeatMapLookupStatus.UNSUPPORTED_CARRIER:
        key = MessageKey.UNSUPPORTED_CARRIER;
        break;
      case SeatMapLookupStatus.SEGMENT_OUT_OF_ORDER:
        key = MessageKey.SEGMENT_OUT_OF_ORDER;
        break;
      case SeatMapLookupStatus.UNKNOWN_CITY_CODE:
        key = MessageKey.UNKNOWN_CITY_CODE;
        break;
      case SeatMapLookupStatus.INVALID_DATES:
      case SeatMapLookupStatus.DOES_NOT_EXIST:
      case SeatMapLookupStatus.SYSTEM_FAILURE:
        if (message) {
          key = MessageKey.SEAT_MAP_LOAD_ERROR;
        } else {
          key = MessageKey.SEAT_MAP_LOAD_SYSTEM_FAILURE;
        }
        break;
      default:
        console.log(`Unhandled seat lookup status [${status}]`);
    }

    if (key) {
      this.store.dispatch(addStackedMessageNoDuplicate(key, [message], { segmentIndex: seatMapSegmentIndex }));
    }
  }

  /**
   * Clears seatmap messages
   */
  public clearSeatMapSaveMessages() {
    this.store.dispatch(removeMessageByKey(MessageKey.AIRPORT_CHECK_IN_WINDOW));
    this.store.dispatch(removeMessageByKey(MessageKey.SAVE_SYSTEM_FAILURE));
    this.store.dispatch(removeMessageByKey(MessageKey.SEGMENT_OUT_OF_ORDER));
    this.store.dispatch(removeMessageByKey(MessageKey.SAVE_TIMEOUT));
  }

  /**
   * Check if premium seat pricing is missing for a seatmap
   */
  private checkSegmentForMissingPremiumPrices(seatMap: SeatMap, segmentIndex: number, passengers: Passenger[]): void {
    if (!this.seatMapUtil.hasAssignablePremiumSeats(seatMap, passengers, segmentIndex)) {
      return;
    }
    if (seatMap.rows?.length > 0) {
      for (const row of seatMap.rows) {
        let foundSeatWithMissingPrice = false;
        for (const seat of row.seats) {
          if (MISSING_SEAT_PRICE === seat.upsellPrice) {
            this.store.dispatch(addStackedMessageNoDuplicate(MessageKey.NO_PREMIUM_PRICES, [], { segmentIndex }));
            foundSeatWithMissingPrice = true;
            break;
          }
        }
        if (foundSeatWithMissingPrice) {
          break;
        }
      }
    }
  }

  /**
   * Check a seatmap for contiguous seat needs
   * Used to check if a seatmap does not have enough seats together for the amount of passengers on a reservation
   * And used to check if a seatmap does not have the required amount fo seats together for CBBG/EXST reservations
   */
  private checkSegmentForNoContiguousSeats(reservation: Reservation, seatMapSegmentIndex: number, segmentMaxContiguousCount: number): void {
    // Check contiguous seat needs for regular reservations
    const passengers: Passenger[] = this.getPassengersWithUnmetContiguousSeatNeeds(reservation, segmentMaxContiguousCount);
    // Check contiguous seat needs for CBBG/EXST reservations
    passengers.forEach((passenger: Passenger) => {
      const type = passenger.extraSeatRefs?.length ? passenger.extraSeatRefs[0].seats[0].extraSeatType : 'extra seat';
      // Not enough contiguous seats found, dispatch message
      this.store.dispatch(
        addStackedMessageNoDuplicate(MessageKey.NO_CONTIGUOUS, [type], { segmentIndex: seatMapSegmentIndex, passengerId: passenger.id })
      );
    });
  }

  /**
   * Get contiguous seat needs for a set of passengers
   */
  private getPassengersWithUnmetContiguousSeatNeeds(reservation: Reservation, segmentMaxContiguousCount: number): Passenger[] {
    if (reservation && reservation.passengers && segmentMaxContiguousCount > 0) {
      return reservation.passengers.filter(
        (psngr: Passenger) => this.passengerUseCase.contiguousSeatCount(psngr) > segmentMaxContiguousCount
      );
    }
    return [];
  }

  /**
   * Dispatch the other airline seatmap message when applicable
   */
  private checkSegmentForOtherAirline(
    reservation: Reservation,
    seatMapSegmentIndex: number,
    seatMap: SeatMap,
    filteredSegments: Segment[]
  ): void {
    if (
      reservation?.isTicketIssuedByOtherAirline &&
      SEAT_AREA.MAIN_CABIN === filteredSegments[seatMapSegmentIndex]?.seatArea &&
      this.seatMapUtil.hasAssignablePremiumSeats(seatMap, reservation.passengers, seatMapSegmentIndex)
    ) {
      this.store.dispatch(addStackedMessageNoDuplicate(MessageKey.OTHER_AIRLINE, [], { segmentIndex: seatMapSegmentIndex }));
    }
  }
}
