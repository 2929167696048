import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { FlightShoppingService } from '../flight-shopping.service';
import { loadFlightShoppingResults, loadFlightShoppingResultsComplete } from './flight-shopping-service.actions';

@Injectable()
export class FlightShoppingServiceEffects {
  constructor(private actions$: Actions, private service: FlightShoppingService) { }

  loadFlightShoppingResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFlightShoppingResults),
      mergeMap((action) =>
        this.service.getFlightShoppingResults(action.request).pipe(
          map((result) => {
            return result.response
              ? loadFlightShoppingResultsComplete(result.success, result.response)
              : loadFlightShoppingResultsComplete(result.success, null);
          })
        )
      ),
      catchError(() => of(loadFlightShoppingResultsComplete(false, null)))
    )
  );
}
