import { createAction } from '@ngrx/store';
import { couponRecordSearchFeatureKey } from './coupon-record-search.state';

export const initializeCouponRecordSearchState = createAction(`[${couponRecordSearchFeatureKey}] Initialize`);

export const setCouponRecordSearchFirstName = createAction(
  `[${couponRecordSearchFeatureKey}] Set coupon record search first name property`,
  (firstName: string) => ({
    firstName,
  })
);

export const setCouponRecordSearchLastName = createAction(
  `[${couponRecordSearchFeatureKey}] Set coupon record search last name property`,
  (lastName: string) => ({
    lastName,
  })
);

export const setCouponRecordSearchStartDate = createAction(
  `[${couponRecordSearchFeatureKey}] Set coupon record search start date property`,
  (startDate: string) => ({
    startDate,
  })
);

export const setCouponRecordSearchEndDate = createAction(
  `[${couponRecordSearchFeatureKey}] Set coupon record search end date property`,
  (endDate: string) => ({
    endDate,
  })
);

export const setCouponRecordSearchDepartureCity = createAction(
  `[${couponRecordSearchFeatureKey}] Set coupon record search departure city property`,
  (departureCity: string) => ({
    departureCity,
  })
);

export const setCouponRecordSearchAirlineCode = createAction(
  `[${couponRecordSearchFeatureKey}] Set coupon record search airline code property`,
  (airlineCode: string) => ({
    airlineCode,
  })
);

export const setCouponRecordSearchTicketNumber = createAction(
  `[${couponRecordSearchFeatureKey}] Set coupon record search ticket number property`,
  (ticketNumber: string) => ({
    ticketNumber,
  })
);
