import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../../state/state';
import { AssignSeatsStatus } from '../../../dtos/response/seats-save-status';
import { MessageKey } from '../../../models/message/message-key';
import { addStackedMessageNoDuplicate } from '../../../services/message-service/state/message.actions';

@Injectable({
  providedIn: 'root',
})
export class SeatAssignmentMessageConverter {
  constructor(private store: Store<RootState>) {}

  /**
   * Maps seat assignment statuses to message keys
   */
  public mapSeatAssignmentMessages(seatSaveStatus: AssignSeatsStatus): void {
    let key: MessageKey;
    switch (seatSaveStatus) {
      case AssignSeatsStatus.SYSTEM_FAILURE:
        key = MessageKey.SAVE_SYSTEM_FAILURE;
        break;
      case AssignSeatsStatus.AIRPORT_CHECK_IN_WINDOW:
        key = MessageKey.AIRPORT_CHECK_IN_WINDOW;
        break;
      case AssignSeatsStatus.SEGMENT_OUT_OF_ORDER:
        key = MessageKey.SEGMENT_OUT_OF_ORDER;
        break;
      case AssignSeatsStatus.TIMEOUT:
        key = MessageKey.SAVE_TIMEOUT;
        break;
      case AssignSeatsStatus.PASSWORD_DECRYPTION_ERROR:
        key = MessageKey.PASSWORD_DECRYPTION_ERROR;
        break;
      case AssignSeatsStatus.SUCCESS:
        // We don't show a message on success
        break;
      default:
        console.log(`Unhandled seat save status [${seatSaveStatus}]`);
    }

    if (key !== undefined) {
      this.store.dispatch(addStackedMessageNoDuplicate(key));
    }
  }
}
