<!--For multiple active callers-->
<auro-dropdown id="callerContextOption" rounded *ngIf="callerCount > 1">
  <auro-menu noCheckmark>
    <auro-menuoption
      *ngFor="let caller of callers; index as idx"
      [attr.id]="caller.id"
      [attr.value]="caller.id"
      (click)="handleClick(caller.id)"
      [selected]="caller.id === activeCaller.id"
      tabindex="0"
    >
      <div class="caller-context-menuoption">
        <div>
          <ng-container
            *ngTemplateOutlet="
              callerContextMedia;
              context: {
                caller: caller,
                activeSelection: caller.id === activeCaller.id,
                isDisconnected: isDisconnected
              }
            "
          ></ng-container>
        </div>
        <ng-container
          *ngTemplateOutlet="
            callerContextDetails;
            context: {
              callerContextDetailsObj: {
                caller: caller,
                activeCaller: activeCaller,
                passengerLoyaltyIcon: true,
                millionMilerStatus: caller.millionMilerStatus,
                activeSelectionName: caller.id === activeCaller.id,
                noInteractionClass: false,
                callerNoInfoText: 'No interaction details',
                isTriggerName: false,
                isTriggerSingle: callerCount > 1
              }
            }
          "
        >
        </ng-container>
      </div>
    </auro-menuoption>
  </auro-menu>
  <ng-container *ngIf="callerCount > 1" [ngTemplateOutlet]="dropdownTriggerBlock"></ng-container>
</auro-dropdown>

<!--For single active caller-->
<ng-container *ngIf="callerCount === 1" [ngTemplateOutlet]="dropdownTriggerBlock"></ng-container>

<ng-template #dropdownTriggerBlock>
  <div [attr.slot]="callerCount > 1 && 'trigger'" id="dropdownTrigger">
    <ng-container
      *ngTemplateOutlet="
        callerContextMedia;
        context: {
          caller: activeCaller,
          disabled: true,
          activeSelection: false,
          isDisconnected: isDisconnected,
          isTriggerSingle: callerCount === 1
        }
      "
    ></ng-container>
    <div class="caller-details-trigger">
      <ng-container
        *ngTemplateOutlet="
          callerContextDetails;
          context: {
            callerContextDetailsObj: {
              caller: activeCaller,
              passengerLoyaltyIcon: false,
              activeSelectionName: false,
              noInteractionClass: true,
              isTriggerName: true,
              callerNoInfoText: 'Caller Context details not available',
              isTriggerSingle: callerCount === 1
            }
          }
        "
      >
      </ng-container>
      <span class="caller-context-count" *ngIf="callerCount > 1">{{ activeCallerIndex }} of {{ callerCount }} </span>
      <auro-icon
        ondark
        category="interface"
        name="chevron-down"
        customColor
        style="color: #0074c8; height: 24px"
        *ngIf="callerCount > 1 ? true : null"
      ></auro-icon>
    </div>
  </div>
</ng-template>

<ng-template
  #callerContextMedia
  let-caller="caller"
  let-disabled="disabled"
  let-activeSelection="activeSelection"
  let-isDisconnected="isDisconnected"
  let-isTriggerSingle="isTriggerSingle"
>
  <div [ngSwitch]="caller?.callerMedia?.toLowerCase()">
    <div
      *ngSwitchCase="'voice'"
      class="caller-context-media-container"
      [ngClass]="{ 'active-selection-icon': activeSelection, 'no-information': isDisconnected, 'single-caller-icon': isTriggerSingle }"
    >
      <ng-container
        *ngTemplateOutlet="callerContextIcon; context: { title: 'Voice interaction', category: 'interface', name: 'phone' }"
      ></ng-container>
    </div>
    <div
      *ngSwitchCase="'chat'"
      class="caller-context-media-container"
      [ngClass]="{ 'active-selection-icon': activeSelection, 'no-information': isDisconnected, 'single-caller-icon': isTriggerSingle }"
    >
      <ng-container
        *ngTemplateOutlet="callerContextIcon; context: { title: 'Chat/SMS text interaction', category: 'interface', name: 'chat' }"
      ></ng-container>
    </div>
    <div
      *ngSwitchCase="'sms'"
      class="caller-context-media-container"
      [ngClass]="{ 'active-selection-icon': activeSelection, 'no-information': isDisconnected, 'single-caller-icon': isTriggerSingle }"
    >
      <ng-container
        *ngTemplateOutlet="callerContextIcon; context: { title: 'Chat/SMS text interaction', category: 'interface', name: 'chat' }"
      ></ng-container>
    </div>
    <div
      *ngSwitchCase="'unknown'"
      class="caller-context-media-container"
      [ngClass]="{ 'active-selection-icon': activeSelection, 'no-information': isDisconnected, 'single-caller-icon': isTriggerSingle }"
    >
      <ng-container
        *ngTemplateOutlet="
          callerContextIcon;
          context: { title: 'Type of interaction cannot be determined.', category: 'alert', name: 'information-stroke' }
        "
      ></ng-container>
    </div>
    <div
      *ngSwitchDefault
      class="caller-context-media-container"
      [ngClass]="{
        'active-selection-icon': activeSelection,
        'no-information-disabled': disabled,
        'no-information': isDisconnected,
        'single-caller-icon': isTriggerSingle
      }"
    >
      <ng-container
        *ngTemplateOutlet="callerContextIcon; context: { title: 'No interaction details.', category: 'alert', name: 'information-stroke' }"
      ></ng-container>
    </div>
  </div>
  <ng-template let-title="title" let-category="category" let-name="name" #callerContextIcon>
    <div
      class="caller-context-icon-title"
      [title]="!isDisconnected ? title : 'Unable to retrieve Caller Context details'"
      slot="trigger"
    ></div>
    <auro-icon
      [title]="title"
      ondark
      [attr.category]="category"
      [attr.name]="name"
      customSize
      style="height: 16px; width: 16px"
      emphasis
    ></auro-icon>
  </ng-template>
</ng-template>

<ng-template #callerContextDetails let-callerContextDetailsObj="callerContextDetailsObj">
  <ng-container *ngIf="callerHasInfo(callerContextDetailsObj.caller)">
    <div
      class="caller-context-name quantum-metric-block"
      [ngClass]="{ 'active-selection-name': callerContextDetailsObj.activeSelectionName }"
      *ngIf="!callerContextDetailsObj.isTriggerName; else triggerNameBlock"
      [title]="getCallerName(callerContextDetailsObj.caller, true)"
    >
      {{ callerContextDetailsObj.caller.callerFirstName + ' ' + callerContextDetailsObj.caller.callerLastName }}
    </div>
    <ng-template #triggerNameBlock>
      <div
        class="caller-context-name quantum-metric-block"
        [ngClass]="{
          'active-selection-name': callerContextDetailsObj.activeSelectionName,
          'single-caller-name': callerContextDetailsObj.isTriggerSingle
        }"
        [title]="getCallerName(callerContextDetailsObj.caller, true)"
      >
        {{ getCallerName(callerContextDetailsObj.caller, false) }}
      </div>
    </ng-template>
    <div *ngIf="callerContextDetailsObj.millionMilerStatus" class="caller-context-loyaltyIcon">
      <app-passenger-loyalty-icon [millionMiler]="callerContextDetailsObj.caller.millionMilerStatus" />
    </div>
    <div *ngIf="callerContextDetailsObj.passengerLoyaltyIcon" class="caller-context-loyaltyIcon">
      <app-passenger-loyalty-icon
        *ngIf="callerContextDetailsObj.caller?.tierStatus"
        [tierStatus]="callerContextDetailsObj.caller.tierStatus"
      ></app-passenger-loyalty-icon>
    </div>
  </ng-container>
  <ng-container *ngIf="!callerHasInfo(callerContextDetailsObj.caller)">
    <div
      class="caller-context-name"
      [ngClass]="{
        'active-selection-name': callerContextDetailsObj.activeSelectionName,
        'no-interaction': callerContextDetailsObj.noInteractionClass
      }"
    >
      <span>{{ callerContextDetailsObj.callerNoInfoText }}</span>
    </div>
  </ng-container>
</ng-template>
