import { createAction } from '@ngrx/store';
import { CardOnFile } from '../../../dtos/response/card-on-file-response/card-on-file';
import { cardOnFileFeatureKey } from './card-on-file-service.state';

export const initializeCardOnFileServiceState = createAction(`[${cardOnFileFeatureKey}] Initialize`);
export const setSelectedCardOnFile = createAction(
  `[${cardOnFileFeatureKey}] Set selected card on file`,
  (selectedCardOnFile: CardOnFile | null) => ({
    selectedCardOnFile,
  })
);
export const loadCardsOnFile = createAction(`[${cardOnFileFeatureKey}] Load cards on file`);
export const resetCardsOnFile = createAction(`[${cardOnFileFeatureKey}] Reset cards on file`);
export const loadCardsOnFileLookupComplete = createAction(
  `[${cardOnFileFeatureKey}] Load cards on file Complete`,
  (cardsOnFile: CardOnFile[], success: boolean) => ({
    cardsOnFile,
    success,
  })
);
