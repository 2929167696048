import { Action, createReducer, on } from '@ngrx/store';
import { initialBaggageJourneyServiceState } from './baggage-service.state';
import {
  loadBaggageDetailsComplete,
  loadBaggageJourneyByConfirmationCode,
  resetBaggageJourneyState,
  setBaggageJourneyConfirmationCode,
  setBaggageJourneyLastNames,
  setSelectedBaggageJourneyGuest,
  setShowSearchForm,
} from './baggage-service.actions';
import { Status } from 'src/app/models/status';

const featureReducer = createReducer(
  initialBaggageJourneyServiceState,
  on(resetBaggageJourneyState, (state) => ({
    ...state,
    baggageJourneyResponse: null,
    status: Status.STABLE,
    lastNames: null,
    confirmationCode: null,
    showSearchForm: true,
    selectedGuest: null,
  })),
  on(loadBaggageJourneyByConfirmationCode, (state) => ({
    ...state,
    baggageJourneyResponse: null,
    status: Status.LOADING,
  })),
  on(loadBaggageDetailsComplete, (state, { baggageJourneyResponse }) => {
    return {
      ...state,
      baggageJourneyResponse,
      status: Status.STABLE,
    };
  }),
  on(setBaggageJourneyLastNames, (state, { lastNames }) => {
    return {
      ...state,
      lastNames,
    };
  }),
  on(setBaggageJourneyConfirmationCode, (state, { confirmationCode }) => {
    return {
      ...state,
      confirmationCode,
    };
  }),
  on(setShowSearchForm, (state, { showSearchForm }) => {
    return {
      ...state,
      showSearchForm,
    };
  }),
  on(setSelectedBaggageJourneyGuest, (state, { selectedGuest }) => {
    return {
      ...state,
      selectedGuest,
    };
  })
);

/**
 * Typescript safe way to export the reducer so that it can be imported in modules
 */
export function baggageServiceReducer(state = initialBaggageJourneyServiceState, action: Action) {
  return featureReducer(state, action);
}
