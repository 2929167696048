import { Passenger } from '../dtos/response/reservation-response/passenger';
import { Reservation } from '../dtos/response/reservation-response/reservation';
import { CouponStatusData } from '../dtos/response/vcr-response/coupon-status-data';

/**
 *
 * @param reservation Reservation response
 * @returns All passengers including extra seat passengers
 */
export function getAllPassengers(reservation: Reservation): Passenger[] {
    const allPassengers: Passenger[] = reservation.passengers.map(pax => pax);
    const extraSeatPassengers =
        reservation.passengers.flatMap(pax => pax.extraSeatRefs).filter((pax): pax is Passenger => !!pax);
    return allPassengers.concat(extraSeatPassengers);
}

/**
 *
 * @param coupon Passenger coupon
 * @param passenger Passenger
 * @returns True when passenger has extra seats and the coupon passenger id matches with
 * one of the extra seat passenger's id; false otherwise
 */
export function isCouponPassengerIdMatchExtraSeatPassengerId(coupon: CouponStatusData, passenger: Passenger) {
    if (passenger && passenger.extraSeatRefs && passenger.extraSeatRefs.length > 0) {
        return passenger.extraSeatRefs.some(extraPax => coupon.passengerId === parseFloat(extraPax.id.replace('.0', '.')).toString());
    }
    return false;
}
