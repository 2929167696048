import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { PipesModule } from '../../pipes/pipes.module';
import { FormInputModule } from '../../shared/form-input/form-input.module';
import { BaggageDrawerComponent } from './baggage-drawer/baggage-drawer.component';
import { DynamicBaggageComponent } from './dynamic-baggage/dynamic-baggage.component';
import { StaticBaggageComponent } from './static-baggage/static-baggage.component';
import { UXModule } from '../../shared/ux/ux.module';
import { FormsModule } from '@angular/forms';
import { DynamicBaggageTableComponent } from './dynamic-baggage-table/dynamic-baggage-table.component';
import { DynamicBaggageRowComponent } from './dynamic-baggage-row/dynamic-baggage-row.component';
import { DynamicBaggageHeaderComponent } from './dynamic-baggage-header/dynamic-baggage-header.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { RouterModule } from '@angular/router';
import { DynamicBaggageButtonComponent } from './dynamic-baggage-button/dynamic-baggage-button.component';
import { MessageBarsModule } from '../../shared/message-bars/message-bars.module';

@NgModule({
  declarations: [
    BaggageDrawerComponent,
    DynamicBaggageComponent,
    StaticBaggageComponent,
    DynamicBaggageTableComponent,
    DynamicBaggageRowComponent,
    DynamicBaggageHeaderComponent,
    DynamicBaggageButtonComponent,
  ],
  imports: [CommonModule, FormInputModule, PipesModule, UXModule, FormsModule, InlineSVGModule, RouterModule, MessageBarsModule],
  exports: [BaggageDrawerComponent, DynamicBaggageButtonComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class BaggageModule {}
