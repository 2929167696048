/**
 * The valid property values for seats. Must match SeatCore.Models.SeatProperties in the C# code.
 */
export enum SeatProperty {
  Accessible = 'Accessible',
  AirportAssignOnly = 'AirportAssignOnly',
  Blocked = 'Blocked',
  BlockedForInfant = 'BlockedForInfant',
  Bulkhead = 'Bulkhead',
  ExitRowSeat = 'ExitRowSeat',
  Galley = 'Galley',
  Inoperative = 'Inoperative',
  Lavatory = 'Lavatory',
  NoInfant = 'NoInfant',
  NoSeat = 'NoSeat',
  OtherAirlinePremium = 'OtherAirlinePremium',
  Preferential = 'Preferential',
  PreferredPlus = 'PreferredPlus',
  PremiumClass = 'PremiumClass',
  RestrictedRecline = 'RestrictedRecline',
  UnaccompaniedMinorRecommended = 'UnaccompaniedMinorRecommended',
  Unknown = 'Unknown',
  Windowless = 'Windowless',
  ZoneRestricted = 'ZoneRestricted',
  Main = 'Main',
  Saver = 'Saver',
  MainPreferred = 'MainPreferred',
  Business = 'Business'
}
