import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setSelectedPassenger, setCurrentSegmentIndex, setCurrentSegmentHashId } from '../features/seat-map/state/seat-map.actions';
import { setHasSavedSessionLevelChanges, setHasReviewedSummary, setPaymentReceived, setPaymentLink } from '../models/state/model.actions';
import { initializeCardOnFileServiceState } from '../services/card-on-file-service/state/card-on-file-service.actions';
import { initializeCompensationServiceState } from '../services/compensation-service/state/compensation-service.actions';
import { initializeFlightServiceState } from '../services/flight-service/state/flight-service.actions';
import { initializeFlightShoppingServiceState } from '../services/flight-shopping-service/state/flight-shopping-service.actions';
import { GlobalEvent, GlobalEventService } from '../services/global-event-service/global-event.service';
import { initializeItineraryState } from '../services/itinerary-service/state/itinerary.actions';
import { initializeReservationServiceState } from '../services/reservation-service/state/reservation-service.actions';
import { initializeReservationTransactionServiceState } from '../services/reservation-transaction-service/state/reservation-transaction-service.actions';
import { initializeSeatsServiceState } from '../services/seat-service/state/seats-service.actions';
import { initializeTicketServiceState } from '../services/ticket-service/state/ticket-service.actions';
import { initializePurchaseState } from '../shared/purchase/state/purchase.actions';
import { initializeAppState } from '../state/actions';
import { RootState } from '../state/state';

@Injectable({
  providedIn: 'root',
})
export class ReservationUseCase {
  constructor(private globalEventService: GlobalEventService, private store: Store<RootState>) {}

  clearReservation(): void {
    this.store.dispatch(setHasSavedSessionLevelChanges(false));
    this.store.dispatch(setHasReviewedSummary(false));
    this.store.dispatch(setPaymentReceived(false));
    this.store.dispatch(setPaymentLink(null));
    this.store.dispatch(initializeTicketServiceState());
    this.store.dispatch(initializeAppState());
    this.store.dispatch(initializeFlightServiceState());
    this.globalEventService.broadcast(GlobalEvent.RESET_PURCHASABILITY);
    this.store.dispatch(setSelectedPassenger(null));
    this.store.dispatch(setCurrentSegmentIndex(0));
    this.store.dispatch(setCurrentSegmentHashId(null));
    this.store.dispatch(initializeReservationTransactionServiceState());
    this.store.dispatch(initializeReservationServiceState());
    this.store.dispatch(initializePurchaseState());
    this.store.dispatch(initializeCardOnFileServiceState());
    this.store.dispatch(initializeItineraryState());
    this.store.dispatch(initializeFlightShoppingServiceState());
    this.store.dispatch(initializeCompensationServiceState());
    this.store.dispatch(initializeSeatsServiceState());
  }
}
