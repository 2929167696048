import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Compensation } from '../../../dtos/response/compensation-response/compensation';
import { selectRouteNestedParam } from '../../../state/selectors';
import { compensationAdapter, compensationServiceFeatureKey, CompensationServiceState } from './compensation-service.state';

const getCompensationServiceState = createFeatureSelector<CompensationServiceState>(compensationServiceFeatureKey);

const { selectEntities, selectAll } = compensationAdapter.getSelectors();

export const getAllCompensationEntities = createSelector(getCompensationServiceState, (state) => selectEntities(state.compensation));
export const getAllCompensations = createSelector(getCompensationServiceState, (state) => selectAll(state.compensation));

/**
 * Gets compensation data for a confirmationCode
 */
export const getCompensations = createSelector(
  getAllCompensations,
  selectRouteNestedParam('confcode'),
  (compensations: Compensation[], confirmationCode: string) =>
    compensations?.filter((compensation) => compensation.confirmationCode === confirmationCode) ?? null
);

/**
 * Gets the Care Kit request data
 * Used for Care Kit summary page when request is successful
 *    since Compensation DAP service does not return compenstion data just a 200 status code
 */
export const getCareKitRequest = createSelector(getCompensationServiceState, (state) => state.careKitRequest);

/**
 * Gets the Care Kit request compensation type
 * Used for Care Kit summary page when request is successful
 *    since Compensation DAP service does not return compenstion data just a 200 status code
 */
export const getCareKitRequestCompensationType = createSelector(
  getCompensationServiceState,
  (state) => state.careKitRequestCompensationType
);

export const getCompensationSuccess = createSelector(
  getCompensationServiceState,
  (state) => state.success
);

export const getCompensationsLoaded = createSelector(
  getCompensationServiceState,
  (state) => state.loaded
);
