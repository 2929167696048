import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GlobalEvent, GlobalEventService } from '../services/global-event-service/global-event.service';

@Injectable()
export class EventInterceptor implements HttpInterceptor {
  constructor(private _eventService: GlobalEventService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let req: HttpRequest<any> = request;
    if (request.url.startsWith('api') && request.headers.get('background') === 'true') {
      req = request.clone({ headers: request.headers.delete('background') });
    } else if (request.url.startsWith('api')) {
      this._eventService.broadcastAjax(GlobalEvent.AJAX_START);
      return next.handle(request).pipe(
        tap({
          next: (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this._eventService.broadcastAjax(GlobalEvent.AJAX_END);
            }
          },
          error: (err: any) => {
            this._eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          }
        })
      );
    }
    return next.handle(req);
  }
}
