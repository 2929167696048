<div class="dropdown-content">
    <div class="dropdown-input-container">
        <div class="dropdown-input" tabindex="0" novalidate>
          <span class="input-prefix" *ngIf="selectedChild?.dataset?.prefix && inputLabelElem.style.display !== 'none'">{{ selectedChild?.dataset?.prefix }}</span>
          <input
            id="dropdownInput"
            class="quantum-metric-encrypt"
            [maxLength]="charLength"
            placeholder="Search"
            spellcheck="false"
            autocomplete="off" list=""
            aria-label="Search"
            title="Search"
            #in
            (input)="isUppercase ? (in.value = searchInputElem.value.toUpperCase()) : in.value"
            [type]="dropdownType"
            name="dropdown"
            data-testid="dropdownInput"
            tabindex="-1"
          />
          <label class="input-label">{{ inputLabel }}</label>
          <span class="dropdown-icon">
            <auro-icon category="interface" name="chevron-down"></auro-icon>
          </span>
        </div>
        <datalist id="list" class="datalist-width-input-group">
          <ng-content></ng-content>
        </datalist>
    </div>
    <button
      class="dropdown-input-group"
      *ngIf="isWithInputGroup"
      (click)="submit($event)"
      data-testid="dropdown-input-button"
      tabindex="0">
        <auro-icon category="interface" name="search" style="color: #fff; top: 4px; position: relative;" customColor></auro-icon>
    </button>
</div>