import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LineItemCharge } from '../../../models/line-item-charge/line-item-charge';
import { Waiver } from '../../../models/waiver/waiver';
import { lineItemChargeAdapter, purchaseFeatureKey, PurchaseState, waiverAdapter } from './purchase.state';

/**
 * Get the entire purchase slice of state
 */
const getPurchaseState = createFeatureSelector<PurchaseState>(purchaseFeatureKey);

/**
 * Get adapter selection methods to allow access to the line item charge entities in the store
 */
const { selectAll: selectAllCharges } = lineItemChargeAdapter.getSelectors();

/**
 * Get adapter selection methods to allow access to the waivers entities in the store
 */
const { selectEntities: selectWaiverEntities, selectAll: selectAllWaivers } = waiverAdapter.getSelectors();

/**
 * Get all of the line item charge objects as an array ordered by insertion order
 */
export const getAllLineItemCharges = createSelector(getPurchaseState, (state) => selectAllCharges(state.charges));

/**
 * Get a dictionary containing all of the waivers in the store, the key is: waiver.chargeType + waiver.passengerHashid + waiver.segmentIndex
 */
export const getAllWaiverEntities = createSelector(getPurchaseState, (state) => selectWaiverEntities(state.waivers));

/**
 * Get an array (ordered by original inset order) containing all of the waivers in the store
 */
export const getAllWaivers = createSelector(getPurchaseState, (state) => selectAllWaivers(state.waivers));

/**
 * Get all line item charges that do not have a waiver
 */
export const getUnwaivedCharges = createSelector(getAllLineItemCharges, getAllWaiverEntities, (charges, waiverEntities) =>
  charges.filter((charge) => !waiverEntities[charge.chargeType + charge.passengerHashId + charge.segmentIndex])
);

/**
 * Get totalPrice for each unwaived line item charge as an array
 */
export const getUnwaivedTotals = createSelector(getUnwaivedCharges, (charges) =>
  charges.map((charge) => charge.totalPrice).filter((totalPrice) => totalPrice !== undefined && totalPrice !== null)
);

/**
 * Get total of all line item charge upsell prices
 */
export const getTotalDue = createSelector(getUnwaivedTotals, (totals) =>
  totals ? Number(totals.reduce((total, current) => total + current, 0).toFixed(2)) : 0
);

/**
 * Get the dollar amount due for a single passenger on a given segment, this amount includes waived charges
 */
export const getTotalDueForPassengerOnSegment = ( passengerHashId: string, segmentIndex: number ) => createSelector(
  getAllLineItemCharges,
  (charges: LineItemCharge[]) =>
    charges
      ?.filter((charge) => charge.passengerHashId === passengerHashId && charge.segmentIndex === segmentIndex)
      .map((charge) => charge.totalPrice)
      .filter((totalPrice) => totalPrice !== undefined && totalPrice !== null)
      .reduce((total, current) => total + current, 0) ?? 0
);

/**
 * Get a single waiver by id
 */
export const getWaiver = ( id: string ) => createSelector(
  getAllWaiverEntities,
  (entities: Dictionary<Waiver>): Waiver => entities[id]
);

/**
 * Get all of the waivers that are flagged as auto waivers (isAutoWaiver: true)
 */
export const getAutoWaivers = createSelector(getAllWaivers, (waivers: Waiver[]) => waivers.filter((waiver) => waiver.isAutoWaiver));

/**
 * Get the number of waivers in the store that are flagged as auto waivers (isAutoWaiver: true)
 */
export const getAutoWaiversCount = createSelector(
  getAllWaivers,
  (waivers: Waiver[]) => waivers.filter((waiver) => waiver.isAutoWaiver).length
);
