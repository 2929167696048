import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AddUmnrRequest } from '../../../dtos/request/ssr-requests/add-umnr-request';
import { SsrDetail } from '../../../dtos/request/ssr-requests/ssr-details';
import { PassengerSSR } from '../../../dtos/response/reservation-response/passenger-ssr';
import { AddSsrStatus } from '../../../dtos/response/ssr-response/add-ssr-status';
import { RemoveSsrStatus } from '../../../dtos/response/ssr-response/remove-ssr-status';
import { SSRCode } from '../../../models/ssr/ssr-code';
import { Status } from '../../../models/status';
import {
  getIsOthsTeenAssistRequested,
  getOthsTeenForm,
  getSelectedFlights,
  getSelectedGuests
} from '../../../shared/form-input/state/forms.selectors';
import { getConfirmedSegmentsHashIds, getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist } from '../../reservation-service/state/reservation-service.selectors';
import { ssrServiceFeatureKey, SsrServiceState } from './ssr-service.state';
import { Segment } from '../../../dtos/response/reservation-response/segment';
import { ActionCode } from '../../../dtos/response/action-code/action-codes';
import { OthsKey } from '../../../models/ssr/oths-key';
import { AddOthsTeenRequest } from '../../../dtos/request/ssr-requests/add-oths-teen-request';
import { AddSsrSelectedFlight } from '../../../dtos/request/ssr-requests/add-ssr-selected-flight';
import { AirlineCode } from '../../../models/airlines/airline-code';
import { AddSsrWithRemarkRequest } from 'src/app/dtos/request/ssr-requests/ssr-with-remark-request';
import { AddSvanRequest } from 'src/app/dtos/request/ssr-requests/add-svan-request';
import { AddExstRequest } from 'src/app/dtos/request/ssr-requests/add-exst-request';
import { AddCbbgRequest } from 'src/app/dtos/request/ssr-requests/add-cbbg-request';
import { PetSsrInventoryStatus } from 'src/app/dtos/response/ssr-response/pet-ssr-inventory-status';
import { BatchDeleteSsrResponse } from 'src/app/dtos/response/ssr-response/batch-delete-ssr-response';

const getSsrServiceState = createFeatureSelector<SsrServiceState>(ssrServiceFeatureKey);

/**
 * Get selected ssr option we chose from drop down UMNR, WCHR, etc
 */
export const getSelectedSsrOption = createSelector(getSsrServiceState, (state): string | undefined => state?.selectedSsrOption);

/**
 * Get selected ssr option in SSRCode format we chose from drop down UMNR, WCHR, etc
 */
export const getSelectedSsrCodeFromOption = createSelector(getSelectedSsrOption, (option: string): SSRCode => SSRCode[option]);

/**
 * Get ssr form validity
 */
export const getSsrFormValid = createSelector(getSsrServiceState, (state): boolean => state.ssrFormValid);

/**
 * Get ssr select flights form validity
 */
export const getSelectFlightsFormValid = createSelector(getSsrServiceState, (state): boolean => state.selectFlightsFormValid);

/**
 * Get ssr select guests form validity
 */
export const getSelectGuestsFormValid = createSelector(getSsrServiceState, (state): boolean => state.selectGuestsFormValid);

/**
 * Get the status of adding the current workflow ssrs to the reservation
 */
export const getAddSsrStatus = createSelector(getSsrServiceState, (state): Status => state.addSsrStatus);

/**
 * Get the status of added ssrs
 */
export const getAddedSsrStatus = createSelector(getSsrServiceState, (state): AddSsrStatus => state.addedSsrStatus);

/**
 * Get the ssrs that failed to be added to the reservation
 */
export const getAddSsrFailure = createSelector(getSsrServiceState, (state): SsrDetail[] => state.addSsrsFailure);

/**
 * Get the ssrs that successfully added to the reservation
 */
export const getAddSsrSuccess = createSelector(getSsrServiceState, (state): SsrDetail[] => state.addSsrsSuccess);

/**
 * Get the batch delete ssrs results
 */
export const getBatchDeleteSsrsResponse = createSelector(getSsrServiceState,
  (state): BatchDeleteSsrResponse => state.batchDeleteSsrsResponse);


/**
 * Get a comprehensive list of ssrs that either failed or successfully added to the reservation in chronological flight order
 */
export const getAddSsrsSorted = createSelector(
  getAddSsrSuccess,
  getAddSsrFailure,
  getConfirmedSegmentsHashIds,
  (success, failures, segmentHashIds): SsrDetail[] => {
    let combinedSsrs: SsrDetail[] = [];
    combinedSsrs = combinedSsrs.concat(success, failures);
    let result: SsrDetail[] = [];
    segmentHashIds.forEach((segmentHashId) => {
      result = result.concat(combinedSsrs.filter((ssr) => ssr?.segmentDetails?.segmentHashId === segmentHashId));
    });
    return result;
  }
);

// Get the list of SSRs grouped by the segment using segmentHashId
export const getAddSsrsSortedGroupBySegment = createSelector(
  getAddSsrsSorted,
  (addSsrsSorted) => {
    const ssrsGroupBySegmentMap = new Map<string, SsrDetail[]>();
    addSsrsSorted.forEach(ssr => {
      if (ssrsGroupBySegmentMap.has(ssr?.segmentDetails?.segmentHashId)) {
        ssrsGroupBySegmentMap.get(ssr?.segmentDetails?.segmentHashId)?.push(ssr);
      } else {
        ssrsGroupBySegmentMap.set(ssr?.segmentDetails?.segmentHashId, [ssr]);
      }
    });

    return ssrsGroupBySegmentMap;
  }
);

// Get the list of SSRs grouped by the segment using segmentHashId and ssr code
export const getAddSsrsSortedGroupBySegmentAndSsrCode = createSelector(
  getAddSsrsSortedGroupBySegment,
  (ssrsGroupedBySegment) => {
    const ssrsGroupedBySegmentAndSsrCode = new Map<string, SsrDetail[]>();

    ssrsGroupedBySegment.forEach((ssrs, segmentHashId) => {
      ssrs.forEach(ssr => {
        const key = segmentHashId + '-' + ssr?.ssrCode;

        if (ssrsGroupedBySegmentAndSsrCode.has(key)) {
          ssrsGroupedBySegmentAndSsrCode.get(key)?.push(ssr);
        } else {
          ssrsGroupedBySegmentAndSsrCode.set(key, [ssr]);
        }
      });

    });

    return ssrsGroupedBySegmentAndSsrCode;
  }
);

// Check the status of Pet Ssrs inventory status
export const isEveryPetSsrsInventoryStatusPending = createSelector(
  getAddSsrsSorted, (ssrs) => ssrs.every(ssr => ssr.petInventoryStatus === PetSsrInventoryStatus.PENDING));

// Returns boolean as to whether pet inventory status is pending with consideration of failures
export const isEveryPetSsrsInventoryStatusPendingConsideringFailures = createSelector(
  getAddSsrsSorted,
  (ssrs) => {
    return ssrs.length === 0
      ? false
      : ssrs.every(ssr => ssr.petInventoryStatus === PetSsrInventoryStatus.PENDING);
  });

// Check if all the pet ssrs failed to add
export const isAllSsrsFailedToAdd = createSelector(
  getAddSsrSuccess,
  getAddedSsrStatus,
  (addSuccessSsrs, addSsrStatus) => {
    return (addSuccessSsrs.length === 0) || (addSsrStatus === AddSsrStatus.PET_REMARKS_ADD_FAILURE);
  }
);

/**
 * Get the status of removing ssr's
 */
export const getRemoveSsrStatus = createSelector(getSsrServiceState, (state): RemoveSsrStatus => state.removeSsrStatus);

/**
 * Get the id of removing ssr's
 */
export const getRemoveSsrId = createSelector(getSsrServiceState, (state): string | undefined => state.removeSsrId);

/**
 * Returns a matching ssr in the failedToAddSsrs list based on the one passed in
 */
export const getMatchingSsr = ( passengerSsr: PassengerSSR ) => createSelector(
  getAddSsrFailure,
  (failures): SsrDetail | undefined =>
    failures?.find(
      (ssr) =>
        ssr.ssrCode === passengerSsr.ssr.serviceCode &&
        ssr.segmentDetails.segmentHashId === passengerSsr.ssr.segmentHashId &&
        ssr.passengerDetails.firstName === passengerSsr.firstName &&
        ssr.passengerDetails.lastName === passengerSsr.lastName
    )
);

/**
 * Maps known ssr errors to formatted messages
 */
export const getMappedSsrFailureMessage = ( passengerSsr: PassengerSSR ) => createSelector(
  getMatchingSsr(passengerSsr),
  (matchingSsr): string | undefined => {
  return getFailureMessageToDisplay(matchingSsr?.failureMessage);
});

export function getFailureMessageToDisplay(failureMessage?: string): string | undefined {

  let message: string | null = null;
  if (failureMessage) {
    failureMessagesDict.forEach((val, key) => {
      if (failureMessage.toUpperCase().includes(key)) {
        message = val;
      }
    });

    // Map message if we already know of this error or return the entire failure message
    return message || failureMessage;
  }
  return undefined;
}

// Failure messages dictionary: new Map<Sabre Error Message, Mapped Message>()
const failureMessagesDict = new Map<string, string>([
  ['EXIT ROW SEAT', 'Exit Row Seat'],
  ['VALIDATION ERROR', 'Validation Error'],
  ['ERROR_NO_AVAILABILITY', 'No Availability'],
  ['CK ITIN', 'Check Itinerary'],
  ['SSR CODE NOT RECOGNIZED', 'Not Eligible For Codeshare'],
  ['ET PROCESSING ERROR INVLD TAC', 'Reservation has invalid ticket time limit (TAC). Use IMAGERES to remove TAC by marking the reservation as ticketed (SF5/f7).'],
  ['RESERVATION ORCHESTRATION', 'Unhandled Error']
]);
/**
 * Selector for checking if there are selected Guests
 */
 export const getSsrContinueButtonDisabledForGuestGeneric = createSelector(
  getSelectedGuests,
  (selectedGuests) => !(selectedGuests?.length > 0 && selectedGuests?.every((guest) => guest.remarksValid))
);

/**
 * Selector for checking if there are selected Guests without remark
 */
 export const getSsrContinueButtonDisabledForGuestGenericWithoutRemark = createSelector(
  getSelectedGuests,
  (selectedGuests) => selectedGuests?.length <= 0
);

/**
 * Selector for checking if all selected guests in the age table have a value
 */
export const getSsrContinueButtonDisabledForGuestAge = createSelector(
  getSelectedGuests,
  (selectedGuests) => !(selectedGuests?.length > 0 && selectedGuests?.every((guest) => guest.age?.length > 0))
);

/**
 * Selector for checking if all selected guests in the svan table have a service animal quantity
 */
export const getSsrContinueButtonDisabledForGuestServiceAnimals = createSelector(
  getSelectedGuests,
  (selectedGuests) => !(selectedGuests?.length > 0 && selectedGuests?.every((guest) => guest.serviceAnimalQuantity?.length > 0))
);

/**
 * Selector for checking if the continue button should be disabled in the OTHS/TEEN scenario
 */
export const getSsrContinueButtonDisabledForOthsTeen = createSelector(
  getSsrContinueButtonDisabledForGuestAge,
  getOthsTeenForm,
  (ageDisabled, othsTeemForm): boolean => {
    return ageDisabled || !othsTeemForm?.assistRequested;
  }
);

/**
 * Selector for determining the continue button state on the first of the SSR forms
 */
export const getSsrContinueButtonDisabled = createSelector(
  getSelectedSsrOption,
  getSsrContinueButtonDisabledForGuestGeneric,
  getSsrContinueButtonDisabledForGuestGenericWithoutRemark,
  getSsrContinueButtonDisabledForGuestAge,
  getSsrContinueButtonDisabledForGuestServiceAnimals,
  getSsrContinueButtonDisabledForOthsTeen,
  getSelectFlightsFormValid,
  (selectedOption,
    ssrWithRemarkDisabled,
    ssrWithoutRemarkDisabled,
    umnrDisabled, svanDisabled,
    othsTeenDisabled,
    selectFlightsFormValid): boolean => {
    switch (selectedOption) {
      case undefined:
      case null:
      case '':
        return true;
      case SSRCode.BLND:
      case SSRCode.DEAF:
      case SSRCode.MAAS:
      case SSRCode.DPNA:
      case SSRCode.CBBG:
      case SSRCode.LANG:
      case SSRCode.MEDA:
      case SSRCode.PPOC:
      case SSRCode.GRPF:
      case SSRCode.GRPS:
      case SSRCode.DEPA:
      case SSRCode.DEPU:
      case SSRCode.BIKE:
      case SSRCode.BULK:
      case SSRCode.FRAG:
      case SSRCode.SPEQ:
      case SSRCode.TKTL:
      case OthsKey.ARMD:
        return ssrWithRemarkDisabled;
      case SSRCode.UMNR:
        return umnrDisabled;
      case SSRCode.SVAN:
        return svanDisabled;
      case OthsKey.TEEN:
        return othsTeenDisabled;
      case SSRCode.EXST:
        return ssrWithoutRemarkDisabled;
      case SSRCode.PETC:
          return !selectFlightsFormValid;
      case SSRCode.WCHR:
      default:
        return false;
    }
  }
);

/**
 * Maps the currently selected flights to an add SSR request model flight
 */
export const getSelectedFlightAsAddSsrSelectedFlights = createSelector(getSelectedFlights, (selectedFlights): AddSsrSelectedFlight[] => {
  const mappedFlights: AddSsrSelectedFlight[] = [];

  selectedFlights?.forEach((selectedFlight: Segment) =>
    mappedFlights.push({
      carrierCode: getAddSsrRequestAirlineCode(selectedFlight),
      segmentHashId: selectedFlight.hashId,
      flightNumber: selectedFlight.operatingAirlineFlightNumber,
      departureDate: selectedFlight.departureDateTime,
      origin: selectedFlight.departureAirport,
      destination: selectedFlight.arrivalAirport,
      classOfService: selectedFlight.serviceClassCode,
      bookingStatus: selectedFlight.actionCode,
      segmentId: selectedFlight.segmentId,
      segmentSequence: selectedFlight.sequence
    })
  );

  return mappedFlights;
});

/**
 * Gets an add UMNR request from the store
 */
export const getAddUmnrRequest = createSelector(
  getSelectedFlightAsAddSsrSelectedFlights,
  getSelectedGuests,
  (selectedFlights, selectedGuests): AddUmnrRequest => {
    const request: AddUmnrRequest = {
      passengerDetails: [],
      segmentDetails: selectedFlights,
    };
    selectedGuests?.forEach((selectedGuest) =>
      request.passengerDetails.push({
        passengerNumber: selectedGuest.passenger.id,
        passengerAge: selectedGuest.age,
      })
    );
    return request;
  }
);

/**
 * Gets an add ssr with remarks request from the store
 */
 export const getAddSsrWithRemarkRequest = createSelector(
  getSelectedFlightAsAddSsrSelectedFlights,
  getSelectedGuests,
  (selectedFlights, selectedGuests): AddSsrWithRemarkRequest => {
    const request: AddSsrWithRemarkRequest = {
      passengerDetails: [],
      segmentDetails: selectedFlights,
    };
    selectedGuests?.forEach((selectedGuest) => {
      const options = {};
      selectedGuest.options?.forEach(option => options[option.name] = option.description);
      request.passengerDetails.push({
        passengerNumber: selectedGuest.passenger.id,
        remarks: selectedGuest.remarks,
        options
      });
    });
    return request;
  }
);

/**
 * Gets an add SVAN request from the store
 */
export const getAddSvanRequest = createSelector(
  getSelectedFlightAsAddSsrSelectedFlights,
  getSelectedGuests,
  (selectedFlights, selectedGuests): AddSvanRequest => {
    const request: AddSvanRequest = {
      passengerDetails: [],
      segmentDetails: selectedFlights,
    };
    selectedGuests?.forEach((selectedGuest) =>
      request.passengerDetails.push({
        passengerNumber: selectedGuest.passenger.id,
        serviceAnimalQuantity: selectedGuest.serviceAnimalQuantity
      })
    );
    return request;
  }
);

/**
 * Get the segments allowed for ssr request
 */
 export const getSegmentsForSSR = createSelector(getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist, (allSegments: Segment[]): Segment[] =>
 allSegments.filter((segment) => [ActionCode.HK, ActionCode.SC, ActionCode.TK, ActionCode.MM].includes(segment.actionCode))
);

/**
 * Gets an add EXST request from the store
 */
 export const getAddExstRequest = createSelector(
  getSegmentsForSSR,
  getSelectedGuests,
  (segments, selectedGuests): AddExstRequest => {
    const request: AddExstRequest = {
      passengerDetails: [],
      segmentDetails: []
    };

    segments?.forEach((selectedFlight: Segment) =>
      request.segmentDetails.push({
          carrierCode: getAddSsrRequestAirlineCode(selectedFlight),
          segmentHashId: selectedFlight.hashId,
          flightNumber: selectedFlight.operatingAirlineFlightNumber,
          departureDate: selectedFlight.departureDateTime,
          origin: selectedFlight.departureAirport,
          destination: selectedFlight.arrivalAirport,
          classOfService: selectedFlight.serviceClassCode,
          bookingStatus: selectedFlight.actionCode,
          segmentId: selectedFlight.segmentId,
          segmentSequence: selectedFlight.sequence
      }));

    selectedGuests?.forEach((selectedGuest) =>
      request.passengerDetails.push({
        passengerNumber: selectedGuest.passenger.id,
        extraSeats: selectedGuest.passenger.extraSeatRefs?.filter(refs => refs.firstName === SSRCode.EXST).map(s => s.id) ?? [],
        remarks: 'SECOND SEAT',
        options: {}
      })
    );

    return request;
  }
);

/**
 * Gets an add CBBG request from the store
 */
export const getAddCbbgRequest = createSelector(
  getSelectedFlightAsAddSsrSelectedFlights,
  getSelectedGuests,
  (selectedFlights, selectedGuests): AddCbbgRequest => {
    const request: AddCbbgRequest = {
      passengerDetails: [],
      segmentDetails: selectedFlights
    };
    selectedGuests?.forEach((selectedGuest) =>
      request.passengerDetails.push({
        passengerNumber: selectedGuest.passenger.id,
        extraSeats: selectedGuest.passenger.extraSeatRefs?.filter(refs => refs.firstName === SSRCode.CBBG).map(s => s.id) ?? [],
        remarks: selectedGuest.remarks,
        options: {}
      })
    );

    return request;
  }
);

/**
 * Gets an add OTHS/TEEN request from the store
 */
export const getAddOthsTeenRequest = createSelector(
  getSelectedFlightAsAddSsrSelectedFlights,
  getSelectedGuests,
  getIsOthsTeenAssistRequested,
  (selectedFlights, selectedGuests, isAssistRequested): AddOthsTeenRequest => {
    const request: AddOthsTeenRequest = {
      passengerDetails: [],
      segmentDetails: selectedFlights,
    };
    selectedGuests?.forEach((selectedGuest) =>
      request.passengerDetails.push({
        passengerNumber: selectedGuest.passenger.id,
        passengerAge: selectedGuest.age,
        assistRequested: isAssistRequested,
      })
    );
    return request;
  }
);

/**
 * Return operating airline code for all but codeshare flights
 * Codeshare flights only work with marketing airline code as of 2/9/2022
 */
function getAddSsrRequestAirlineCode(selectedFlight: Segment) {
  if (
    (selectedFlight.marketedByAirlineCode === AirlineCode.AS && selectedFlight.operatedByAirlineCode !== AirlineCode.AS) ||
    !selectedFlight.operatedByAirlineCode
  ) {
    return selectedFlight.marketedByAirlineCode;
  } else {
    return selectedFlight.operatedByAirlineCode;
  }
}
