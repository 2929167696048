import { Passenger } from '../../../dtos/response/reservation-response/passenger';
import { FlightSearchCriteria } from '../../../models/voluntary-change/flight-search-criteria';
import { SegmentAndAction } from '../../../models/voluntary-change/segment-and-action';
import { SelectedFlightResult } from '../../../models/voluntary-change/selected-flight-result';
import { RootState } from '../../../state/state';

export const voluntaryChangeFeatureKey = 'voluntaryChange';

export interface State extends RootState {
  voluntaryChange: VoluntaryChangeState;
}

export interface VoluntaryChangeState {
  selectedGuests: Passenger[];
  selectedFlights: SegmentAndAction[];
  selectedCabinType: string;
  flightSearchCriteria: FlightSearchCriteria[];
  selectedFlightResult: SelectedFlightResult | null;
}

export const initialVoluntaryChangeState: VoluntaryChangeState = {
  selectedGuests: [],
  selectedFlights: [],
  selectedCabinType: '',
  flightSearchCriteria: [],
  selectedFlightResult: null,
};
