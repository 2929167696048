export enum ItineraryActionCode {
    HK = 0,
    KK = 1,
    NN = 2,
    OB = 3,
    SC = 4,
    SS = 5,
    LL = 6,
    JL = 7,
    MM = 8,
    BB = 9,
    DS = 10,
    SB = 11,
    HL = 12,
}
