import { Action, createReducer, on } from '@ngrx/store';
import { initialSeatMapDisplayServiceState } from './seat-map-display-service.state';
import {
  setSeatMapDisplayAssignSeatFormerOccupant,
  setSeatMapDisplayAssignedSeat,
  setSeatMapDisplayIsRevert,
  setSeatMapDisplayPassenger,
  setSeatMapDisplayType,
  setSeatMapDisplayUnassignedSeat
} from './seat-map-display-service.actions';

const featureReducer = createReducer(
  initialSeatMapDisplayServiceState,
  on(setSeatMapDisplayAssignedSeat, (state, { assignedSeat }) => ({ ...state, changeEvent: { ...state.changeEvent, assignedSeat } })),
  on(setSeatMapDisplayAssignSeatFormerOccupant, (state, { assignedSeatFormerOccupant }) =>
    ({ ...state, changeEvent: { ...state.changeEvent, assignedSeatFormerOccupant } })),
  on(setSeatMapDisplayPassenger, (state, { passenger }) => ({ ...state, changeEvent: { ...state.changeEvent, passenger } })),
  on(setSeatMapDisplayIsRevert, (state, { isRevert }) => ({ ...state, changeEvent: { ...state.changeEvent, isRevert } })),
  on(setSeatMapDisplayType, (state, { seatChangeType }) => ({ ...state, changeEvent: { ...state.changeEvent, type: seatChangeType } })),
  on(setSeatMapDisplayUnassignedSeat, (state, { unassignedSeat }) => ({ ...state, changeEvent: { ...state.changeEvent, unassignedSeat } })),
);

/**
 * Typescript safe way to export the reducer so that it can be imported in modules
 */
export function seatMapDisplayServiceReducer(state = initialSeatMapDisplayServiceState, action: Action) {
  return featureReducer(state, action);
}
