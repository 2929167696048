import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { RootState } from '../../../state/state';
import { FeeService } from '../fee.service';
import { getAllBagsAndAncillaryFees, getAllBagsAndAncillaryFeesComplete, loadFeeResults, loadFeeResultsComplete } from './fee.actions';
import { getFeeRequest } from './fee.selectors';

@Injectable()
export class FeeEffects {
  constructor(private store: Store<RootState>, private actions$: Actions, private service: FeeService) {}

  loadFeeResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFeeResults),
      withLatestFrom(this.store.select(getFeeRequest)),
      mergeMap(([action, request]) => this.service.getFees(request).pipe(map((result) => loadFeeResultsComplete(result.success, result)))),
      catchError(() => of(loadFeeResultsComplete(false, null)))
    )
  );

  getAllBagsAndAncillaryFees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllBagsAndAncillaryFees),
      withLatestFrom(this.store.select(getFeeRequest)),
      mergeMap(([action, request]) =>
        this.service.getAllBagsAndAncillaryFees(request).pipe(map((result) => getAllBagsAndAncillaryFeesComplete(result.success, result)))
      ),
      catchError(() => of(getAllBagsAndAncillaryFeesComplete(false, null)))
    )
  );
}
