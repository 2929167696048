import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer as observableDefer, Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { AirlineCode } from '../../models/airlines/airline-code';
import { AddMileagePlanRequest } from '../../dtos/request/add-mileage-plan-request/add-mileage-plan-request';
import { AddMileagePlanResponse } from '../../dtos/response/add-mileage-plan-response';
import { MileagePlanDetail } from '../../dtos/request/add-mileage-plan-request/mileage-plan-detail';
import { RemoveMileagePlanResponse } from '../../dtos/response/remove-mileage-plan-response';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';

export interface ReservationMileagePlanServiceAPI {
  add(confirmationCode: string, mileagePlanDetails: MileagePlanDetail[]): Observable<AddMileagePlanResponse>;
  remove(confirmationCode: string, loyaltyId: string): Observable<RemoveMileagePlanResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class ReservationMileagePlanService implements ReservationMileagePlanServiceAPI {
  constructor(private http: HttpClient, private eventService: GlobalEventService) {}

  add(
    confirmationCode: string,
    mileagePlanDetails: MileagePlanDetail[],
    frequentFlyerCarrier?: AirlineCode,
    frequentFlyerNumber?: string
  ): Observable<AddMileagePlanResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    const body: AddMileagePlanRequest = {
      recordLocator: confirmationCode,
      ffDetails: [...mileagePlanDetails],
      receivedFrom: 'RAIN',
    };
    return this.http.post<any>(`api/reservation/${confirmationCode}/mileage-plan`, body, options).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map(() => {
        return { success: true };
      }),
      catchError((err: HttpErrorResponse) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return observableOf({ success: false, errorMessage: 'Request timed out.' });
        }
        return observableOf({
          success: false,
          errorMessage: this.getErrorMessage(err),
          frequentFlyerCarrier,
          frequentFlyerNumber,
        });
      })
    );
  }

  remove(confirmationCode: string, loyaltyId: string): Observable<RemoveMileagePlanResponse> {
    const options = { headers: new HttpHeaders({ background: 'true' }) };
    return this.http.delete<RemoveMileagePlanResponse>(`api/reservation/${confirmationCode}/mileage-plan?id=${loyaltyId}`, options).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map(() => {
        return { success: true };
      }),
      catchError((err: HttpErrorResponse) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return observableOf({ success: false, errorMessage: 'Request timed out.' });
        }
        return observableOf({ success: false, errorMessage: this.getErrorMessage(err) });
      })
    );
  }

  private getErrorMessage(errorResponse: HttpErrorResponse): string {
    let errorMessage: string = errorResponse?.error ?? 'No error message received';
    if (typeof errorMessage !== 'string') {
      errorMessage = 'Unexpected type in error message.';
    }
    return errorMessage;
  }
}
