import { Passenger } from '../../dtos/response/reservation-response/passenger';
import { SeatData } from '../../dtos/response/seat-map-lookup-response/seat-data';

export enum SeatChangeType {
  ASSIGNED = 1,
  CHANGED,
  UNASSIGNED,
}

/**
 * Event representing seat assignments and unassignments.
 */
export interface SeatChangeEvent {
  /**
   * The seat that the passenger has been assigned to.
   */
  assignedSeat?: SeatData;
  /**
   * The passenger (if any) who was formerly assigned to the assignedSeat. This passenger will no longer have an assigned seat.
   */
  assignedSeatFormerOccupant?: Passenger;
  /**
   * Flag indicating if the seat change represents a revert to the PNR-persisted seat.
   */
  isRevert?: boolean;
  /**
   * The passenger that has been assigned to or unassigned from a seat.
   */
  passenger?: Passenger;
  /**
   * The type of change represented by this event.
   */
  type?: SeatChangeType;
  /**
   * The seat that the passenger has been unassigned from.
   */
  unassignedSeat?: SeatData;
}
