import { Injectable } from '@angular/core';
import { SegmentPurchase } from '../dtos/request/purchase-request/segment-purchase';
import { Segment } from '../dtos/response/reservation-response/segment';
import { Tax } from '../dtos/response/reservation-response/tax';

@Injectable({
  providedIn: 'root',
})
export class ChangeOfGaugeUseCase {
  /**
   * For a normal reservation this will simply return the segments. For a Change of Gauge
   * reservation where we have added fake segments, this function will "undo" the Change
   * of Gauge logic and return the actual segments. These "undone" segments should be
   * valid for purchasing but not seat assignment.
   */
  public getRealSegments(segments: Segment[]): Segment[] {
    const result: Segment[] = [];
    const segmentCount = segments.length;

    for (let i = 0; i < segmentCount; i++) {
      const segment = segments[i];
      if (segment.changeOfGauge) {
        // The current segment has been flagged as having a change of gauge. We need to
        // look forward through the extracted segments and find the last one so we can
        // undo the CoG extraction by resetting the original segment's data.
        let endSegment: Segment = null;
        i++;

        while (i < segmentCount) {
          const nextSegment = segments[i];
          if (nextSegment.displayChangeOfGaugeWarning) {
            endSegment = nextSegment;
            i++;
          } else {
            i--;
            break;
          }
        }

        if (!endSegment) {
          throw new Error('Found a change of gauge segment without an extracted segment');
        }

        const fixedSegment: Segment = { ...segment, arrivalAirport: endSegment.arrivalAirport };
        result.push(fixedSegment);
      } else {
        result.push(segment);
      }
    }
    return result;
  }

  /**
   * Given segments and a segment index, return the "real" segment index after fake change of gauge
   * segments have been ignored.
   */
  public getRealSegmentIndex(segments: Segment[], segmentIndex: number): number {
    const fakeSegmentsInSlice = segments.slice(0, segmentIndex + 1).filter((seg) => seg.displayChangeOfGaugeWarning);
    return segmentIndex - fakeSegmentsInSlice.length;
  }

  /**
   * Change of gauge gives us some bogus lineitem charges that are important for RAIN but can't
   * be there for the EMD service. This method will combine line item charges for the same segment
   */
  public fixPassengerLineItemCharges(segmentPurchases: SegmentPurchase[]): SegmentPurchase[] {
    const fixedSegmentPurchases: SegmentPurchase[] = [];

    for (const existingPurchase of segmentPurchases) {
      const fixedSegmentPurchase = fixedSegmentPurchases.find((s) => s.segmentRefIndex === existingPurchase.segmentRefIndex);

      if (fixedSegmentPurchase && fixedSegmentPurchase.pricingInfo) {
        fixedSegmentPurchase.pricingInfo.basePrice += existingPurchase.pricingInfo.basePrice;
        fixedSegmentPurchase.pricingInfo.totalPrice += existingPurchase.pricingInfo.totalPrice;
        fixedSegmentPurchase.pricingInfo.taxes = this.mergeTaxes(
          fixedSegmentPurchase.pricingInfo.taxes,
          existingPurchase.pricingInfo.taxes
        );
      } else {
        fixedSegmentPurchases.push(existingPurchase);
      }
    }

    return fixedSegmentPurchases;
  }

  private mergeTaxes(taxesA: Tax[], taxesB: Tax[]): Tax[] {
    const result: Tax[] = taxesA;

    for (const taxB of taxesB) {
      const existing: Tax = result.find((t) => t.code === taxB.code);

      if (existing) {
        existing.amount += taxB.amount;
      } else {
        result.push(taxB);
      }
    }

    return result;
  }
}
