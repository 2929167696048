import * as fromRouter from '@ngrx/router-store';
import { initialModelState, ModelState } from '../models/state/model.state';

export interface RootState {
  model: ModelState;
  app: RootAppState; // use sparingly, is the data truly global?
  router: fromRouter.RouterReducerState<any> | null;
}

export interface RootAppState {
  callerName: string; // caller name used in the reservation flow today, but it seems apparent it will be needed in most/all future flows
}

export const initialAppState: RootAppState = {
  callerName: '',
};

// this is not used in ngrx directly, it is used for unit tests
export const rootInitialState: RootState = {
  model: initialModelState,
  app: initialAppState,
  router: null,
};
