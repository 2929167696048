import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { DirectivesModule } from '../../directives/directives.module';
import { LongMessageBarComponent } from './long-message-bar/long-message-bar.component';
import { StackedLongMessageBarComponent } from './stacked-long-message-bar/stacked-long-message-bar.component';
import { PipesModule } from '../../pipes/pipes.module';
import { JetStreamMessageBarComponent } from './jet-stream-message-bar/jet-stream-message-bar.component';
import { JetStreamShortMessageBarComponent } from './jet-stream-short-message-bar/jet-stream-short-message-bar.component';

@NgModule({
  declarations: [
    LongMessageBarComponent,
    StackedLongMessageBarComponent,
    JetStreamMessageBarComponent,
    JetStreamShortMessageBarComponent,
  ],
  imports: [CommonModule, InlineSVGModule, DirectivesModule, PipesModule],
  exports: [
    LongMessageBarComponent,
    StackedLongMessageBarComponent,
    JetStreamMessageBarComponent,
    JetStreamShortMessageBarComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MessageBarsModule {}
