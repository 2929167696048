import { createAction } from '@ngrx/store';
import { Passenger } from '../../../dtos/response/reservation-response/passenger';
import { seatMapFeatureKey } from './seat-map.state';

export const setCurrentSegmentIndex = createAction(`[${seatMapFeatureKey}] Set Current Segment Index`, (segmentIndex: number) => ({
  segmentIndex,
}));

export const setCurrentSegmentHashId = createAction(`[${seatMapFeatureKey}] Set Current Segment Hash Id`, (segmentHashId: string | null) => ({
  segmentHashId,
}));

/**
 * Sets the selected passenger in the passenger list
 */
 export const setSelectedPassenger = createAction(
  `[${seatMapFeatureKey}] Set selected passenger`,
  (selectedPassenger: Passenger) => ({
    selectedPassenger,
  })
);
