import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class ReservationGuard  {
  constructor(private router: Router) {}

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let hasConfCode = false;
    next.pathFromRoot.forEach((route) => {
      if (route.paramMap.get('confcode')) {
        hasConfCode = true;
      }
    });
    if (hasConfCode) {
      return true;
    } else {
      this.router.navigate(['home']);
      return false;
    }
  }
}
