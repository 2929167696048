<div class="recent-confirmation-code-container"
    id="conf-code-container"
    (window:resize)="windowResize()">
    <div class="recent-confirmation-code-left-margin" *ngIf="this.recentConfirmationCodesToShow.length > 0">
    </div>
    <div class="recent-confirmation-code-list-container">
        <app-recent-confirmation-code-tile *ngFor="let rcc of recentConfirmationCodesToShow"
            [id]="rcc.isActive ? 'currentPnr' : 'recentPnr' + rcc.id"
            [ngClass]="{'recent-confirmation-code-item-separator': true,
                        'recent-confirmation-code-item-active': rcc.isActive }"
            [confirmationCode]="rcc.confirmationCode"
            [passengerNames]="rcc.passengerNames"
            [isActive]="rcc.isActive"
            tileClasses="{{rcc.isActive
                ? 'recent-confirmation-code-tile-align-center recent-confirmation-code-tile recent-confirmation-code-tile-active'
                : 'recent-confirmation-code-tile-align-center recent-confirmation-code-tile'}}"
            appSpaceOrEnterKeySelector>
        </app-recent-confirmation-code-tile>
        <auro-dropdown id="recentConfirmationCodeDropdown" inset rounded [class]="dropdownClasses"
        *ngIf="recentConfirmationCodesInDropdown.length !== 0">
            <auro-menu noCheckmark [style]="dropdownMenuWidth">
                <auro-menuoption
                    *ngFor="let rcc of recentConfirmationCodesInDropdown"
                    [attr.id]="rcc.confirmationCode"
                    [attr.value]="rcc.confirmationCode"
                    [selected]="rcc.isActive"
                    (click)="handleClick()">
                    <div>
                        <app-recent-confirmation-code-tile class="recent-confirmation-code-list-menuoption"
                            [confirmationCode]="rcc.confirmationCode"
                            [passengerNames]="rcc.passengerNames"
                            alignment="left"
                            [isActive]="rcc.isActive"
                            tileclasses="{{rcc.isActive
                                ? 'recent-confirmation-code-tile-align-left recent-confirmation-code-tile recent-confirmation-code-tile-active'
                                : 'recent-confirmation-code-tile-align-left recent-confirmation-code-tile'}}">
                        </app-recent-confirmation-code-tile>
                    </div>
                </auro-menuoption>
            </auro-menu>
            <div slot="trigger"
                id="dropdownTrigger" class="recent-confirmation-code-overflow-menu-trigger" [style]="dropdownTriggerPadding">
                <div class="recent-confirmation-code-overflow-menu-trigger">
                    <div *ngIf="recentConfirmationCodesInDropdown.length > 0"
                        class="recent-confirmation-code-overflow-menu-trigger-text">More</div>
                    <auro-icon ondark
                        category="interface"
                        name="chevron-down"
                        customColor
                        style="color: #0074C8;"
                        *ngIf="recentConfirmationCodesInDropdown.length > 0 ? true : null"></auro-icon>
                </div>
            </div>
        </auro-dropdown>
    </div>
    <div class="recent-confirmation-code-right-margin" *ngIf="this.recentConfirmationCodesInDropdown.length > 0">
    </div>
</div>