/**
 * Pulls the weight values out of the description and formats them
 * Ex: Up to 50 lb/23 kg Up to 62 li/158 lcm
 */
export function parseWeight(details: string): string {
  if (details.includes('Up to')) {
    // Get 50 lb/23 kg
    const weightValue = details.split('Up to')[1];
    // Get 50lb
    const weightValueFormattedLbs = weightValue.split('/')[0]?.replace(/\s/g, '');
    // Get 23kg
    const weightValueFormattedKgs = weightValue.split('/')[1]?.replace(/\s/g, '');
    // Create 50lb / 23kg
    const weightValueFormatted = weightValueFormattedLbs + ' / ' + weightValueFormattedKgs;
    return weightValueFormatted;
  }
  return 'No data';
}

/**
 * Pulls the size values out of the description and formats them
 * Ex: Up to 50 lb/23 kg Up to 62 li/158 lcm
 */
export function parseSize(details: string): string {
  if (details.includes('kg Up to ')) {
    // Get 62 li/258 lcm
    const sizeValue = details.split('kg Up to ')[1];

    // Get 62li
    const sizeValueFormattedLi = sizeValue.split('/')[0]?.replace(/\s/g, '');
    // Get 258lcm
    const sizeValueFormattedLcm = sizeValue.split('/')[1]?.replace(/\s/g, '');

    // Create 62li / 258lcm
    const sizeValueFormatted = sizeValueFormattedLi + ' / ' + sizeValueFormattedLcm;
    return sizeValueFormatted;
  }
  return 'No data';
}
