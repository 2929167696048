import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth-service/auth.service';
import { Store } from '@ngrx/store';
import { RootState } from '../state/state';
import { getUser } from '../services/login-service/state/login-service.selector';
import { UserData } from '../dtos/response/login-response/user-data';
import { getCallerName } from '../state/selectors';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private user: UserData;
  private callerName: string;

  constructor(private authService: AuthService, private injector: Injector, private store: Store<RootState>) {
    this.store.select(getUser).subscribe((user) => (this.user = user));
    this.store.select(getCallerName).subscribe((callerName) => (this.callerName = callerName));
  }

  /**
   * Adds the SSO token to all Web API requests and verifies all Web API responses to make sure that the
   * token was not rejected.
   * @param req the request being made by RAIN
   * @param next the next request handler in the chain
   * @returns the HTTP event
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestToForward = req;
    if (req.url.startsWith('api')) {
      if (this.authService === undefined) {
        this.authService = this.injector.get(AuthService);
      }
      const token = this.authService.getAccessToken();
      if (token && token.length > 10) {
        const tokenValue = 'Bearer ' + token;
        requestToForward = this.handleHeaders(req, tokenValue);
      } else {
        this.authService.onInit();
        return observableOf(null);
      }
    }

    let tokenRejected = false;
    return next.handle(requestToForward).pipe(
      tap({
        next: (event) => {},
        error: (error) => {
          const responseHeaders: HttpHeaders = (error as HttpErrorResponse).headers;
          if (responseHeaders.has('www-authenticate')) {
            tokenRejected = true;
          }
        },
      }),
      finalize(() => {
        if (tokenRejected) {
          localStorage.removeItem('access_token');
          this.authService.onInit();
        }
      })
    );
  }

  private handleHeaders(req: HttpRequest<any>, tokenValue: string): HttpRequest<any> {
    if (req.url !== 'api/login') {
      return req.clone({
        setHeaders: {
          Authorization: tokenValue,
          sabreUser: this.user.username,
          sabreEncryptedPw: this.user.encryptedPassword,
          sabreAAA: this.user.sabreAAA ?? '',
          receivedFrom: this.callerName ? this.callerName : this.user.username,
        },
      });
    } else {
      return req.clone({ setHeaders: { Authorization: tokenValue } });
    }
  }
}
