import { createAction } from '@ngrx/store';
import { SdcEligibility } from '../../../dtos/response/sdc-eligibility-response/sdc-eligibility';
import { SdcEligibilityStatus } from '../../../dtos/response/sdc-eligibility-response/sdc-eligibility-status';
import { sdcEligibilityServiceFeatureKey } from './sdc-eligibility-service.state';

export const initializeSdcEligibilityServiceState = createAction(`[${sdcEligibilityServiceFeatureKey}] Initialize`);

/**
 * Get the SDC (Same Day Confirm) eligibility for the current routed reservation
 */
export const getSdcEligibility = createAction(`[${sdcEligibilityServiceFeatureKey}] Get SDC eligibility`);


export const setGetSdcEligibilitySuccess = createAction(
  `[${sdcEligibilityServiceFeatureKey}] Set getSdcEligibility success`,
  (sdcEligibilityResponse: SdcEligibility) => ({
    sdcEligibilityResponse,
  })
);

export const setGetSdcEligibilityFailure = createAction(
  `[${sdcEligibilityServiceFeatureKey}] Set getSdcEligibility failure`,
  (getSdcEligibleResponseStatus: SdcEligibilityStatus) => ({
    getSdcEligibleResponseStatus,
  })
);
