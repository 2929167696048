import { Action, createReducer, on } from '@ngrx/store';
import { Waiver } from '../../../models/waiver/waiver';
import {
  initializePurchaseState,
  removeLineItemCharge,
  removeWaiver,
  removeWaiversByProperty,
  resetLineItemCharges,
  setLineItemCharge,
  setWaiver,
  setWaivers,
} from './purchase.actions';
import { initialPurchaseState, lineItemChargeAdapter, waiverAdapter } from './purchase.state';

const featureReducer = createReducer(
  initialPurchaseState,
  on(setLineItemCharge, (state, { lineItemCharge }) => ({
    ...state,
    charges: lineItemChargeAdapter.setOne(lineItemCharge, state.charges),
  })),
  on(removeLineItemCharge, (state, { id }) => ({ ...state, charges: lineItemChargeAdapter.removeOne(id, state.charges) })),
  on(resetLineItemCharges, (state) => ({ ...state, charges: lineItemChargeAdapter.removeAll(state.charges) })),
  on(setWaivers, (state, { waivers }) => {
    return {
      ...state,
      waivers: waiverAdapter.setAll(waivers, state.waivers),
    };
  }),
  on(setWaiver, (state, { waiver }) => {
    return {
      ...state,
      waivers: waiverAdapter.setOne(waiver, state.waivers),
    };
  }),
  on(removeWaiver, (state, { id }) => {
    return {
      ...state,
      waivers: waiverAdapter.removeOne(id, state.waivers),
    };
  }),
  on(removeWaiversByProperty, (state, { property, value }) => {
    return {
      ...state,
      waivers: waiverAdapter.removeMany((waiver: Waiver) => waiver[property] === value, state.waivers),
    };
  }),

  on(initializePurchaseState, (state) => ({ ...state, ...initialPurchaseState }))
);

export function purchaseReducer(state = initialPurchaseState, action: Action) {
  return featureReducer(state, action);
}
