export enum RowProperties
{
    ExitRowLeft = 'ExitRowLeft',
    ExitRowMiddle = 'ExitRowMiddle',
    ExitRowRight = 'ExitRowRight',
    ExitDoorLeft = 'ExitDoorLeft',
    ExitDoorRight = 'ExitDoorRight',
    GalleyLeft = 'GalleyLeft',
    GalleyMiddle = 'GalleyMiddle',
    GalleyRight = 'GalleyRight',
    LavatoryLeft = 'LavatoryLeft',
    LavatoryMiddle = 'LavatoryMiddle',
    LavatoryRight = 'LavatoryRight',
    OverWing = 'OverWing',
    BulkheadLeft = 'BulkheadLeft',
    BulkheadMiddle = 'BulkheadMiddle',
    BulkheadRight = 'BulkheadRight',
    ClosetLeft = 'ClosetLeft',
    ClosetMiddle = 'ClosetMiddle',
    ClosetRight = 'ClosetRight',
    NotExist = 'NotExist',
    Unknown = 'Unknown'
}
