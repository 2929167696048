import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { CardOnFile } from 'src/app/dtos/response/card-on-file-response/card-on-file';
import { CardOnFileService } from '../../../services/card-on-file-service/card-on-file.service';
import { getMileagePlanForAllPassengersOnActiveReservation } from '../../../services/reservation-service/state/reservation-service.selectors';
import { RootState } from '../../../state/state';
import { loadCardsOnFile, loadCardsOnFileLookupComplete } from './card-on-file-service.actions';

@Injectable()
export class CardOnFileServiceEffects {
  constructor(private actions$: Actions, private cardOnFileService: CardOnFileService, private store: Store<RootState>) {}

  getCardsOnFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCardsOnFile),
      withLatestFrom(this.store.pipe(select(getMileagePlanForAllPassengersOnActiveReservation))),
      exhaustMap(([action, mileagePlanNumbers]) => {
        if(mileagePlanNumbers?.length > 0) {
          return this.cardOnFileService.getCardsOnFileForMileagePlanNumbers(mileagePlanNumbers).pipe(
            map((cardOnFileResponse) => {
              return loadCardsOnFileLookupComplete(cardOnFileResponse.cardsOnFile, cardOnFileResponse.success);
            })
          );
        }
        return of(loadCardsOnFileLookupComplete([] as CardOnFile[], true));
      }),
      catchError((error) => {
        return of(loadCardsOnFileLookupComplete(null, false));
      })
    )
  );
}
