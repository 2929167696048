import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { TravelAdvisoriesService } from '../travel-advisories.service';
import { TravelAdvisoryResponse } from '../../../dtos/response/travel-advisory-response/travel-advisory-response';
import { getTravelAdvisories, getTravelAdvisoriesComplete } from './travel-advisories-service.actions';

@Injectable()
export class TravelAdvisoriesServiceEffects {
  constructor(private actions$: Actions, private travelAdvisoriesService: TravelAdvisoriesService) {}

  getTravelAdvisories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTravelAdvisories),
      mergeMap((action) =>
        this.travelAdvisoriesService.getTravelAdvisories().pipe(
          map((result) => {
            return getTravelAdvisoriesComplete(result);
          })
        )
      ),
      catchError(() => {
        const response: TravelAdvisoryResponse = { success: false };
        return of(getTravelAdvisoriesComplete(response));
      })
    )
  );
}
