import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LineItemCharge } from '../../../models/line-item-charge/line-item-charge';
import { Waiver } from '../../../models/waiver/waiver';
import { RootState } from '../../../state/state';

export const purchaseFeatureKey = 'purchase';

export interface State extends RootState {
  purchase: PurchaseState;
}

export interface PurchaseState {
  charges: LineItemChargeEntity;
  waivers: WaiverEntity;
}

export interface LineItemChargeEntity extends EntityState<LineItemCharge> {}

export const lineItemChargeAdapter: EntityAdapter<LineItemCharge> = createEntityAdapter<LineItemCharge>({
  selectId: (lineItemCharge) => lineItemCharge.chargeType + lineItemCharge.passengerHashId + lineItemCharge.segmentIndex,
});

export const initialLineItemChargeState: LineItemChargeEntity = lineItemChargeAdapter.getInitialState({});

export interface WaiverEntity extends EntityState<Waiver> {}

export const waiverAdapter: EntityAdapter<Waiver> = createEntityAdapter<Waiver>({
  selectId: (waiver) => waiver.chargeType + waiver.passengerHashId + waiver.segmentIndex,
});

export const initialPurchaseState: PurchaseState = {
  charges: initialLineItemChargeState,
  waivers: waiverAdapter.getInitialState(),
};
