import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import { PassengerSegmentsRequest } from '../dtos/request/purchase-request/passenger-segments-request';
import { PassengerTransactionRequest } from '../dtos/request/purchase-request/passenger-transaction-request';
import { SegmentPurchase } from '../dtos/request/purchase-request/segment-purchase';
import { SegmentTransaction } from '../dtos/request/purchase-request/segment-transaction';
import { SegmentWaiver } from '../dtos/request/purchase-request/segment-waiver';
import { TransactionSegment } from '../dtos/request/purchase-request/transaction-segment';
import { Passenger } from '../dtos/response/reservation-response/passenger';
import { PassengerSeat } from '../dtos/response/reservation-response/passenger-seat';
import { Reservation } from '../dtos/response/reservation-response/reservation';
import { Segment } from '../dtos/response/reservation-response/segment';
import { CouponStatusData } from '../dtos/response/vcr-response/coupon-status-data';
import { LineItemCharge } from '../models/line-item-charge/line-item-charge';
import { Waiver } from '../models/waiver/waiver';
import { CouponMapper } from '../services/ticket-service/coupon-mapper';
import { getAllLineItemCharges, getAllWaiverEntities } from '../shared/purchase/state/purchase.selectors';
import { RootState } from '../state/state';
import { ChangeOfGaugeUseCase } from './change-of-gauge.use-case';
import { PassengerUseCase } from './passenger.use-case';
import { getAllPassengers } from '../utils/passenger-helper';

@Injectable({
  providedIn: 'root',
})
export class TransactionUseCase {
  private transactionSegments: TransactionSegment[];
  private lineItemCharges: LineItemCharge[];
  private waivers: Dictionary<Waiver>;

  constructor(
    private readonly changeOfGaugeUseCase: ChangeOfGaugeUseCase,
    private readonly passengerUseCase: PassengerUseCase,
    private store: Store<RootState>
  ) {
    this.store.pipe(select(getAllLineItemCharges)).subscribe((lineItemCharges) => (this.lineItemCharges = lineItemCharges));
    this.store.pipe(select(getAllWaiverEntities)).subscribe((waivers) => (this.waivers = waivers));
  }

  /**
   * Get passenger segment purchases
   * @param reservation Routed reservation data
   * @param coupons Coupon data
   * @param filteredSegments A list of filtered NGRX segments to use, use getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodes
   *  for the current segment list
   * @returns PassengerSegmentsRequest
   */
  getPassengerSegmentPurchases(
    reservation: Reservation,
    coupons: CouponStatusData[],
    filteredSegments: Segment[]
  ): PassengerSegmentsRequest {
    const passengersWithLineItems: PassengerTransactionRequest[] = [];
    this.transactionSegments = [];

    const allPassengers: Passenger[] = getAllPassengers(reservation);

    allPassengers.forEach((resPassenger: Passenger) => {
      const passengerLineItemCharges: LineItemCharge[] = this.lineItemCharges.filter(
        (lineItemCharge) => lineItemCharge.passengerHashId === resPassenger.hashId
      );
      this.addPassengerLineItemCharge(
        resPassenger,
        filteredSegments,
        passengerLineItemCharges,
        passengersWithLineItems,
        coupons,
        reservation
      );
    });
    passengersWithLineItems.forEach((passengerPurchaseRequest) => {
      passengerPurchaseRequest.segmentPurchases.forEach((segmentPurchase) => {
        segmentPurchase.segmentRefIndex = this.upsertTransactionSegmentArray(segmentPurchase, filteredSegments);
      });
      passengerPurchaseRequest.segmentWaivers.forEach((segmentWaiver) => {
        segmentWaiver.segmentRefIndex = this.upsertTransactionSegmentArray(segmentWaiver, filteredSegments);
      });
    });

    return {
      passengerTransactions: passengersWithLineItems,
      segments: this.transactionSegments,
    } as PassengerSegmentsRequest;
  }

  private addPassengerLineItemCharge(
    resPassenger: Passenger,
    resSegments: Segment[],
    passengerLineItemCharges: LineItemCharge[],
    passengersWithLineItems: PassengerTransactionRequest[],
    coupons: CouponStatusData[],
    reservation: Reservation
  ): void {
    if (!passengerLineItemCharges.length) {
      return;
    }
    const passengerUnWaivedLineItemCharges = passengerLineItemCharges.filter(
      (passengerLineItemCharge) =>
        !this.waivers[passengerLineItemCharge.chargeType + passengerLineItemCharge.passengerHashId + passengerLineItemCharge.segmentIndex]
    );
    const passengerWaivedLineItemCharges = passengerLineItemCharges.filter(
      (passengerLineItemCharge) =>
        !!this.waivers[passengerLineItemCharge.chargeType + passengerLineItemCharge.passengerHashId + passengerLineItemCharge.segmentIndex]
    );

    let segmentPurchases: SegmentPurchase[] = passengerUnWaivedLineItemCharges.map((lineItemCharge: LineItemCharge) => {
      const passengerSeat: PassengerSeat = resPassenger.seats[lineItemCharge.segmentIndex];
      const actualSegmentIndex = this.changeOfGaugeUseCase.getRealSegmentIndex(resSegments, lineItemCharge.segmentIndex);
      const passengerCoupon = CouponMapper.getPassengerCoupon(resPassenger, resSegments[lineItemCharge.segmentIndex], reservation, coupons);

      return {
        couponNumber: passengerCoupon.couponNumber,
        pricingInfo: {
          basePrice: lineItemCharge.basePrice,
          totalPrice: lineItemCharge.totalPrice,
          taxes: lineItemCharge.taxes,
          productId: passengerSeat.ancillaryDesignator
        },
        seatLocation: `${passengerSeat.row}${passengerSeat.letter}`,
        segmentRefIndex: actualSegmentIndex,
        ticketNumber: passengerCoupon.ticketNumber,
      };
    });

    segmentPurchases = this.changeOfGaugeUseCase.fixPassengerLineItemCharges(segmentPurchases);
    const segmentWaivers: SegmentWaiver[] = passengerWaivedLineItemCharges.map((lineItemCharge: LineItemCharge) => {
      const passengerSeat: PassengerSeat = resPassenger.seats[lineItemCharge.segmentIndex];
      const waiver = this.waivers[lineItemCharge.chargeType + lineItemCharge.passengerHashId + lineItemCharge.segmentIndex];
      return {
        pricingInfo: {
          basePrice: lineItemCharge.basePrice,
          totalPrice: lineItemCharge.totalPrice,
          taxes: lineItemCharge.taxes,
          productId: passengerSeat.ancillaryDesignator
        },
        seatLocation: `${passengerSeat.row}${passengerSeat.letter}`,
        segmentRefIndex: lineItemCharge.segmentIndex,
        waiverAgentRemark: waiver.comment,
        waiverReason: waiver.reason,
        isAutoWaiver: waiver.isAutoWaiver
      };
    });

    passengersWithLineItems.push({
      firstName: resPassenger.firstName,
      id: resPassenger.id,
      lastName: resPassenger.lastName,
      loyalty: this.passengerUseCase.alaskaEliteTierStatus(resPassenger) ? resPassenger.loyalty : null,
      segmentPurchases,
      segmentWaivers,
    });
  }

  private upsertTransactionSegmentArray(segmentTransaction: SegmentTransaction, reservationSegments: Segment[]): number {
    let newSegment: TransactionSegment;
    const originalIndex = segmentTransaction.segmentRefIndex;

    // get reference to real segments
    const realSegments = this.changeOfGaugeUseCase.getRealSegments(reservationSegments);

    // grab the corresponding segment
    const segmentToBeCopied = realSegments[originalIndex];

    // Search for the corresponding segment in the list of already added transaction segments.
    let segmentSearchIndex = this.transactionSegments.findIndex(
      (segment) =>
        segment.actionCode === segmentToBeCopied.actionCode &&
        segment.carrierCode === segmentToBeCopied.marketedByAirlineCode &&
        segment.flightNumber === segmentToBeCopied.operatingAirlineFlightNumber &&
        segment.arrivalAirport === segmentToBeCopied.arrivalAirport &&
        segment.departureAirport === segmentToBeCopied.departureAirport &&
        segment.departureDate === segmentToBeCopied.departureDateTime.slice(0, 10) &&
        segment.serviceClassCode === segmentToBeCopied.serviceClassCode
    );

    // If the segment isn't in the list, create it and add it to the end of the array.
    // Then set the index of the searched for segment to the index of the item just added.
    if (segmentSearchIndex === -1) {
      newSegment = {
        actionCode: segmentToBeCopied.actionCode,
        carrierCode: segmentToBeCopied.marketedByAirlineCode,
        flightNumber: segmentToBeCopied.operatingAirlineFlightNumber,
        arrivalAirport: segmentToBeCopied.arrivalAirport,
        departureAirport: segmentToBeCopied.departureAirport,
        departureDate: segmentToBeCopied.departureDateTime.slice(0, 10),
        serviceClassCode: segmentToBeCopied.serviceClassCode,
      } as TransactionSegment;

      this.transactionSegments.push(newSegment);

      segmentSearchIndex = this.transactionSegments.indexOf(newSegment);
    }

    // Update transaction with segment index for matching segment being sent to purchase endpoint.
    // This is to adjust for cases like; 1 PX - 2 Segment, where PC upgrade is only done on 2nd segment.
    // Segment Index for line item change is 1 (2nd segment), but we only send the segment that had a
    // purchase to the purchase endpoint. So if we didn't update we would send that PAX 1 had a purchase on
    // segment 2, but we would only be sending 1 segment to the endpoint. So we instead update the transaction
    // to match the segment being sent to the endpoint.
    return segmentSearchIndex;
  }
}
