export class NameNumberUtil {
  public static ToNonZeroPaddedCollapseDecimal(input: string): string{
    try {
      if (!input) {
        return input;
      }

      const split = input.split('.');

      if (split.length < 2){
        return input;
      }

      const whole = +split[0];
      const dec = +split[1];

      const parsed = `${whole}.${dec}`;
      return parsed;
    } catch {
      return input;
    }
  }

  public static IsEquivalent(inputA: string, inputB: string): boolean {
    const zeroPaddedA = this.ToNonZeroPaddedCollapseDecimal(inputA);
    const zeroPaddedB = this.ToNonZeroPaddedCollapseDecimal(inputB);
    return zeroPaddedA === zeroPaddedB;
  }
}
