import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Reservation } from 'src/app/dtos/response/reservation-response/reservation';
import { RootState } from 'src/app/state/state';

export const reservationSearchHistoryFeatureKey = 'reservationSearchHistoryService';

export interface State extends RootState {
    reservationSearchHistoryState: ReservationSearchHistoryState;
}

export interface ReservationSearchHistoryState extends EntityState<Reservation> {

}

export const reservationSearchHistoryAdapter = createEntityAdapter<Reservation>({
    selectId: res => res.confirmationCode,
    // order the confirmation codes by recently searched reservations
    sortComparer: false
});

export const initialReservationSearchHistoryState: ReservationSearchHistoryState = reservationSearchHistoryAdapter.getInitialState({});
