export enum TierStatus {
  GOLD = 'Gold',
  GOLD_100K = 'Gold100k',
  GOLD_75K = 'Gold75K',
  MVP = 'MVP',
  STANDARD = 'Standard',
  RBY = 'Ruby',
  SPH = 'Sapphire',
  EMD = 'Emerald'
}
