// The contents of this file were copied almost exactly from stack overflow to solve an issue with NGRX effects running
// inside the Angular zone and causing E2E test failures
// https://stackoverflow.com/questions/43121400/run-ngrx-effect-outside-of-angulars-zone-to-prevent-timeout-in-protractor
import { NgZone } from '@angular/core';
import { SchedulerLike, Subscription } from 'rxjs';

/**
 * A utility class that allows asynchronous tasks to be scheduled outside of the Angular zone
 */
class LeaveZoneScheduler {
  constructor(private zone: NgZone, private scheduler: SchedulerLike) {}

  schedule(...args: any): Subscription {
    return this.zone.runOutsideAngular(() => this.scheduler.schedule.apply(this.scheduler, args));
  }
}

/**
 * A utility class that allows asynchronous tasks that were scheduled outside of the Angular zone to re-enter the zone
 */
class EnterZoneScheduler {
  constructor(private zone: NgZone, private scheduler: SchedulerLike) {}

  schedule(...args: any): Subscription {
    return this.zone.run(() => this.scheduler.schedule.apply(this.scheduler, args));
  }
}

/**
 * Utility function that will create a new instance of the LeaveZoneScheduler class with the provided parameters passed into the constructor
 * @param zone - The Angular Zone (ngZone) to leave
 * @param scheduler - The scheduler to use under the hood
 */
export function leaveZone(zone: NgZone, scheduler: SchedulerLike): SchedulerLike {
  return new LeaveZoneScheduler(zone, scheduler) as any;
}

/**
 * Utility function that will create a new instance of the EnterZoneScheduler class with the provided parameters passed into the constructor
 * @param zone - The Angular Zone (ngZone) to enter
 * @param scheduler - The scheduler to use under the hood
 */
export function enterZone(zone: NgZone, scheduler: SchedulerLike): SchedulerLike {
  return new EnterZoneScheduler(zone, scheduler) as any;
}
