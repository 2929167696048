import { ItineraryResponse } from 'src/app/dtos/response/itinerary-response/itinerary-response';
import { RootState } from '../../../state/state';

/**
 * The string name of the itinerary "slice" of state
 */
export const itineraryFeatureKey = 'itinerary';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  itinerary: ItineraryState;
}

/**
 * The state that is used in relation to itinerary, it is an extension of root state
 * these values are available in and should be accessed from the root state model
 */
export interface ItineraryState {
  revalResponse: ItineraryResponse | null;
  reissueResponse: ItineraryResponse | null;
}

/**
 * Initial state of the itinerary slice of state, used when itinerary service state is
 * bootstrapped via the reducer in an app module also used when state is reset
 */
export const initialItineraryState: ItineraryState = {
  revalResponse: null,
  reissueResponse: null
};
