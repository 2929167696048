export enum ReservationCreationResponseStatus {
  DOES_NOT_EXIST = 1,
  SUCCESS,
  SYSTEM_FAILURE,
  TIMEOUT,
  BAD_REQUEST,
  NO_OP_DATE,
  NO_OP_FLIGHT_NUM,
  MARKET_EXIT,
  INAVLID_CLASS,
  INVALID_BOARD_POINT,
  PASSWORD_DECRYPTION_ERROR
}
