import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { RootState } from '../../../state/state';
import { getRoutedConfirmationCode } from '../../reservation-service/state/reservation-service.selectors';
import { CompensationService } from '../compensation.service';
import {
  loadCompensation,
  setCompensations,
  submitCareKit,
  submitCareKitComplete
} from './compensation-service.actions';
import { getCompensations, getCompensationsLoaded } from './compensation-service.selectors';

@Injectable()
export class CompensationServiceEffects {
  constructor(private actions$: Actions, private compensationService: CompensationService, private store: Store<RootState>) {}

  getCompensation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCompensation),
      withLatestFrom(
        this.store.pipe(select(getRoutedConfirmationCode)),
        this.store.pipe(select(getCompensations)),
        this.store.pipe(select(getCompensationsLoaded))),
      mergeMap(([action, confirmationCode, cachedCompensations, compensationsLoaded]) => {
        if (!compensationsLoaded) {
          return this.compensationService.getCompensations(confirmationCode).pipe(
            map((compensationResponse) => {
              if (compensationResponse.success && compensationResponse.compensation) {
                return setCompensations(compensationResponse.compensation);
              } else {
                return setCompensations([]);
              }
            })
          );
        }
        return of(setCompensations(cachedCompensations));
      })
    )
  );

  submitCareKit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitCareKit),
      mergeMap((action) => {
        return this.compensationService.submitCareKit(action.request, action.compensationType).pipe(
          map((compensationResponse) => {
            if (compensationResponse.success) {
              return submitCareKitComplete(true);
            } else {
              return submitCareKitComplete(false, compensationResponse.errorMessage);
            }
          })
        );
      })
    )
  );
}
