<span class="loyalty-number quantum-metric-encrypt"
 [ngClass]="{'link-hover': allowOpenProfile && !applyCallerContextStyling}"
  *ngIf="loyaltyNumber"
  (click)="openProfile()"
  (keypress)="onKeyPress($event)"
  tabindex="0">
  <div [ngClass]="{ 'caller-context-link': applyCallerContextStyling }">
    <div [ngClass]="{ 'auro-icon-text-placement': applyCallerContextStyling }">
      <auro-icon
      *ngIf="applyCallerContextStyling"
      category="in-flight"
      name="passenger"
      accent
      [attr.customSize]="true"
      >
    </auro-icon>
    <span style="white-space: nowrap;">{{ airlineCode ? airlineCode + ' ' + loyaltyNumber : loyaltyNumber }}</span>
    </div>
  </div>
</span>
