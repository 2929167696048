export interface ModelState {
  paymentLink: string | undefined;
  paymentReceived: boolean;
  hasReviewedSummary: boolean;
  hasSavedSessionLevelChanges: boolean;
}

export const initialModelState: ModelState = {
  paymentLink: undefined,
  paymentReceived: false,
  hasReviewedSummary: false,
  hasSavedSessionLevelChanges: false,
};

export const modelFeatureKey = 'model';
