import { Action, createReducer, on } from '@ngrx/store';
import { Status } from '../../../models/status';
import {
  initializeSecureFlightInfoState,
  removeKtnRedressNumbers,
  secureFlightInfoUpdateComplete,
  setSecureFlightAddSrrStatus,
  submitSecureFlightInfo,
  updateSecureFlightInfoStatus
} from './secure-flight-info.actions';
import { initialSecureFlightInfoState, secureFlightInfoAdapter } from './secure-flight-info.state';

const featureReducer = createReducer(
  initialSecureFlightInfoState,
  /**
   * Reinitialize the secure flight info state
   */
  on(initializeSecureFlightInfoState, (state) => ({ ...state, ...initialSecureFlightInfoState })),
  /**
   * Submits an update for secure flight info
   */
  on(submitSecureFlightInfo, (state, { request }) => {
    return {
      ...state,
      secureFlightInfos: request.nameAndBirthdayData
        ? secureFlightInfoAdapter.setOne({ status: Status.UPDATING_SECURE_FLIGHT_BIO_INFO, success: false, ...request },
            state.secureFlightInfos)
        : secureFlightInfoAdapter.setOne({ status: Status.UPDATING_SECURE_FLIGHT_PRECHECK_INFO, success: false, ...request },
            state.secureFlightInfos),
    };
  }),
  /**
   * Submits an update for secure flight info
   */
   on(removeKtnRedressNumbers, (state, { confirmationCode, passengerHashId }) => {
    return {
      ...state,
      secureFlightInfos: secureFlightInfoAdapter.setOne(
        {
          status: Status.REMOVING_SECURE_FLIGHT_PRECHECK_INFO, passengerHashId
        },
        state.secureFlightInfos)
    };
  }),
  /**
   * Submits an update for secure flight info
   */
   on(secureFlightInfoUpdateComplete, (state, { response, passengerHashId }) => {
    return {
      ...state,
      secureFlightInfos: response === null || response?.status === 200
        ? secureFlightInfoAdapter.setOne({ status: Status.STABLE, success: true, passengerHashId }, state.secureFlightInfos)
        : secureFlightInfoAdapter.setOne({ status: Status.STABLE, success: false, passengerHashId }, state.secureFlightInfos)
    };
  }),
  /**
   * Updates the CRUD status of a particular secure flight info
   */
  on(updateSecureFlightInfoStatus, (state, { hashId, status }) => {
    return {
      ...state,
      secureFlightInfos: secureFlightInfoAdapter.updateOne({ id: hashId, changes: { status } }, state.secureFlightInfos),
    };
  }),

  on(setSecureFlightAddSrrStatus, (state, { addSsrStatus }) => ({
    ...state,
    addStatus: addSsrStatus,
    loadStatus: Status.STABLE,
  })),
);

export function secureFlightInfoReducer(state = initialSecureFlightInfoState, action: Action) {
  return featureReducer(state, action);
}
