import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { FormInputModule } from '../../shared/form-input/form-input.module';
import { UXModule } from '../../shared/ux/ux.module';
import { LoginComponent } from './login.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, InlineSVGModule, UXModule, FormInputModule],
  declarations: [LoginComponent],
  exports: [LoginComponent],
})
export class LoginModule {}
