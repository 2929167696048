import { Component, Input, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';

/**
 * -Generic dropdown component
 * -Supports both input and button based dropdown
 *  - send dropdownType = 'button' to use it as a button, by default it's 'text'
 * - To remove input button
 *  - send isWithInputGroup = false
 * - To add auro icon to the dropdown contents
 *  - send isAuroIconBased = true
 * - To make inputs uppercase
 *  - send isUppercase = true, by default retains original value
 * - To validate inputs
 *  - send data-pattern from the caller component
 * - For allowed characters in inputs
 *  - send data-allowedchars from the caller component
 * - To execute a method on the caller component
 *  - send hostComponent = 'this' and data-executemethod = 'methodname' from the caller component
 *
 */
@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropdownComponent implements OnInit, AfterViewInit {
  @Input() dropdownType = 'text';
  @Input() isWithInputGroup = true;
  @Input() isBorderless = false;
  @Input() hostComponent: any;
  @Input() activeClassStyle: string;
  @Input() isAuroIconBased = true;
  @Input() isUppercase = false;

  isValid = false;
  charLength = 256;
  dropdownElement: HTMLDivElement;
  listElement: HTMLDataListElement;
  searchInputElem: HTMLInputElement;
  dropdownIcon: HTMLElement;
  inputLabel: string;
  inputLabelSup = '';
  selectedChildIndex = 0;
  selectedChild: HTMLElement;
  dropdownInputGroupElem: HTMLElement;
  childItems: HTMLElement[];
  inputLabelElem: HTMLLabelElement;

  ngOnInit(): void {
    this.initializeElements();
    this.childItems = Array.from(this.listElement.children) as HTMLElement[];
    // default to the first option
    this.selectedChild = this.childItems[0];

    this.attachDropdownChildEvents();
    this.addEventListeners();
    this.attachKeyboardEvents();
  }

  ngAfterViewInit(): void {
    const dropdownInputGroupElem = document.querySelector<HTMLElement>('.dropdown-input-group');
    if (dropdownInputGroupElem) {
      this.dropdownInputGroupElem = dropdownInputGroupElem;
    }
  }

  // Needed this type initializations to resolve the ovelap
  // between sonarlint's type assertion code smell and typescript's strict-check
  private initializeElements() {
    const listElement = document.querySelector<HTMLDataListElement>('#list');
    if (listElement) {
      this.listElement = listElement;
    }
    const searchInputElem = document.querySelector<HTMLInputElement>('#dropdownInput');
    if (searchInputElem) {
      this.searchInputElem = searchInputElem;
    }
    const dropdownIconElem = document.querySelector<HTMLElement>('.dropdown-icon');
    if (dropdownIconElem) {
      this.dropdownIcon = dropdownIconElem;
    }
    const inputLabelElem = document.querySelector<HTMLLabelElement>('.input-label');
    if (inputLabelElem) {
      this.inputLabelElem = inputLabelElem;
    }
    const dropdownElem = document.querySelector<HTMLDivElement>('.dropdown-input');
    if (dropdownElem) {
      this.dropdownElement = dropdownElem;
    }
  }

  private attachKeyboardEvents() {
    this.searchInputElem.onkeydown = (e: any) => this.keyboardEventsWiringHelper(e);
    this.listElement.onkeydown = (e: any) => this.keyboardEventsWiringHelper(e);
  }

  private keyboardEventsWiringHelper(e: any) {

    // Arrow Down
    if (e.keyCode === 40) {
      this.selectedChildIndex = Math.min(this.selectedChildIndex + 1, this.childItems.length - 1);
      const childElem = this.childItems[this.selectedChildIndex];
      this.setBackgroundFocusForChildItems(childElem);
    }

    // Arrow Up
    if (e.keyCode === 38) {
      this.selectedChildIndex = Math.max(this.selectedChildIndex - 1, 0);
      const childElem = this.childItems[this.selectedChildIndex];
      this.setBackgroundFocusForChildItems(childElem);
    }

    // Space
    if (e.keyCode === 32) {
      this.updateSearchInput(true);
      this.searchInputElem.value = '';
      this.searchInputElem.focus();
      this.removeActiveClass();
      this.updateSelectedChildActiveClass(this.childItems[this.selectedChildIndex]);
      this.updateSelectedChild();
      this.updateSelectedChildLabel();
      this.updateMaxLengthForInputForSelectedChild();
      this.removeDropdownInputGroupValidBackground();
    }

    // Enter
    if (e.keyCode === 13) {
      this.submit(e);
    }
  }

  private setBackgroundFocusForChildItems(element: HTMLElement) {
    this.removeFocusedBackgroundOfChildItems(this.childItems);
    element.classList.add('focus-background');
  }

  submit(e: Event) {
    e.stopPropagation();
    if (this.isValid) {
      this.invokeMethods(this.searchInputElem.value);
      this.hideList();
      this.searchInputElem.value = '';
      this.updateSearchInput(false);
      this.dropdownInputGroupElem.classList.remove('input-valid-background');
    } else {
      this.searchInputElem.focus();
    }
  }

  private updateSelectedChild() {
    if (this.hasChildItems()) {
      for (let index = 0; index < this.childItems.length; index++) {
        const element = this.childItems[index];
        if (element.classList.contains('list-active')) {
          this.selectedChildIndex = index;
          break;
        }
      }
      this.selectedChild = this.childItems[this.selectedChildIndex];
    }
  }

  private updateMaxLengthForInputForSelectedChild() {
    if (this.selectedChild) {
      const maxLength = this.selectedChild.dataset.max ?? '256';
      this.charLength = parseInt(maxLength, 10);
    }
  }

  private updateSelectedChildActiveClass(element: HTMLElement) {
    if (element && !element.classList.contains('list-active')) {
      element.classList.add('list-active');
      if (this.isAuroIconBased) {
        this.updateAuroIconActiveSelector(element);
      }
    }
  }

  private attachDropdownChildEvents() {
    if (this.hasChildItems()) {
      this.childItems.forEach(item => {
        item.addEventListener('click', (e: any) => this.dropdownChildItemsClickHandler(e));
        item.addEventListener('mouseover', (e: any) => this.dropdownChildItemsMouseoverHandler(e));
      });
    }
  }

  private hasChildItems() {
    return this.childItems && this.childItems.length > 0;
  }

  private addEventListeners() {
    this.searchInputElem.onclick = (e) => {
      e.stopPropagation();
      this.toggleList();
    };

    this.searchInputElem.oninput = (e: any) => this.validateInput(e);

    this.dropdownIcon.onclick = (e) => {
      e.stopPropagation();
      this.toggleList();
      this.searchInputElem.focus();
    };

    document.onclick = (e: any) => { this.documentClickHandler(e); };

    document.addEventListener('keydown', (e: any) => { this.documentKeydownHandler(e); });
  }

  documentClickHandler(e: any){
    if (!e.target.name || e.target.name !== 'dropdown') {
      this.hideList();
    }
  }

  documentKeydownHandler(e: any){
    if (e.keyCode === 32 || e.keyCode === 13) {
      if (e.target === this.dropdownElement) {
        this.showList();
        this.listElement.focus();
        this.searchInputElem.focus();
      }
    }
  }

  private updateInactiveAuroIconStyle(element: HTMLElement) {
    if (element && this.isAuroIconBased) {
      const auroIcon = element.firstChild as HTMLElement;
      const cssClass = auroIcon.dataset.inactiveclass ?? '';
      auroIcon.setAttribute('style', cssClass);
    }
  }

  private updateAuroIconActiveSelector(element: HTMLElement) {
    if (this.isAuroIconBased) {
      const auroIcon = element.firstChild as HTMLElement;
      if (auroIcon.dataset.activeclass) {
        auroIcon.setAttribute('style', auroIcon.dataset.activeclass);
      }
    }
  }

  private removeFocusedBackgroundOfChildItems(items: HTMLElement[]) {
    items.forEach((item) => {
      item.classList.remove('focus-background');
    });
  }

  private validateInput(e: any) {
    if (!this.selectedChild) {
      this.searchInputElem.value = '';
      return;
    }

    const patternString = this.selectedChild.dataset.pattern ?? '';
    const patternRegex = new RegExp(patternString);
    const allowedCharsPatternString: string = this.selectedChild.dataset.allowedchars ?? '';
    const allowedCharsPatternRexExp = new RegExp(allowedCharsPatternString);

    this.validateSearchInputCharacters(allowedCharsPatternRexExp);
    this.applySearchInputValidationSettings(patternRegex);
  }

  private validateSearchInputCharacters(allowedChars: RegExp) {
    const splitValue = this.searchInputElem.value.split('');
    let charactersToFilter = 0;

    const filteredSplitValue = splitValue.map(function(character) {
      if (!allowedChars.test(character)) {
        charactersToFilter++;
        return '';
      }
      return character;
    });

    if (!charactersToFilter) {
      return;
    }

    this.searchInputElem.value = filteredSplitValue.join('');
  }

  private applySearchInputValidationSettings(pattern: RegExp) {
    if (this.searchInputElem.value) {
      if (!pattern.test(this.searchInputElem.value)) {
        this.removeDropdownInputGroupValidBackground();
        this.isValid = false;
      } else {
        this.isValid = true;
        this.addDropdownInputGroupBackground();
      }
    } else {
      this.removeDropdownInputGroupValidBackground();
    }
  }

  private addDropdownInputGroupBackground() {
    this.dropdownInputGroupElem.classList.add('input-valid-background');
  }

  private removeDropdownInputGroupValidBackground() {
    this.dropdownInputGroupElem.classList.remove('input-valid-background');
  }

  private dropdownChildItemsClickHandler(e: any) {
    e.stopPropagation();
    if (e) {
      const targetElem = e.target;
      const elem = !targetElem.classList.contains('dropdown-option') ? targetElem.parentElement : targetElem;
      if (elem) {
        this.removeActiveClass();
        this.removeFocusedBackgroundOfChildItems(this.childItems);
        this.updateSelectedChildActiveClass(elem);
        this.updateSelectedChild();
        this.updateSelectedChildLabel();
        this.updateMaxLengthForInputForSelectedChild();
        this.updateSearchInput(true);
        this.searchInputElem.focus();
        this.searchInputElem.value = '';
        this.isValid = false;
        this.removeDropdownInputGroupValidBackground();
      }
    }
  }

  private dropdownChildItemsMouseoverHandler(e: any) {
    if(e.target?.id){
      this.setBackgroundFocusForChildItems(e.target);
    }
  }

  private removeActiveClass(element = this.selectedChild) {
    if (element) {
      element.classList.remove('list-active');
      if (this.isAuroIconBased) {
        this.updateInactiveAuroIconStyle(element);
      }
    }
  }

  private toggleList() {
    const listStyle = this.listElement.getAttribute('style');
    if (!listStyle) {
      this.showList();
    } else {
      this.hideList();
    }
  }

  private showList() {
    this.listElement.style.display = 'block';
    if (this.selectedChild) {
      this.updateSelectedChildActiveClass(this.selectedChild);
      this.updateSelectedChild();
      this.updateSelectedChildLabel();
      this.updateMaxLengthForInputForSelectedChild();
      this.updateSearchInput(true);
    }
  }

  private hideList() {
    this.listElement.removeAttribute('style');
    this.removeFocusedBackgroundOfChildItems(this.childItems);
    this.updateSearchInput(false);
  }

  private updateSearchInput(isShow: boolean) {
    if (!isShow && !this.searchInputElem.value) {
      this.searchInputElem.setAttribute('placeholder', 'Search');
      this.inputLabelElem.style.display = 'none';
      this.searchInputElem.style.paddingTop = '1px';
      this.searchInputElem.blur();
      this.removeDropdownInputGroupValidBackground();
    } else {
      this.searchInputElem.setAttribute('placeholder', '');
      this.searchInputElem.style.paddingTop = '12px';
    }
  }

  private updateSelectedChildLabel() {
    if (this.selectedChild) {
      const label = this.selectedChild.dataset.label ?? '';
      this.inputLabelElem.innerHTML = label;
      this.inputLabelElem.style.display = 'block';
    }
  }

  private invokeMethods(val: string) {
    const methodName = this.selectedChild?.dataset?.executemethod;
    if (methodName) {
      const method = Reflect.get(this.hostComponent, methodName);
      if (typeof method === 'function') {
        method.apply(this.hostComponent, [val]);
      }
    }
  }
}
