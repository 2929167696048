import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, Observable, of, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { CallerContext } from '../../dtos/response/caller-context-response/caller-context';
import { CallerContextResponse } from '../../dtos/response/caller-context-response/caller-context-response';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';

export interface CallerContextServiceAPI {
  getCallers(): Observable<CallerContextResponse>;
}
/**
 * Injectable service to interface with caller-context endpoint in RAIN API
 */
@Injectable({
  providedIn: 'root',
})
export class CallerContextService implements CallerContextServiceAPI {
  constructor(private http: HttpClient) {}

  /**
   * Get a list of active calls assigned to the network name in the access token that is attached to this request via the api.interceptor
   */
  getCallers(): Observable<CallerContextResponse> {
    // background: 'true' header will prevent the global loading animation from displaying
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    return this.http.get<CallerContext[]>(`api/caller-context`, options).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => defer(() => throwError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((callers) => ({ success: true, callers })),
      catchError((err: HttpErrorResponse) => {
        return of({
          success: false,
          errorMessage: timeoutError.statusText === err.statusText ? 'Request timed out.' : err.error,
        });
      })
    );
  }
}
