<ng-container *ngIf="activeCaller$ | async as activeCaller; else noCaller">
  <div class="caller-context-users">
    <auro-popover placement="bottom" removespace *ngIf="!!(errorMessage$ | async)">
      Unable to retrieve Caller Context details.
      <span class="disconnected" slot="trigger"></span>
    </auro-popover>
    <app-caller-context-dropdown
      [activeCaller]="activeCaller$ | async"
      [callers]="callers$ | async"
      [callerCount]="callerCount$ | async"
      [activeCallerIndex]="activeCallerIndex$ | async"
      [isDisconnected]="!!(errorMessage$ | async)"
      [callerHasInfo]="callerHasInfo"
    ></app-caller-context-dropdown>
    <div class="active-caller-details quantum-metric-encrypt">
      <app-passenger-loyalty-icon
        *ngIf="!!activeCaller?.millionMilerStatus && activeCaller?.millionMilerStatus !== '0'"
        [millionMiler]="activeCaller.millionMilerStatus"
        [large]="true"
        style="position: relative; top: -1.5px"
      />
      <app-passenger-loyalty-icon
        *ngIf="!!activeCaller?.tierStatus && activeCaller?.tierStatus !== 'Regular'"
        [tierStatus]="activeCaller.tierStatus"
        [large]="true"
        style="position: relative; top: -1.5px"
      />
      <app-passenger-alaska-bank-card
        class="caller-bank-card"
        *ngIf="callerHasInfo(activeCaller)"
        [isAlaskaBankCard]="!!activeCaller?.callerCreditCardTier && activeCaller?.callerCreditCardTier === 'Signature'"
      />
      <a
        *ngIf="activeCaller?.confirmationCode"
        (click)="openReservation(activeCaller.confirmationCode)"
        (keypress)="onKeyPress($event)"
      >
        <div class="caller-context-link">
          <div class="auro-icon-text-placement">
            <auro-icon
              category="interface"
              name="reservation-stroke"
              accent
              [attr.customSize]="true"
              style="height: 16px; width: 16px; top: -2px; position: relative"
            ></auro-icon>
            <span> {{ activeCaller.confirmationCode }} </span>
          </div>
        </div>
      </a>
      <app-passenger-loyalty-number
        *ngIf="activeCaller?.loyaltyNumber"
        class="caller-loyalty-number"
        [airlineCode]="activeCaller.airlineCode"
        [loyaltyNumber]="activeCaller.loyaltyNumber"
        [allowOpenProfile]="true"
        [applyCallerContextStyling]="true"
      >
      </app-passenger-loyalty-number>
    </div>
  </div>
</ng-container>
<ng-template #noCaller>
  <div class="no-caller">Caller Context is on standby, patiently anticipating an incoming call/chat to show available details.</div>
</ng-template>
