import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SeatMapDisplayServiceState, seatMapDisplayServiceFeatureKey } from './seat-map-display-service.state';
import { SeatProperty } from '../../../models/seat-map/seat-property';

const getSeatMapDisplayServiceState = createFeatureSelector<SeatMapDisplayServiceState>(seatMapDisplayServiceFeatureKey);

export const getSeatMapDisplayChangeEvent = createSelector(getSeatMapDisplayServiceState, (state)  => state?.changeEvent);

export const getAssignedUpsellPrice = createSelector(getSeatMapDisplayChangeEvent, (changeEvent): number =>
  (changeEvent?.assignedSeat && !!changeEvent?.assignedSeat.upsellPrice) ? changeEvent.assignedSeat.upsellPrice : 0);
export const getUnassignedUpsellPrice = createSelector(getSeatMapDisplayChangeEvent, (changeEvent): number =>
  (changeEvent?.unassignedSeat && !!changeEvent?.unassignedSeat.upsellPrice) ? changeEvent.unassignedSeat.upsellPrice : 0);
export const getHasUpsellPriceDelta = createSelector(getAssignedUpsellPrice, getUnassignedUpsellPrice,
  (assignedSeatPrice: number, unassignedSeatPrice: number) => assignedSeatPrice !== unassignedSeatPrice);

export const getIsAssignedToHandicapSeat = createSelector(getSeatMapDisplayChangeEvent, (changeEvent) =>
  changeEvent?.assignedSeat && changeEvent?.assignedSeat.primaryProperty === SeatProperty.Accessible);
export const getIsUnassignedFromHandicapSeat = createSelector(getSeatMapDisplayChangeEvent, (changeEvent) =>
  changeEvent?.unassignedSeat && changeEvent?.unassignedSeat.primaryProperty === SeatProperty.Accessible);

export const getIsAssignedToMinorSeat = createSelector(getSeatMapDisplayChangeEvent, (changeEvent) =>
  changeEvent?.assignedSeat && changeEvent?.assignedSeat.primaryProperty === SeatProperty.UnaccompaniedMinorRecommended);
export const getIsUnassignedToMinorSeat = createSelector(getSeatMapDisplayChangeEvent, (changeEvent) =>
  changeEvent?.unassignedSeat && changeEvent?.unassignedSeat.primaryProperty === SeatProperty.UnaccompaniedMinorRecommended);

export const getIsAssignedToNonPremiumClassSeat = createSelector(getSeatMapDisplayChangeEvent, (changeEvent) =>
  changeEvent?.assignedSeat && !changeEvent?.assignedSeat.upsellPrice);
export const getIsUnassignedFromNonPremiumClassSeat = createSelector(getSeatMapDisplayChangeEvent, (changeEvent) =>
  changeEvent?.unassignedSeat && !changeEvent?.unassignedSeat.upsellPrice);

export const getIsAssignedToPremiumClassSeat = createSelector(getSeatMapDisplayChangeEvent, (changeEvent) =>
  changeEvent?.assignedSeat && changeEvent?.assignedSeat.upsellPrice !== undefined && changeEvent?.assignedSeat.upsellPrice !== null);
export const getIsUnassignedFromPremiumClassSeat = createSelector(getSeatMapDisplayChangeEvent, (changeEvent) =>
  changeEvent?.unassignedSeat && changeEvent?.unassignedSeat.upsellPrice !== undefined && changeEvent?.unassignedSeat.upsellPrice !== null);
export const getIsDowngradeFromPremiumClassSeat = createSelector(getIsUnassignedFromPremiumClassSeat, getIsAssignedToNonPremiumClassSeat,
  (unassignedFromPremium: boolean, assignedToNonPremium: boolean) => unassignedFromPremium && assignedToNonPremium);

export const getIsAssignedToWindowlessWindowSeat = createSelector(getSeatMapDisplayChangeEvent, (changeEvent) =>
  changeEvent?.assignedSeat &&
  (changeEvent?.assignedSeat.primaryProperty === SeatProperty.Windowless ||
    changeEvent?.assignedSeat.properties?.includes(SeatProperty.Windowless)));
export const getIsUnassignedFromWindowlessWindowSeat = createSelector(getSeatMapDisplayChangeEvent, (changeEvent) =>
  changeEvent?.unassignedSeat &&
  (changeEvent?.unassignedSeat.primaryProperty === SeatProperty.Windowless ||
    changeEvent?.unassignedSeat.properties?.includes(SeatProperty.Windowless)));

export const getSkipSelectingNextPassenger = createSelector(
  getSeatMapDisplayChangeEvent,
  getIsAssignedToHandicapSeat,
  getIsAssignedToMinorSeat,
  getIsDowngradeFromPremiumClassSeat,
  getIsAssignedToWindowlessWindowSeat,
  (changeEvent, assignedHandicap, assignedMinor, downgrade, assignedWindowlessWindow) => {
    const isPremium = changeEvent?.unassignedSeat?.upsellName === 'Premium' ? true : false;
    return assignedHandicap || assignedMinor || (isPremium && downgrade) || assignedWindowlessWindow;
  }
);
