import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { AssignSeatsStatus } from '../../../dtos/response/seats-save-status';
import { Status } from '../../../models/status';
import { RootState } from '../../../state/state';
import { SeatMapLookupRequest } from '../../../dtos/request/seat-map-lookup-request/seat-map-lookup-request';
import { SeatMapLookupResponse } from '../../../dtos/response/seat-map-lookup-response/seat-map-lookup-response';

/**
 * The string name of the seats service "slice" of state
 */
export const seatsServiceFeatureKey = 'seatsService';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  seatsService: SeatsServiceState;
}

/**
 * The state that is used in relation to the seats service, it is an extension of root state
 * these values are available in and should be accessed from the root state model
 */
export interface SeatsServiceState {
  currentSeatMapSegmentIndex: number | null;
  seatMaps: SeatMapEntity;
  seatMapLookupCrudStatus: Status;
  staticSeatMapRequest: SeatMapLookupRequest | null;
  assignSeatsStatus: AssignSeatsStatus | null;
  staticSeatMapIncludeSsrs: boolean;
  assignSeatsCrudStatus: Status;
}

export interface SeatMapEntity extends EntityState<SeatMapLookupResponse> {}

export const seatMapAdapter = createEntityAdapter<SeatMapLookupResponse>({
  // Fall back to -1 if the segmentIndex is undefined or null,
  // It would be better if we can use a defined type like segmentHashId instead of optional type for ngRx entity Ids
  selectId: (response) => response.segmentIndex ?? -1
});

export const initialSeatMapState: SeatMapEntity = seatMapAdapter.getInitialState({});

/**
 * Initial state of the mileage plan profile service slice of state, used when mileage plan profile service state is
 * bootstrapped via the reducer in an app module also used when state is reset
 */
export const initialSeatsServiceState: SeatsServiceState = {
  currentSeatMapSegmentIndex: null,
  seatMaps: initialSeatMapState,
  seatMapLookupCrudStatus: Status.STABLE,
  staticSeatMapRequest: null,
  staticSeatMapIncludeSsrs: false,
  assignSeatsStatus: null,
  assignSeatsCrudStatus: Status.STABLE,
};
