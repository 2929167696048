export enum RemoveSsrStatus {
  SUCCESS = 1,
  SYSTEM_FAILURE,
  TIMEOUT,
  UNCAUGHT,
  PASSWORD_DECRYPTION_ERROR,
  PET_SSR_GENERAL_ERROR,
  PET_SSR_PAST_DATED_PNR_ERROR,
  PET_SSR_SIMUTANEOUS_CHANGES_ERROR,
  PET_SSR_UNKNOWN_ERROR,
  NULL
}
