import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Reservation } from '../../../dtos/response/reservation-response/reservation';
import { getRoutedReservation } from '../../../services/reservation-service/state/reservation-service.selectors';
import { RootState } from '../../../state/state';
import { getConfirmationCodes, getPassengerNamesFromPnrHistory } from 'src/app/services/reservation-search-history-service/state/reservation-search-history.selectors';
import { Router, NavigationEnd, RouterEvent, Event } from '@angular/router';

export interface RecentConfirmationCode {
  id: number;
  confirmationCode: string;
  isActive: boolean;
  passengerNames: string;
}

@Component({
  selector: 'app-recent-confirmation-codes',
  templateUrl: './recent-confirmation-codes.component.html',
  styleUrls: ['./recent-confirmation-codes.component.scss'],
})
export class RecentConfirmationCodesComponent implements OnInit, OnDestroy {
  recentConfirmationCodes: RecentConfirmationCode[] = [];
  recentConfirmationCodesToShow: RecentConfirmationCode[] = [];
  recentConfirmationCodesInDropdown: RecentConfirmationCode[] = [];

  widthContainer = 0;
  widthRecentConfirmationCodeTile = 112;
  widthLeftMargin = 32;
  widthRightMargin = 32;
  widthRightDropdown = 112;
  numberOfRecentConfirmationCodesToShow = 0;
  numberOfRecentConfirmationCodesInDropdown = 0;
  queueIdCounter = 0;
  isActiveConfirmationCodeInDropdown: boolean;
  dropdownMenuWidth = '';
  dropdownTriggerPadding = '';
  dropdownClasses = '';
  currentConfirmationCode = '';
  isRoutedOnReservation: boolean;

  private onDestroy$ = new Subject<void>();
  private bodyChanges: MutationObserver;

  constructor(private store: Store<RootState>, private router: Router) {}

  ngOnInit(): void {
    this.subscribeToRouterEvents();
    this.subscribeToHistoricalConfirmationCodes();
    this.subscribeToRoutedReservation();
    this.subscribeToMenuResizing();
    this.resizeRecentConfirmationCodeControl();
  }

  ngOnDestroy(): void {
    this.bodyChanges?.disconnect();
    this.onDestroy$.next();
  }

  subscribeToRouterEvents() {
    this.processRouteChange(this.router.url);

    this.router.events
      .pipe(
        filter((e: Event | RouterEvent): e is RouterEvent => e instanceof NavigationEnd),
        takeUntil(this.onDestroy$)
      )
      .subscribe((e: RouterEvent) => {
        this.processRouteChange(this.router.url);
      });
  }

  subscribeToRoutedReservation() {
    this.store.pipe(select(getRoutedReservation), takeUntil(this.onDestroy$)).subscribe((reservation) => {
      if (reservation?.confirmationCode) {
        const confirmationCodeChanged = this.currentConfirmationCode !== reservation?.confirmationCode;
        this.currentConfirmationCode = reservation?.confirmationCode;
        this.processPassengerName(reservation);
        if (confirmationCodeChanged) {
          this.resizeRecentConfirmationCodeControl();
        }
      }
    });
  }

  subscribeToHistoricalConfirmationCodes() {
    this.store.pipe(select(getConfirmationCodes), takeUntil(this.onDestroy$)).subscribe((confCodes) => {
      this.recentConfirmationCodes = confCodes.map((c) => {
        this.queueIdCounter++;
        let passengerNames = '';
        this.store.pipe(select(getPassengerNamesFromPnrHistory(c)), take(1)).subscribe((paxNames) => {
          passengerNames = paxNames.join('\n');
        });
        return {
          id: this.queueIdCounter,
          confirmationCode: c,
          isActive: false,
          passengerNames,
        } as RecentConfirmationCode;
      }).reverse();

      this.resizeRecentConfirmationCodeControl();
    });
  }

  processPassengerName(reservation: Reservation) {
    const recentConfirmationCode = this.recentConfirmationCodes.find((rcc) => rcc.confirmationCode === this.currentConfirmationCode);
    if (recentConfirmationCode.passengerNames === '') {
      const passengerNamesCollection = reservation.passengers.map((passenger) => `${passenger.firstName} ${passenger.lastName}`);
      const passengerNamesString = passengerNamesCollection ? 'Guests: \n' + passengerNamesCollection.join('\n') : '';
      recentConfirmationCode.passengerNames = passengerNamesString;
      this.resizeRecentConfirmationCodeControl();
    }
  }

  processRouteChange(url: string) {
    this.isRoutedOnReservation = url.startsWith('/reservation') && !url.includes('/care-kit/form') ? true : false;
    this.resizeRecentConfirmationCodeControl();
  }

  subscribeToMenuResizing() {
    this.bodyChanges = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => {
        if (mutation.type === 'attributes') {
          //used to wait for the .25 second transiton from nav menu to complete
          setTimeout(() => {
            this.resizeRecentConfirmationCodeControl();
          }, 275);
        }
      });
    });

    this.bodyChanges.observe(document.getElementsByClassName('contentBody')[0], { attributes: true });
  }

  handleClick() {
    this.hideDropdown();
  }

  hideDropdown() {
    document.querySelector<any>('#recentConfirmationCodeDropdown')?.hide();
  }

  windowResize() {
    this.resizeRecentConfirmationCodeControl();
  }

  resizeRecentConfirmationCodeControl() {
    this.widthContainer =
      document.getElementById('conf-code-container').offsetWidth - this.widthLeftMargin - this.widthRightDropdown - this.widthRightMargin;

    this.numberOfRecentConfirmationCodesToShow = Math.floor(this.widthContainer / this.widthRecentConfirmationCodeTile);
    this.numberOfRecentConfirmationCodesInDropdown = this.recentConfirmationCodes.length - this.numberOfRecentConfirmationCodesToShow;

    this.setActiveConfirmationCode();

    this.recentConfirmationCodesToShow = this.recentConfirmationCodes.slice(0, this.numberOfRecentConfirmationCodesToShow);
    this.recentConfirmationCodesInDropdown = this.recentConfirmationCodes.slice(this.numberOfRecentConfirmationCodesToShow);

    this.setDropdownStyles();
    this.setIsActiveConfirmationCodeInDropdown();
    this.setRecentConfirmationCodeDropdownClass();
  }

  setActiveConfirmationCode() {
    this.recentConfirmationCodes.forEach((rcc) => {
      rcc.isActive = this.isRoutedOnReservation && rcc.confirmationCode === this.currentConfirmationCode ? true : false;
    });
  }

  setDropdownStyles() {
    const availableSpace = this.widthContainer % this.widthRecentConfirmationCodeTile;
    this.dropdownTriggerPadding = `padding-left: ${availableSpace}px;`;

    const availableSpaceIncludingDropdown = availableSpace + this.widthRightDropdown;
    this.dropdownMenuWidth = `width: ${availableSpaceIncludingDropdown}px;`;
  }

  setIsActiveConfirmationCodeInDropdown() {
    this.isActiveConfirmationCodeInDropdown = this.recentConfirmationCodesInDropdown.find((rcc) => rcc.isActive)?.isActive ? true : false;
  }

  setRecentConfirmationCodeDropdownClass() {
    let dropdownClasses = '';

    if (this.recentConfirmationCodesInDropdown?.length > 0) {
      dropdownClasses = 'recent-confirmation-code-overflow-dropdown';
    }

    if (this.isActiveConfirmationCodeInDropdown) {
      dropdownClasses = dropdownClasses + (dropdownClasses.length > 0 ? ' ' : '') + 'recent-confirmation-code-overflow-dropdown-active';
    }

    this.dropdownClasses = dropdownClasses;
  }
}
