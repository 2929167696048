import { ActionCode } from './action-codes';

/**
 * Unconfirmed action codes - this list comes from the old 'segments' list filtering that used to be in API
 */
export const unconfirmedActionCodes: ReadonlyArray<ActionCode> = [
  ActionCode.HL,
  ActionCode.DS,
  ActionCode.JL,
  ActionCode.WK,
  ActionCode.UC,
  ActionCode.UN,
];
