import { Action, createReducer, on } from '@ngrx/store';
import { Status } from '../../../models/status';
import {
  contactTracingInfoUpdateComplete,
  initializeContactTracingState,
  removeContactTracingAddressInfo,
  removeContactTracingEmailInfo,
  removeContactTracingPhoneInfo,
  submitContactTracingAddressInfo,
  submitContactTracingEmailInfo,
  submitContactTracingPhonesInfo,
  updateContactTracingInfoStatus,
} from './contact-tracing.actions';
import { contactTracingInfoAdapter, initialContactTracingState } from './contact-tracing.state';

const featureReducer = createReducer(
  initialContactTracingState,
  /**
   * Reinitialize the contact tracing state
   */
  on(initializeContactTracingState, (state) => ({ ...state, ...initialContactTracingState })),
  /**
   * Submits an update for contact tracing email info
   */
  on(submitContactTracingEmailInfo, (state, { request }) => {
    return {
      ...state,
      contactTracingInfo: contactTracingInfoAdapter.setOne(
        { status: Status.UPDATING_CONTACT_TRACING_EMAIL_INFO, passengerHashId: request.passengerHashId },
        state.contactTracingInfo
      ),
    };
  }),
  /**
   * Submits an update for contact tracing phones info
   */
  on(submitContactTracingPhonesInfo, (state, { request }) => {
    return {
      ...state,
      contactTracingInfo: contactTracingInfoAdapter.setOne(
        { status: Status.UPDATING_CONTACT_TRACING_PHONES_INFO, passengerHashId: request.passengerHashId },
        state.contactTracingInfo
      ),
    };
  }),
  /**
   * Submits an update for contact tracing address info
   */
  on(submitContactTracingAddressInfo, (state, { request }) => {
    return {
      ...state,
      contactTracingInfo: contactTracingInfoAdapter.setOne(
        { status: Status.UPDATING_CONTACT_TRACING_ADDRESS_INFO, passengerHashId: request.passengerHashId },
        state.contactTracingInfo
      ),
    };
  }),
  /**
   * Submits an update for contact tracing info
   */
  on(contactTracingInfoUpdateComplete, (state, { response, passengerHashId }) => {
    return {
      ...state,
      contactTracingInfo:
        response === null
          ? contactTracingInfoAdapter.setOne({ success: true, passengerHashId }, state.contactTracingInfo)
          : contactTracingInfoAdapter.setOne({ success: false, passengerHashId }, state.contactTracingInfo),
    };
  }),
  /**
   * Updates the CRUD status of a particular contact tracing info
   */
  on(updateContactTracingInfoStatus, (state, { hashId, status }) => {
    return {
      ...state,
      contactTracingInfo: contactTracingInfoAdapter.updateOne({ id: hashId, changes: { status } }, state.contactTracingInfo),
    };
  }),
  /**
   * Removes a contact tracing email info
   */
  on(removeContactTracingEmailInfo, (state, { confirmationCode, ssrId, passengerHashId }) => {
    return {
      ...state,
      contactTracingInfo: contactTracingInfoAdapter.setOne(
        {
          status: Status.REMOVING_CONTACT_TRACING_EMAIL_INFO,
          passengerHashId,
        },
        state.contactTracingInfo
      ),
    };
  }),
  /**
   * Removes a contact tracing phone info
   */
  on(removeContactTracingPhoneInfo, (state, { confirmationCode, ssrId, passengerHashId }) => {
    return {
      ...state,
      contactTracingInfo: contactTracingInfoAdapter.setOne(
        {
          status: Status.REMOVING_CONTACT_TRACING_PHONE_INFO,
          passengerHashId,
        },
        state.contactTracingInfo
      ),
    };
  }),
  /**
   * Removes a contact tracing address info
   */
  on(removeContactTracingAddressInfo, (state, { confirmationCode, ssrId, passengerHashId }) => {
    return {
      ...state,
      contactTracingInfo: contactTracingInfoAdapter.setOne(
        {
          status: Status.REMOVING_CONTACT_TRACING_ADDRESS_INFO,
          passengerHashId,
        },
        state.contactTracingInfo
      ),
    };
  })
);

export function contactTracingReducer(state = initialContactTracingState, action: Action) {
  return featureReducer(state, action);
}
