import { Action, createReducer, on } from '@ngrx/store';
import { Status } from '../../../models/status';
import {
  addSsrsFullFailure,
  addSsrsFullSuccess,
  addSsrsPartialSuccess,
  addSsrsComplete,
  addUmnrSsrs,
  addWheelchairSsrs,
  initializeSsrServiceState,
  removeSsr,
  removeSsrFailure,
  removeSsrSuccess,
  setRemoveSsrStatus,
  setSelectedSsrOption,
  setSelectFlightsFormValid,
  setSelectGuestsFormValid,
  setSsrFormValid,
  addOthsTeenSsrs,
  addSsrsWithRemark,
  addSvanSsrs,
  addExstSsrs,
  addCbbgSsrs,
  batchDeleteSsrsComplete,
  setRemoveSsrId
} from './ssr-service.actions';
import { initialSsrServiceState } from './ssr-service.state';

const featureReducer = createReducer(
  initialSsrServiceState,
  // Store the SSR option we chose from drop down WCHR, UMNR, etc.
  on(setSelectedSsrOption, (state, { selectedSsrOption }) => ({ ...state, selectedSsrOption })),
  on(setSsrFormValid, (state, { ssrFormValid }) => ({ ...state, ssrFormValid })),
  on(setSelectFlightsFormValid, (state, { selectFlightsFormValid }) => ({ ...state, selectFlightsFormValid })),
  on(setSelectGuestsFormValid, (state, { selectGuestsFormValid }) => ({ ...state, selectGuestsFormValid })),
  on(initializeSsrServiceState, (state) => ({ ...state, ...initialSsrServiceState })),
  /**
   * On addWheelchairSsrs, updating the addSsrStatus to updating
   */
  on(addWheelchairSsrs, (state) => ({
    ...state,
    addedSsrStatus: null,
    addSsrStatus: Status.UPDATING,
  })),
  on(addUmnrSsrs, (state) => ({
    ...state,
    addedSsrStatus: null,
    addSsrStatus: Status.UPDATING,
  })),
  on(addSsrsWithRemark, (state) => ({
    ...state,
    addedSsrStatus: null,
    addSsrStatus: Status.UPDATING,
  })),
  on(addSvanSsrs, (state) => ({
    ...state,
    addedSsrStatus: null,
    addSsrStatus: Status.UPDATING,
  })),
  on(addExstSsrs, (state) => ({
    ...state,
    addedSsrStatus: null,
    addSsrStatus: Status.UPDATING,
  })),
  on(addCbbgSsrs, (state) => ({
    ...state,
    addedSsrStatus: null,
    addSsrStatus: Status.UPDATING
  })),
  on(addOthsTeenSsrs, (state) => ({
    ...state,
    addedSsrStatus: null,
    addSsrStatus: Status.UPDATING,
  })),
  /**
   * On addSsrsFullSuccess, update ssr status and the add ssr
   */
  on(addSsrsFullSuccess, (state, { successfullyAddedSsrs, addSsrStatus }) => ({
    ...state,
    addedSsrStatus: addSsrStatus,
    addSsrsSuccess: successfullyAddedSsrs,
    addSsrStatus: Status.STABLE,
  })),
  /**
   * On addSsrsPartialSuccess, update the add ssr status and add the success/failure models
   */
  on(addSsrsPartialSuccess, (state, { failedSsrs, successfullyAddedSsrs, addSsrStatus }) => ({
    ...state,
    addedSsrStatus: addSsrStatus,
    addSsrsFailure: failedSsrs,
    addSsrsSuccess: successfullyAddedSsrs,
    addSsrStatus: Status.STABLE,
  })),
  /**
   * On addSsrsFullFailure, update the add ssr status and add the failed SSRs
   */
  on(addSsrsFullFailure, (state, { addSsrStatus, failedSsrs }) => ({
    ...state,
    addedSsrStatus: addSsrStatus,
    addSsrsFailure: failedSsrs,
    addSsrStatus: Status.STABLE,
  })),
  /**
   * On addSsrsComplete, Update the success/failure list and set the service status
   */
   on(addSsrsComplete, (state, { failedSsrs, successfullyAddedSsrs, addSsrStatus }) => ({
    ...state,
    addedSsrStatus: addSsrStatus,
    addSsrsFailure: failedSsrs,
    addSsrsSuccess: successfullyAddedSsrs,
    addSsrStatus: Status.STABLE,
  })),
  /**
   * On removeSsr, updating the removeSsrStatus to updating
   */
  on(removeSsr, (state) => ({
    ...state,
  })),
  /**
   * On removeSsrSuccess, updating the removeSsrStatus to stable
   */
  on(removeSsrSuccess, (state, { removeSsrStatus }) => ({
    ...state,
    removeSsrStatus,
  })),
  /**
   * On removeSsrFailure, updating the removeSsrStatus to stable
   */
  on(removeSsrFailure, (state, { removeSsrStatus }) => ({
      ...state,
      removeSsrStatus,
    })),
  /**
   * On setRemoveSsrStatus, update status to whatever value is passed in
   */
  on(setRemoveSsrStatus, (state, { removeSsrStatus }) => ({
    ...state,
    removeSsrStatus,
  })),
  /**
   * On setRemoveSsrId, update status to whatever value is passed in
   */
  on(setRemoveSsrId, (state, { removeSsrId }) => ({
    ...state,
    removeSsrId,
  })),
  /**
   * On removeSsrSuccess, updating the removeSsrStatus to stable
   */
  on(batchDeleteSsrsComplete, (state, { response }) => ({
    ...state,
    batchDeleteSsrsResponse: response
  })),
);

export function ssrServiceReducer(state = initialSsrServiceState, action: Action) {
  return featureReducer(state, action);
}
