export enum PassengerDataSlice {
  MileagePlan,
  BioInfo,
  KnownTraveler,
  Redress,
  InternationalDocument,
  EmergencyContact,
  ContactTracing,
  ContactTracingPhone,
  ContactTracingEmail,
  ContactTracingAddress,
  /**
   * Used when reservation lookup fails. Bail out since "update" functionality wouldn't work.
   */
  ReservationLookupFailure,
  /**
   * When mileage plan is updated, we remove the existing entry before re-adding the new one.
   * This will be used to indicate a failure upon attempting to remove the existing mileage plan.
   */
  MileagePlanRemove,
  /**
   * When bio info is updated, we remove the existing entry before re-adding the new one.
   * This will be used to indicate a failure upon attempting to remove the existing bio info.
   */
  BioInfoRemove,
  /**
   * When known traveler is updated, we remove the existing entry before re-adding the new one.
   * This will be used to indicate a failure upon attempting to remove the existing known traveler.
   */
  KnownTravelerRemove,
  /**
   * When redress is updated, we remove the existing entry before re-adding the new one.
   * This will be used to indicate a failure upon attempting to remove the existing redress.
   */
  RedressRemove,
  /**
   * When international document is updated, we remove the existing entry before re-adding the new one.
   * This will be used to indicate a failure upon attempting to remove the existing international document.
   */
  InternationalDocumentRemove,
  /** When international document is updated, we remove the existing entry before re-adding the new one.
   * Country of residence is a separate SSR entry.
   * This will be used to indicate a failure upon attempting to remove the existing Country of residence.
   */
  InternationalDocumentCountryOfResidenceRemove,
  /**
   * When emergency contact is updated, we remove the existing entry before re-adding the new one.
   * This will be used to indicate a failure upon attempting to remove the existing emergency contact.
   */
  EmergencyContactRemove,
  /**
   * When contact tracing phone number is updated, we remove the existing entry before re-adding the new one.
   * This will be used to indicate a failure upon attempting to remove the existing contact tracing phone number.
   */
  ContactTracingPhoneRemove,
  /**
   * When contact tracing email address is updated, we remove the existing entry before re-adding the new one.
   * This will be used to indicate a failure upon attempting to remove the existing contact tracing email address.
   */
  ContactTracingEmailRemove,
  /**
   * When contact tracing address is updated, we remove the existing entry before re-adding the new one.
   * This will be used to indicate a failure upon attempting to remove the existing contact tracing address.
   */
  ContactTracingAddressRemove,
  /**
   * When lap infant is updated, we remove the existing entry before re-adding the new one.
   * This will be used to indicate a failure upon attempting to remove the existing emergency contact.
   */
  LapInfantRemove,
  /**
   * Used when requested remove data slice is unrecognized.
   */
  RemoveFailure
}
