import { createAction } from '@ngrx/store';
import { seatMapDisplayServiceFeatureKey } from './seat-map-display-service.state';
import { Seat } from 'src/app/models/seat-map/seat';
import { Passenger } from '../../../dtos/response/reservation-response/passenger';
import { SeatChangeType } from '../../../models/seat-map/seat-change-event';

export const initializeSeatMapDisplayServiceState = createAction(`[${seatMapDisplayServiceFeatureKey}] Initialize`);

export const setSeatMapDisplayAssignedSeat = createAction(`[${seatMapDisplayServiceFeatureKey}] Set SMD assigned seat`,
  (assignedSeat: Seat) => ({ assignedSeat })
);
export const setSeatMapDisplayAssignSeatFormerOccupant = createAction(`[${seatMapDisplayServiceFeatureKey}] Set SMD former occupant`,
  (assignedSeatFormerOccupant: Passenger) => ({ assignedSeatFormerOccupant })
);
export const setSeatMapDisplayPassenger = createAction(`[${seatMapDisplayServiceFeatureKey}] Set SMD passenger`,
  (passenger: Passenger | null) => ({ passenger })
);
export const setSeatMapDisplayIsRevert = createAction(`[${seatMapDisplayServiceFeatureKey}] Set SMD revert`,
  (isRevert: boolean) => ({ isRevert })
);
export const setSeatMapDisplayType = createAction(`[${seatMapDisplayServiceFeatureKey}] Set SMD type`,
  (seatChangeType: SeatChangeType) => ({ seatChangeType })
);
export const setSeatMapDisplayUnassignedSeat = createAction(`[${seatMapDisplayServiceFeatureKey}] Set SMD unassigned seat`,
  (unassignedSeat: Seat | undefined) => ({ unassignedSeat })
);
