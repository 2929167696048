<span *ngIf="category === 'elite'"
  [ngClass]="noTopMargin ? 'loyalty-icon-no-top' : 'loyalty-icon'"
  title="{{ title }}">
  <alaska-elitetags type="{{ type }}"
    size="{{ large ? 'large' : 'small' }}"
    category="elite"></alaska-elitetags>
</span>
<span *ngIf="category === 'oneworld'"
  [ngClass]="noTopMargin ? 'loyalty-icon-no-top' : 'loyalty-icon'"
  title="oneworld&reg; {{ title }}">
  <alaska-elitetags type="{{ type }}"
    size="{{ large ? 'large' : 'small' }}"
    category="oneworld"></alaska-elitetags>
</span>
