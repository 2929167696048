import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigationActionTiming, RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './features/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { BrowserBlockerComponent } from './guards/browser-guard/browser-blocker.component';
import { BrowserGuard } from './guards/browser-guard/browser.guard';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { InvoluntaryChangeGuard } from './guards/involuntary-change.guard';
import { LoggedInUserGuard } from './guards/logged-in-user.guard';
import { ManualAssociationGuard } from './guards/manual-association.guard';
import { PaymentDueGuard } from './guards/payment-due.guard';
import { PaymentGuard } from './guards/payment.guard';
import { ReservationGuard } from './guards/reservation.guard';
import { SeatMapGuard } from './guards/seatmap.guard';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { EventInterceptor } from './interceptors/event.interceptor';
import { CommonHeaderLayoutComponent } from './shared/ux-header/common-header-layout/common-header-layout.component';
import { NoCommonHeaderLayoutComponent } from './shared/ux-header/no-common-header-layout/no-common-header-layout.component';
import { UxHeaderModule } from './shared/ux-header/ux-header.module';
import { UXModule } from './shared/ux/ux.module';
import { TicketDetailsGuard } from './guards/ticket-details.guard';

const routes: Routes = [
  {
    path: 'browser-blocker',
    component: BrowserBlockerComponent, // no guards because this route is for incompatible browsers, not logged in, not authorized
  },
  {
    path: 'auth',
    component: AuthComponent,
    canActivate: [AuthGuard], // no login guard because you can view this route without being logged in
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard], // should be included in all routes
  },
  {
    // if you do this, you don't need multiple router outlets
    path: '',
    component: CommonHeaderLayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
        canLoad: [AuthGuard], // should be included in all routes for lazy loaded modules
        canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard], // should be included in all routes
      },
      {
        path: 'reservation',
        loadChildren: () => import('./features/reservation/reservation.module').then((m) => m.ReservationModule),
        canLoad: [AuthGuard], // should be included in all routes for lazy loaded modules
        canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard],
        canActivateChild: [ReservationGuard]
      },
      {
        path: 'search',
        loadChildren: () => import('./features/search/search.module').then((m) => m.SearchModule),
        canLoad: [AuthGuard], // should be included in all routes for lazy loaded modules
        canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard],
        data: { menuId: 'reservation-search' }
      },
      {
        path: 'coupon-record-search',
        loadChildren: () => import('./features/coupon-record-search/coupon-record-search.module').then((m) => m.CouponRecordSearchModule),
        canLoad: [AuthGuard], // should be included in all routes for lazy loaded modules
        canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard],
        data: { menuId: 'ticket-search' }
      },
      {
        path: 'mileage-plan-profile-search',
        loadChildren: () =>
          import('./features/mileage-plan-profile-search/mileage-plan-profile-search.module').then((m) => m.MileagePlanProfileSearchModule),
        canLoad: [AuthGuard], // should be included in all routes for lazy loaded modules
        canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard],
        data: { menuId: 'guest-profile-search' }
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
    ],
  },
  {
    path: '',
    component: NoCommonHeaderLayoutComponent,
    children: [
      {
        path: 'manual-association',
        loadChildren: () => import('./features/manual-association/manual-association.module').then((m) => m.ManualAssociationModule),
        canLoad: [AuthGuard], // should be included in all routes for lazy loaded modules
        canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard],
        canActivateChild: [ManualAssociationGuard],
      },
      {
        path: 'initial-booking/:confcode',
        loadChildren: () => import('./features/initial-booking/initial-booking.module').then((m) => m.InitialBookingModule),
        canLoad: [AuthGuard], // should be included in all routes for lazy loaded modules
        canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard],
      },
      {
        path: 'ticket-details/:ticketNumber',
        loadChildren: () => import('./features/ticket-details/ticket-details.module').then((m) => m.TicketDetailsModule),
        canLoad: [AuthGuard], // should be included in all routes for lazy loaded modules
        canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard],
        canActivateChild: [TicketDetailsGuard],
      },
    ],
  },

  {
    path: 'reservation',
    component: NoCommonHeaderLayoutComponent,
    children: [
      {
        path: ':confcode/seatmap',
        loadChildren: () => import('./features/seat-map/seat-map.module').then((m) => m.SeatMapModule),
        canActivateChild: [SeatMapGuard],
        canLoad: [AuthGuard], // should be included in all routes for lazy loaded modules
        canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard],
      },
      {
        path: ':confcode/summary',
        canActivateChild: [PaymentDueGuard],
        loadChildren: () => import('./features/summary/summary.module').then((m) => m.SummaryModule),
        canLoad: [AuthGuard], // should be included in all routes for lazy loaded modules
        canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard],
      },
      {
        path: ':confcode/payment',
        canActivateChild: [PaymentGuard],
        loadChildren: () => import('./features/payment/payment.module').then((m) => m.PaymentModule),
        canLoad: [AuthGuard], // should be included in all routes for lazy loaded modules
        canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard],
      },
      {
        path: ':confcode/payment-link',
        loadChildren: () => import('./features/payment-link/payment-link.module').then((m) => m.PaymentLinkModule),
        canLoad: [AuthGuard], // should be included in all routes for lazy loaded modules
        canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard],
      },
      {
        path: ':confcode/waivers',
        canActivateChild: [PaymentDueGuard],
        loadChildren: () => import('./features/waivers/waivers.module').then((m) => m.WaiversModule),
        canLoad: [AuthGuard], // should be included in all routes for lazy loaded modules
        canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard],
      },
      {
        path: ':confcode/involuntary-change/:involuntaryChangeType',
        canActivateChild: [InvoluntaryChangeGuard],
        loadChildren: () => import('./features/involuntary-change/involuntary-change.module').then((m) => m.InvoluntaryChangeModule),
        canLoad: [AuthGuard], // should be included in all routes for lazy loaded modules
        canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard],
      },
      {
        path: ':confcode/rebook',
        canActivateChild: [InvoluntaryChangeGuard],
        loadChildren: () => import('./features/voluntary-change/voluntary-change.module').then((m) => m.VoluntaryChangeModule),
        canLoad: [AuthGuard], // should be included in all routes for lazy loaded modules
        canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard],
      },
      {
        path: ':confcode/same-day-confirm',
        canActivateChild: [InvoluntaryChangeGuard],
        loadChildren: () => import('./features/same-day-confirm/same-day-confirm.module').then((m) => m.SameDayConfirmModule),
        canLoad: [AuthGuard], // should be included in all routes for lazy loaded modules
        canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard],
      },
      {
        path: ':confcode/ssr',
        loadChildren: () => import('./features/ssr/ssr.module').then((m) => m.SsrModule),
        canLoad: [AuthGuard], // should be included in all routes for lazy loaded modules
        canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard],
      },
      {
        path: ':confcode/tcp',
        loadChildren: () => import('./features/tcp/tcp.module').then((m) => m.TcpModule),
        canLoad: [AuthGuard], // should be included in all routes for lazy loaded modules
        canActivate: [BrowserGuard, AuthGuard, LoggedInUserGuard],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    InlineSVGModule.forRoot(),
    UXModule,
    UxHeaderModule,
    CommonModule,
    StoreRouterConnectingModule.forRoot({
      // Change dispatch timing: dispatched after guards and resolvers successfully ran and the new route will be activated
      navigationActionTiming: NavigationActionTiming.PostActivation,
      // Built in ngrx serializer - ignores paramMap, queryParamMap, and component
      routerState: RouterState.Minimal,
    }),
  ],
  exports: [RouterModule],
  declarations: [BrowserBlockerComponent, AuthComponent],
})
export class AppRoutingModule {
  static forRoot(): ModuleWithProviders<AppRoutingModule> {
    return {
      ngModule: AppRoutingModule,
      providers: [
        ReservationGuard,
        CanDeactivateGuard,
        BrowserGuard,
        LoggedInUserGuard,
        AuthGuard,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ApiInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: EventInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
      ],
    };
  }
}
