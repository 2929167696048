import { Action, createReducer, on } from '@ngrx/store';
import { initialVoluntaryChangeState } from './voluntary-change.state';
import {
  resetVoluntaryChangeState,
  setFlightSearchCriteria,
  setSelectedCabinType,
  setSelectedFlightResult,
  setSelectedFlights,
  setSelectedGuests,
  updateFlightChangeAction,
  updateFlightSearchCriteria,
} from './voluntary-change.actions';

const featureReducer = createReducer(
  initialVoluntaryChangeState,
  on(resetVoluntaryChangeState, (state, { passengers, segments }) => ({
    ...state,
    selectedGuests: passengers,
    selectedFlights: segments.map((segment, index) => ({ id: index, segment, action: 'Keep' })),
    selectedCabinType: '',
    flightSearchCriteria: [],
  })),
  on(setSelectedGuests, (state, { guests }) => ({
    ...state,
    selectedGuests: guests,
  })),
  on(setSelectedFlights, (state, { selectedFlights }) => {
    const updatedSelectedFlights = selectedFlights.map((selectedFlight, index) => ({ ...selectedFlight, id: index }));
    return { ...state, selectedFlights: updatedSelectedFlights };
  }),
  on(updateFlightChangeAction, (state, { segment, action }) => {
    if (!segment || !action) {
      return state;
    }
    const updatedSelectedFlights = state.selectedFlights.map((selectedFlight) =>
      selectedFlight.segment === segment ? { ...selectedFlight, action } : selectedFlight
    );
    return { ...state, selectedFlights: updatedSelectedFlights };
  }),
  on(setSelectedCabinType, (state, { selectedCabinType }) => ({
    ...state,
    selectedCabinType,
  })),
  on(setFlightSearchCriteria, (state, { flightSearchCriteria }) => ({
    ...state,
    flightSearchCriteria,
  })),
  on(updateFlightSearchCriteria, (state, { flightSearchCriteria }) => {
    const updatedFlightSearchCriteria = state.flightSearchCriteria.map((fsc) =>
      fsc.id === flightSearchCriteria.id ? flightSearchCriteria : fsc
    );
    return { ...state, flightSearchCriteria: updatedFlightSearchCriteria };
  }),
  on(setSelectedFlightResult, (state, { selectedFlightResult }) => ({
    ...state,
    selectedFlightResult,
  }))
);

export function voluntaryChangeReducer(state = initialVoluntaryChangeState, action: Action) {
  return featureReducer(state, action);
}
