<div class="slim-data-row">
  <div class="data vertical-center left-align text bag">{{ bagCount }}</div>
  <div class="data vertical-center left-align text max-weight">{{ parseWeight(fee?.description?.details) }}</div>
  <div class="data vertical-center left-align text max-size">{{ parseSize(fee?.description?.details) }}</div>
  <div class="data vertical-center right-align text cost">${{ fee?.baseAmount }}</div>
  <div class="waiver-info">
    <alaska-popover position="left">
      <div class="text popover-content waiver-reason-popover">
        <div *ngIf="!isOneworldWaiver; else displayoneworldWaiver">
          {{ getFormattedWaiverReason(fee?.waiverReason) }}
        </div>
        <ng-template #displayoneworldWaiver>
          <div>
            <strong>one</strong>world {{ getFormattedWaiverReason(fee?.waiverReason) }}
          </div>
        </ng-template>
      </div>
      <a class="hover-icon" tabindex="0" [routerLink]="[]" slot="trigger" [inlineSVG]="'/assets/icon-exclamation-mark-circle.svg'"></a>
    </alaska-popover>
  </div>
</div>
