import { AddTcpRequest } from 'src/app/dtos/request/add-tcp-request';
import { osiServiceFeatureKey } from './osi-service.state';
import { createAction } from '@ngrx/store';
import { AddOsiStatus } from 'src/app/dtos/response/osi-response/add-osi-status';
import { TcpDetail } from 'src/app/dtos/response/osi-response/tcp-detail';

export const initializeOsiServiceState = createAction(`[${osiServiceFeatureKey}] Initialize`);
/**
 * Add TCPs to a reservation based on form inputs
 */
export const addTcpOsi = createAction(
  `[${osiServiceFeatureKey}] Add Tcp Osi to reservation`,
  (confirmationCode: string, addTcpRequest: AddTcpRequest) => ({
    confirmationCode,
    addTcpRequest,
  })
);

/**
 * All TCPs successfully added to reservation
 */
export const addTcpOsiFullSuccess = createAction(
  `[${osiServiceFeatureKey}] All OSIs successfully added to reservation`,
  (addOsiSuccess: TcpDetail[] | undefined, addOsiStatus: AddOsiStatus) => ({
    addOsiSuccess,
    addOsiStatus,
  })
);

/**
 * Some TCPs successfully added to reservation
 */
export const addTcpOsiPartialSuccess = createAction(
  `[${osiServiceFeatureKey}] Some OSIs successfully added to reservation`,
  (addOsiFailure: TcpDetail[] | undefined, addOsiSuccess: TcpDetail[] | undefined, addOsiStatus: AddOsiStatus) => ({
    addOsiFailure,
    addOsiSuccess,
    addOsiStatus,
  })
);

/**
 * Failed to add any osis to reservation
 */
export const addTcpOsiFullFailure = createAction(
  `[${osiServiceFeatureKey}] Failed to add any OSIs to reservation`,
  (addOsiFailure: TcpDetail[] | undefined, addOsiStatus: AddOsiStatus) => ({
    addOsiStatus,
    addOsiFailure,
  })
);

