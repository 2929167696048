/**
 * CAUTION CAUTION CAUTION
 * @description This file is in priority order
 * If you add a new message key, please add it in the correct order!!!
 */
export enum MessageKey {
  // HIGHEST PRIORITY MESSAGE - this will get shown first in a stack of messages
  PASSWORD_DECRYPTION_ERROR,
  LIMITED_PERMISSIONS,
  MISSING_OBCINH_PERMISSION,
  SSR_DELETE_FAILED,
  PET_SSR_DELETE_FAILED_ON_GENERAL_ERROR,
  PET_SSR_DELETE_FAILED_ON_PAST_DATED_PNR,
  PET_SSR_DELETE_FAILED_ON_SIMUTANEOUS_CHANGES,
  PET_SSR_DELETE_FAILED_ON_UNKNOWN_ERROR,
  ONE_TRAVEL_ADVISORY,
  MULTIPLE_TRAVEL_ADVISORY,
  NO_NAME_NUMBER,
  INVOLUNTARY_CHANGE,
  ACTIVE_CHAT_PAYMENT_LINK,
  GENERAL,
  NO_SEGMENTS,
  NO_PREMIUM_PRICES,
  SAVE_SYSTEM_FAILURE,
  AIRPORT_CHECK_IN_WINDOW,
  SEGMENT_OUT_OF_ORDER,
  UNKNOWN_SEAT_AREA,
  SAVE_TIMEOUT,
  SEAT_MAP_LOAD_ERROR,
  SEAT_MAP_LOAD_SYSTEM_FAILURE,
  SEAT_MAP_LOAD_TIMEOUT,
  TRANSACTION_SUCCESSFUL,
  MANUAL_ASSOCIATION_REVAL_FAILURE,
  MANUAL_ASSOCIATION_SUCCESS,
  TICKET_API_FAILURE,
  INVOLUNTARY_CHANGE_REISSUE_INTERNAL_SERVER_ERROR,
  INVOLUNTARY_CHANGE_DELETE_OSI_FAILURE,
  INVOLUNTARY_CHANGE_ADD_OSI_FAILURE,
  INVOLUNTARY_CHANGE_REISSUE_SUCCESS,
  INVOLUNTARY_CHANGE_REVAL_FAILURE,
  INVOLUNTARY_CHANGE_ITINERARY_FAILURE,
  INVOLUNTARY_CHANGE_REVAL_TIMEOUT,
  INVOLUNTARY_CHANGE_NEED_PHONE_FIELD,
  EXTRA_SEAT_ERROR,
  INVOLUNTARY_CHANGE_FLIFO,
  INVOLUNTARY_CHANGE_CONNECTION_TIME_FAILURE,
  INVOLUNTARY_CHANGE_INVALID_DEPARTURE_DATE,
  INVOLUNTARY_CHANGE_REISSUE_VCR_PNR_NAME_DO_NOT_MATCH,
  INVOLUNTARY_CHANGE_REISSUE_PNR_IN_AAA,
  INVOLUNTARY_CHANGE_REISSUE_OTHER_FEE_MULTIPLE_REPEATS,
  INVOLUNTARY_CHANGE_REISSUE_PARTIAL_REISSUE_FAILURE_WITH_FAILED_ASSOCIATION,
  INVOLUNTARY_CHANGE_REISSUE_PARTIAL_REISSUE_FAILURE_WITH_SUCCESSFUL_ASSOCIATION,
  INVOLUNTARY_CHANGE_REISSUE_ASSOCIATION_TIMEOUT,
  ASSOCIATION_INTERNAL_SERVER_ERROR,
  INVOLUNTARY_CHANGE_REISSUE_PARTIAL_TRANSFER_SSR,
  INVOLUNTARY_CHANGE_REISSUE_FAILED_TRANSFER_SSR,
  INVOLUNTARY_CHANGE_REISSUE_DEFAULT,
  INVOLUNTARY_CHANGE_OG_TICKET_LOOKUP_FAILURE,
  INVOLUNTARY_CHANGE_GENERATE_REQUEST_FAILURE,
  INVOLUNTARY_CHANGE_REISSUE_TIMEOUT,
  INVOLUNTARY_CHANGE_FLIGHT_AVAILABILITY_UNKNOWN_FAILURE,
  INVOLUNTARY_CHANGE_FLIGHT_AVAILABILITY_SYSTEM_FAILURE,
  INVOLUNTARY_CHANGE_FLIGHT_AVAILABILITY_TIMEOUT,
  INVOLUNTARY_CHANGE_REISSUE_FAILED_RES_LOOKUP_ASSOCIATION,
  INVOLUNTARY_CHANGE_REVAL_SUCCESS_FLIGHT_NOT_DISPLAYED,
  INVOLUNTARY_CHANGE_REVAL_SUCCESS,
  ADD_SDC_OSI_FAILURE,
  ITIN_UPDATE_INVALID_FLIGHT_NUMBER,
  ITIN_UPDATE_INVALID_ORIGIN,
  ITIN_UPDATE_INVALID_DESTINATION,
  ITIN_UPDATE_INTERNAL_SERVER_ERROR,
  ITIN_UPDATE_UNKNOWN_FAILURE,
  SDC_FLIGHT_REQUEST_FAILED,
  SDC_FEE_PRICING_UNAVAILABLE,
  REVAL_ELIGIBILITY_TYPE_NO_TICKETS,
  REVAL_ELIGIBILITY_TYPE_COUPONS_NOT_OK,
  REVAL_ELIGIBILITY_TYPE_OPERATED_BY_OA,
  REVAL_ELIGIBILITY_TYPE_CHANGE_OF_ROUTING,
  ADD_MP_INELIGIBLE,
  ADD_MP_TIMEOUT,
  ADD_MP_DOES_NOT_EXIST_FOR_AIRLINE,
  ADD_MP_DATA_EXISTS_FOR_PASSENGER,
  ADD_MP_NAME_MISMATCH,
  ADD_MP_NO_AGREEMENT,
  ADD_MP_SIMULATENOUS_CHANGES,
  REMOVE_MP_TIMEOUT,
  REMOVE_MP_NOT_FOUND_IN_CONTEXT,
  REMOVE_MP_SIMULATENOUS_CHANGES,
  SECURE_FLIGHT_INFO_NO_ITIN,
  SECURE_FLIGHT_INFO_FAILURE,
  SECURE_FLIGHT_INFO_PARTIAL_SUCCESS,
  SECURE_FLIGHT_INFO_UNKNOWN,
  SECURE_FLIGHT_INFO_TIMEOUT,
  CONTACT_TRACING_INFO_NO_ITIN,
  CONTACT_TRACING_INFO_FAILURE,
  REMOVE_CONTACT_TRACING_TAC_ERROR,
  REMOVE_CONTACT_TRACING_SIMULTANEOUS_CHANGES,
  REMOVE_CONTACT_TRACING_FULL_FAILURE,
  REMOVE_CONTACT_TRACING_PARTIAL_FAILURE,
  UNCAUGHT,
  TAC,
  COMPANION_FARE,
  SAVER_FARE_CLASS,
  OUTSIDE_CHANNELS,
  BEREAVEMENT_FARE,
  NO_ITINERARY,
  GROUP_RESERVATION,
  ONEWORLD_AIRLINE_TICKET_SOURCE,
  OTHER_AIRLINE_TICKET_SOURCE,
  COMMERCIAL_ACCOUNT,
  BULK_OPAQUE,
  FLIGHT_PASS,
  STATE_OF_ALASKA_FARE,
  GOVERNMENT_FARE,
  PARTNER_AWARD,
  ALASKA_AWARD,
  TICKET_TIME_LIMIT,
  FCAA,
  CODESHARE,
  STANDBY_RESERVATION,
  DEADHEAD,
  MINOR_IN_MINOR_SEAT,
  PREMIUM_CLASS_DOWNGRADE,
  HANDICAP,
  ADULT_IN_MINOR_SEAT,
  WINDOWLESS_WINDOW,
  NO_CONTIGUOUS,
  OTHER_AIRLINE,
  UNSUPPORTED_CARRIER,
  INSIDE_CHECK_IN_WINDOW,
  UNKNOWN_CITY_CODE,
  EXTRA_SEAT_NO_SSRS,
  EXIT_ROW_WITH_MINOR,
  CAREKIT_FAILURE,
  CAREKIT_SUCCESS,
  ADD_SSR_SUCCESS,
  ADD_SSR_PARTIAL_SUCCESS,
  ADD_SSR_FAILURE,

  //PETC & AVIH messages - start
  UNHANDLED_PETS_SSR_FAILURE,
  PETS_SSR_ADD_REMARKS_FAILED,
  PETS_SSR_FULL_CREATION_FAILURE,
  PETS_SSR_PARTIAL_CREATION_FAILURE,
  PETS_QUEUE_CALL_FAILED,
  PETS_SSR_FULL_SUCCESS,
  PETS_SSR_PARTIAL_FAILURE,
  PETS_SSR_FULL_FAILURE,
  //PETC & AVIH messages - end

  NON_REV,
  PURCHASE_PROCESSING_FAILURE,
  PURCHASE_INVALID_CARD_NUMBER_FAILURE,
  PURCHASE_INVALID_COUPONS_FAILURE,
  PURCHASE_CARD_EXPIRED_FAILURE,
  PURCHASE_DO_NOT_HONOR_FAILURE,
  PURCHASE_UNCAUGHT_FAILURE,
  PURCHASE_SYSTEM_FAILURE,
  PURCHASE_TIMEOUT,
  CBBG_EXST_LINK_FAILURE,
  GUEST_REMOVE_TIMEOUT,
  BIO_INFO_CHECK_ITIN,
  BIO_INFO_DOB_ALREADY_COLLECTED,
  BIO_INFO_INVALID_NAME_NUMBER,
  BIO_INFO_SIMULTANEOUS_CHANGES,
  BIO_INFO_TIME_LIMIT,
  BIO_INFO_GENERIC,
  BIO_INFO_REMOVE,
  MILEAGE_PLAN_DOES_NOT_EXIST,
  MILEAGE_PLAN_DATA_EXISTS,
  MILEAGE_PLAN_NAME_MISMATCH,
  MILEAGE_PLAN_NO_AGREEMENT,
  MILEAGE_PLAN_SIMULTANEOUS_CHANGES,
  MILEAGE_PLAN_SERVICE_ERROR,
  MILEAGE_PLAN_PAST_DATE_PNR,
  MILEAGE_PLAN_TKT_TIME_LIMIT,
  MILEAGE_PLAN_INVLD_TAC_DATE,
  MILEAGE_PLAN_NEED_ADDRESS,
  MILEAGE_PLAN_UPDATE_RESERVATION_PROCESSING_ERROR,
  MILEAGE_PLAN_GENERIC,
  MILEAGE_PLAN_REMOVE,
  KTN_CHECK_ITIN,
  KTN_PNR_UPDATED,
  KTN_INVALID_COUNTRY_CODE,
  KTN_INVALID_TAC_DATE,
  KTN_PNR_SAVE_TIMEOUT,
  KTN_GENERIC,
  KTN_REMOVE,
  REDRESS_CHECK_ITIN,
  REDRESS_GENERIC,
  REDRESS_REMOVE,
  INTERNATIONAL_DOCS_GENERIC,
  INTERNATIONAL_DOCS_BAD_REQUEST,
  INTERNATIONAL_DOCS_REMOVE,
  INTERNATIONAL_DOCS_COUNTRY_OF_RESIDENCE_REMOVE,
  EMERGENCY_CONTACT_GENERIC,
  EMERGENCY_CONTACT_REMOVE,
  CONTACT_TRACING_TIMEOUT,
  CONTACT_TRACING_PNR_UPDATED,
  CONTACT_TRACING_TAC_DATE,
  CONTACT_TRACING_ADD_EMAIL_GENERIC,
  CONTACT_TRACING_ADD_PHONE_GENERIC,
  CONTACT_TRACING_ADD_ADDRESS_GENERIC,
  GUEST_SAVE_TIMEOUT,
  LAP_INFANT_PARTIAL_FAILURE,
  LAP_INFANT_UNKNOWN,
  LAP_INFANT_TIMEOUT,
  LAP_INFANT_ASSOCIATED,
  LAP_INFANT_REMOVE_FAILURE,
  LAP_INFANT_REMOVE_TIMEOUT,
  LAP_INFANT_UNKNOWN_WITH_LINK,
  LAP_INFANT_REMOVE_UNKNOWN_WITH_LINK,
  AUTO_FILL_MP_TIMEOUT,
  AUTO_FILL_MP_FULL_FAILURE,
  AUTO_FILL_MP_NAME_MISMATCH,
  AUTO_FILL_MP_PARTIAL_TRANSFERED,
  AUTO_FILL_MP_NOT_FOUND,
  RESERVATION_INFO_SAVE_PARTIAL_FAILURE,
  RESERVATION_INFO_SAVE_SIMULTANEOUS_CHANGES,
  RESERVATION_INFO_SAVE_TIMEOUT,
  RESERVATION_INFO_SAVE_FAILURE_GENERIC,
  INITIAL_BOOKING_NO_PRICE_QUOTE,
  MILEAGE_PLAN_MAINTENANCE,
  FLIGHT_DETAILS_FAILURE_GENERIC,
  BAGGAGE_WEIGHT_BASED_CARRIER,
  BAGGAGE_NO_ITIN,
  BAGGAGE_GROUPS,
  BAGGAGE_GUEST_COUNT,
  BAGGAGE_GENERIC,
  TICKET_SEARCH_TIMEOUT,
  TICKET_SEARCH_UNCAUGHT,
  TICKET_SEARCH_NO_MESSAGE,
  MANUAL_ASSOCIATION_DEFAULT,
  RESERVATION_CREATION_TIMEOUT,
  RESERVATION_CREATION_SYSTEM_FAILURE,
  RESERVATION_CREATION_NOOP,
  RESERVATION_CREATION_FLIGHT_NUM_NOOP,
  RESERVATION_CREATION_INVALID_CLASS,
  RESERVATION_CREATION_INVALID_BOARD_POINT,
  ASSOCIATION_TIMEOUT,
  ASSOCIATION_BEYOND_TRAVEL_VALIDITY,
  ASSOCIATION_SYSTEM_FAILURE,
  ASSOCIATION_ADVISORY_UNUSED_TRAVEL_APPLIED_TO_FUTURE_TRAVEL,
  INELIGIBLE_NOT_ALL_COUPONS_OK_OR_USED,
  INELIGIBLE_TICKET_FLOWN_OUT_OF_COUPON_ORDER,
  INELIGIBLE_NOT_ALL_ROUTES_MATCH,
  INELIGIBLE_NOT_ALL_RESERVATIONS_INACTIVE,
  INELIGIBLE_NOT_ALL_SEGMENT_STATUS_OK,
  MILEAGE_PLAN_ENROLLMENT_TIMEOUT,
  MILEAGE_PLAN_ENROLLMENT_SUCCESS,
  MILEAGE_PLAN_ENROLLMENT_MEMBER_EXIST,
  NEWSFEED_ERROR,
  ADVISORY_ERROR,
  SEAT_UPGRADE_INVALID_COUPON_STATUS,
  SEAT_UPGRADE_TICKET_VALIDATION_ERROR,
  MP_LOOKUP_PARTIAL_FAILURE,
  MP_LOOKUP_TIMEOUT,
  MP_LOOKUP_NOT_FOUND,
  RESERVATION_LOOKUP_DOES_NOT_EXIST,
  RESERVATION_LOOKUP_TIMEOUT,
  RESERVATION_LOOKUP_FORMAT,
  TCP_OSI_PARTIAL_FAILURE,
  TCP_OSI_FULL_FAILURE,
  PAYMENT_LINK_GENERATION_FAILURE,
  DELETE_REMARK_SUCCESS,
  DELETE_REMARK_FULL_FAILURE,
  DELETE_REMARK_TIMEOUT_FAILURE,
  DELETE_REMARK_SIMULATENOUS_CHANGES_ERROR,

  ADD_REMARK_SUCCESS,
  ADD_REMARK_SIMULATENOUS_CHANGES_ERROR,
  ADD_REMARK_FULL_FAILURE,
  ADD_REMARK_TIMEOUT_FAILURE,
  ADD_RAIN_BOOKING_REMARKS_SUCCESS,
  ADD_RAIN_BOOKING_REMARKS_FAILURE,
  ADD_RAIN_BOOKING_REMARKS_SIMULATENOUS_CHANGES,
  RESHOP_FLIGHT_SEARCH_ERROR,
  RESHOP_FLIGHT_SEARCH_TIMEOUT,
  /**
   * This should be the lowest priority message, it is intended to be used as a catch all
   */
  UNKNOWN_WITH_LINK,
  // LOWEST PRIORITY MESSAGE - this will get shown last in a stack of messages
}
