import { createAction, props } from '@ngrx/store';
import { reservationSearchHistoryFeatureKey } from './reservation-search-history.state';
import { Reservation } from 'src/app/dtos/response/reservation-response/reservation';

export const addReservationToSearchHistory = createAction(
  `${reservationSearchHistoryFeatureKey} Add the loaded reservation to the search history`,
  props<{ reservation: Reservation }>()
);

export const removeReservationFromSearchHistory = createAction(
  `${reservationSearchHistoryFeatureKey} Remove the reservation from the search history`,
  (confirmationCode: string) => ({
    confirmationCode,
  })
);
