import { ActionCode } from './action-codes';

/**
 * Confirmed action codes - this list comes from the old 'unconfirmedSegments' list filtering that used to be in API
 * DO NOT USE this list to filter confirmed/unconfirmed segments, use the confirmationStatus property
 */
export const confirmedActionCodes: ReadonlyArray<ActionCode> = [
  ActionCode.HK,
  ActionCode.SC,
  ActionCode.TK,
  ActionCode.KL,
  ActionCode.LL,
  ActionCode.HL,
  ActionCode.JL,
  ActionCode.BL,
  ActionCode.WL,
];
