import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getIsLoggedIn } from '../services/login-service/state/login-service.selector';
import { LoginServiceState } from '../services/login-service/state/login-service.state';

@Injectable()
export class LoggedInUserGuard  {
  constructor(private loginServiceStore: Store<LoginServiceState>, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.loginServiceStore.select(getIsLoggedIn).pipe(
      map((isLoggedIn) => {
        // user navigates to the login component
        if (state.url.startsWith('/login')) {
          // user is logged in
          if (isLoggedIn) {
            // send user to dashboard
            this.router.navigateByUrl('/home/dashboard');
            return false;
          }
          // user is not logged in, allow access to login component
          return true;
        } else {
          // user navigates to a component
          // user is not logged in
          if (!isLoggedIn) {
            // send user to the login component
            this.router.navigateByUrl('login');
            return false;
          }
          // allow access to the component
          return true;
        }
      })
    );
  }
}
