export enum SeatCode {
  AIRPORT_CONTROL = 'Z',
  BULKHEAD = 'B',
  DISABLED = 'H',
  INOPERATIVE = 'N',
  PREFERRED = 'Q',
  PREMIUM = 'P',
  SAVER_FARE_ELIGIBLE = 'X',
  SSR_BLOCKED = 'S',
  UNACCOMPANIED_MINOR = 'L',
  UNDESIRABLE = 'U',
  INFANT_BLOCKED = 'I',
  ZONE_RESTRICTED = 'O',
}
