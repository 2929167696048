import { RootState } from '../../../state/state';
import { AddDeleteRemarkStatus } from 'src/app/dtos/response/remarks-response/add-delete-remark-status';

export const initialBookingFeatureKey = 'initialBooking';

export interface State extends RootState {
  initialBookingState: InitialBookingState;
}

export interface InitialBookingState {
  addRainBookingRemarksStatus: AddDeleteRemarkStatus;
  rainBookingRemarksAdded: boolean;
  fareRulesAgreed: boolean;
}

export const initialInitialBookingEntityState: InitialBookingState = {
  addRainBookingRemarksStatus: AddDeleteRemarkStatus.NONE,
  rainBookingRemarksAdded: false,
  fareRulesAgreed: false,
};
