import { createAction } from '@ngrx/store';
import { PurchaseResponse } from '../../../dtos/response/purchase-response/purchase-response';
import { Reservation } from '../../../dtos/response/reservation-response/reservation';
import { Segment } from '../../../dtos/response/reservation-response/segment';
import { BluefinShieldConexPayment } from '../../../models/payment-info/bluefin-payment';
import { CardOnFilePayment } from '../../../models/payment-info/card-on-file-payment';
import { PaymentInfo } from '../../../models/payment-info/payment-info';
import { PaymentType } from '../../../models/payment-info/payment-type';
import { purchaseServiceFeatureKey } from './purchase-service.state';

export const initializePurchaseServiceState = createAction(`[${purchaseServiceFeatureKey}] Initialize`);

export const purchase = createAction(
  `[${purchaseServiceFeatureKey}] Purchase`,
  (
    reservation: Reservation,
    totalDue: number,
    sabreId: string,
    paymentInfo: PaymentInfo,
    paymentType: PaymentType,
    filteredSegments?: Segment[]
  ) => ({
    reservation,
    totalDue,
    sabreId,
    paymentInfo,
    paymentType,
    filteredSegments
  })
);

export const purchaseComplete = createAction(
  `[${purchaseServiceFeatureKey}] Purchase complete`,
  (success: boolean, response: PurchaseResponse | null) => ({
    success,
    response,
  })
);

export const setMaskedCardNumber = createAction(`[${purchaseServiceFeatureKey}] Set masked card number`, (maskedCardNumber: string) => ({
  maskedCardNumber,
}));

export const setPurchaseEmail = createAction(`[${purchaseServiceFeatureKey}] Set purchase email`, (purchaseEmail: string | null) => ({
  purchaseEmail,
}));

export const setPurchaseEmailValid = createAction(`[${purchaseServiceFeatureKey}] Set purchase email validity`, (purchaseEmailValid: boolean) => ({
  purchaseEmailValid,
}));

export const setPaymentInfo = createAction(
  `[${purchaseServiceFeatureKey}] Set payment info`,
  (paymentInfo: CardOnFilePayment | BluefinShieldConexPayment | null) => ({
    paymentInfo,
  })
);
