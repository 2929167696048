import { createAction } from '@ngrx/store';
import { modelFeatureKey } from './model.state';

export const initializeModelState = createAction(`[${modelFeatureKey}] Initialize`);
export const setPaymentLink = createAction(`[${modelFeatureKey}] Set payment link'`, (paymentLink: string) => ({ paymentLink }));
export const setPaymentReceived = createAction(`[${modelFeatureKey}] Set payment received`, (paymentReceived: boolean) => ({
  paymentReceived,
}));
export const setHasReviewedSummary = createAction(`[${modelFeatureKey}] Set has reviewed summary`, (hasReviewedSummary: boolean) => ({
  hasReviewedSummary,
}));
export const setHasSavedSessionLevelChanges = createAction(
  `[${modelFeatureKey}] Set has saved session level changes`,
  (hasSavedSessionLevelChanges: boolean) => ({ hasSavedSessionLevelChanges })
);
