import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalEvent, GlobalEventService } from '../../../services/global-event-service/global-event.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit, OnDestroy {
  showSpinner = false;
  private _subscriptions: Subscription[];

  constructor(private _eventService: GlobalEventService) {
    this._subscriptions = [];
  }

  ngOnInit() {
    this._subscriptions.push(
      this._eventService.on(GlobalEvent.AJAX_START).subscribe(() => {
        this.showSpinner = true;
      })
    );
    this._subscriptions.push(
      this._eventService.on(GlobalEvent.AJAX_END).subscribe(() => {
        this.showSpinner = false;
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
