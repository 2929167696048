import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer as observableDefer, Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { PaymentLinkRequest } from '../../dtos/request/payment-link-request';
import { CardOnFilePurchaseInput } from '../../dtos/request/purchase-request/card-on-file-purchase-input';
import { PassengerSegmentsRequest } from '../../dtos/request/purchase-request/passenger-segments-request';
import { PassengerTransactionRequest } from '../../dtos/request/purchase-request/passenger-transaction-request';
import { PurchaseRequest, PurchaseRequestBluefin } from '../../dtos/request/purchase-request/purchase-request';
import { SegmentPurchase } from '../../dtos/request/purchase-request/segment-purchase';
import { TransactionSegment } from '../../dtos/request/purchase-request/transaction-segment';
import { CancelPaymentLinkResponse } from '../../dtos/response/cancel-payment-link-response';
import { PaymentLinkResponse } from '../../dtos/response/payment-link-response/payment-link-response';
import { PaymentLinkStatus } from '../../dtos/response/payment-link-response/payment-link-status';
import { PurchaseResponse } from '../../dtos/response/purchase-response/purchase-response';
import { PurchaseStatus } from '../../dtos/response/purchase-response/purchase-status';
import { Passenger } from '../../dtos/response/reservation-response/passenger';
import { PassengerSeat } from '../../dtos/response/reservation-response/passenger-seat';
import { Reservation } from '../../dtos/response/reservation-response/reservation';
import { Segment } from '../../dtos/response/reservation-response/segment';
import { CouponStatusData } from '../../dtos/response/vcr-response/coupon-status-data';
import { PaymentInfo } from '../../models/payment-info/payment-info';
import { PaymentType } from '../../models/payment-info/payment-type';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { ChangeOfGaugeUseCase } from '../../use-cases/change-of-gauge.use-case';
import { TransactionUseCase } from '../../use-cases/transaction.use-case';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';
import { CouponMapper } from '../ticket-service/coupon-mapper';

export interface PurchaseServiceAPI {
  purchase(
    reservation: Reservation,
    totalDue: number,
    sabreId: string,
    paymentInfo: PaymentInfo,
    paymentType: PaymentType,
    email: string,
    coupons: CouponStatusData[],
  ): Observable<PurchaseResponse>;
  getPaymentLink(confirmationCode: string): Observable<PaymentLinkResponse>;
  generatePaymentLink(
    reservation: Reservation,
    totalDue: number,
    sabreId: string,
    coupons: CouponStatusData[],
    filteredSegments: Segment[],
    email?: string
  ): Observable<PaymentLinkResponse>;
  cancelPaymentLink(transactionId: string): Observable<CancelPaymentLinkResponse>;
}

@Injectable()
export class PurchaseService implements PurchaseServiceAPI {
  constructor(
    private readonly http: HttpClient,
    private readonly eventService: GlobalEventService,
    private readonly transactionUseCase: TransactionUseCase,
    private readonly changeOfGaugeUseCase: ChangeOfGaugeUseCase
  ) {}

  purchase(
    reservation: Reservation,
    totalDue: number,
    sabreId: string,
    paymentInfo: PaymentInfo,
    paymentType: PaymentType,
    email: string,
    coupons: CouponStatusData[],
    filteredSegments?: Segment[],
  ): Observable<PurchaseResponse> {

    let body: PurchaseRequest;
    let url: string;

    if(paymentInfo.bluefinShieldConexPayment)
    {
      url = 'api/purchase/bluefin';
      body = paymentType === PaymentType.PREMIUM_CLASS_UPGRADE
        ? this.getPurchaseRequestBluefin(reservation, totalDue, sabreId, paymentInfo, coupons, filteredSegments)
        : this.getSdcPurchaseRequestBluefin(reservation, totalDue, sabreId, paymentInfo, coupons, filteredSegments);
    }
    else
    {
      url = 'api/purchase/cof';
      body = paymentType === PaymentType.PREMIUM_CLASS_UPGRADE
        ? this.getPurchaseRequestCardOnFile(reservation, totalDue, sabreId, paymentInfo, coupons, filteredSegments, email)
        : this.getSdcPurchaseRequestCardOnFile(reservation, totalDue, sabreId, paymentInfo, coupons, filteredSegments, email);
    }

    return this.http.post<PurchaseResponse>(url, body).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((purchaseResponse: any) => {
        return {
          status: PurchaseStatus.SUCCESS,
          ticketNumbers: purchaseResponse.ticketNumbers,
        };
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return observableOf({ status: PurchaseStatus.TIMEOUT, errorDisplayMessage: err.error.message });
        } else {
          return observableOf({ status: PurchaseStatus.SYSTEM_FAILURE, errorDisplayMessage: err.error.message });
        }
      })
    );
  }

  getPaymentLink(confirmationCode: string): Observable<PaymentLinkResponse> {
    return this.http.get<PaymentLinkResponse>(`api/paymentLink/${confirmationCode}`).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((response) => {
        return {
          status: PaymentLinkStatus.SUCCESS,
          paymentUrl: response.paymentUrl,
          expirationTime: response.expirationTime,
        };
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return observableOf({
            status: PaymentLinkStatus.TIMEOUT,
          });
        }
        if (404 === err.status) {
          return observableOf({
            status: PaymentLinkStatus.NOT_FOUND,
          });
        }
        return observableOf({
          status: PaymentLinkStatus.SYSTEM_FAILURE,
        });
      })
    );
  }

  generatePaymentLink(
    reservation: Reservation,
    totalDue: number,
    sabreId: string,
    coupons: CouponStatusData[],
    filteredSegments: Segment[],
    email?: string
  ): Observable<PaymentLinkResponse> {
    const body: PaymentLinkRequest = this.getPaymentLinkRequest(reservation, totalDue, sabreId, coupons, filteredSegments, email ?? '');
    return this.http.post<PaymentLinkResponse>('api/paymentLink', body).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((response) => {
        return {
          status: PaymentLinkStatus.SUCCESS,
          paymentUrl: response.paymentUrl,
          expirationTime: response.expirationTime,
        };
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return observableOf({ status: PaymentLinkStatus.TIMEOUT, errorDisplayMessage: err.error?.message });
        } else {
          return observableOf({ status: PaymentLinkStatus.SYSTEM_FAILURE, errorDisplayMessage: err.error?.message });
        }
      })
    );
  }

  cancelPaymentLink(transactionId: string): Observable<CancelPaymentLinkResponse> {
    return this.http.delete(`api/paymentLink/${transactionId}`).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map(() => {
        return {
          success: true,
        };
      }),
      catchError((err) => {
        let message = 'Unhandled exception.';
        if (err.error && err.error.message) {
          message = err.error.message;
        }
        return observableOf({
          success: false,
          errorDisplayMessage: message,
        });
      })
    );
  }

  protected getPurchaseRequestCardOnFile(
    reservation: Reservation,
    totalDue: number,
    sabreId: string,
    paymentInfo: PaymentInfo,
    coupons: CouponStatusData[],
    filteredSegments: Segment[],
    email: string
  ): PurchaseRequest {
    const passengersWithLineItems: PassengerSegmentsRequest = this.transactionUseCase.getPassengerSegmentPurchases(
      reservation,
      coupons,
      filteredSegments
    );

    const cardOnFilePurchaseInput: CardOnFilePurchaseInput = {
      creditCardLastFour: paymentInfo.cardOnFilePaymentInfo.creditCardLastFour,
      creditCardType: paymentInfo.cardOnFilePaymentInfo.creditCardType,
      id: paymentInfo.cardOnFilePaymentInfo.id,
      memberGuid: paymentInfo.cardOnFilePaymentInfo.memberGuid,
      mileagePlanNumber: paymentInfo.cardOnFilePaymentInfo.mileagePlanNumber,
      cardHolderName: paymentInfo.cardOnFilePaymentInfo.fullName,
    };

    const request: PurchaseRequest = {
      confirmationCode: reservation.confirmationCode,
      passengers: passengersWithLineItems.passengerTransactions,
      segments: passengersWithLineItems.segments,
      totalDue,
      sabreId,
      cardOnFilePurchaseInput,
      confirmationEmail: email !== '' ? email : null,
      paymentType: PaymentType.PREMIUM_CLASS_UPGRADE
    };

    return request;
  }

  protected getPurchaseRequestBluefin(
    reservation: Reservation,
    totalDue: number,
    sabreId: string,
    paymentInfo: PaymentInfo,
    coupons: CouponStatusData[],
    filteredSegments: Segment[]
  ): PurchaseRequest {
    const passengersWithLineItems: PassengerSegmentsRequest = this.transactionUseCase.getPassengerSegmentPurchases(
      reservation, coupons, filteredSegments
    );
    const email = paymentInfo.bluefinShieldConexPayment.emailAddress?.trim();

    const request: PurchaseRequestBluefin = {
      confirmationCode: reservation.confirmationCode,
      passengers: passengersWithLineItems.passengerTransactions,
      segments: passengersWithLineItems.segments,
      totalDue,
      sabreId,
      shieldconexToken: paymentInfo.bluefinShieldConexPayment.bluefinShieldConexToken,
      confirmationEmail: email !== '' ? email : null,
      paymentType: PaymentType.PREMIUM_CLASS_UPGRADE
    };

    return request;
  }

  protected getSdcPurchaseRequestCardOnFile(
    reservation: Reservation,
    totalDue: number,
    sabreId: string,
    paymentInfo: PaymentInfo,
    coupons: CouponStatusData[],
    filteredSegments: Segment[],
    email: string
  ): PurchaseRequest {
    const cardOnFilePurchaseInput: CardOnFilePurchaseInput = {
      creditCardLastFour: paymentInfo.cardOnFilePaymentInfo.creditCardLastFour,
      creditCardType: paymentInfo.cardOnFilePaymentInfo.creditCardType,
      id: paymentInfo.cardOnFilePaymentInfo.id,
      memberGuid: paymentInfo.cardOnFilePaymentInfo.memberGuid,
      mileagePlanNumber: paymentInfo.cardOnFilePaymentInfo.mileagePlanNumber,
      cardHolderName: paymentInfo.cardOnFilePaymentInfo.fullName,
    };

    const request: PurchaseRequest = {
      confirmationCode: reservation.confirmationCode,
      passengers: this.getSdcPurchaseRequestPassengers(reservation, totalDue, coupons, filteredSegments),
      segments: this.getSdcPurchaseRequestSegments(filteredSegments),
      totalDue,
      sabreId,
      cardOnFilePurchaseInput,
      confirmationEmail: email !== '' ? email : null,
      paymentType: PaymentType.SAME_DAY_CONFIRM
    };

    return request;
  }

  protected getSdcPurchaseRequestBluefin(
    reservation: Reservation,
    totalDue: number,
    sabreId: string,
    paymentInfo: PaymentInfo,
    coupons: CouponStatusData[],
    filteredSegments: Segment[]
  ): PurchaseRequest {
    const email = paymentInfo.bluefinShieldConexPayment.emailAddress?.trim();

    const request: PurchaseRequestBluefin = {
      confirmationCode: reservation.confirmationCode,
      passengers: this.getSdcPurchaseRequestPassengers(reservation, totalDue, coupons, filteredSegments),
      segments: this.getSdcPurchaseRequestSegments(filteredSegments),
      totalDue,
      sabreId,
      shieldconexToken: paymentInfo.bluefinShieldConexPayment.bluefinShieldConexToken,
      confirmationEmail: email !== '' ? email : null,
      paymentType: PaymentType.SAME_DAY_CONFIRM
    };

    return request;
  }

  private getSdcPurchaseRequestPassengers(
    reservation: Reservation,
    totalDue: number,
    coupons: CouponStatusData[],
    filteredSegments: Segment[]
  ): PassengerTransactionRequest[] {
    const passengers: PassengerTransactionRequest[] = [];
    reservation.passengers.forEach((passenger) => {
      passengers.push({
        firstName: passenger.firstName,
        id: passenger.id,
        lastName: passenger.lastName,
        loyalty: passenger.loyalty ?? null,
        segmentPurchases: this.getSdcSegmentPurchasesForPassenger(reservation, passenger, totalDue, coupons, filteredSegments),
        segmentWaivers: [],
      });
    });
    return passengers;
  }

  private getSdcPurchaseRequestSegments(filteredSegments: Segment[]): TransactionSegment[] {
    const segments: TransactionSegment[] = [];
    if (filteredSegments) {
      filteredSegments.forEach((segment) => {
        segments.push({
          actionCode: segment.actionCode,
          carrierCode: segment.marketedByAirlineCode,
          flightNumber: segment.operatingAirlineFlightNumber,
          arrivalAirport: segment.arrivalAirport,
          departureAirport: segment.departureAirport,
          departureDate: segment.departureDateTime.split('T')[0],
          serviceClassCode: segment.serviceClassCode,
        });
      });
    }
    return segments;
  }

  private getSdcSegmentPurchasesForPassenger(
    reservation: Reservation,
    passenger: Passenger,
    totalDue: number,
    coupons: CouponStatusData[],
    filteredSegments: Segment[]
  ): SegmentPurchase[] {
    const segmentPurchases: SegmentPurchase[] = [];
    const passengerSeat: PassengerSeat = passenger.seats[0] ?? null;
    const actualSegmentIndex = this.changeOfGaugeUseCase.getRealSegmentIndex(filteredSegments, 0);
    const passengerCoupon = CouponMapper.getPassengerCoupon(passenger, filteredSegments[actualSegmentIndex], reservation, coupons);
    segmentPurchases.push({
      couponNumber: passengerCoupon.couponNumber,
      pricingInfo: {
        basePrice: totalDue / reservation.passengers.length,
        totalPrice: totalDue / reservation.passengers.length,
        taxes: [],
      },
      seatLocation: passengerSeat?.row && passengerSeat?.letter ? `${passengerSeat.row}${passengerSeat.letter}` : '',
      segmentRefIndex: actualSegmentIndex,
      ticketNumber: passengerCoupon.ticketNumber,
    });
    return segmentPurchases;
  }

  private getPaymentLinkRequest(
    reservation: Reservation,
    totalDue: number,
    sabreId: string,
    coupons: CouponStatusData[],
    filteredSegments: Segment[],
    email?: string
  ): PaymentLinkRequest {
    const passengersWithLineItems: PassengerSegmentsRequest = this.transactionUseCase.getPassengerSegmentPurchases(
      reservation,
      coupons,
      filteredSegments
    );
    // Filter out VOID tickets since they cannot have EMDs attached
    const ticketNumbers = reservation.ticketNumbers?.filter((ticket) => !ticket.isVoid)?.map((ticket) => ticket.ticketNumber) ?? [];

    // Build payment link request
    const request: PaymentLinkRequest = {
      confirmationCode: reservation.confirmationCode,
      passengers: passengersWithLineItems.passengerTransactions,
      segments: passengersWithLineItems.segments,
      ticketNumbers,
      totalDue,
      email,
      sabreId,
    };

    return request;
  }
}
