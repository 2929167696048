import { MessageStatus } from 'src/app/models/message/message-status';
import { RichMessage } from 'src/app/models/message/rich-message';
import { RootState } from 'src/app/state/state';

export const messageStateFeatureKey = 'messageService';

export interface State extends RootState {
  messageService: MessageState;
}

export interface MessageState {
  // Legacy Styles
  globalMessages: RichMessage[];
  stackedLongMessages: RichMessage[];
  shortMessages: RichMessage[];
  longMessage: RichMessage | null;
  // JetStream Styles
  globalJetStreamMessages: RichMessage[];
  jetStreamMessages: RichMessage[];
  jetStreamShortMessages: RichMessage[];
  // Helpers
  currentGlobalMessageIndex: number;
  currentLongMessageIndex: number;
  currentShortMessageIndex: number;
  status: MessageStatus | null;
  error: MessageStatus | null;
}

export const initialMessageState: MessageState = {
  globalMessages: [],
  stackedLongMessages: [],
  shortMessages: [],
  globalJetStreamMessages: [],
  jetStreamMessages: [],
  jetStreamShortMessages: [],
  longMessage: null,
  currentGlobalMessageIndex: 0,
  currentLongMessageIndex: 0,
  currentShortMessageIndex: 0,
  status: MessageStatus.STABLE,
  error: null,
};
