import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { FlightReshopService } from '../flight-reshop.service';
import { loadFlightReshopResults, loadFlightReshopResultsComplete } from './flight-reshop-service.actions';
import { getFlightSearchRequest } from '../../../features/voluntary-change/state/voluntary-change.selectors';
import { Store } from '@ngrx/store';
import { RootState } from '../../../state/state';

@Injectable()
export class FlightReshopServiceEffects {
  constructor(private store: Store<RootState>, private actions$: Actions, private service: FlightReshopService) {}

  loadFlightReshopResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFlightReshopResults),
      withLatestFrom(this.store.select(getFlightSearchRequest)),
      mergeMap(([action, request]) =>
        this.service
          .getFlightReshopResults(request)
          .pipe(map((result) => loadFlightReshopResultsComplete(result.reshopResponse, result.error)))
      ),
      catchError((err) => of(loadFlightReshopResultsComplete(null, err.error?.toString())))
    )
  );
}
