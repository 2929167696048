import { Injectable } from '@angular/core';
import { PhoneFieldResponse } from '../../../dtos/response/phone-field-response/phone-field-response';
import { MessageKey } from '../../../models/message/message-key';

@Injectable({
  providedIn: 'root',
})
export class PhoneFieldMessageConverter {
  /**
   * Map message keys to the phone field response based on error conditions
   * @param phoneFieldResponse The response from the phone field save
   * @returns An updated phoneFieldResponse with mapped message keys
   */
  convertSavePhoneFieldMessage(phoneFieldResponse: PhoneFieldResponse): PhoneFieldResponse {
    let mappedMessageKeys: MessageKey[] = [];

    if (!phoneFieldResponse) {
      mappedMessageKeys.push(MessageKey.UNKNOWN_WITH_LINK);
    } else {
      // Timeout
      if (phoneFieldResponse.exceptionContent?.toLowerCase().includes('timeout')) {
        mappedMessageKeys.push(MessageKey.RESERVATION_INFO_SAVE_TIMEOUT);
      }
      // Password Decryption Error
      if (phoneFieldResponse.exceptionContent?.toLowerCase().includes('password decryption error')) {
        mappedMessageKeys.push(MessageKey.PASSWORD_DECRYPTION_ERROR);
      }
      // Partial failure
      // In the case of a partial failure we will map this and the message keys specific to the errors
      if (
        phoneFieldResponse.response?.failedList &&
        phoneFieldResponse.response.failedList.length > 0 &&
        phoneFieldResponse.response?.successList &&
        phoneFieldResponse.response.successList.length > 0
      ) {
        mappedMessageKeys.push(MessageKey.RESERVATION_INFO_SAVE_PARTIAL_FAILURE);
      }
      // Mapped errors - specific to the error
      if (phoneFieldResponse.response?.failedList && phoneFieldResponse.response?.failedList?.length > 0) {
        phoneFieldResponse.response.failedList.forEach((failure) => {
          mappedMessageKeys = this.convertSavePhoneFieldErrorMessages(failure.failureMessage, mappedMessageKeys);
        });
      }
      // If we've identified an error and made it this far without mapping a key, we'll map the unknown error key.
      if (phoneFieldResponse.statusCode !== 200 && phoneFieldResponse.statusCode !== 207 && mappedMessageKeys?.length === 0) {
        mappedMessageKeys?.push(MessageKey.RESERVATION_INFO_SAVE_FAILURE_GENERIC);
      }
    }

    phoneFieldResponse = {
      ...phoneFieldResponse,
      mappedErrorKeys: mappedMessageKeys,
    };

    return phoneFieldResponse;
  }

  convertSavePhoneFieldErrorMessages(failureMessage: string, mappedMessageKeys: MessageKey[]): MessageKey[] {
    if (failureMessage) {
      const failerMessageLower = failureMessage?.toLowerCase();

      if (failerMessageLower.includes('simultaneous changes')) {
        mappedMessageKeys.push(MessageKey.RESERVATION_INFO_SAVE_SIMULTANEOUS_CHANGES);
      }
    }
    return mappedMessageKeys;
  }
}
