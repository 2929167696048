import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { RootState } from '../../../state/state';
import { WaiverService } from '../waiver.service';
import { logSdcAutoWaiver, logSdcAutoWaiverComplete } from './waiver-service.actions';
import { getLogSdcAutoWaiverRequest } from './waiver-service.selectors';

@Injectable()
export class WaiverServiceEffects {
  constructor(private store: Store<RootState>, private actions$: Actions, private waiverService: WaiverService) {}

  logSdcAutoWaiver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logSdcAutoWaiver),
      mergeMap((action) =>
        observableOf(action).pipe(
          withLatestFrom(
            this.store.select(getLogSdcAutoWaiverRequest( action.waiverReason, action.originalAmount ))
          )
        )
      ),
      mergeMap(([action, request]) => {
        this.waiverService.logSDCAutoWaiver(request);
        return observableOf(logSdcAutoWaiverComplete());
      })
    )
  );
}
