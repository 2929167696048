import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, Observable, throwError as observableThrowError } from 'rxjs';
import { map, take, timeout } from 'rxjs/operators';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { SecureFlightInfoResponse } from '../../dtos/response/secure-flight-info/secure-flight-info-response';
import { Store } from '@ngrx/store';
import { RootState } from '../../state/state';
import { NavigationStart, Router } from '@angular/router';
import { ContactTracingEmailRequest } from '../../dtos/request/contact-tracing-request/contact-tracing-email-request';
import { ContactTracingPhonesRequest } from '../../dtos/request/contact-tracing-request/contact-tracing-phones-request';
import { loadReservation } from '../reservation-service/state/reservation-service.actions';
import { ContactTracingAddressRequest } from '../../dtos/request/contact-tracing-request/contact-tracing-address-request';

export interface ContactTracingServiceAPI {
  addContactTracingEmail(
    request: ContactTracingEmailRequest,
    confirmationCode: string,
    sabreUsername: string
  ): Observable<SecureFlightInfoResponse>;
  addContactTracingPhones(
    request: ContactTracingPhonesRequest,
    confirmationCode: string,
    sabreUsername: string
  ): Observable<SecureFlightInfoResponse>;
  addContactTracingDestinationAddress(
    request: ContactTracingAddressRequest,
    confirmationCode: string,
    sabreUsername: string
  ): Observable<SecureFlightInfoResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class ContactTracingService implements ContactTracingServiceAPI {
  constructor(private store: Store<RootState>, private router: Router, private http: HttpClient) {}

  /**
   * Contact Tracing - add email
   */
  addContactTracingEmail(
    request: ContactTracingEmailRequest,
    confirmationCode: string
  ): Observable<SecureFlightInfoResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };

    return this.http.post<SecureFlightInfoResponse>(`api/reservation/${confirmationCode}/secure-flight-info/email`, request, options).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((response) => {
        return response;
      })
    );
  }

  /**
   * Contact Tracing - add primary and secondary phone numbers
   */
  addContactTracingPhones(
    request: ContactTracingPhonesRequest,
    confirmationCode: string
  ): Observable<SecureFlightInfoResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };

    return this.http.post<SecureFlightInfoResponse>(`api/reservation/${confirmationCode}/secure-flight-info/phone`, request, options).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((response) => {
        return response;
      })
    );
  }

  /**
   * Contact Tracing - add destination address
   */
   addContactTracingDestinationAddress(
    request: ContactTracingAddressRequest,
    confirmationCode: string
  ): Observable<SecureFlightInfoResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };

    return this.http
      .post<SecureFlightInfoResponse>(`api/reservation/${confirmationCode}/secure-flight-info/address`, request, options)
      .pipe(
        timeout({
          each: TimeoutLimit.LONG,
          with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
        }),
        map((response) => {
          return response;
        })
      );
  }

  // Extracted this method to the service so that we can manipulate this step in the mock service.
  // We need to be able to manipulate it so that the green check can display in the integration tests.
  refreshReservation(confirmationCode: string): void {
    // We want to refresh the reservation when there is a route change (such as when we change tabs).
    // This will give the user a better experience than after each save.
    this.router.events.pipe(take(1)).subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.store.dispatch(loadReservation(confirmationCode));
      }
    });
  }
}
