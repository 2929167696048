import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer as observableDefer, Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { LoginAttemptStatus } from '../../dtos/response/login-response/login-attempt-status';
import { UserData } from '../../dtos/response/login-response/user-data';
import { LoginResponse } from '../../dtos/response/login-response/login-response';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';

export interface LoginServiceAPI {
  login(username: string, password: string): Observable<LoginResponse>;
}

@Injectable()
export class LoginService {
  constructor(private http: HttpClient, protected eventService: GlobalEventService) {}

  login(username: string, password: string): Observable<LoginResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    return this.http.post<UserData>('api/login', { username, password }, options).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((user: UserData) => {
        return { user, status: LoginAttemptStatus.SUCCESS };
      }),
      catchError((err) => {
        const result: LoginResponse = { user: null, status: LoginAttemptStatus.SYSTEM_FAILURE };
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          result.status = LoginAttemptStatus.TIMEOUT;
        }
        if (401 === err.status) {
          result.status = LoginAttemptStatus.BAD_CREDENTIALS;
        }
        if (403 === err.status) {
          result.status = LoginAttemptStatus.INSUFFICIENT_PRIVILEGES;
        }
        return observableOf(result);
      })
    );
  }
}
