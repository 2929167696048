import { AbstractControl as UntypedFormControl, ValidatorFn } from '@angular/forms';

export function patternWithMessage(pattern: RegExp, message: string): ValidatorFn {
  return (control: UntypedFormControl) => {
    if (control.value && !control.value.match(pattern)) {
      return {
        patternErrorMessage: message,
      };
    } else {
      return null;
    }
  };
}
