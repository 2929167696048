import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-passenger-alaska-bank-card',
  templateUrl: './passenger-alaska-bank-card.component.html',
  styleUrls: ['./passenger-alaska-bank-card.component.scss'],
})
export class PassengerAlaskaBankCardComponent {
  @Input() isAlaskaBankCard: boolean;
}
