import { Action, createReducer, on } from '@ngrx/store';
import { enrollMileagePlanMember,
    enrollMileagePlanMemberFailure,
    enrollMileagePlanMemberSuccess,
    resetContactInformationForAnotherGuest,
    resetMileagePlanEnrollmentResult,
    setContactInformationForAnotherGuest,
    setEnrolledMileagePlanData,
    setShareContactInfoToEnrollAnotherGuest
} from './mileage-plan-enrollment-service.actions';
import { initialMileagePlanEnrollmentServiceState } from './mileage-plan-enrollment-service.state';
import { Status } from 'src/app/models/status';
import { MileagePlanEnrollmentResult } from 'src/app/dtos/response/mileage-plan-response/mileage-plan-enrollment-result';

const featureReducer = createReducer(
    initialMileagePlanEnrollmentServiceState,
    on(enrollMileagePlanMember, (state, {mileagePlanEnrollmentRequest}) => ({
        ...state,
        mileagePlanEnrollmentStatus: Status.LOADING,
    })),
    on(enrollMileagePlanMemberSuccess, (state, {mileagePlanEnrollmentResult}) => ({
        ...state,
        mileagePlanEnrollmentStatus: Status.STABLE,
        mileagePlanEnrollmentResult,
        memberNumber: mileagePlanEnrollmentResult?.result?.mileagePlanNumber,
    })),
    on(enrollMileagePlanMemberFailure, (state, {mileagePlanEnrollmentResult}) => ({
        ...state,
        mileagePlanEnrollmentStatus: Status.FAILED,
        mileagePlanEnrollmentResult
    })),
    on(setEnrolledMileagePlanData, (state, {mileagePlanData}) => ({
        ...state,
        mileagePlanData
    })),
    on(setShareContactInfoToEnrollAnotherGuest, (state, {shareContactInfoToEnrollAnotherGuest}) => ({
        ...state,
        contactInfoToEnrollAnotherGuest: {
            ...state.contactInfoToEnrollAnotherGuest,
            shareContactInfoToEnrollAnotherGuest
        }
    })),
    on(setContactInformationForAnotherGuest, (state, {contactInfoToEnrollAnotherGuest}) => ({
        ...state,
        contactInfoToEnrollAnotherGuest
    })),
    on(resetMileagePlanEnrollmentResult, (state) => ({
        ...state,
        memberNumber: null as unknown as string,
        mileagePlanEnrollmentStatus: Status.STABLE,
        mileagePlanEnrollmentResult: null as unknown as MileagePlanEnrollmentResult
    })),
    on(resetContactInformationForAnotherGuest, (state) => ({
        ...state,
        contactInfoToEnrollAnotherGuest: initialMileagePlanEnrollmentServiceState.contactInfoToEnrollAnotherGuest
    }))
);

export function mileagePlanEnrollmentServiceReducer(state = initialMileagePlanEnrollmentServiceState, action: Action) {
    return featureReducer(state, action);
}
