import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, Observable, of, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { FeeRequest } from '../../dtos/request/fee-request';
import { BagsAndAncillariesDetail } from '../../dtos/response/bags-and-ancillaries-response/bags-and-ancillaries-detail';
import { BagsAndAncillariesResponse } from '../../dtos/response/bags-and-ancillaries-response/bags-and-ancillaries-response';
import { FeeDetail } from '../../dtos/response/fee-response/fee-detail';
import { FeeResponse } from '../../dtos/response/fee-response/fee-response';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';

export interface FeeServiceAPI {
  getFees(request: FeeRequest): Observable<FeeResponse>;
}
/**
 * Injectable service to interface with fee endpoint in RAIN API
 */
@Injectable({
  providedIn: 'root',
})
export class FeeService implements FeeServiceAPI {
  constructor(private http: HttpClient) {}

  getFees(request: FeeRequest): Observable<FeeResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    return this.http
      .get<FeeDetail>(
        `api/fee/${request.confirmationCode}/${request.departureCity}/${request.passengerNameNumbers}/${request.flightDate}`,
        options
      )
      .pipe(
        timeout({
          each: TimeoutLimit.MEDIUM,
          with: () => defer(() => throwError(() => new HttpErrorResponse(timeoutError)))
        }),
        map((response) => ({ success: true, response })),
        catchError((err: HttpErrorResponse) => {
          if (timeoutError.statusText === err.statusText) {
            return of({ success: false, error: 'Timeout' });
          } else if (err.status === 500) {
            return of({ success: false, error: 'System failure' });
          } else {
            return of({ success: false, error: 'Uncaught' });
          }
        })
      );
  }

  getAllBagsAndAncillaryFees(request: FeeRequest): Observable<BagsAndAncillariesResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    return this.http
      .get<BagsAndAncillariesDetail>(
        `api/fee/bags-ancillaries/${request.confirmationCode}/${request.departureCity}/${request.passengerNameNumbers}/${request.flightDate}`,
        options
      )
      .pipe(
        timeout({
          each: TimeoutLimit.MEDIUM,
          with: () => defer(() => throwError(() => new HttpErrorResponse(timeoutError))),
        }),
        map((response) => ({ success: true, response })),
        catchError((err: HttpErrorResponse) => {
          const mappedError = err?.error?.TechnicalErrorMessage;
          if (timeoutError.statusText === err.statusText) {
            return of({ success: false, error: 'Timeout' });
          } else if (err.status === 500) {
            return of({ success: false, error: mappedError ?? 'System failure' });
          } else {
            return of({ success: false, error: mappedError ?? 'Uncaught' });
          }
        })
      );
  }
}
