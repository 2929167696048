import { AddOsiStatus } from 'src/app/dtos/response/osi-response/add-osi-status';
import { TcpDetail } from 'src/app/dtos/response/osi-response/tcp-detail';
import { Status } from 'src/app/models/status';
import { RootState } from 'src/app/state/state';

/**
 * The string name of the osi service "slice" of state
 */
export const osiServiceFeatureKey = 'osiService';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  osiService: OsiServiceState;
}

/**
 * The state that is used in relation to osi service, it is an extension of root state
 * these values are available in and should be accessed from the root state model
 */
export interface OsiServiceState {
  addOsiStatus: Status;
  addedOsiStatus?: AddOsiStatus;
  addOsiFailure?: TcpDetail[];
  addOsiSuccess?: TcpDetail[];
}

/**
 * Initial state of the osi service slice of state,
 * used when osi service state is bootstrapped via the reducer in an app module
 * also used when state is reset
 */
export const initialOsiServiceState: OsiServiceState = {
  addOsiStatus: Status.STABLE,
  addOsiSuccess: [],
  addOsiFailure: [],
};
