import { createAction } from '@ngrx/store';
import { ContactInfoToEnrollAnotherGuest } from 'src/app/models/mileage-plan-enrollment/contact-info-to-enroll-another-guest';
import { mileagePlanEnrollmentServiceFeatureKey } from './mileage-plan-enrollment-service.state';
import { MileagePlanEnrollmentRequest } from 'src/app/dtos/request/mileage-plan-enrollment-request';
import { MileagePlanEnrollmentResult } from 'src/app/dtos/response/mileage-plan-response/mileage-plan-enrollment-result';

export const initializeMileagePlanEnrollmentServiceState = createAction(`[${mileagePlanEnrollmentServiceFeatureKey}] Initialize`);

export const enrollMileagePlanMember = createAction(
    `[${mileagePlanEnrollmentServiceFeatureKey}] Mileage plan enrollment enroll member`,
    (mileagePlanEnrollmentRequest: MileagePlanEnrollmentRequest) => ({
        mileagePlanEnrollmentRequest
    })
);

export const enrollMileagePlanMemberSuccess = createAction(
    `[${mileagePlanEnrollmentServiceFeatureKey}] Mileage plan enrollment enroll member success`,
    (mileagePlanEnrollmentResult: MileagePlanEnrollmentResult) => ({
        mileagePlanEnrollmentResult
    })
);

export const enrollMileagePlanMemberFailure = createAction(
    `[${mileagePlanEnrollmentServiceFeatureKey}] Mileage plan enrollment enroll member failure`,
    (mileagePlanEnrollmentResult: MileagePlanEnrollmentResult) => ({
        mileagePlanEnrollmentResult
    })
);

export const setEnrolledMileagePlanData = createAction(
    `[${mileagePlanEnrollmentServiceFeatureKey}] Mileage plan enrollment set enrolled Mileage Plan data`,
    (mileagePlanData: MileagePlanEnrollmentRequest) => ({
        mileagePlanData
    })
);

export const setShareContactInfoToEnrollAnotherGuest = createAction(
    `[${mileagePlanEnrollmentServiceFeatureKey}] Mileage plan enrollment share contact info to enroll another guest`,
    (shareContactInfoToEnrollAnotherGuest: boolean) => ({
        shareContactInfoToEnrollAnotherGuest
    })
);

export const setContactInformationForAnotherGuest = createAction(
    `[${mileagePlanEnrollmentServiceFeatureKey}] Mileage plan enrollment contact information to enroll another guest`,
    (contactInfoToEnrollAnotherGuest: ContactInfoToEnrollAnotherGuest) => ({
        contactInfoToEnrollAnotherGuest
    })
);

export const resetMileagePlanEnrollmentResult = createAction(
    `[${mileagePlanEnrollmentServiceFeatureKey}] Mileage plan enrollment reset result`
);

export const resetContactInformationForAnotherGuest = createAction(
    `[${mileagePlanEnrollmentServiceFeatureKey}] Mileage plan enrollment contact information to reset`
);
