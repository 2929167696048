export const MainCabin = [
  'Y',
  'B',
  'H',
  'K',
  'M',
  'L',
  'V',
  'S',
  'N',
  'Q',
  'O',
  'G',
  'X',
  'T',
];
