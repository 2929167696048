import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer as observableDefer, Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { AssignSeatsRequest } from '../../dtos/request/assign-seats/assign-seats-request';
import { SeatMapLookupStatus } from '../../dtos/response/seat-map-lookup-response/seat-map-lookup-status';
import { AssignSeatsStatus } from '../../dtos/response/seats-save-status';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';
import { SeatMapLookupRequest } from '../../dtos/request/seat-map-lookup-request/seat-map-lookup-request';
import { SeatMapDetails } from '../../dtos/response/seat-map-lookup-response/seat-map-details';
import { SeatMapLookupResponse } from '../../dtos/response/seat-map-lookup-response/seat-map-lookup-response';
import { SeatMapUtil } from '../../utils/seat-map/seat-map-util';
import { SeatMapLookupRequestV2 } from 'src/app/dtos/request/seat-map-lookup-request/seat-map-lookup-request-v2';

export interface SeatsServiceAPI {
  assignSeats(confirmationCode: string, request: AssignSeatsRequest): Observable<AssignSeatsStatus>;
  seatMapLookup(request: SeatMapLookupRequest, background?: boolean): Observable<SeatMapLookupResponse>;
}

@Injectable()
export class SeatsService implements SeatsServiceAPI {
  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private eventService: GlobalEventService,

    private seatMapUtil: SeatMapUtil
  ) {}

  assignSeats(confirmationCode: string, request: AssignSeatsRequest): Observable<AssignSeatsStatus> {
    return this.http.put<any>(`api/seats/${confirmationCode}`, request).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map(() => {
        return AssignSeatsStatus.SUCCESS;
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return observableOf(AssignSeatsStatus.TIMEOUT);
        }
        if (400 === err.status && err.error && err.error.errorDetails) {
          const errorCode = err.error.errorDetails.errorCode;
          let status: AssignSeatsStatus;
          switch (errorCode) {
            case 'PasswordDecryptionError':
              status = AssignSeatsStatus.PASSWORD_DECRYPTION_ERROR;
              break;
            case 'AirportCheckInWindow':
              status = AssignSeatsStatus.AIRPORT_CHECK_IN_WINDOW;
              break;
            case 'SegmentOutOfOrder':
              status = AssignSeatsStatus.SEGMENT_OUT_OF_ORDER;
              break;
            default:
              status = AssignSeatsStatus.SYSTEM_FAILURE;
              break;
          }
          return observableOf(status);
        }
        return observableOf(AssignSeatsStatus.SYSTEM_FAILURE);
      })
    );
  }

  /**
   * Performs a seat map lookup for the given flight, can be attached to a reservation or not
   */
  seatMapLookup(request: SeatMapLookupRequest, background = true): Observable<SeatMapLookupResponse> {
    const options = this.buildSeatMapLookupOptions(request, background);
    return this.http.get<SeatMapDetails>(`api/seats/${request?.carrierCode}/${request?.flightNumber}`, options).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => {
        return { response, status: SeatMapLookupStatus.SUCCESS, success: true };
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = err?.error?.errorDisplayMessage;
        // Request timeout occurred
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return observableOf({ status: SeatMapLookupStatus.TIMEOUT, errorMessage, success: false });
        }
        // 404 returned, flight seat map could not be found by the downstream service
        else if (404 === err.status) {
          return observableOf({ status: SeatMapLookupStatus.DOES_NOT_EXIST, errorMessage, success: false });
        }

        // All other errors
        const errorStatus = this.seatMapUtil.mapSeatMapLookupErrorStatus(err?.error?.errorCode);

        if (errorStatus === SeatMapLookupStatus.INVALID_DATES) {
          if (errorMessage.endsWith(' and 2 days in the past.')) {
            errorMessage = errorMessage.substring(0, errorMessage.length - 24);
          }
        }

        return observableOf({ status: errorStatus, errorMessage, success: false });
      })
    );
  }

  /**
   * Performs a seat map lookup using v2 endpoint for the given flight, can be attached to a reservation or not
   */
  seatMapLookupV2(request: SeatMapLookupRequestV2, background = true): Observable<SeatMapLookupResponse> {
    return this.http.post<SeatMapDetails>('api/seats/lookup', request).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => {
        return { response, status: SeatMapLookupStatus.SUCCESS, success: true };
      }),
      catchError((err: HttpErrorResponse) => {
        const errorMessage = err?.error?.errorDisplayMessage;
        // Request timeout occurred
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return observableOf({ status: SeatMapLookupStatus.TIMEOUT, errorMessage, success: false });
        }
        // 404 returned, flight seat map could not be found by the downstream service
        else if (404 === err.status) {
          return observableOf({ status: SeatMapLookupStatus.DOES_NOT_EXIST, errorMessage, success: false });
        }

        return observableOf({ status: err.status, errorMessage, success: false });
      })
    );
  }



  /**
   * Builds the options for the seat map lookup
   */
  private buildSeatMapLookupOptions(request: SeatMapLookupRequest, background: boolean) {
    const departureDateTime = this.datePipe.transform(request?.departureDate, 'yyyy-MM-dd HH:mm') || '';
    const options = {
      params: new HttpParams()
        .set('origin', request?.origin)
        .set('destination', request?.destination)
        .set('departureDate', departureDateTime)
        .set('fareClass', request?.fareClass),
      headers: new HttpHeaders({
        background: background.toString(),
      }),
    };

    if (request) {
      // Add any ssrs to the request
      if (request.ssrs && request.ssrs?.length > 0) {
        request.ssrs.forEach((ssr) => {
          options.params = options.params.append('ssr', ssr);
        });
      }

      // Add any ticket numbers to the request
      if (request.ticketNumbers && request.ticketNumbers?.length > 0) {
        const tickets = request.ticketNumbers.join(',');
        options.params = options.params.append('ticketNumber', tickets);
      }

      // Add any tier statuses to the request
      if (request.tierStatuses && request.tierStatuses?.length > 0) {
        request.tierStatuses.forEach((tierStatus) => {
          options.params = options.params.append('tierStatus', tierStatus);
        });
      }
    }
    return options;
  }
}
