import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, timeout } from 'rxjs/operators';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { defer as observableDefer, Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { NewsFeedResponse } from 'src/app/dtos/response/news-feed-response/news-feed-response';
import { NewsFeedLookupStatus } from 'src/app/dtos/response/news-feed-response/news-feed-lookup-status';
import { News } from 'src/app/dtos/response/news-feed-response/news';

export interface NewsFeedServiceAPI {
  getNewsFeed(): Observable<NewsFeedResponse>;
}

@Injectable()
export class NewsFeedService implements NewsFeedServiceAPI {
  constructor(private http: HttpClient) {}

  getNewsFeed(): Observable<NewsFeedResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    return this.http.get<News[]>(`api/kontent/news-feed`, options).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((newsList) => {
        return { newsList, lookupStatus: NewsFeedLookupStatus.SUCCESS };
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          return observableOf({ newsList: null, lookupStatus: NewsFeedLookupStatus.TIMEOUT });
        }
        if (404 === err.status) {
          return observableOf({ newsList: null, lookupStatus: NewsFeedLookupStatus.DOES_NOT_EXIST });
        }
        return observableOf({ newsList: null, lookupStatus: NewsFeedLookupStatus.SYSTEM_FAILURE });
      })
    );
  }
}
