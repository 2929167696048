import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MileagePlanProfileService } from '../../mileage-plan-profile-service/mileage-plan-profile.service';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/state/state';
import { Injectable } from '@angular/core';
import { mileagePlanAutoFillProfileSearch, mileagePlanAutoFillProfileSearchComplete } from './mileage-plan-auto-fill-service.actions';
import { map, catchError, of, exhaustMap } from 'rxjs';
import { MileagePlanProfileLookupStatus } from 'src/app/dtos/response/mileage-plan-profile-lookup-response/mileage-plan-profile-lookup-status';
import { MileagePlanMessageConverter } from 'src/app/utils/message-converters/mileage-plan/mileage-plan-message-converter';
import { addMultipleJetStreamMessagesNoDuplicate } from '../../message-service/state/message.actions';
import { MessageKey } from 'src/app/models/message/message-key';

@Injectable()
export class MileagePlanAutoFillServiceEffects {
  constructor(
    private actions$: Actions,
    private mileagePlanProfileService: MileagePlanProfileService,
    private mileagePlanMessageConverter: MileagePlanMessageConverter,
    private store: Store<RootState>
  ) { }

  mileagePlanAutoFillProfileSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mileagePlanAutoFillProfileSearch),
      exhaustMap((action) => {
        const backgroundLookup = !action.autoFillOnLookup;
        return this.mileagePlanProfileService.mileagePlanProfileLookup(action.mileagePlanNumber, backgroundLookup).pipe(
          map((result) => {
            // We only care about accountLookupResponse when auto filling, that's all the needed data comes from
            // Convert error messages
            if(action.autoFillOnLookup) {
              const messageKeys = this.mileagePlanMessageConverter.convertMileagePlanLookupMessage(result, true);
              this.store.dispatch(
                addMultipleJetStreamMessagesNoDuplicate(
                  messageKeys ?? [], undefined, { passengerId: action.passengerId }
                )
              );
            }

            return mileagePlanAutoFillProfileSearchComplete(result, action.passengerHashId, action.autoFillOnLookup);
          }),
          catchError(() => {
            if(action.autoFillOnLookup) {
              this.store.dispatch(
                addMultipleJetStreamMessagesNoDuplicate(
                  [MessageKey.AUTO_FILL_MP_FULL_FAILURE], undefined, { passengerId: action.passengerId }
                )
              );
            }
            return of(
              mileagePlanAutoFillProfileSearchComplete(
                {status: MileagePlanProfileLookupStatus.UNCAUGHT},
                action.passengerHashId,
                action.autoFillOnLookup
              ));
          })
        );
      })
    )
  );
}
