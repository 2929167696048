import { createAction } from '@ngrx/store';
import { PurchaseStatus } from '../../../dtos/response/purchase-response/purchase-status';
import { LineItemCharge } from '../../../models/line-item-charge/line-item-charge';
import { Waiver } from '../../../models/waiver/waiver';
import { purchaseFeatureKey } from './purchase.state';

/**
 * Trigger the reducer to set the purchase state to its initial values
 */
export const initializePurchaseState = createAction(`[${purchaseFeatureKey}] Initialize`);

/**
 * Set a single line item charge in the store
 */
export const setLineItemCharge = createAction(`[${purchaseFeatureKey}] Set line item charge`, (lineItemCharge: LineItemCharge) => ({
  lineItemCharge,
}));

/**
 * Remove a single line item charge from the store by ID
 */
export const removeLineItemCharge = createAction(`[${purchaseFeatureKey}] Remove line item charge`, (id: string) => ({
  id,
}));

/**
 * Remove all line item charges from the store
 */
export const resetLineItemCharges = createAction(`[${purchaseFeatureKey}] Reset line item charges`);

/**
 * Set all waivers in the store to the provided array, this will remove existing waivers that are not present in the new array
 */
export const setWaivers = createAction(`[${purchaseFeatureKey}] Set Waivers`, (waivers: Waiver[]) => ({ waivers }));

/**
 * Set a single waiver in the store
 */
export const setWaiver = createAction(`[${purchaseFeatureKey}] Set Single Waiver`, (waiver: Waiver) => ({ waiver }));

/**
 * Remove a single waiver from the store by ID
 */
export const removeWaiver = createAction(`[${purchaseFeatureKey}] Remove Single Waiver`, (id: string) => ({ id }));

/**
 * Remove any waivers from the store that have the given value for the given property
 */
export const removeWaiversByProperty = createAction(
  `[${purchaseFeatureKey}] Remove Waivers by property`,
  (property: keyof Waiver, value: any) => ({
    property,
    value,
  })
);

/**
 * The purchase process has been completed
 */
export const purchaseComplete = createAction(`[${purchaseFeatureKey}] Purchase Complete`,
  (status: PurchaseStatus, errorMessage: string, purchaseType: string) =>
  ({ status, errorMessage, purchaseType }));

/**
 * The purchase metrics logging has been completed
 */
export const purchaseMetricsLogged = createAction(`[${purchaseFeatureKey}] Purchase Metrics Logged`);
