import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/state/state';
import { getNewsFeed } from './news-feed-service.selectors';
import { loadNewsFeed, loadNewsFeedComplete } from './news-feed-service.actions';
import { NewsFeedService } from '../news-feed-service';
import { NewsFeedLookupStatus } from 'src/app/dtos/response/news-feed-response/news-feed-lookup-status';

@Injectable()
export class NewsFeedServiceEffects {
  constructor(private store: Store<RootState>, private actions$: Actions, private newsFeedService: NewsFeedService) {}

  loadNewsFeed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNewsFeed),
      withLatestFrom(this.store.select(getNewsFeed)),
      mergeMap(() => {
        return this.newsFeedService
          .getNewsFeed()
          .pipe(map((newsFeedResponse) => loadNewsFeedComplete(newsFeedResponse)));
      }),
      catchError(() => {
        return of(loadNewsFeedComplete({lookupStatus: NewsFeedLookupStatus.SYSTEM_FAILURE, newsList: null }));
      })
    )
  );
}
