import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { mileagePlanProfileSearch } from '../../../services/mileage-plan-profile-service/state/mileage-plan-profile-service.actions';
import { setMileagePlanProfileSearchMileagePlanNumber } from '../../../state/mileage-plan-profile-search/mileage-plan-profile-search.actions';
import { RootState } from '../../../state/state';
import { Router } from '@angular/router';
import { resetJetStreamMessagesForNonFiltered } from '../../../services/message-service/state/message.actions';
import { LoggerService } from 'src/app/services/logger-service/logger.service';
import { getUser } from 'src/app/services/login-service/state/login-service.selector';
import { Subject, takeUntil } from 'rxjs';
import { UserData } from 'src/app/dtos/response/login-response/user-data';

@Component({
  selector: 'app-passenger-loyalty-number',
  templateUrl: './passenger-loyalty-number.component.html',
  styleUrls: ['./passenger-loyalty-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassengerLoyaltyNumberComponent implements OnInit, OnDestroy {
  @Input() airlineCode: string;
  @Input() loyaltyNumber: string;
  @Input() allowOpenProfile = false;
  @Input() applyCallerContextStyling = false;

  private user: UserData;
  private onDestroy$ = new Subject<void>();

  constructor(private store: Store<RootState>, private router: Router, private loggerService: LoggerService) {}

  ngOnInit(): void {
    this.store.pipe(select(getUser), takeUntil(this.onDestroy$)).subscribe((user) => { this.user = user;});
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  openProfile(): void {
    if (this.allowOpenProfile) {

      this.loggerService.log({
        event: 'CallerContext',
        linkType: 'MileagePlanNumber',
        linkNumber: this.loyaltyNumber,
        username: this.user.username,
        homeCity: this.user.sabreAAA,
      });

      this.store.dispatch(resetJetStreamMessagesForNonFiltered());
      this.store.dispatch(setMileagePlanProfileSearchMileagePlanNumber(this.loyaltyNumber));
      this.store.dispatch(mileagePlanProfileSearch());
      this.router.navigate(['mileage-plan-profile-search', this.loyaltyNumber, 'profile', 'summary']);
    }
  }

  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.code === 'Space') {
      this.openProfile();
    }
  }
}
