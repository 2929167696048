import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PassengerSegmentsRequest } from '../../dtos/request/purchase-request/passenger-segments-request';
import { TransactionRequest } from '../../dtos/request/purchase-request/transaction-request';
import { Reservation } from '../../dtos/response/reservation-response/reservation';
import { Segment } from '../../dtos/response/reservation-response/segment';
import { CouponStatusData } from '../../dtos/response/vcr-response/coupon-status-data';
import { TransactionUseCase } from '../../use-cases/transaction.use-case';

@Injectable({
  providedIn: 'root',
})
export class WaiverService {
  constructor(private readonly http: HttpClient, private readonly transactionUseCase: TransactionUseCase) {}

  waiver(reservation: Reservation, sabreId: string, coupons: CouponStatusData[], filteredSegments: Segment[]) {
    const passengersWithLineItems: PassengerSegmentsRequest = this.transactionUseCase.getPassengerSegmentPurchases(
      reservation,
      coupons,
      filteredSegments
    );
    const request: TransactionRequest = {
      confirmationCode: reservation.confirmationCode,
      passengers: passengersWithLineItems.passengerTransactions,
      segments: passengersWithLineItems.segments,
      sabreId,
    };
    return this.http.post('api/transactions', request).subscribe();
  }

  logSDCAutoWaiver(request: TransactionRequest) {
    return this.http.post('api/transactions', request).subscribe();
  }
}
