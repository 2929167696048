import { createAction } from '@ngrx/store';
import { LoginAttemptStatus } from '../../../dtos/response/login-response/login-attempt-status';
import { UserData } from '../../../dtos/response/login-response/user-data';
import { loginServiceFeatureKey } from './login-service.state';

export const initializeLoginServiceState = createAction(`[${loginServiceFeatureKey}] Initialize`);

export const login = createAction(`[${loginServiceFeatureKey}] Login`, (username: string, password: string) => ({
  username,
  password,
}));

export const loginComplete = createAction(`[${loginServiceFeatureKey}] Login Complete`, (user: UserData, status: LoginAttemptStatus) => ({
  user,
  status,
}));

export const logout = createAction(`[${loginServiceFeatureKey}] Logout`);
