import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth-service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canLoad(route: Route): boolean {
    return this.isAuthorized(route.path);
  }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.isAuthorized(state.url);
  }

  private isAuthorized(path: string): boolean {
    // if you are on the auth component
    if (path.startsWith('/auth')) {
      // and you have a valid access token
      if (this.authService.hasValidAccessToken()) {
        // send the user to the login page
        this.router.navigateByUrl('login');
        return false;
      }
       // Clear the user so Sabre login is forced after auth
       localStorage.removeItem('user');

      // and you don't have a valid access token, allow navigation to auth component
      return true;
    } else {
      // you are not on the auth component
      // and you don't have a valid access token
      if (!this.authService.hasValidAccessToken()) {
        // block activation and send to the auth component
        this.router.navigateByUrl('auth');

        // Clear the user so Sabre login is forced after auth
        localStorage.removeItem('user');
        return false;
      }
      // you do have a valid access token, allow navigation
      return true;
    }
  }
}
