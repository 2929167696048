import { createAction } from '@ngrx/store';
import { CallerContext } from '../../../dtos/response/caller-context-response/caller-context';
import { callerContextFeatureKey } from './caller-context-service.state';

/**
 * Trigger the reducer to set the caller context state to its initial values
 */
export const initializeCallerContextState = createAction(`[${callerContextFeatureKey}] Initialize`);

/**
 * triggers the polling network call via an effect to load the callers from the API
 */
export const startCallerPolling = createAction(`[${callerContextFeatureKey}] Start caller context polling`);

/**
 * stops the polling network call via an effect to prevent the polling from happening when it is not needed load the callers from the API
 */
export const stopCallerPolling = createAction(`[${callerContextFeatureKey}] Stop caller context polling`);

/**
 * triggers the reducer to replace all callers in the store with the CallerContext array passed into the action
 */
export const setCallers = createAction(`[${callerContextFeatureKey}] Set callers`, (callers: CallerContext[]) => ({
  callers,
}));

/**
 * triggers the reducer to set the error message in the store with the message passed into the action
 */
export const setCallerContextErrorMessage = createAction(
  `[${callerContextFeatureKey}] Set caller context error message`,
  (errorMessage: string) => ({
    errorMessage,
  })
);

/**
 * Trigger the reducer to set the active caller id to the value passed into the action
 */
export const setActiveCallerId = createAction(`[${callerContextFeatureKey}] Set active caller ID`, (id: string) => ({
  id,
}));

/**
 * Trigger the reducer to set dismissed to true on the caller that has the provided id
 */
export const dismissCaller = createAction(`[${callerContextFeatureKey}] Dismiss caller`, (id: string) => ({
  id,
}));

/**
 * Trigger the reducer to select the previous caller
 */
export const selectNextCaller = createAction(`[${callerContextFeatureKey}] Select next caller`);

/**
 * Trigger the reducer to select the next caller
 */
export const selectPreviousCaller = createAction(`[${callerContextFeatureKey}] Select previous caller`);
