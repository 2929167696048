import { SdcEligibilityStatus } from '../../../dtos/response/sdc-eligibility-response/sdc-eligibility-status';
import { getRoutedConfirmationCode } from '../../reservation-service/state/reservation-service.selectors';
import { SdcEligibilityService } from '../sdc-eligibility-service';
import { getSdcEligibility, setGetSdcEligibilityFailure, setGetSdcEligibilitySuccess } from './sdc-eligibility-service.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { RootState } from '../../../state/state';
import { Store } from '@ngrx/store';

@Injectable()
export class SdcEligibilityServiceEffects {
  constructor(private actions$: Actions, private sdcEligibilityService: SdcEligibilityService, private store: Store<RootState>) {}

  getSdcEligibility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSdcEligibility),
      withLatestFrom(this.store.select(getRoutedConfirmationCode)),
      mergeMap(([action, confirmationCode]) =>
        this.sdcEligibilityService
          .getSdcEligibility(confirmationCode)
          .pipe(
            map((result) =>
              result.status === SdcEligibilityStatus.SUCCESS
                ? setGetSdcEligibilitySuccess(result.sdcEligibilityResponse)
                : setGetSdcEligibilityFailure(result.status)
            )
          )
      ),
      catchError(() => of(setGetSdcEligibilityFailure(SdcEligibilityStatus.UNCAUGHT)))
    )
  );
}
