import { PhoneFieldResponse } from '../../../dtos/response/phone-field-response/phone-field-response';
import { RootState } from '../../../state/state';

/**
 * The string name of the "slice" of state
 */
export const phoneFieldFeatureKey = 'phoneField';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  phoneField: PhoneFieldState;
}

/**
 * The state that is used in relation to phone field, it is an extension of root state
 * these values are available in and should be accessed from the root state model
 */
export interface PhoneFieldState {
  phoneFieldResponse: PhoneFieldResponse | null;
}

/**
 * Initial state of the phone field slice of state, used when phone field service state is
 * bootstrapped via the reducer in an app module also used when state is reset
 */
export const initialPhoneFieldState: PhoneFieldState = {
  phoneFieldResponse: null
};
