import { createFeatureSelector, createSelector } from '@ngrx/store';
import { loginServiceFeatureKey, LoginServiceState } from './login-service.state';

const getLoginServiceState = createFeatureSelector<LoginServiceState>(loginServiceFeatureKey);

export const getUser = createSelector(getLoginServiceState, (state) => state.user);

export const getIsLoggedIn = createSelector(getLoginServiceState, (state) => state.user !== null);

export const getLoginStatus = createSelector(getLoginServiceState, (state) => state.status);

export const getLoginLoadingStatus = createSelector(getLoginServiceState, (state) => state.loading);
