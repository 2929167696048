import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SecureFlightInfoAddStatus } from 'src/app/dtos/response/secure-flight-info/secure-flight-info-add-status';
import { Status } from 'src/app/models/status';
import { SecureFlightInfo } from '../../../dtos/request/secure-flight-info/secure-flight-info';
import { RootState } from '../../../state/state';

/**
 * The string name of the ssr service 'slice' of state
 */
export const secureFlightInfoFeatureKey = 'secureFlightInfo';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  secureFlightInfo: SecureFlightInfoState;
}

/**
 * The state that represents the secure flight info
 */
export interface SecureFlightInfoState {
  secureFlightInfos: SecureFlightInfoEntity;
  loadStatus: Status;
  addStatus: SecureFlightInfoAddStatus;
}

export interface SecureFlightInfoEntity extends EntityState<SecureFlightInfo> {}
export const secureFlightInfoAdapter: EntityAdapter<SecureFlightInfo> = createEntityAdapter<SecureFlightInfo>({
  selectId: (info) => info.passengerHashId,
});

/**
 * Initial state for the secure flight info
 */
export const initialSecureFlightInfoState: SecureFlightInfoState = {
  secureFlightInfos: secureFlightInfoAdapter.getInitialState(),
  loadStatus: Status.STABLE,
  addStatus: SecureFlightInfoAddStatus.SUCCESS,
};
