export enum SeatMapLookupStatus {
  DOES_NOT_EXIST = 1,
  INVALID_DATES,
  NO_SEGMENTS,
  NO_PREMIUM_PRICES,
  OTHER_AIRLINE,
  SUCCESS,
  SYSTEM_FAILURE,
  TIMEOUT,
  UNSUPPORTED_CARRIER,
  INSIDE_CHECK_IN_WINDOW,
  ALREADY_CHECKED_IN,
  SEGMENT_OUT_OF_ORDER,
  UNKNOWN_CITY_CODE,
  UNCAUGHT,
}
