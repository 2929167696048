import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer as observableDefer, Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { CareKitRequest } from '../../dtos/request/care-kit-request/care-kit-request';
import { Compensation } from '../../dtos/response/compensation-response/compensation';
import { CompensationResponse } from '../../dtos/response/compensation-response/compensation-response';
import { EmpowermentToolkitResponse } from '../../dtos/response/empowerment-toolkits-response';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';

export interface CompensationServiceAPI {
  getCompensations(confirmationCode: string): Observable<CompensationResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class CompensationService implements CompensationServiceAPI {
  constructor(
    private http: HttpClient,
    private eventService: GlobalEventService) {}

  getCompensations(confirmationCode: string): Observable<CompensationResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    return this.http.get<Compensation[]>(`api/compensation/${confirmationCode}`, options).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((compensation) => {
        return { success: true, compensation };
      }),
      catchError((err: HttpErrorResponse) => {
        return observableOf({ success: false, errorMessage: err.statusText });
      })
    );
  }

  submitCareKit(request: CareKitRequest, type: string): Observable<CompensationResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'false',
      }),
    };
    return this.http.post<EmpowermentToolkitResponse>(`api/compensation/publish/${type}`, request, options).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => {
        return { success: response.isSuccessDAP, errorMessage: response.errorMessage ?? null };
      }),
      catchError((err: HttpErrorResponse) => {
        this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
        return observableOf({ success: false, errorMessage: err.statusText });
      })
    );
  }
}
