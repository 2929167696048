import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourceCenterDrawerComponent } from './resource-center-drawer/resource-center-drawer.component';
import { InlineSVGModule } from 'ng-inline-svg-2';



@NgModule({
  declarations: [
    ResourceCenterDrawerComponent
  ],
  imports: [
    CommonModule,
    InlineSVGModule
  ],
  exports: [
    ResourceCenterDrawerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ResourceCenterModule {

}
