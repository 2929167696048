import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take, withLatestFrom } from 'rxjs/operators';
import { SeatChangeEvent, SeatChangeType } from '../../models/seat-map/seat-change-event';
import { ChargeUseCase } from '../../use-cases/charge.use-case';
import { SeatMapUtil } from './seat-map-util';
import { PassengerUseCase } from '../../use-cases/passenger.use-case';
import { GlobalEvent, GlobalEventService } from '../../services/global-event-service/global-event.service';
import { RootState } from '../../state/state';
import { getSeatMapDisplayChangeEvent } from '../../services/seat-map-display-service/state/seat-map-display-service.selectors';
import { AssignSeatsRequest } from '../../dtos/request/assign-seats/assign-seats-request';
import { AssignSeatsRequestSegment } from '../../dtos/request/assign-seats/assign-seats-request-segment';
import { getCallerName } from '../../state/selectors';
import { getCurrentSegmentHash, getSeatAssignmentDiff } from '../../features/seat-map/state/seat-map.selectors';
import { SeatMap } from '../../models/seat-map/seat-map';
import { Seat } from 'src/app/models/seat-map/seat';
import { Passenger } from '../../dtos/response/reservation-response/passenger';
import { ExtraSeatType } from '../../dtos/response/reservation-response/extra-seat-type';
import {
  getPassengerOriginalSeatPrice,
  getPassengers,
  getRoutedConfirmationCode,
  isSeatsPrimaryPropertyMismatch,
} from '../../services/reservation-service/state/reservation-service.selectors';
import { setPassengerSeat } from '../../services/reservation-service/state/reservation-service.actions';
import { LineItemChargeType } from '../../models/line-item-charge/line-item-charge-type';
import { SeatLineItemCharges } from 'src/app/models/line-item-charge/seat-line-item-charges';
import { PassengerSeatLocation } from '../../dtos/response/seat-map-lookup-response/passenger-seat-location';
import { Segment } from '../../dtos/response/reservation-response/segment';
import { SeatMapSeatUtil } from './seat-map-seat-util';
import { Waiver } from '../../models/waiver/waiver';
import { removeLineItemCharge, removeWaiver, setWaiver } from '../../shared/purchase/state/purchase.actions';
import {
  setSeatMapDisplayAssignedSeat,
  setSeatMapDisplayAssignSeatFormerOccupant,
  setSeatMapDisplayPassenger,
  setSeatMapDisplayType,
  setSeatMapDisplayUnassignedSeat,
} from '../../services/seat-map-display-service/state/seat-map-display-service.actions';
import { ChargePricing } from '../../models/line-item-charge/charge-pricing';
import { setSeatMapLookupCrudStatus, updateSingleSeatMapEntity } from '../../services/seat-service/state/seats-service.actions';
import { Status } from '../../models/status';
import { SeatProperty } from 'src/app/models/seat-map/seat-property';

/**
 * Provides methods that abstract the business logic for assigning and un-assigning seats
 */
@Injectable({
  providedIn: 'root',
})
export class SeatAssignmentUtil {
  changeEvent: SeatChangeEvent | null;

  constructor(
    private chargeUseCase: ChargeUseCase,
    private seatMapUtil: SeatMapUtil,
    private seatMapSeatUtil: SeatMapSeatUtil,
    private passengerUseCase: PassengerUseCase,
    private globalEventService: GlobalEventService,
    private store: Store<RootState>
  ) {
    this.store.select(getSeatMapDisplayChangeEvent).subscribe((event) => (this.changeEvent = event));
  }

  /**
   * Prior to 9/15/2022 this logic was handled in the seatmapsession. Migrated for SMS gut.
   * Compares the current in-memory seat map state for each segment against that segment's persisted state
   * in order to produce a "diff". The diff allows the user to review changes before clicking the Save button.
   * @returns The diff that can be used to save the user's seat map changes. If there are no
   * differences, this method returns null.
   */
  public getSeatAssignmentDiff(): AssignSeatsRequest | null {
    let callerName = '';
    let seatAssignmentsDiff: AssignSeatsRequestSegment[] | null = null;
    this.store.pipe(select(getCallerName), take(1)).subscribe((cName) => (callerName = cName));
    this.store.pipe(select(getSeatAssignmentDiff), take(1)).subscribe((diff) => (seatAssignmentsDiff = diff));
    if (seatAssignmentsDiff) {
      return {
        callerName,
        segments: seatAssignmentsDiff,
      };
    }
    return null;
  }

  /**
   * Assign a seat for a given segment. This method contains logic to update the seat map seat and the passenger seat as well
   * as handle scenarios like extra seats and generating line item charges for PC seats
   * @param segmentIndex the 0 based index for the segment, used to access the correct position in the passenger seat array,
   * updating the proper seat map, and generating the correct line item charges
   * @param seatMap the seat map that contains the seat that is being assigned
   * @param seat the seat that is being assigned
   * @param passenger the passenger that the seat is being assigned to
   */
  public assignSeat(segmentIndex: number, seatMap: SeatMap, seat: Seat, passenger: Passenger) {
    if (passenger && passenger.extraSeatRefs && passenger.extraSeatRefs.length > 0) {
      const primaryPassengerSeatLetter = this.handleExstCbbgExtraSeatAssignment(seatMap, seat, segmentIndex, passenger);
      // primaryPassengerSeatLetter is null
      this.assignSingleSeat(segmentIndex, seatMap, this.seatMapUtil.getSeat(seatMap, seat.row, primaryPassengerSeatLetter), passenger);
    } else {
      this.assignSingleSeat(segmentIndex, seatMap, seat, passenger);
    }
  }

  private handleExstCbbgExtraSeatAssignment(seatMap: SeatMap, seat: Seat, segmentIndex: number, passenger: Passenger): string {
    const windowSeat = this.seatMapUtil.getWindowSeat(seatMap, seat.row, seat.contiguousGroupLetters);
    const availableSeatLetters = seat.contiguousGroupLetters?.slice(0) || []; // clone
    // Objects get sent by reference, whereas primitives are copied by value. Use object to pass by reference
    // so that the value will get updated throughout the helper methods.
    const primaryPassengerSeatLetterObj = { primaryPassengerSeatLetter: '' };

    const cbbgLetter = this.assignCabinBaggageIfApplicable(
      segmentIndex,
      seatMap,
      passenger,
      primaryPassengerSeatLetterObj.primaryPassengerSeatLetter,
      windowSeat,
      seat,
      availableSeatLetters
    );
    if (cbbgLetter) {
      this.handleCbbgLetter(availableSeatLetters, passenger, cbbgLetter, primaryPassengerSeatLetterObj, seatMap, seat);
    }

    const exstLetter = this.assignExtraSeatIfApplicable(
      segmentIndex,
      seatMap,
      passenger,
      primaryPassengerSeatLetterObj.primaryPassengerSeatLetter,
      windowSeat,
      seat,
      availableSeatLetters
    );
    if (exstLetter) {
      this.handleExstLetter(availableSeatLetters, passenger, exstLetter, primaryPassengerSeatLetterObj, seatMap, seat);
    }
    return primaryPassengerSeatLetterObj.primaryPassengerSeatLetter;
  }

  private handleCbbgLetter(
    availableSeatLetters: string[],
    passenger: Passenger,
    cbbgLetter: string,
    primaryPassengerSeatLetterObj: { primaryPassengerSeatLetter: string },
    seatMap: SeatMap,
    seat: Seat
  ) {
    const cbbgRefs: Passenger[] = this.passengerUseCase.getExtraSeatRefByType(passenger, ExtraSeatType.CBBG);
    availableSeatLetters.splice(availableSeatLetters.indexOf(cbbgLetter), 1);
    // Support for 2 CBBG SSRs with left-side columns selected
    if (cbbgRefs.length === 2 && availableSeatLetters.includes('C')) {
      primaryPassengerSeatLetterObj.primaryPassengerSeatLetter = 'C';
      // Support for 2 CBBG SSRs with right-side columns selected
    } else if (cbbgRefs.length === 2 && availableSeatLetters.includes('F')) {
      primaryPassengerSeatLetterObj.primaryPassengerSeatLetter = 'F';
      // Support for standard 1 CBBG or CBBG/EXST scenario
    } else {
      primaryPassengerSeatLetterObj.primaryPassengerSeatLetter = this.getNextContiguousSeatLetter(
        seatMap,
        availableSeatLetters,
        cbbgLetter,
        seat.contiguousGroupLetters
      );
    }
  }

  private handleExstLetter(
    availableSeatLetters: string[],
    passenger: Passenger,
    exstLetter: string,
    primaryPassengerSeatLetterObj: { primaryPassengerSeatLetter: string },
    seatMap: SeatMap,
    seat: Seat
  ) {
    const exstRefs: Passenger[] = this.passengerUseCase.getExtraSeatRefByType(passenger, ExtraSeatType.EXST);
    availableSeatLetters.splice(availableSeatLetters.indexOf(exstLetter), exstRefs.length);
    // Support for 2 EXST SSRs with left-side columns selected
    if (exstRefs.length === 2 && availableSeatLetters.includes('C')) {
      primaryPassengerSeatLetterObj.primaryPassengerSeatLetter = 'C';
      // Support for 2 EXST SSRs with right-side columns selected
    } else if (exstRefs.length === 2 && availableSeatLetters.includes('F')) {
      primaryPassengerSeatLetterObj.primaryPassengerSeatLetter = 'F';
      // Support for standard 1 EXST or CBBG/EXST scenario
    } else if (!primaryPassengerSeatLetterObj.primaryPassengerSeatLetter) {
      primaryPassengerSeatLetterObj.primaryPassengerSeatLetter = this.getNextContiguousSeatLetter(
        seatMap,
        availableSeatLetters,
        exstLetter,
        seat.contiguousGroupLetters,
        false,
        seat.aisleSeat,
        seat.row
      );
    }
  }

  /**
   * Unassign a seat for a given segment. This method contains logic to update the seat map seat and the passenger seat as well
   * as handle scenarios like extra seats and removing line item charges for unassigned PC seats
   * @param passenger the passenger that the seat is being unassigned from
   * @param seatMap the seat map that contains the seat that is being assigned
   * @param segmentIndex the 0 based index for the segment, used to access the correct position in the passenger seat array,
   * updating the proper seat map, and removing the correct line item charges
   */
  public clearPassengerSeat(passenger: Passenger, seatMap: SeatMap, segmentIndex: number) {
    if (!passenger || !passenger.seats || !passenger.seats[segmentIndex].row) {
      const seat = passenger.seats[segmentIndex];
      this.store
        .pipe(select(getRoutedConfirmationCode), withLatestFrom(this.store.pipe(select(getCurrentSegmentHash))), take(1))
        .subscribe(([confirmationCode, segmentHashId]) =>
          this.store.dispatch(setPassengerSeat(confirmationCode, passenger.hashId, seat, segmentIndex, segmentHashId))
        );
      this.chargeUseCase.processCharge(
        this.getSeatLineItemChargeType(seat?.primaryProperty || ''),
        passenger.id,
        passenger.hashId,
        segmentIndex
      );
      return;
    }
    const passengerSeatLoc: PassengerSeatLocation = this.passengerUseCase.getPassengerSeatLocation(passenger, segmentIndex);
    const passengerSeat = {
      extraSeatType: passenger.seats[segmentIndex].extraSeatType,
    };
    this.store
      .pipe(select(getRoutedConfirmationCode), withLatestFrom(this.store.pipe(select(getCurrentSegmentHash))), take(1))
      .subscribe(([confirmationCode, segmentHashId]) =>
        this.store.dispatch(setPassengerSeat(confirmationCode, passenger.hashId, passengerSeat, segmentIndex, segmentHashId))
      );
    this.clearPassengerSeatAssignmentFromSeatMap(passengerSeatLoc, seatMap, segmentIndex);
    this.chargeUseCase.processCharge(
      this.getSeatLineItemChargeType(passengerSeatLoc?.primaryProperty || ''),
      passenger.id,
      passenger.hashId,
      segmentIndex
    );
    passenger.extraSeatRefs?.forEach((extraSeatRef: Passenger) => {
      this.clearPassengerSeat(extraSeatRef, seatMap, segmentIndex);
    });
  }

  /**
   * Handle auto waivers during seat assignment
   * @param passenger the passenger being assigned to a seat
   * @param seat the seat that is being assigned
   * @param reservation the reservation that the passenger is on
   * @param segmentIndex the 0 based index for the impacted segment
   */
  public handlePaidSeatWaivers(passenger: Passenger, seat: Seat, segmentIndex: number, filteredSegments: Segment[]) {
    const segment = filteredSegments[segmentIndex];
    const isSeatAnUpsell = seat?.upsellPrice && seat?.upsellPrice > 0;
    if (!isSeatAnUpsell) {
      let previousSeatUpsellPrice;
      this.store
        .pipe(select(getPassengerOriginalSeatPrice(passenger.hashId, segment.hashId)), take(1))
        .subscribe((price) => (previousSeatUpsellPrice = price));
      if (this.seatMapSeatUtil.isPremiumClass(seat) && !previousSeatUpsellPrice) {
        let reason: string;
        switch (segment.highestEliteTierStatus) {
          case 'MVP':
            reason = 'MVP AUTO WAIVER';
            break;
          case 'Gold':
            reason = 'GOLD AUTO WAIVER';
            break;
          case 'MVP Gold 75K':
            reason = 'GOLD_75 AUTO WAIVER';
            break;
          case 'MVP Gold 100K':
            reason = 'GOLD_100 AUTO WAIVER';
            break;
          default:
            reason = 'UNKNOWN AUTO WAIVER';
            break;
        }
        const waiver: Waiver = {
          chargeType: LineItemChargeType.PREMIUM_SEAT_UPGRADE,
          passengerHashId: passenger.hashId,
          flightNumber: segment.marketingAirlineFlightNumber,
          airlineCode: segment.marketedByAirlineCode,
          segmentIndex,
          amount: seat.upsellPrice,
          reason,
          comment: 'AUTO WAIVER',
          isAutoWaiver: true,
        };
        this.store.dispatch(setWaiver(waiver));
      } else {
        this.store.dispatch(removeWaiver(LineItemChargeType.PREMIUM_SEAT_UPGRADE + passenger.hashId + segmentIndex));
      }
    }
  }

  /**
   * Determines if a given passenger contains a CBBG extra seat, if it does, it assigns an extra seat
   * @param segmentIndex the 0 based index for the segment, used to access the correct position in the passenger seat array,
   * updating the proper seat map, and generating the correct line item charges
   * @param seatMap the seat map that contains the seat that is being assigned
   * @param passenger the passenger that will be checked for a EXST seat
   * @param primaryPassengerSeatLetter the letter of the seat the actual passenger is sitting in
   * @param windowSeat the seat in the contiguous seating group (eg: not interrupted by an aisle A,B,C)) that is the window seat
   * @param selectedSeat the seat that was clicked on the seatmap
   * @param availableSeatLetters the letters for the seats that are available to be assigned
   */
  private assignCabinBaggageIfApplicable(
    segmentIndex: number,
    seatMap: SeatMap,
    passenger: Passenger,
    primaryPassengerSeatLetter: string,
    windowSeat: Seat | null,
    selectedSeat: Seat,
    availableSeatLetters: string[]
  ) {
    let cbbgSeatLetter: string | null = null;
    const exstRefs: Passenger[] = this.passengerUseCase.getExtraSeatRefByType(passenger, ExtraSeatType.EXST);
    const cbbgRefs: Passenger[] = this.passengerUseCase.getExtraSeatRefByType(passenger, ExtraSeatType.CBBG);
    // Handle 2 CBBG SSRs
    if (cbbgRefs.length === 2) {
      this.assignSeatsForTwoCBBGRefs(segmentIndex, seatMap, selectedSeat, availableSeatLetters, cbbgRefs);
      return availableSeatLetters[0];
    } else if (cbbgRefs.length > 0) {
      cbbgRefs.forEach((cbbgRef) => {
        // If CBBG has already been assigned, we know where the primary passenger has to sit and hence where the CBBG seat must be
        if (primaryPassengerSeatLetter) {
          cbbgSeatLetter = this.getNextContiguousSeatLetter(
            seatMap,
            availableSeatLetters,
            primaryPassengerSeatLetter,
            selectedSeat.contiguousGroupLetters,
            false,
            true,
            selectedSeat.row
          );
        } else if (selectedSeat.windowSeat) {
          cbbgSeatLetter = windowSeat ? windowSeat.letter : '';
        } else if (selectedSeat.aisleSeat && !exstRefs.length) {
          cbbgSeatLetter = this.getNextContiguousSeatLetter(
            seatMap,
            availableSeatLetters,
            selectedSeat.letter,
            selectedSeat.contiguousGroupLetters,
            false,
            false,
            selectedSeat.row
          );
        } else if (windowSeat && (!windowSeat.passenger || windowSeat.passenger.id === cbbgRef.id)) {
          cbbgSeatLetter = windowSeat.letter;
        } else {
          cbbgSeatLetter = this.getNextContiguousSeatLetter(
            seatMap,
            availableSeatLetters,
            selectedSeat.letter,
            selectedSeat.contiguousGroupLetters,
            false,
            true,
            selectedSeat.row
          );
        }
        this.assignSingleSeat(
          segmentIndex,
          seatMap,
          this.seatMapUtil.getSeat(seatMap, selectedSeat.row, cbbgSeatLetter),
          cbbgRef,
          ExtraSeatType.CBBG
        );
      });
      return windowSeat?.letter;
    }
    return null;
  }

  private assignSeatsForTwoCBBGRefs(
    segmentIndex: number,
    seatMap: SeatMap,
    selectedSeat: Seat,
    availableSeatLetters: string[],
    cbbgRefs: Passenger[]
  ) {
    this.assignSingleSeat(
      segmentIndex,
      seatMap,
      this.seatMapUtil.getSeat(seatMap, selectedSeat.row, availableSeatLetters[0]),
      cbbgRefs[0],
      ExtraSeatType.CBBG
    );
    this.assignSingleSeat(
      segmentIndex,
      seatMap,
      this.seatMapUtil.getSeat(seatMap, selectedSeat.row, availableSeatLetters[1]),
      cbbgRefs[1],
      ExtraSeatType.CBBG
    );
  }

  /**
   * Determines if a given passenger contains a EXST extra seat, if it does, it assigns an extra seat
   * @param segmentIndex the 0 based index for the segment, used to access the correct position in the passenger seat array,
   * updating the proper seat map, and generating the correct line item charges
   * @param seatMap the seat map that contains the seat that is being assigned
   * @param passenger the passenger that will be checked for a EXST seat
   * @param primaryPassengerSeatLetter the letter of the seat the actual passenger is sitting in
   * @param windowSeat the seat in the contiguous seating group (eg: not interrupted by an aisle A,B,C)) that is the window seat
   * @param selectedSeat the seat that was clicked on the seatmap
   * @param availableSeatLetters the letters for the seats that are available to be assigned
   */
  private assignExtraSeatIfApplicable(
    segmentIndex: number,
    seatMap: SeatMap,
    passenger: Passenger,
    primaryPassengerSeatLetter: string | null,
    windowSeat: Seat | null,
    selectedSeat: Seat,
    availableSeatLetters: string[]
  ) {
    let exstSeatLetter: string | null = null;
    const exstRefs: Passenger[] = this.passengerUseCase.getExtraSeatRefByType(passenger, ExtraSeatType.EXST);
    // Handle 2 EXST SSRs
    if (exstRefs.length === 2) {
      this.assignSingleSeat(
        segmentIndex,
        seatMap,
        this.seatMapUtil.getSeat(seatMap, selectedSeat.row, availableSeatLetters[0]),
        exstRefs[0],
        ExtraSeatType.EXST
      );
      this.assignSingleSeat(
        segmentIndex,
        seatMap,
        this.seatMapUtil.getSeat(seatMap, selectedSeat.row, availableSeatLetters[1]),
        exstRefs[1],
        ExtraSeatType.EXST
      );
      exstSeatLetter = availableSeatLetters[0];
    } else {
      exstRefs.forEach((exstRef) => {
        // If CBBG has already been assigned, we know where the primary passenger has to sit and hence where the EXST seat must be
        if (primaryPassengerSeatLetter) {
          exstSeatLetter = this.getNextContiguousSeatLetter(
            seatMap,
            availableSeatLetters,
            primaryPassengerSeatLetter,
            selectedSeat.contiguousGroupLetters,
            false,
            true,
            selectedSeat.row
          );
        } else if (selectedSeat.windowSeat) {
          exstSeatLetter = windowSeat ? windowSeat.letter : '';
        } else if (selectedSeat.aisleSeat) {
          exstSeatLetter = this.getNextContiguousSeatLetter(
            seatMap,
            availableSeatLetters,
            selectedSeat.letter,
            selectedSeat.contiguousGroupLetters,
            false,
            false,
            selectedSeat.row
          );
        } else if (windowSeat && (!windowSeat.passenger || windowSeat.passenger.id === exstRef.id)) {
          exstSeatLetter = windowSeat.letter;
        } else {
          exstSeatLetter = this.getNextContiguousSeatLetter(
            seatMap,
            availableSeatLetters,
            selectedSeat.letter,
            selectedSeat.contiguousGroupLetters,
            false,
            true,
            selectedSeat.row
          );
        }
        this.assignSingleSeat(
          segmentIndex,
          seatMap,
          this.seatMapUtil.getSeat(seatMap, selectedSeat.row, exstSeatLetter),
          exstRef,
          ExtraSeatType.EXST
        );
      });
    }

    return exstSeatLetter;
  }

  /**
   * Returns the next contiguous seat letter (eg: it will not cross an aisle) with ability to prefer window or aisle seat
   * @param seatMap the seat map that contains the seat that is being assigned
   * @param availableSeatLetters the letters for the seats that are available to be assigned
   * @param extraSeatLetter the letter of the seat an EXST is assigned to
   * @param contiguousGroupLetters a contiguous seating group (eg: not interrupted by an aisle A,B,C)
   * @param preferWindow if true, will return a window seat if both a window and aisle seat are available
   * @param preferAisle if true, will return a aisle seat if both a window and aisle seat are available
   * @param row the row that is being checked
   */
  private getNextContiguousSeatLetter(
    seatMap: SeatMap,
    availableSeatLetters: string[],
    extraSeatLetter: string,
    contiguousGroupLetters: string[] | undefined,
    preferWindow = false,
    preferAisle = false,
    row = 0
  ) {
    let nextContiguousSeatLetter = '';
    const higherLetter = this.getContiguousSeatLetter(seatMap, availableSeatLetters, extraSeatLetter, contiguousGroupLetters, 1);
    const lowerLetter = this.getContiguousSeatLetter(seatMap, availableSeatLetters, extraSeatLetter, contiguousGroupLetters, -1);

    if (higherLetter) {
      if (lowerLetter) {
        if (preferWindow && !this.seatMapUtil.getSeat(seatMap, row, higherLetter)?.windowSeat) {
          nextContiguousSeatLetter = lowerLetter;
        } else if (preferAisle && !this.seatMapUtil.getSeat(seatMap, row, higherLetter)?.aisleSeat) {
          nextContiguousSeatLetter = lowerLetter;
        } else {
          nextContiguousSeatLetter = higherLetter;
        }
      } else {
        nextContiguousSeatLetter = higherLetter;
      }
    } else {
      nextContiguousSeatLetter = lowerLetter;
    }
    return nextContiguousSeatLetter;
  }

  /**
   * Returns the next contiguous seat letter (eg: it will not cross an aisle)
   * @param seatMap the seat map that contains the seat that is being assigned
   * @param availableSeatLetters the letters for the seats that are available to be assigned
   * @param extraSeatLetter the letter of the seat an EXST is assigned to
   * @param contiguousGroupLetters a contiguous seating group (eg: not interrupted by an aisle A,B,C)
   * @param increment which direction to check in -1 goes back and 1 foes forward
   */
  private getContiguousSeatLetter(
    seatMap: SeatMap,
    availableSeatLetters: string[],
    extraSeatLetter: string,
    contiguousGroupLetters: string[] | undefined,
    increment: number
  ) {
    const rowSeatLetters: string[] = seatMap.seatLetters;
    const extraSeatLetterRowIndex = rowSeatLetters.indexOf(extraSeatLetter);

    let contigLetter = rowSeatLetters[extraSeatLetterRowIndex + increment];
    if (
      (contiguousGroupLetters && contiguousGroupLetters.indexOf(contigLetter) <= -1) ||
      availableSeatLetters.indexOf(contigLetter) <= -1
    ) {
      contigLetter = '';
    }
    return contigLetter;
  }

  /**
   * Assign a single seat and perform related tasks (update line item charges, broadcast assignment)
   * @param segmentIndex the 0 based index for the segment, used to access the correct position in the passenger seat array,
   * updating the proper seat map, and generating the correct line item charges
   * @param seatMap the seat map that contains the seat that is being assigned
   * @param seat the seat that is being assigned
   * @param passenger the passenger that is being assigned to the seat
   * @param extraSeatType if provided, the type of extra seat that is being assigned (eg: EXST or CBBG)
   */
  private assignSingleSeat(
    segmentIndex: number,
    seatMap: SeatMap,
    seat: Seat | undefined,
    passenger: Passenger,
    extraSeatType?: ExtraSeatType
  ) {
    this.store.pipe(select(getPassengers), take(1)).subscribe((passengers) => {
      const ngrxPassenger: Passenger | undefined = passengers.find((pax) => pax.hashId === passenger.hashId);
      this.clearPassengersOldSeatIfNecessary(ngrxPassenger, seatMap, segmentIndex);
    });

    if (!this.changeEvent?.passenger) {
      this.store.dispatch(setSeatMapDisplayPassenger(passenger));
      this.store.dispatch(setSeatMapDisplayType(SeatChangeType.ASSIGNED));
    }

    if (seat) {
      // If the newly selected seat currently has a passenger in it, remove the current passenger and put the new passenger
      if (seat.passenger) {
        // Track the former occupant of the seat being assigned to the current passenger
        this.store.dispatch(setSeatMapDisplayAssignSeatFormerOccupant(seat.passenger));
        // Remove the seat association from the former occupant
        this.clearPassengerSeat(seat.passenger, seatMap, segmentIndex);
      }
      // Assign the seat to the current passenger in the reservation state
      this.passengerUseCase.assignSeatInReservationState(passenger, seat, segmentIndex, extraSeatType);

      // Assign the current passenger to the seat
      const seatCopy: Seat = {
        ...seat,
        passenger,
      };

      // Assign the seat to the curret passenger in the seatmap state
      this.assignSeatInSeatMapState(seatCopy, segmentIndex);

      // If passenger was NOT originally seated in the same class, process charge
      let segmentHashId;
      let isSeatPrimaryPropertyMismatch = false;
      this.store.pipe(select(getCurrentSegmentHash), take(1)).subscribe((segHashId) => (segmentHashId = segHashId));
      this.store.pipe(select(isSeatsPrimaryPropertyMismatch(passenger.hashId, segmentHashId)), take(1)).subscribe((val) => {
        isSeatPrimaryPropertyMismatch = val;
      });

      const seatPricing: ChargePricing | null = this.getSeatPricing(seatCopy);
      const seatLineItemCharges = SeatLineItemCharges.get(seatCopy.primaryProperty || SeatProperty.Unknown);

      if (seatPricing === null || isSeatPrimaryPropertyMismatch) {
        this.chargeUseCase.processCharge(seatLineItemCharges, passenger.id, passenger.hashId, segmentIndex, seatPricing);
      }

      this.store.dispatch(setSeatMapDisplayAssignedSeat(seatCopy));
    }

    if (!extraSeatType) {
      this.globalEventService.broadcast(GlobalEvent.SEAT_ASSIGNMENT_CHANGE);
    }

    // Reset passenger after seat assignment processed
    this.store.dispatch(setSeatMapDisplayPassenger(null));
  }

  /**
   * Update the ngrx seatmap to have the current passenger info attached to the new seat
   */
  private assignSeatInSeatMapState(newSeatInfo: Seat, segmentIndex: number) {
    this.store.dispatch(setSeatMapLookupCrudStatus(Status.UPDATING));
    this.store.dispatch(updateSingleSeatMapEntity(segmentIndex, newSeatInfo));
  }

  /**
   * return the charge pricing of a seat if it exists, if not return null
   * @param seat the seat to check for pricing data
   */
  private getSeatPricing(seat: Seat): ChargePricing | null {
    return seat.upsellPrice
      ? ({
          basePrice: seat.upsellPriceBeforeTaxes,
          totalPrice: seat.upsellPrice,
          taxes: seat.upsellTaxes,
        } as ChargePricing)
      : null;
  }

  /**
   * Removes the passenger from a seat map seat assignment if they are being assigned to a new seat
   * @param passenger the passenger that is being assigned to the seat
   * @param seatMap the seat map that contains the seat that is being assigned
   * @param segmentIndex the 0 based index for the segment, used to access the correct position in the passenger seat array
   */
  private clearPassengersOldSeatIfNecessary(passenger: Passenger | undefined, seatMap: SeatMap, segmentIndex: number) {
    // If the current passenger was already assigned to a different seat, clear the current passenger from that seat
    if (passenger?.seats[segmentIndex].row) {
      const passengerSeatLoc: PassengerSeatLocation = this.passengerUseCase.getPassengerSeatLocation(passenger, segmentIndex);

      // Clear any line item charges associated with this seat
      const seatLineItemCharge = SeatLineItemCharges.get(SeatProperty[passengerSeatLoc.primaryProperty || '']);
      this.store.dispatch(removeLineItemCharge(seatLineItemCharge + passenger.hashId + segmentIndex));

      this.store.dispatch(setSeatMapDisplayPassenger(passenger));
      this.store.dispatch(setSeatMapDisplayType(SeatChangeType.CHANGED));
      this.store.dispatch(
        setSeatMapDisplayUnassignedSeat(this.seatMapUtil.getSeat(seatMap, passengerSeatLoc.row, passengerSeatLoc.letter))
      );
      this.clearPassengerSeatAssignmentFromSeatMap(passengerSeatLoc, seatMap, segmentIndex, true);
    }
  }

  /**
   * Remove the passenger reference from a seat map seat
   * @param passengerSeat the seat that is assigned on the passenger object
   * @param seatMap the seat map that contains the seat that is being assigned
   * @param suppressEvent if true, do not broadcast a seat assignment change event
   */
  private clearPassengerSeatAssignmentFromSeatMap(
    passengerSeat: PassengerSeatLocation,
    seatMap: SeatMap,
    segmentIndex: number,
    suppressEvent = false
  ) {
    const seat = this.seatMapUtil.getSeat(seatMap, passengerSeat.row, passengerSeat.letter);

    if (seat) {
      const seatCopy: Seat = {
        ...seat,
        passenger: undefined,
      };

      this.assignSeatInSeatMapState(seatCopy, segmentIndex);

      if (!suppressEvent) {
        this.store.dispatch(setSeatMapDisplayPassenger(passengerSeat.passenger));
        this.store.dispatch(setSeatMapDisplayType(SeatChangeType.UNASSIGNED));
        this.store.dispatch(setSeatMapDisplayUnassignedSeat(seatCopy));
        this.globalEventService.broadcast(GlobalEvent.SEAT_ASSIGNMENT_CHANGE);
      }
    }
  }

  public getSeatLineItemChargeType(seatPrimaryProperty: string) {
    return SeatLineItemCharges.get(SeatProperty[seatPrimaryProperty]);
  }
}
