import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Status } from '../../../models/status';
import { RootState } from '../../../state/state';
import { initializeItineraryState } from '../../itinerary-service/state/itinerary.actions';
import { FlightAvailabilityService } from '../flight-availability.service';
import {
  flightAvailabilityFailure,
  flightAvailabilitySuccess,
  loadFlightAvailability,
  setFlightAvailabilityStabilityStatus,
} from './flight-availability-service.actions';
import { getAllFlightAvailabilityResponses } from './flight-availability-service.selectors';

@Injectable()
export class FlightAvailabilityServiceEffects {
  constructor(private actions$: Actions, private flightAvailabilityService: FlightAvailabilityService, private store: Store<RootState>) {}

  getFlightAvailability$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFlightAvailability),
      withLatestFrom(this.store.pipe(select(getAllFlightAvailabilityResponses))),
      mergeMap(([{ flightAvailabilityRequest }, flightAvailabilityResponses]) => {
        const seg = flightAvailabilityRequest.flightSegment;
        const alreadyPresent =
          flightAvailabilityResponses.findIndex((f) => f.id === `${seg.departureAirportCode}${seg.arrivalAirportCode}`) >= 0;
        if (alreadyPresent) {
          return of(setFlightAvailabilityStabilityStatus(Status.STABLE));
        } else {
          this.store.dispatch(initializeItineraryState());
          return this.flightAvailabilityService.getFlightAvailability(flightAvailabilityRequest).pipe(
            map((result) => {
              return result.success ? flightAvailabilitySuccess(result) : flightAvailabilityFailure(result.error);
            })
          );
        }
      }),
      filter((action) => !!action)
    )
  );
}
