import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteNestedParam } from '../../../state/selectors';
import {
  reservationTransactionResponseAdapter,
  reservationTransactionServiceFeatureKey,
  ReservationTransactionServiceState,
} from './reservation-transaction-service.state';

/**
 * Get the entire transactions slice of state which includes waivers, refunds and charges
 */
const getReservationTransactionServiceState = createFeatureSelector<ReservationTransactionServiceState>(
  reservationTransactionServiceFeatureKey
);

/**
 * Get adapter selection methods to allow access to the transaction entities in the store
 */
const { selectEntities } = reservationTransactionResponseAdapter.getSelectors();

/**
 * Get all of the transaction objects as a dictionary accessible by id
 */
export const getAllReservationTransactionResponseEntities = createSelector(getReservationTransactionServiceState, (state) =>
  selectEntities(state.reservationTransactionResponse)
);

/**
 * Get all of the transaction objects as an entity
 */
export const getReservationTransactionResponse = createSelector(
  getAllReservationTransactionResponseEntities,
  selectRouteNestedParam('confcode'),
  (entities, confirmationCode: string) => entities[confirmationCode]
);

/**
 * Get all of the transaction objects as an array
 */
export const getReservationTransactions = createSelector(getReservationTransactionResponse, (response) => response?.reservationTransaction);

/**
 * Get a slice of all fees transactions
 */
export const getFeeTransactions = createSelector(getReservationTransactions, (reservationTransaction) => reservationTransaction?.charges);

/**
 * Get a slice of all waiver transactions
 */
export const getWaiverTransactions = createSelector(
  getReservationTransactions,
  (reservationTransaction) => reservationTransaction?.waivers
);
