import { Action, createReducer, on } from '@ngrx/store';
import {
  dismissCaller,
  initializeCallerContextState,
  setActiveCallerId,
  setCallerContextErrorMessage,
  setCallers,
} from './caller-context-service.actions';
import { callerContextAdapter, initialCallerContextState } from './caller-context-service.state';

/**
 * Handles all state changes, there is no way to change the state without a reducer AND the reducer never
 * modifies state, it clones the state from the store, changes that cloned state in some way, and then replaces
 * the entire old state with the cloned and modified state.
 */
const featureReducer = createReducer(
  initialCallerContextState,
  /**
   * Replace all callers in the store with the provided callers
   */
  on(setCallers, (state, { callers }) => ({
    ...state,
    callers: callerContextAdapter.setAll(callers, state.callers),
    errorMessage: null,
  })),
  /**
   * Set the error message on the caller context slice of state
   */
  on(setCallerContextErrorMessage, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  })),
  /**
   * Set the active caller id property in the store
   */
  on(setActiveCallerId, (state, { id }) => ({
    ...state,
    activeCallerId: id,
  })),
  /**
   * Set the dismissed flag to true on the caller that matches the provided id
   */
  on(dismissCaller, (state, { id }) => ({
    ...state,
    callers: callerContextAdapter.mapOne(
      {
        id,
        map: (caller) => ({ ...caller, dismissed: true }),
      },
      state.callers
    ),
  })),
  /**
   * Set the state to the initial value defined in the caller-context-service.state file
   */
  on(initializeCallerContextState, (state) => ({ ...state, ...initialCallerContextState }))
);

/**
 * Typescript safe way to export the reducer so that it can be imported in modules
 */
export function callerContextReducer(state = initialCallerContextState, action: Action) {
  return featureReducer(state, action);
}
