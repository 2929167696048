<div class="dynamic-baggage-container">
  <div class="baggage-advisory">
    <app-jet-stream-short-message-bar></app-jet-stream-short-message-bar>
  </div>
  <div *ngIf="!showAdvisory">
    <div class="dynamic-baggage-content" *ngIf="(bagsAndAncillaryFeesStatus$ | async) === Status.STABLE; else loadingDots">
      <div *ngIf="(passengerFees$ | async).length > 0">
        <div class="input passenger-select dynamic-baggage-passenger-dropdown">
          <select class="floating-select text" (change)="onSelect($event)">
            <option class="quantum-metric-block"
              *ngFor="let passengerFee of passengerFees$ | async; let passengerIndex = index"
              [value]="passengerFee.paxNameNumber"
              [selected]="passengerIndex === 0"
            >
              {{ passengerFee.firstName | titlecase }} {{ passengerFee.lastName | titlecase }}
            </option>
          </select>
        </div>
        <app-dynamic-baggage-table></app-dynamic-baggage-table>
      </div>
    </div>
    <ng-template #loadingDots>
      <span class="loading-dots-container">
        <app-loading-dots></app-loading-dots>
      </span>
    </ng-template>
  </div>
</div>
