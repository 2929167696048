import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Status } from 'src/app/models/status';
import { CardOnFile } from '../../../dtos/response/card-on-file-response/card-on-file';
import { RootState } from '../../../state/state';

export const cardOnFileFeatureKey = 'cardOnFileState';

export interface State extends RootState {
  cardOnFile: CardOnFileServiceState;
}

export interface CardOnFileServiceState {
  cardsOnFile: CardsOnFileEntity;
  selectedCardOnFile: CardOnFile | null;
  cardsOnFileSuccess: boolean;
  status: Status;
}

export interface CardsOnFileEntity extends EntityState<CardOnFile> {}

export const cardsOnFileAdapter: EntityAdapter<CardOnFile> = createEntityAdapter<CardOnFile>({
  selectId: (response) => {
    return response.mileagePlanNumber;
  }
});

export const initialCardsOnFileState: CardsOnFileEntity = cardsOnFileAdapter.getInitialState({});

export const initialCardOnFileServiceState: CardOnFileServiceState = {
  cardsOnFile: initialCardsOnFileState,
  selectedCardOnFile: null,
  cardsOnFileSuccess: true,
  status: Status.STABLE
};
