import { createAction } from '@ngrx/store';
import { mileagePlanAutoFillServiceFeatureKey } from './mileage-plan-auto-fill-service.state';
import { MileagePlanProfileLookupResponse } from 'src/app/dtos/response/mileage-plan-profile-lookup-response/mileage-plan-profile-lookup-response';

export const initializeMileagePlanAutoFillServiceState = createAction(`[${mileagePlanAutoFillServiceFeatureKey}] Initialize`);

/**
 * Mileage Plan Auto Fill Flow:
 * 1. Auto Fill button is clicked (MileagePlanAutoFillButtonComponent)
 * 2. Mileage Plan lookup is triggered (action -> effect -> Mileage Plan Lookup Service)
 * 3. Check if lookup was completed (MileagePlanAutoFillButtonComponent)
 * 4. Check if account lookup slice was successful (MileagePlanAutoFillButtonComponent)
 * 5. Check if account lookup data matches passenger, gather auto fill data
 * 6. Dispatch action to update passenger form with auto fill data (autoFillPassengerData())
 * 7. Dispatch action to set the flag which indicates an auto fill was triggered (setAutoFillTrigger())
 * 8. Update the forms UI to reflect the auto filled data (PassengerBioFormComponent, PassengerKtnRedressFormComponent)
 */
export const mileagePlanAutoFillProfileSearch = createAction(
  `[${mileagePlanAutoFillServiceFeatureKey}] Mileage plan auto fill profile search`,
  (mileagePlanNumber: string, passengerHashId: string, passengerId: string, autoFillOnLookup: boolean = true) => ({
    mileagePlanNumber,
    passengerHashId,
    passengerId,
    autoFillOnLookup
  })
);

export const mileagePlanAutoFillProfileSearchComplete = createAction(
  `[${mileagePlanAutoFillServiceFeatureKey}] Mileage plan auto fill profile search complete`,
  (response: MileagePlanProfileLookupResponse, passengerHashId: string, autoFillOnLookup: boolean = true) => ({
    response,
    passengerHashId,
    autoFillOnLookup
  })
);
