import { createReducer, on } from '@ngrx/store';
import { initialReservationSearchHistoryState, reservationSearchHistoryAdapter } from './reservation-search-history.state';
import { addReservationToSearchHistory, removeReservationFromSearchHistory } from './reservation-search-history.actions';

export const reservationSearchHistoryReducer = createReducer(
  initialReservationSearchHistoryState,
  on(addReservationToSearchHistory, (state, action) => {
    const maxRecentConfirmationCodeQueueCount = 20;
    const length = Object.keys(state.entities).length;

    //Adding a new confirmation code and there are already 20 confirmation codes in the list
    if (length === maxRecentConfirmationCodeQueueCount && !state.entities.hasOwnProperty(action.reservation.confirmationCode)) {
      //Remove the oldest confirmation code
      const oldestConfCode = state.ids[0].toString();
      state = reservationSearchHistoryAdapter.removeOne(oldestConfCode, state);
    }

    return reservationSearchHistoryAdapter.setOne(action.reservation, state);
  }),
  on(removeReservationFromSearchHistory, (state, action) => {
    return reservationSearchHistoryAdapter.removeOne(action.confirmationCode, state);
  })
);
