<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<div id="loginDlg" class="app-container">
  <div class="large-components">
    <div class="header">
      <span class="blue-logo" [inlineSVG]="'/assets/logo-rain-blue.svg'"></span>
    </div>
    <div class="form-wide-wrapper">
      <div class="form-tight-wrapper">
        <div class="version-title">Version {{ version }}</div>
        <div *ngIf="loginErrorMessage !== null" class="danger-text" role="alert" id="loginPageError">
          {{ loginErrorMessage }} <span *ngIf="contactCenterMessage"> <a href="https://alaskaair.sharepoint.com/sites/CallCenterIssues/SitePages/Home.aspx" target="_blank">{{ contactCenterMessage }}</a></span>
        </div>
        <form [formGroup]="formModel" (ngSubmit)="onSubmit()" novalidate>
          <div class="form-input quantum-metric-block">
            <app-input
              id="username"
              label="Sabre ID"
              [form]="formModel"
              inputName="username"
              [invalid]="(username.invalid && username.dirty && username.touched) || (username.invalid && username.touched)"
            >
            </app-input>
          </div>
          <div class="form-input quantum-metric-block">
            <app-input
              [inputType]="'password'"
              id="password"
              label="Password"
              [readonly]="false"
              [form]="formModel"
              inputName="password"
              [invalid]="(password.invalid && password.dirty && password.touched) || (password.invalid && password.touched)"
            >
            </app-input>
          </div>
          <div class="form-input">
            <label class="form-input-checkbox">
              <input class="checkbox-input" type="checkbox" value="" formControlName="rememberusername" />
              <span class="checkbox-label">Remember Sabre ID</span>
            </label>
          </div>
          <button id="logInBtn" type="submit" class="button-primary" [disabled]="formModel.invalid || (loading$ | async)">
            <ng-container *ngIf="!(loading$ | async); else loadingDots">SIGN IN</ng-container>
          </button>
        </form>
        <ng-template #loadingDots><app-loading-dots [dotColor]="'white'"></app-loading-dots></ng-template>
      </div>
      <div class="manage-password">
        <a href="http://psmc.alaskaair.com/passwordreset/" rel="noopener noreferrer" target="_blank">Manage password</a>
      </div>
    </div>
  </div>
</div>
