export enum AddSsrStatus {
  SUCCESS = 1,
  PARTIAL_SUCCESS,
  SYSTEM_FAILURE,
  TIMEOUT,
  UNCAUGHT,
  PET_REMARKS_ADD_FAILURE,
  PET_QUEUE_FAILURE,
  FULL_FAILURE,
  PASSWORD_DECRYPTION_ERROR
}
