import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ResourceCenterModule } from 'src/app/features/resource-center/resource-center.module';
import { DirectivesModule } from '../../directives/directives.module';
import { BaggageModule } from '../../features/baggage/baggage.module';
import { DrawersModule } from '../drawers/drawers.module';
import { FormInputModule } from '../form-input/form-input.module';
import { UXModule } from '../ux/ux.module';
import { CommonHeaderLayoutComponent } from './common-header-layout/common-header-layout.component';
import { NoCommonHeaderLayoutComponent } from './no-common-header-layout/no-common-header-layout.component';
import { NoCommonHeaderComponent } from './no-common-header/no-common-header.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { GlobalSearchBarComponent } from './global-search-bar/global-search-bar.component';
import { SearchComponent } from './search/search.component';
import { CallerContextModule } from 'src/app/features/caller-context/caller-context.module';
import { RecentConfirmationCodesComponent } from './recent-confirmation-codes/recent-confirmation-codes.component';
import { RecentConfirmationCodeTileComponent } from './recent-confirmation-code-tile/recent-confirmation-code-tile.component';
import { MessageBarsModule } from '../message-bars/message-bars.module';

@NgModule({
  declarations: [
    SpinnerComponent,
    CommonHeaderLayoutComponent,
    NoCommonHeaderLayoutComponent,
    NoCommonHeaderComponent,
    GlobalSearchBarComponent,
    SearchComponent,
    RecentConfirmationCodesComponent,
    RecentConfirmationCodeTileComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    InlineSVGModule,
    UXModule,
    FormInputModule,
    ReactiveFormsModule,
    DirectivesModule, // DO NOT REMOVE THIS UNTIL WE ARE READY TO RELEASE INITIAL BOOKING
    DrawersModule,
    BaggageModule,
    ResourceCenterModule,
    CallerContextModule,
    MessageBarsModule
  ],
  exports: [CommonHeaderLayoutComponent, NoCommonHeaderLayoutComponent, NoCommonHeaderComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class UxHeaderModule {}
