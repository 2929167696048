import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { exhaustMap, map, shareReplay, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Passenger } from '../dtos/response/reservation-response/passenger';
import { Reservation } from '../dtos/response/reservation-response/reservation';
import { Segment } from '../dtos/response/reservation-response/segment';
import { TicketDetailsError } from '../dtos/response/ticket-detail-response/ticket-detail-error';
import { TicketDetailsResponse } from '../dtos/response/ticket-detail-response/ticket-detail-response';
import { CouponStatusData } from '../dtos/response/vcr-response/coupon-status-data';
import { LineItemCharge } from '../models/line-item-charge/line-item-charge';
import { PassengerSegmentCoupon } from '../models/passenger-segment-coupon';
import { TicketValidationResult } from '../models/ticket-validation-result';
import { GlobalEvent, GlobalEventService } from '../services/global-event-service/global-event.service';
import { setPassengerCoupon, setPassengerCoupons } from '../services/reservation-service/state/reservation-service.actions';
import {
  getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodes,
  getRoutedReservation,
  getTicketNumbersNoVoid
} from '../services/reservation-service/state/reservation-service.selectors';
import { CouponMapper } from '../services/ticket-service/coupon-mapper';
import { TicketService } from '../services/ticket-service/ticket.service';
import { getAllLineItemCharges } from '../shared/purchase/state/purchase.selectors';
import { RootState } from '../state/state';
import { ChangeOfGaugeUseCase } from './change-of-gauge.use-case';
import { getAllPassengers, isCouponPassengerIdMatchExtraSeatPassengerId } from '../utils/passenger-helper';

@Injectable({
  providedIn: 'root',
})
export class TicketValidationUseCase {
  private canPurchaseTickets$: Observable<TicketValidationResult>;
  private killSub$ = new Subject<void>();

  constructor(
    private store: Store<RootState>,
    private eventService: GlobalEventService,
    private changeOfGaugeUseCase: ChangeOfGaugeUseCase,
    private ticketService: TicketService ) {
    // on RESET_PURCHASABILITY kill the canPurchaseTickets$ subscription by emitting on killSub$
    this.eventService.on(GlobalEvent.RESET_PURCHASABILITY).subscribe(() => this.killSub$.next());
    // delete the local property when killSub$ emits, the subscription has already ended so we need to clean up that variable
    this.killSub$.subscribe(() => (this.canPurchaseTickets$ = null));
  }

  /**
   * Evaluates the ticket details for tickets attached to the reservation and determines if the tickets are eligible for EMD purchases
   */
  public canPurchaseTickets(): Observable<TicketValidationResult> {
    if (!this.canPurchaseTickets$) {
      this.canPurchaseTickets$ = this.store.pipe(
        takeUntil(this.killSub$),
        select(getTicketNumbersNoVoid),
        exhaustMap((tickets) => this.getTicketDetailsOrReturnError(tickets)),
        withLatestFrom(
          this.store.select(getRoutedReservation),
          this.store.select(getAllLineItemCharges),
          this.store.select(getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodes)
        ),
        map(([ticketDetailsResponse, reservation, lineItemCharges, filteredSegments]) =>
          this.evaluateTicketDetailsResponse(ticketDetailsResponse, reservation, lineItemCharges, filteredSegments)
        ),
        shareReplay(1)
      );
    }
    return this.canPurchaseTickets$;
  }

  private getTicketDetailsOrReturnError(tickets: string[]): Observable<TicketDetailsResponse> {
    return tickets?.length
      ? this.ticketService.getTicketDetails(tickets)
      : of({ success: false, error: TicketDetailsError.NO_TICKETS } as TicketDetailsResponse);
  }

  private evaluateTicketDetailsResponse(
    ticketDetailsResponse: TicketDetailsResponse,
    reservation: Reservation,
    lineItemCharges: LineItemCharge[],
    filteredSegments: Segment[]
  ): TicketValidationResult {
    if (ticketDetailsResponse?.success) {
      return this.evaluateTicketDetails(ticketDetailsResponse, reservation, lineItemCharges, filteredSegments)
        ? TicketValidationResult.VALID
        : TicketValidationResult.INVALID;
    } else {
      return ticketDetailsResponse?.error === TicketDetailsError.NO_TICKETS
        ? TicketValidationResult.INVALID
        : TicketValidationResult.ERROR;
    }
  }

  /**
   * Determine if each line item charge has the correct number of valid coupons, update the coupons on each passenger
   * @param ticketDetailsResponse network response containing the full ticket detail model which contains all of the coupons
   * @param reservation active reservation that contains the passengers to run validation against
   * @param lineItemCharges charges that will each require a matching valid coupon
   */
  private evaluateTicketDetails(
    ticketDetailsResponse: TicketDetailsResponse,
    reservation: Reservation,
    lineItemCharges: LineItemCharge[],
    filteredSegments: Segment[]
  ): boolean {
    let result = true;

    // if there are no passengers, tickets are invalid
    if (!reservation.passengers?.length) {
      return false;
    }
    try {
      const allPassengers: Passenger[] = getAllPassengers(reservation);

      for (let passengerIndex = 0; result && passengerIndex < allPassengers.length; passengerIndex++) {
        // get the current passenger
        const passenger: Passenger = allPassengers[passengerIndex];
        // reset the coupons so that if validation fails the passenger does not have old coupons
        this.store.dispatch(setPassengerCoupons(reservation.confirmationCode, passenger.hashId, []));
        // get the line item charges for the passenger in this iteration of the loop
        const passengerLineItemCharges: LineItemCharge[] = lineItemCharges
          ? lineItemCharges.filter((lineItemCharge) => lineItemCharge.passengerHashId === passenger.hashId)
          : [];
        for (let lineItemChargeIndex = 0; result && lineItemChargeIndex < passengerLineItemCharges.length; lineItemChargeIndex++) {
          const passengerLineItemCharge = passengerLineItemCharges[lineItemChargeIndex];
          const passengerSegmentCoupons: any[] = ticketDetailsResponse.ticketDetails
            .flatMap((ticketDetail) => CouponMapper.getCouponsFromTicketDetail(ticketDetail))
            .filter((coupon) => this.isCouponForPassenger(coupon, passenger, reservation, passengerLineItemCharge, filteredSegments));

          // expect a VCR for each seat (default 1, + any extra seats)
          const expectedCoupons = 1 + (passenger.extraSeatRefs?.length ?? 0);

          // if number of validated VCRs is equal to expected number, add a VCR to passenger coupons
          if (passengerSegmentCoupons.length === expectedCoupons) {
            const coupon: PassengerSegmentCoupon = {
              ticketNumber: passengerSegmentCoupons[0].ticketNumber,
              couponNumber: passengerSegmentCoupons[0].couponNumber,
              status: passengerSegmentCoupons[0].status,
            };
            this.store.dispatch(
              setPassengerCoupon(reservation.confirmationCode, passenger.hashId, coupon, passengerLineItemCharge.segmentIndex)
            );
          } else {
            result = false;
          }
        }
      }
    } catch (error) {
      result = false;
    }
    return result;
  }

  /**
   * Determine if the given coupon and line item charge belongs to the given passenger
   * @param coupon the coupon to check ownership of
   * @param passenger the passenger to check against
   * @param reservation the reservation to get the segments from so that we can get the segment that matches the line item charge
   * @param passengerLineItemCharge the line item charge to check ownership of
   */
  private isCouponForPassenger(
    coupon: CouponStatusData,
    passenger: Passenger,
    reservation: Reservation,
    passengerLineItemCharge: LineItemCharge,
    filteredSegments: Segment[]
  ): boolean {
    {
      const realSegments = this.changeOfGaugeUseCase.getRealSegments(filteredSegments);
      const realSegmentIndex = this.changeOfGaugeUseCase.getRealSegmentIndex(filteredSegments, passengerLineItemCharge.segmentIndex);
      const segmentData = realSegments[realSegmentIndex];

      const result =
        coupon.isValid &&
        this.confirmPassengerChargesMatch(coupon, passengerLineItemCharge, passenger, reservation) &&
        // flight number may come back with leading 0's, cast to int to remove
        parseInt(coupon.flightNumber, 10) === parseInt(segmentData.operatingAirlineFlightNumber, 10) &&
        coupon.airlineCode === segmentData.marketedByAirlineCode &&
        new Date(coupon.departureDate).toUTCString() === new Date(segmentData.departureDateTime).toUTCString() &&
        coupon.departureAirport === segmentData.departureAirport &&
        coupon.arrivalAirport === segmentData.arrivalAirport &&
        coupon.classOfService === (segmentData.originalClassOfService ?? segmentData.serviceClassCode) &&
        ('OK' === coupon.status || 'CKIN' === coupon.status);
      return result;
    }
  }

  /**
   * Determine if the given passenger (or one of its extra seat passengers) is the owner of the given charge
   * @param coupon the service coupon data from the ticket details that needs to be owned by the same guest as the charge
   * @param charge the line item charge that needs to be owned by the same guest as the coupon
   * @param passenger the guest to check ownership against
   * @param reservation the reservation to use for additional data if a match cannot be established on passenger ID alone
   */
  private confirmPassengerChargesMatch(
    coupon: CouponStatusData,
    charge: LineItemCharge,
    passenger: Passenger,
    reservation: Reservation
  ): boolean {

    // vcr has passenger ID or there are extra seats assigned to the passenger attempt to match on name
    if(CouponMapper.IsDividedReservation(reservation)){ // Reservation has been divided
      return (
        // match on name when passenger has extra seats
        isCouponPassengerIdMatchExtraSeatPassengerId(coupon, passenger) ||
        // previous matching attempts fail, so attempt to match on name
        CouponMapper.isPassengerNameMatchFound(passenger, coupon, reservation)
      );
    }
    else{ // Normal reservation
      return (
        // match passengers id with VCR passenger ID
        coupon.passengerId === parseFloat(charge.passengerId.replace('.0', '.')).toString() ||
        // match on id when passenger has extra seats
        isCouponPassengerIdMatchExtraSeatPassengerId(coupon, passenger) ||
        // previous matching attempts fail, so attempt to match on name
        CouponMapper.isPassengerNameMatchFound(passenger, coupon, reservation)
      );
    }
  }
}
