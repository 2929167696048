import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, timeout } from 'rxjs/operators';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { PreOrderMealResponse } from 'src/app/dtos/response/pre-order-meal-response/pre-order-meal-response';
import { defer as observableDefer, Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';
import { PreOrdersByRecordLocator } from 'src/app/dtos/response/pre-order-meal-response/pre-order-by-record-locator';
import { PreOrderLookupStatus } from 'src/app/dtos/response/pre-order-meal-response/pre-order-lookup-status';
import { PreOrderMealSegmentDetail } from 'src/app/dtos/request/pre-oreder-meal-request/pre-order-meal-request-segment-detail';

export interface PreOrderMealServiceAPI {
  getPreOrderMeals(confirmationCode: string, segmentDetails: PreOrderMealSegmentDetail[]): Observable<PreOrderMealResponse>;
}

@Injectable()
export class PreOrderMealService implements PreOrderMealServiceAPI {
  constructor(private http: HttpClient, private globalEventService: GlobalEventService) {}

  getPreOrderMeals(confirmationCode: string, segmentDetails: PreOrderMealSegmentDetail[]): Observable<PreOrderMealResponse> {
    const options = {
      headers: new HttpHeaders({}),
    };
    return this.http.post<PreOrdersByRecordLocator>(`api/reservation/${confirmationCode.toUpperCase()}/meal-preorders`, segmentDetails, options).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((preOrderMealData) => {
        return { preOrdersByRecordLocator: preOrderMealData, status: PreOrderLookupStatus.SUCCESS, confirmationCode };
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.globalEventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return observableOf({ preOrdersByRecordLocator: null, status: PreOrderLookupStatus.TIMEOUT, confirmationCode });
        }
        if (404 === err.status) {
          return observableOf({ preOrdersByRecordLocator: null, status: PreOrderLookupStatus.DOES_NOT_EXIST, confirmationCode });
        }
        return observableOf({ preOrdersByRecordLocator: null, status: PreOrderLookupStatus.SYSTEM_FAILURE, confirmationCode });
      })
    );
  }
}
