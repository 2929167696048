import { Action, createReducer, on } from '@ngrx/store';
import {
  setCurrentSegmentHashId,
  setCurrentSegmentIndex,
  setSelectedPassenger,
} from './seat-map.actions';
import { initialSeatMapState } from './seat-map.state';

const featureReducer = createReducer(
  initialSeatMapState,
  on(setCurrentSegmentIndex, (state, { segmentIndex }) => {
    return {
      ...state,
      currentSegmentIndex: segmentIndex,
    };
  }),
  on(setCurrentSegmentHashId, (state, { segmentHashId }) => {
    return {
      ...state,
      currentSegmentHashId: segmentHashId,
    };
  }),
  // Store the selected passenger from the passenger list.
  on(setSelectedPassenger, (state, { selectedPassenger }) => ({
    ...state,
    selectedPassenger,
  })),
);

export function seatMapReducer(state = initialSeatMapState, action: Action) {
  return featureReducer(state, action);
}

