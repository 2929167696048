import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssistRequestedOptions } from '../../../models/forms/assist-requested-options';
import { FormKey } from '../../../models/forms/form-key';
import { formsFeatureKey, FormsState } from './forms.state';

/**
 * Get the entire forms state
 */
const getFormsState = createFeatureSelector<FormsState>(formsFeatureKey);

/**
 * Get the value for a single form
 */
export const getFormValue = (formKey: FormKey) => createSelector(getFormsState, (formState: FormsState) => formState[formKey]);

/**
 * Get the value for the wheelchair form
 */
export const getWchrForm = createSelector(getFormsState, (formState: FormsState) => formState[FormKey.wchrForm]);

/**
 * Get the value for the OTHS/TEEN form
 */
export const getOthsTeenForm = createSelector(getFormsState, (formState: FormsState) => formState[FormKey.othsTeenForm]);

/**
 * Get the selected segments from the select flights form
 */
export const getSelectedFlights = createSelector(getFormsState, (formState: FormsState) =>
  formState[FormKey.selectFlightsForm]?.segments.filter((formSegment) => formSegment.isSelected).map((formSegment) => formSegment.segment)
);

/**
 * Get all segments from the select flights form
 */
export const getAllSegments = createSelector(getFormsState, (formState: FormsState) =>
  formState[FormKey.selectFlightsForm]?.segments.map((formSegment) => formSegment.segment)
);

/**
 * Get the selected guests from the select guests form
 */
export const getSelectedGuestsPassengerDetails = createSelector(getFormsState, (formState) =>
  formState[FormKey.selectGuestsForm]?.passengerRows?.filter((formGuest) => formGuest.isSelected).map((formGuest) => formGuest.passenger)
);

/**
 * Get the selected guests from the select guests form
 */
export const getSelectedGuests = createSelector(getFormsState, (formState) =>
  formState[FormKey.selectGuestsForm]?.passengerRows.filter((formGuest) => formGuest.isSelected)
);

/**
 * Get the teen assist requested flag
 */
export const getOthsTeenAssistRequested = createSelector(
  getFormsState,
  (formState: FormsState): string | undefined => formState[FormKey.othsTeenForm]?.assistRequested
);

/**
 * Returns true if teen assist is requested, otherwise false
 */
export const getIsOthsTeenAssistRequested = createSelector(
  getOthsTeenAssistRequested,
  (value: string): boolean => AssistRequestedOptions.Requested === value
);

/**
 * Get the value for the guests pet details form
 */
export const getSelectGuestsPetForm = createSelector(getFormsState, (formState: FormsState) => formState[FormKey.selectGuestsPetForm]);

/**
 * Get the value for the multiple guests pet details form
 */
export const getSelectMultipleGuestsPetForm = createSelector(
  getFormsState,
  (formState: FormsState) => formState[FormKey.selectMultipleGuestsPetForm]
);
