import { RootState } from '../../../state/state';
import { BaggageJourneyResponse } from 'src/app/dtos/response/baggage-journey-response/baggage-journey-response';
import { Status } from 'src/app/models/status';

export const baggageServiceFeatureKey = 'baggageJourneyService';

export interface State extends RootState {
  baggageJourneyService: BaggageJourneyServiceState;
}

export interface BaggageJourneyServiceState {
  baggageJourneyResponse: BaggageJourneyResponse | null;
  status: Status;
  lastNames: string[] | null;
  confirmationCode: string | null;
  showSearchForm: boolean;
  selectedGuest: string | null;
}

export const initialBaggageJourneyServiceState: BaggageJourneyServiceState = {
  baggageJourneyResponse: null,
  status: Status.STABLE,
  lastNames: null,
  confirmationCode: null,
  showSearchForm: true,
  selectedGuest: null,
};
