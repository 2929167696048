import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { getPaymentReceived } from '../models/state/model.selectors';
import { getAllWaivers, getTotalDue } from '../shared/purchase/state/purchase.selectors';
import { RootState } from '../state/state';

@Injectable({
  providedIn: 'root',
})
export class PaymentDueGuard  {
  private isTotalDue$: Observable<boolean>;

  constructor(private store: Store<RootState>) {
    this.isTotalDue$ = this.store.pipe(
      select(getTotalDue),
      withLatestFrom(this.store.pipe(select(getPaymentReceived)), this.store.pipe(select(getAllWaivers))),
      map(([totalDue, paymentReceived, waivers]) => !paymentReceived && (totalDue > 0 || waivers?.length > 0))
    );
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isTotalDue$;
  }
  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isTotalDue$;
  }
}
