import { Action, createReducer, on } from '@ngrx/store';
import { initialPhoneFieldState } from './phone-field.state';
import { initializePhoneFieldState, savePhoneFieldComplete } from './phone-field.actions';

const featureReducer = createReducer(
  initialPhoneFieldState,
  on(initializePhoneFieldState, (state) => ({ ...state, ...initialPhoneFieldState })),
  on(savePhoneFieldComplete, (state, { response }) => ({...state, phoneFieldResponse: response})),
);

export function phoneFieldReducer(state = initialPhoneFieldState, action: Action) {
  return featureReducer(state, action);
}
