/**
 * Waiver values coming out of the AirportAncillaryService BagsAndAncillaries endpoint
 * We map these to formatted verbiage in the baggage feature
 */
export const bagsAndAncillariesWaivers = {
  VisaSignaturePlatinum: 'Bank_Of_America',
  VisaSmallBusiness: 'BOABusiness',
  WorldEliteMastercard: 'TDWorldElite',
  MVP: 'MVP',
  Gold: 'Gold',
  Gold75K: 'Gold_75',
  Gold100K: 'Gold_100',
  OneworldRuby: 'OneWorldRuby',
  OneworldSapphire: 'OneWorldSapphire',
  OneworldEmerald: 'OneWorldEmerald',
  FirstClass: 'First_Class',
  Club49: 'Club_49',
  IntraAlaska: 'Intra_AK',
  Military: 'Military',
  AgentWaiver: 'Agent_Waiver',
  OAL: 'OAL',
  DeltaBaggageAgreement: 'Delta_Silver',
  AAExecutivePlatnium: 'AA_Executive_Platinum',
  AAPlatinum: 'AA_Platinum',
  AAExecutive: 'AA_Executive',
  AAConciergeKey: 'AA_ConciergeKey',
  AAPlatinumPro: 'AA_PlatinumPro'
};
