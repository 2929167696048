import { createAction } from '@ngrx/store';
import { Status } from '../../../models/status';
import { mileagePlanProfileServiceFeatureKey } from './mileage-plan-profile-service.state';
import { MileagePlanProfileLookupResponse } from '../../../dtos/response/mileage-plan-profile-lookup-response/mileage-plan-profile-lookup-response';

export const initializeMileagePlanProfileServiceState = createAction(`[${mileagePlanProfileServiceFeatureKey}] Initialize`);

export const mileagePlanProfileSearch = createAction(`[${mileagePlanProfileServiceFeatureKey}] Mileage plan profile search`);

export const mileagePlanProfileSearchSuccess = createAction(
  `[${mileagePlanProfileServiceFeatureKey}] Mileage plan profile search success`,
  (response: MileagePlanProfileLookupResponse) => ({
    response,
  })
);

export const mileagePlanProfileSearchFailure = createAction(
  `[${mileagePlanProfileServiceFeatureKey}] Mileage plan profile search failure`,
  (response: MileagePlanProfileLookupResponse) => ({
    response,
  })
);

export const mileagePlanProfileSearchStatus = createAction(
  `[${mileagePlanProfileServiceFeatureKey}] Set mileage plan profile search stability status`,
  (status: Status) => ({
    status,
  })
);
