<div class="recent-confirmation-code-tile-container" (mouseenter)="onHover()" (mouseleave)="onLeave()">
  <a
    *ngIf="confirmationCode"
    [title]="passengerNames"
    [id]="id"
    class="{{
      this.isActive
        ? 'recent-confirmation-code-tile-link quantum-metric-encrypt'
        : 'recent-confirmation-code-tile-link quantum-metric-block'
    }}"
  >
    <div [class]="tileClasses">
      <div class="recent-confirmation-code-tile-icon-text-placement">
        <ng-container *ngIf="isHovered && !onlySingleConfirmationCode; else noHover">
          <auro-icon
            class="qm-recent-pnr-close"
            category="interface"
            name="x-lg"
            customColor
            [attr.customSize]="true"
            style="height: 16px; width: 16px; top: -2px; position: relative"
            (click)="removeConfirmationCode()"
          />
        </ng-container>
        <ng-template #noHover>
          <auro-icon
            category="interface"
            name="reservation-stroke"
            customColor
            [attr.customSize]="true"
            style="height: 16px; width: 16px; top: -2px; position: relative"
          />
        </ng-template>
        <span [routerLink]="['reservation/', confirmationCode]"> {{ confirmationCode }} </span>
      </div>
    </div>
  </a>
</div>
