import { Action, createReducer, on } from '@ngrx/store';
import { initialSeatMapV2State } from './seat-map-v2.state';
import {
  initializeSeatMapV2State,
  seatMapLookupComplete,
  setCurrentSeatMapHashId,
  setPassengerSortType,
  setSelectedSeatMapPassengerHashId,
} from './seat-map-v2.actions';
import { Status } from 'src/app/models/status';

const featureReducer = createReducer(
  initialSeatMapV2State,
  /**
   * Set the current sorting type for seat map passenger list
   */
  on(setPassengerSortType, (state, { sortType }) => ({ ...state, currentPassengerSortType: sortType })),
  /**
   * Set the seat map selected passenger hash id
   */
  on(setSelectedSeatMapPassengerHashId, (state, { passengerHashId }) => ({ ...state, selectedPassengerHashId: passengerHashId })),
  /**
   * On the completion of the seat map lookup, update the seat map data for the current segment index
   */
  on(seatMapLookupComplete, (state, { seatMapLookupResponse }) => {
    if (!seatMapLookupResponse.success) {
      return {
        ...state,
        seatMapLookupCrudStatus: Status.FAILED,
      };
    } else {
      return {
        ...state,
        seatMap: seatMapLookupResponse?.response?.seatMap,
        seatMapLookupCrudStatus: Status.STABLE,
      };
    }
  }),
  on(setCurrentSeatMapHashId, (state, { hashId }) => ({ ...state, currentSeatMapHashId: hashId })),
  on(initializeSeatMapV2State, (state) => ({ ...state, ...initialSeatMapV2State }))
);

export function seatMapV2Reducer(state = initialSeatMapV2State, action: Action) {
  return featureReducer(state, action);
}
