/**
 * Represents the current CRUD status of an object
 */
export enum Status {
  /**
   * There are no active CRUD operations for this object
   */
  STABLE = 'Stable',
  /**
   * A GET request is pending
   */
  LOADING = 'Loading',
  /**
   * A POST/PUT request is pending
   */
  UPDATING = 'Updating',
  /**
   * A POST/PUT request is pending for secure flight bio info
   */
  UPDATING_SECURE_FLIGHT_BIO_INFO = 'Updating secure flight bio info',
  /**
   * A POST/PUT request is pending for secure flight precheck info
   */
  UPDATING_SECURE_FLIGHT_PRECHECK_INFO = 'Updating secure flight precheck info',
  /**
   * A POST/PUT request is pending for removing secure flight precheck info
   */
  REMOVING_SECURE_FLIGHT_PRECHECK_INFO = 'Removing secure flight precheck info',
  /**
   * A POST/PUT request is complete for removing secure flight info
   */
   REMOVED_SECURE_FLIGHT_INFO = 'Removed secure flight info',
  /**
   * A POST/PUT request is pending for contact tracing email info
   */
  UPDATING_CONTACT_TRACING_EMAIL_INFO = 'Updating contact tracing email info',
  /**
   * A POST/PUT request is pending for contact tracing phones info
   */
  UPDATING_CONTACT_TRACING_PHONES_INFO = 'Updating contact tracing phones info',
  /**
   * A POST/PUT request is pending for contact tracing address info
   */
  UPDATING_CONTACT_TRACING_ADDRESS_INFO = 'Updating contact tracing address info',
  /**
   * A POST/PUT request is pending for removing contact tracing email info
   */
  REMOVING_CONTACT_TRACING_EMAIL_INFO = 'Removing contact tracing email info',
  /**
   * A POST/PUT request is pending for removing contact tracing phone info
   */
  REMOVING_CONTACT_TRACING_PHONE_INFO = 'Removing contact tracing phone info',
  /**
   * A POST/PUT request is pending for removing contact tracing address info
   */
  REMOVING_CONTACT_TRACING_ADDRESS_INFO = 'Removing contact tracing address info',
  /**
   * A DELETE request is pending
   */
  DELETING = 'Deleting',
  /**
   * A request is failed
   */
  FAILED = 'FAILED',

  /**
   * A request is in initial state before any action
   */
  INITIAL = 'Initial',
}
