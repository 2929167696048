import { createAction } from '@ngrx/store';
import { ContactTracingAddressRequest } from '../../../dtos/request/contact-tracing-request/contact-tracing-address-request';
import { ContactTracingEmailRequest } from '../../../dtos/request/contact-tracing-request/contact-tracing-email-request';
import { ContactTracingPhonesRequest } from '../../../dtos/request/contact-tracing-request/contact-tracing-phones-request';
import { SecureFlightInfoResponse } from '../../../dtos/response/secure-flight-info/secure-flight-info-response';
import { Status } from '../../../models/status';
import { contactTracingFeatureKey } from './contact-tracing.state';

/**
 * Resets the contact tracing info
 */
 export const initializeContactTracingState = createAction(
  `[${contactTracingFeatureKey}] Initialize contact tracing`);

/**
 * Submits the contact tracing email info to the API
 */
export const submitContactTracingEmailInfo = createAction(
  `[${contactTracingFeatureKey}] Submit contact tracing email info`,
  (request: ContactTracingEmailRequest) => ({
    request,
  })
);

/**
 * Submits the contact tracing phone info to the API
 */
 export const submitContactTracingPhonesInfo = createAction(
  `[${contactTracingFeatureKey}] Submit contact tracing phones info`,
  (request: ContactTracingPhonesRequest) => ({
    request,
  })
);

/**
 * Submits the contact tracing address info to the API
 */
 export const submitContactTracingAddressInfo = createAction(
  `[${contactTracingFeatureKey}] Submit contact tracing address info`,
  (request: ContactTracingAddressRequest) => ({
    request,
  })
);

/**
 * Updates the status of a single contact tracing info
 */
export const updateContactTracingInfoStatus = createAction(
  `[${contactTracingFeatureKey}] Update the status of a contact tracing info`,
  (hashId: string, status: Status) => ({
    hashId, status
  })
);

/**
 * Called when the contact tracing info API call is complete
 */
export const contactTracingInfoUpdateComplete = createAction(
  `[${contactTracingFeatureKey}] Contact tracing info update complete`,
  (response: SecureFlightInfoResponse, passengerHashId: string) => ({
    response,
    passengerHashId
  })
);

/**
 * Called when the user wants to remove contact tracing email info
 */
 export const removeContactTracingEmailInfo = createAction(
  `[${contactTracingFeatureKey}] Remove contact tracing email info`,
  (confirmationCode: string, ssrId: string, passengerHashId: string) => ({
    confirmationCode,
    ssrId,
    passengerHashId
  })
);

/**
 * Called when the user wants to remove contact tracing phone info
 */
 export const removeContactTracingPhoneInfo = createAction(
  `[${contactTracingFeatureKey}] Remove contact tracing phone info`,
  (confirmationCode: string, ssrId: string, passengerHashId: string) => ({
    confirmationCode,
    ssrId,
    passengerHashId
  })
);

/**
 * Called when the user wants to remove contact tracing address info
 */
 export const removeContactTracingAddressInfo = createAction(
  `[${contactTracingFeatureKey}] Remove contact tracing address info`,
  (confirmationCode: string, ssrId: string, passengerHashId: string) => ({
    confirmationCode,
    ssrId,
    passengerHashId
  })
);

/**
 * Called when the remove contact tracing info API call is complete
 */
 export const contactTracingInfoRemovalComplete = createAction(
  `[${contactTracingFeatureKey}] Contact tracing info removal complete`,
  (response: SecureFlightInfoResponse) => ({
    response,
  })
);

