import { Action, createReducer, on } from '@ngrx/store';
import { initializeFormsState, resetFormValue, setFormValue, setMultipleGuestsPetFormSegments } from './forms.actions';
import { initialFormsState } from './forms.state';
import { FormKey } from '../../../models/forms/form-key';

const featureReducer = createReducer(
  initialFormsState,
  on(setFormValue, (state, { formKey, formValue }) => ({
    ...state,
    [formKey]: formValue,
  })),
  on(setMultipleGuestsPetFormSegments, (state, { flightSegments }) => {
    const updatedPassengerRows = state[FormKey.selectMultipleGuestsPetForm].passengerRows.map((passengerRow) => {
      return {
        ...passengerRow,
        passengerPetDetailsRowArray: passengerRow.passengerPetDetailsRowArray.map((row) => ({ ...row, flightSegments })),
      };
    });
    return {
      ...state,
      [FormKey.selectMultipleGuestsPetForm]: { ...state[FormKey.selectMultipleGuestsPetForm], passengerRows: updatedPassengerRows },
    };
  }),
  on(resetFormValue, (state, { formKey }) => ({
    ...state,
    [formKey]: null,
  })),
  on(initializeFormsState, (state) => ({ ...state, ...initialFormsState }))
);

export function formsReducer(state = initialFormsState, action: Action) {
  return featureReducer(state, action);
}
