export enum TimeType {
  YEAR,
  MONTH,
  DAY,
}
/**
 * Calculates an offset date based on an input date, a time type, and an amount to increment by
 * @param date - the reference date for the offset
 * @param offset - object containing the type and amount of the offset
 * @example
 * calculateOffsetDate('01-01-1990', {type: TimeType.Year, amount: 1}) returns a date of '01-01-1991'
 * calculateOffsetDate('01-01-1990', {type: TimeType.Year, amount: -1}) returns a date of '01-01-1990'
 * calculateOffsetDate('01-01-1990', {type: TimeType.MONTH, amount: 1}) returns a date of '02-01-1991'
 * calculateOffsetDate('01-01-1990', {type: TimeType.MONTH, amount: -1}) returns a date of '12-01-1990'
 * calculateOffsetDate('01-01-1990', {type: TimeType.DAY, amount: 1}) returns a date of '01-02-1991'
 * calculateOffsetDate('01-01-1990', {type: TimeType.DAY, amount: -1}) returns a date of '12-31-1990'
 * calculateOffsetDate('01-01-1990', {type: TimeType.INVALID, amount: 1}) returns a date of '01-01-1990'
 */
export function calculateOffsetDate(date: Date, offset: { type: TimeType; amount: number }): Date {
  const tempDate = new Date(date);
  switch (offset.type) {
    case TimeType.YEAR:
      tempDate.setFullYear(date.getFullYear() + offset.amount);
      break;
    case TimeType.MONTH:
      tempDate.setMonth(date.getMonth() + offset.amount);
      break;
    case TimeType.DAY:
      tempDate.setDate(date.getDate() + offset.amount);
      break;
    default:
      break;
  }
  return tempDate;
}
