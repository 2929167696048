import { Action, createReducer, on } from '@ngrx/store';
import {
  initializeReservationTransactionServiceState,
  loadTransactionComplete,
  loadTransactionFailure,
  loadTransactions,
} from './reservation-transaction-service.actions';
import { initialReservationTransactionServiceState, reservationTransactionResponseAdapter } from './reservation-transaction-service.state';

const featureReducer = createReducer(
  initialReservationTransactionServiceState,
  on(loadTransactions, (state) => ({ ...state })),
  on(loadTransactionComplete, (state, { reservationTransactionResponse }) => {
    return {
      ...state,
      reservationTransactionResponse: reservationTransactionResponseAdapter.setOne(
        reservationTransactionResponse,
        state.reservationTransactionResponse
      ),
    };
  }),
  on(loadTransactionFailure, (state, { errorTransactionResponse }) => {
    return {
      ...state,
      reservationTransactionResponse: reservationTransactionResponseAdapter.setOne(
        errorTransactionResponse,
        state.reservationTransactionResponse
      ),
    };
  }),
  on(initializeReservationTransactionServiceState, (state) => ({ ...state, ...initialReservationTransactionServiceState }))
);

export function reservationTransactionServiceReducer(state = initialReservationTransactionServiceState, action: Action) {
  return featureReducer(state, action);
}
