import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ReservationTransactionResponse } from '../../../dtos/response/reservation-transaction-response/reservation-transaction-response';
import { RootState } from '../../../state/state';

export const reservationTransactionServiceFeatureKey = 'reservationTransactionService';

export interface State extends RootState {
  reservationTransactionService: ReservationTransactionServiceState;
}

export interface ReservationTransactionServiceState {
  reservationTransactionResponse: ReservationTransactionEntity;
}

export interface ReservationTransactionEntity extends EntityState<ReservationTransactionResponse> {}

// eslint-disable-next-line max-len
export const reservationTransactionResponseAdapter: EntityAdapter<ReservationTransactionResponse> = createEntityAdapter<ReservationTransactionResponse>(
  {
    selectId: (reservationTransactionResponse) => reservationTransactionResponse?.reservationTransaction?.confirmationCode,
  }
);

export const initialReservationTransactionState: ReservationTransactionEntity = reservationTransactionResponseAdapter.getInitialState({});

export const initialReservationTransactionServiceState: ReservationTransactionServiceState = {
  reservationTransactionResponse: initialReservationTransactionState,
};
