import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class BrowserGuard  {
  isChrome: boolean;
  private _router: Router;

  constructor(router: Router) {
    this._router = router;
    // must exclude edge due to chromium edge containing "Chrome" in the user agent string
    this.isChrome = navigator.userAgent.includes('Chrome') && !navigator.userAgent.includes('Edg');
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.isChrome) {
      this._router.navigateByUrl('browser-blocker');
      return false;
    }
    return true;
  }
}
