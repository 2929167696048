import { Action, createReducer, on } from '@ngrx/store';
import { SeatMap } from '../../../models/seat-map/seat-map';
import { Status } from '../../../models/status';
import {
  assignSeats,
  assignSeatsComplete,
  initializeSeatsServiceState,
  reservationSeatMapLookupComplete,
  setSeatMapLookupCrudStatus,
  setStaticSeatMapIncludeSsrs,
  setStaticSeatMapRequest,
  updateSingleSeatMapEntity,
} from './seats-service.actions';
import { initialSeatsServiceState, seatMapAdapter } from './seats-service.state';
/**
 * Handles all state changes, there is no way to change the state without a reducer AND the reducer never
 * modifies state, it clones the state from the store, changes that cloned state in some way, and then replaces
 * the entire old state with the cloned and modified state.
 */
const featureReducer = createReducer(
  initialSeatsServiceState,
  /**
   * On the completion of the seat map lookup, update the seat map data for the current segment index
   */
  on(reservationSeatMapLookupComplete, (state, { response }) => {
    if (response.segmentIndex !== null && response.segmentIndex !== undefined && response.segmentIndex !== -1) {
      return {
        ...state,
        seatMaps: seatMapAdapter.setOne(response, { ...state.seatMaps }),
        seatMapLookupCrudStatus: Status.STABLE,
      };
    }

    if (!response.success) {
      return {
        ...state,
        seatMapLookupCrudStatus: Status.FAILED,
      };
    } else {
      return state;
    }
  }),

  /**
   * Set the crud status for an assign seats request
   */
  on(assignSeats, (state, { request }) => ({
    ...state,
    assignSeatsCrudStatus: Status.LOADING,
  })),

  /**
   * Set the crud status and the response status when the assign seats request completes
   */
  on(assignSeatsComplete, (state, { response }) => ({
    ...state,
    assignSeatsCrudStatus: Status.STABLE,
    assignSeatsStatus: response,
  })),
  on(updateSingleSeatMapEntity, (state, { segmentIndex, newSeat }) => {
    const currentSeatMap: SeatMap = state.seatMaps.entities[segmentIndex].processedSeatMap;
    const updatedRows = [];
    for (const row of currentSeatMap.rows) {
      if (row.number === newSeat.row) {
        const updatedSeatDatas = [];
        for (const seatData of row.seats) {
          if (seatData.letter === newSeat.letter) {
            updatedSeatDatas.push(newSeat);
          } else if (seatData.passenger?.hashId === newSeat.passenger?.hashId) {
            // If seat was previously assigned to CBBG/EXST, unassign it
            updatedSeatDatas.push({ ...seatData, passenger: undefined });
          } else {
            updatedSeatDatas.push(seatData);
          }
        }
        const updatedSeats = [];
        for (const seat of row.seats) {
          if (seat.letter === newSeat.letter) {
            updatedSeats.push(newSeat);
          } else if (seat.passenger?.hashId === newSeat.passenger?.hashId) {
            // If seat was previously assigned to CBBG/EXST, unassign it
            updatedSeats.push({ ...seat, passenger: undefined });
          } else {
            updatedSeats.push(seat);
          }
        }
        updatedRows.push({
          ...row,
          seatDatas: updatedSeatDatas,
          seats: updatedSeats,
        });
      } else {
        // Ensure removal of CBBG/EXST seats from seat map display.
        const updatedSeatDatas = [];
        for (const seatData of row.seats) {
          if (seatData.passenger?.hashId === newSeat.passenger?.hashId) {
            // If seat was previously assigned to CBBG/EXST, unassign it
            updatedSeatDatas.push({ ...seatData, passenger: undefined });
          } else {
            updatedSeatDatas.push(seatData);
          }
        }
        const updatedSeats = [];
        for (const seat of row.seats) {
          if (seat.passenger?.hashId === newSeat.passenger?.hashId) {
            // If seat was previously assigned to CBBG/EXST, unassign it
            updatedSeats.push({ ...seat, passenger: undefined });
          } else {
            updatedSeats.push(seat);
          }
        }
        updatedRows.push({
          ...row,
          seatDatas: updatedSeatDatas,
          seats: updatedSeats,
        });
      }
    }

    const updatedSeatMap = {
      ...currentSeatMap,
      rows: updatedRows,
    };

    return {
      ...state,
      seatMaps: seatMapAdapter.updateOne(
        {
          id: segmentIndex,
          changes: {
            processedSeatMap: updatedSeatMap,
          },
        },
        state.seatMaps
      ),
      seatMapLookupCrudStatus: Status.STABLE,
    };
  }),
  on(setSeatMapLookupCrudStatus, (state, { status }) => ({
    ...state,
    seatMapLookupCrudStatus: status,
  })),
  on(setStaticSeatMapRequest, (state, { seatMapLookupRequest }) => ({
    ...state,
    staticSeatMapRequest: seatMapLookupRequest,
  })),
  on(setStaticSeatMapIncludeSsrs, (state, { staticSeatMapIncludeSsrs }) => ({
    ...state,
    staticSeatMapIncludeSsrs,
  })),
  /**
   * Set the state to the initial value defined in the ticket-service.state file
   */
  on(initializeSeatsServiceState, (state) => ({ ...state, ...initialSeatsServiceState }))
);

/**
 * Typescript safe way to export the reducer so that it can be imported in modules
 */
export function seatsServiceReducer(state = initialSeatsServiceState, action: Action) {
  return featureReducer(state, action);
}
