import { TicketDesignator } from './ticket-designator';

export const bulkOpaqueDesignators: string[] = [
  TicketDesignator.XPO1,
  TicketDesignator.XPE1,
  TicketDesignator.XPH1,
  TicketDesignator.WHL1,
  TicketDesignator.WHL2,
  TicketDesignator.WHL3,
  TicketDesignator.WHL4,
  TicketDesignator.WHL5,
  TicketDesignator.WHL6,
  TicketDesignator.WHL7,
  TicketDesignator.WHL8,
  TicketDesignator.WHL9,
  TicketDesignator.PKGE,
  TicketDesignator.PKGT,
  TicketDesignator.PKGP,
];
