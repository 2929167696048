import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CareKitRequest } from '../../../dtos/request/care-kit-request/care-kit-request';
import { Compensation } from '../../../dtos/response/compensation-response/compensation';
import { RootState } from '../../../state/state';

export const compensationServiceFeatureKey = 'compensationService';

export interface State extends RootState {
  compensationService: CompensationServiceState;
}

export interface CompensationServiceState {
  careKitRequest: CareKitRequest | null;
  careKitRequestCompensationType: string | null;
  compensation: CompensationEntity;
  success: boolean | null;
  errorMessage: string | null;
  loaded: boolean;
}

export interface CompensationEntity extends EntityState<Compensation> {}

export const compensationAdapter: EntityAdapter<Compensation> = createEntityAdapter<Compensation>({
  selectId: (response) => response.hashId,
});

export const initialCompensationState: CompensationEntity = compensationAdapter.getInitialState({});

export const initialCompensationServiceState: CompensationServiceState = {
  careKitRequest: null,
  careKitRequestCompensationType: null,
  compensation: initialCompensationState,
  success: null,
  errorMessage: null,
  loaded: false
};
