import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { RootState } from '../../../state/state';
import { ReservationTransactionService } from '../reservation-transaction.service';
import { loadTransactionComplete, loadTransactionFailure, loadTransactions } from './reservation-transaction-service.actions';
import { getReservationTransactionResponse } from './reservation-transaction-service.selectors';

@Injectable()
export class ReservationTransactionServiceEffects {
  constructor(
    private actions$: Actions,
    private reservationTransactionService: ReservationTransactionService,
    private store: Store<RootState>
  ) {}

  getTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTransactions),
      mergeMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.select(getReservationTransactionResponse))
        )
      ),
      mergeMap(([action, cachedReservationTransactionResponse]) => {
        if (cachedReservationTransactionResponse?.success === true) {
          return of(loadTransactionComplete(cachedReservationTransactionResponse));
        }
        return this.reservationTransactionService
          .getTransactions(action.confirmationCode, action.lastNames)
          .pipe(map((transactionResponse) => loadTransactionComplete(transactionResponse)));
      }),
      catchError(() => of(loadTransactionFailure()))
    )
  );
}
