import { ActionCode } from './action-codes';

/**
 * Filtered action codes - this comes from the old 'allsegments' list filtering in the API
 */
export const waitlistActionCodes: ReadonlyArray<ActionCode> = [
  ActionCode.HL,
  ActionCode.LL,
  ActionCode.JL,
  ActionCode.BL,
  ActionCode.WL,
];
