import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { TierStatus } from '../../../models/tier-status';
import { getTierStatusFromText } from '../../../utils/tier-status-helper';

@Component({
  selector: 'app-passenger-loyalty-icon',
  templateUrl: './passenger-loyalty-icon.component.html',
  styleUrls: ['./passenger-loyalty-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassengerLoyaltyIconComponent implements OnChanges {
  @Input() tierStatus: string;
  @Input() millionMiler: number | string;
  @Input() large = false;
  @Input() noTopMargin = false;
  type: string;
  category: string;
  title: string;

  ngOnChanges() {
    const tierStatus: TierStatus = getTierStatusFromText(this.tierStatus);
    switch (tierStatus) {
      case TierStatus.GOLD_100K: {
        this.type = '100k';
        this.category = 'elite';
        this.title = 'MVP Gold 100K';
        break;
      }
      case TierStatus.GOLD_75K: {
        this.type = '75k';
        this.category = 'elite';
        this.title = 'MVP Gold 75K';
        break;
      }
      case TierStatus.GOLD: {
        this.type = 'gold';
        this.category = 'elite';
        this.title = 'MVP Gold';
        break;
      }
      case TierStatus.MVP: {
        this.type = 'mvp';
        this.category = 'elite';
        this.title = 'MVP';
        break;
      }
      case TierStatus.EMD: {
        this.type = 'emerald';
        this.category = 'oneworld';
        this.title = 'Emerald';
        break;
      }
      case TierStatus.SPH: {
        this.type = 'sapphire';
        this.category = 'oneworld';
        this.title = 'Sapphire';
        break;
      }
      case TierStatus.RBY: {
        this.type = 'ruby';
        this.category = 'oneworld';
        this.title = 'Ruby';
        break;
      }
      default: {
        this.type = null;
        this.category = null;
        this.title = null;
        break;
      }
    }
    if (typeof this.millionMiler === 'string') {
      const millionMilerAsInt = parseInt(this.millionMiler, 10);
      this.mapMillionMiler(millionMilerAsInt);
    } else {
      this.mapMillionMiler(this.millionMiler);
    }
  }

  mapMillionMiler(millionMiler: number) {
    if (millionMiler === 1) {
      this.type = 'mm';
      this.category = 'elite';
      this.title = 'Million Miler Flyer';
    }
    if (millionMiler >= 2) {
      this.type = '2mm';
      this.category = 'elite';
      this.title = '2 Million Miler Flyer';
    }
  }
}
