import { Action, createReducer, on } from '@ngrx/store';
import { initializeLoginServiceState, login, loginComplete } from './login-service.actions';
import { initialLoginServiceState } from './login-service.state';

const featureReducer = createReducer(
  initialLoginServiceState,
  on(login, (state) => ({ ...state, ...initialLoginServiceState, loading: true })),
  on(loginComplete, (state, { user, status }) => ({ ...state, user, status, loading: false })),
  on(initializeLoginServiceState, (state) => ({ ...state, ...initialLoginServiceState }))
);

export function loginServiceReducer(state = initialLoginServiceState, action: Action) {
  return featureReducer(state, action);
}
