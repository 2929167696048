import { createAction } from '@ngrx/store';
import { preorderMealServiceFeatureKey } from './preorder-meal-service.state';
import { PreOrderMealResponse } from 'src/app/dtos/response/pre-order-meal-response/pre-order-meal-response';

export const initializePreorderMealServiceState = createAction(`[${preorderMealServiceFeatureKey}] Initialize`);


export const clearPreOrderedMeals = createAction(`[${preorderMealServiceFeatureKey}] Clear preorder meals`);

export const loadPreOrderedMeals = createAction(`[${preorderMealServiceFeatureKey}] Load preorder meals`, (recordLocator: string) => ({
  recordLocator,
}));

export const loadPreOrderedMealsComplete = createAction(
  `[${preorderMealServiceFeatureKey}] Load Preorder Meal Complete`,
  (preOrderMealResponse: PreOrderMealResponse | null) => ({
    preOrderMealResponse,
  })
);
