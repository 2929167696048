import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { RootState } from '../../../state/state';
import {
  getCancelledFlightCount,
  getChangedFlightCount,
  getFlightCount,
  getKeptFlightCount
} from '../../flight-availability-service/state/flight-availability-service.selectors';
import { getSelectedInvoluntaryChangeOption } from '../../reservation-service/state/reservation-service.selectors';
import { ItineraryService } from '../itinerary.service';
import {
  reissueComplete,
  reissueMetricsLogged,
  revalComplete,
  revalFailureLogged,
  submitIrropReissue,
  submitReval,
  submitRevalForIrropReissue,
  submitRevalForScheduleChangeReissue,
  submitScheduleChangeReissue,
  submitScheduleChangeReissueAcceptFlights,
  submitSdcReval
} from './itinerary.actions';
import {
  getIrropReissueNewFlightsRequest,
  getRevalRequest,
  getScheduleChangeReissueNewFlightsRequest,
  getScheduleReissueRequestAcceptFlights,
  getSdcRevalRequest
} from './itinerary.selectors';

@Injectable()
export class ItineraryEffects {
  constructor(private actions$: Actions, private itineraryService: ItineraryService, private store: Store<RootState>) {}

  /**
   * Effect for handling a reval when the guest accepts changes
   */
  submitReval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitReval),
      withLatestFrom(this.store.select(getRevalRequest)),
      mergeMap(([action, request]) => {
        return this.itineraryService.changeItineraryAndRevalidateTicket(request).pipe(map((result) => revalComplete(result)));
      })
    )
  );

  /**
   * Effect for handling an SDC reval
   */
  submitSdcReval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitSdcReval),
      withLatestFrom(this.store.select(getSdcRevalRequest)),
      mergeMap(([action, request]) => {
        return this.itineraryService.changeItineraryAndRevalidateTicket(request).pipe(map((result) => revalComplete(result)));
      })
    )
  );

  /**
   * Effect for handling a reval when the guest is eligible for reval instead of schedule change reissue
   */
  submitRevalForScheduleChangeReissue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitRevalForScheduleChangeReissue),
      withLatestFrom(this.store.select(getScheduleChangeReissueNewFlightsRequest)),
      mergeMap(([action, request]) => {
        return this.itineraryService.changeItineraryAndRevalidateTicket(request).pipe(map((result) => revalComplete(result)));
      })
    )
  );

  /**
   * Effect for handling a reval when the guest is eligible for reval instead of schedule change reissue
   */
  submitRevalForIrropReissue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitRevalForIrropReissue),
      withLatestFrom(this.store.select(getIrropReissueNewFlightsRequest)),
      mergeMap(([action, request]) => {
        return this.itineraryService.changeItineraryAndRevalidateTicket(request).pipe(map((result) => revalComplete(result)));
      })
    )
  );

  /**
   * Effect for handling a schedule change reissue when the guest accepts the flights as is
   */
  submitScheduleChangeReissueAcceptFlights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitScheduleChangeReissueAcceptFlights),
      withLatestFrom(this.store.select(getScheduleReissueRequestAcceptFlights)),
      mergeMap(([action, request]) => {
        return this.itineraryService.changeItineraryAndReissueTicket(request).pipe(map((result) => reissueComplete(result)));
      })
    )
  );

  /**
   * Effect for handling a schedule change reissue where the guest changes the flights
   */
  submitScheduleChangeReissue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitScheduleChangeReissue),
      withLatestFrom(this.store.select(getScheduleChangeReissueNewFlightsRequest)),
      mergeMap(([action, request]) => {
        return this.itineraryService.changeItineraryAndReissueTicket(request).pipe(map((result) => reissueComplete(result)));
      })
    )
  );

  /**
   * Effect for handling an IRROP reissue where the guest changes the flights
   */
  submitIrropReissue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitIrropReissue),
      withLatestFrom(this.store.select(getIrropReissueNewFlightsRequest)),
      mergeMap(([action, request]) => {
        return this.itineraryService.changeItineraryAndReissueTicket(request).pipe(map((result) => reissueComplete(result)));
      })
    )
  );

  /**
   * Effect for handling a reval failure for either schedule change or irrop
   */
  logRevalFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(revalComplete),
      withLatestFrom(this.store.select(getSelectedInvoluntaryChangeOption)),
      mergeMap(([action, involType]) => {
        return of(revalFailureLogged());
      })
    )
  );

  logReissueMetrics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reissueComplete),
      withLatestFrom(
        this.store.pipe(select(getChangedFlightCount)),
        this.store.pipe(select(getKeptFlightCount)),
        this.store.pipe(select(getCancelledFlightCount)),
        this.store.pipe(select(getFlightCount)),
        this.store.pipe(select(getSelectedInvoluntaryChangeOption))
      ),
      mergeMap(([action, changedFlightCount, keptFlightCount, cancelledFlightCount, totalFlights, ticketChangeType]) => {
        return of(reissueMetricsLogged());
      })
    )
  );
}
