// tslint:disable: no-unused-expression
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GDSTicketsCloudModelsResponsesElectronicMiscDocument } from 'src/app/dtos/response/gds-ticket-cloud-response/gDSTicketsCloudModelsResponsesElectronicMiscDocument';
import { GDSTicketsCloudModelsResponsesTicket } from 'src/app/dtos/response/gds-ticket-cloud-response/gDSTicketsCloudModelsResponsesTicket';
import { TransactionSegment } from 'src/app/dtos/response/reservation-transaction-response/transaction-segment';
import { AncillaryFeeDetails } from 'src/app/models/fees/ancillary-fee-details';
import { AncillaryFeesInfo } from 'src/app/models/fees/ancillary-fees-info';
import { EMDTicketDetail } from '../../../dtos/response/emd-ticket-detail-response/emd-ticket-detail';
import { GDSTicketsCloudModelsResponsesServiceCouponTicket } from '../../../dtos/response/gds-ticket-cloud-response/gDSTicketsCloudModelsResponsesServiceCouponTicket';
import { GDSTicketsCloudModelsResponsesTicketDetail } from '../../../dtos/response/gds-ticket-cloud-response/gDSTicketsCloudModelsResponsesTicketDetail';
import { Passenger } from '../../../dtos/response/reservation-response/passenger';
import { Segment } from '../../../dtos/response/reservation-response/segment';
import { CouponStatusData } from '../../../dtos/response/vcr-response/coupon-status-data';
import { FareType } from '../../../dtos/response/vcr-response/fare-type';
import { ReservationRevalEligibilityType } from '../../../models/reservation-reval-eligibility-type';
import { Status } from '../../../models/status';
import { TicketSearchResultRow } from '../../../models/ticket-search-result-row';
import {
  getAllPastDatedSegmentsExcludingARNK,
  getAllSegments,
  getAnySegmentOperatedByNonAirgroupAirline,
  getConfirmedAndPastDatedSegments,
  getHasItinerary,
  getHasItineraryV2,
  getHasValidPhoneField,
  getInvoluntaryChangeSegmentsGreaterThanSixDaysDeparture,
  getInvoluntaryChangeSegmentsGreaterThanSixDaysDepartureV2,
  getIsDividedReservation,
  getIsTicketIssuedByOtherAirline,
  getPassengers,
  getPassengersAndExtraSeatPassengers,
  getPassengersAndExtraSeatPassengersCount,
  getTicketNumbersNoVoid,
} from '../../reservation-service/state/reservation-service.selectors';
import { CouponMapper } from '../coupon-mapper';
import {
  EMDAdapter,
  TicketServiceState,
  ancillaryTicketDetailsAdapter,
  couponAdapter,
  searchResultsAdapter,
  ticketDetailsAdapter,
  ticketServiceFeatureKey,
} from './ticket-service.state';
import { bulkOpaqueDesignators } from '../../../models/tickets/bulk-opaque-ticket-designators';
import { selectRouteNestedParam } from 'src/app/state/selectors';

// selectors for coupon entities
const { selectEntities: selectCouponEntities, selectAll: selectAllCoupons } = couponAdapter.getSelectors();

// selectors for search result entities
const { selectAll: selectAllSearchResults } = searchResultsAdapter.getSelectors();

// selectors for EMD entities
const { selectEntities: selectEMDEntities } = EMDAdapter.getSelectors();

// selectors for ticket details entities
const { selectAll: selectAllTicketDetails } = ticketDetailsAdapter.getSelectors();

// selectors for ticket detail entities
const { selectEntities: selectTicketDetailsEntities } = ticketDetailsAdapter.getSelectors();

// selectors for ancillary ticket details entities
const { selectEntities: selectAncillaryTicketDetailsEntities, selectAll: selectAllAncillaryTicketDetails } =
  ancillaryTicketDetailsAdapter.getSelectors();

/**
 * Get the entire Ticket Service slice of state, not for use outside of the selector file
 */
const getTicketServiceState = createFeatureSelector<TicketServiceState>(ticketServiceFeatureKey);

// probably want to use this one below for auto-waivers stuff
/**
 * Get all coupons as a dictionary with the key being the ticket number + coupon number and the value being the coupon
 */
export const getAllCouponEntities = createSelector(
  getTicketServiceState,
  (state): Dictionary<CouponStatusData> => selectCouponEntities(state.coupons)
);

/**
 * Get all coupons as an array
 */
export const getAllCoupons = createSelector(getTicketServiceState, (state) => selectAllCoupons(state.coupons));

/**
 * Get all coupons that are in an OK status
 */
export const getAllOKCoupons = createSelector(getAllCoupons, (coupons: CouponStatusData[]) => coupons.filter((c) => c.status === 'OK'));

/**
 * Get all coupons that are in an OK and CKIN status
 */
export const getAllOKAndCKINCoupons = createSelector(getAllCoupons, (coupons: CouponStatusData[]) =>
  coupons.filter((c) => c.status === 'OK' || c.status === 'CKIN')
);

/**
 * Get OK/USED coupons that are associated with a ticket in which the ticket has entirely OK or USED coupons, with ARNK support.
 * Important note: ARNK coupons WILL NOT be returned.
 * Examples:
 *   A ticket contains all OK coupons. Return all OK coupons.
 *   A ticket contains an OK coupon and a USED coupon. Return OK/USED coupons.
 *   A ticket contains an OK, USED, and ARNK coupon. Return OK/USED coupons.
 *   A ticket contains OK/EXCH coupons. Return NO coupons.
 * This selector is only used to build up the getPassengerNumbersAndTickets selector as of 4/15/22.
 */
export const getOnlyOKUsedCoupons = createSelector(getAllCoupons, (coupons: CouponStatusData[]) => {
  const ticketsWithNonOKUsedCoupons = coupons
    .filter((c) => c.status !== 'OK' && c.status !== 'USED' && c.status !== 'VOID')
    .map((coupon) => coupon.ticketNumber);
  return coupons.filter((c) => (c.status === 'OK' || c.status === 'USED') && !ticketsWithNonOKUsedCoupons.includes(c.ticketNumber));
});

/**
 * Get all coupons that are in an OK or USED status
 */
export const getAllOKAndUsedCoupons = createSelector(getAllCoupons, (coupons: CouponStatusData[]) =>
  coupons.filter((c) => c.status === 'OK' || c.status === 'USED')
);

/**
 * Get all coupons that are in an OK, USED or CKIN status. Used for SDC.
 */
export const getAllOKUsedAndCkinCoupons = createSelector(getAllCoupons, (coupons: CouponStatusData[]) =>
  coupons.filter((c) => c.status === 'OK' || c.status === 'USED' || c.status === 'CKIN')
);

/**
 * Get all coupons that are in an OK or USED status, excluding coupons corresponding
 * to segments that are no longer in the face of the PNR (isPast segment).
 */
export const getAllOKAndUsedCouponsExcludingIsPastSegmentCoupons = createSelector(
  getAllCoupons,
  getAllSegments,
  getAllPastDatedSegmentsExcludingARNK,
  (coupons: CouponStatusData[], segments: Segment[], pastDatedSegments: Segment[]) =>
    coupons.filter((c) => {
      const couponHasMatchingSegment =
        segments?.find((segment) => segment.departureAirport === c.departureAirport && segment.arrivalAirport === c.arrivalAirport);
      const isPastDated = pastDatedSegments?.find(segment => segment.hashId === couponHasMatchingSegment?.hashId);

      // Return only USED coupons that map to a segment and not a past dated segment
      return c.status === 'OK' || (c.status === 'USED' && couponHasMatchingSegment && !isPastDated);
    })
);

/**
 * Get all ticketDesignators as an array
 */
export const getAllTicketDesignators = createSelector(
  getAllCoupons,
  (coupons: CouponStatusData[]) => coupons?.filter((coupon) => coupon.ticketDesignator).map((coupon) => coupon.ticketDesignator) ?? []
);

/**
 * THIS STYLE SHOULD BE USED SPARINGLY. TO UNIT TEST YOU WILL NEED TO MOCK THE UNDERLYING SELECTOR CALL (getAllCoupons)
 * Get coupons where supplied "property" of coupon equals supplied "value" (agnostic of type). Returns as an array of CouponStatusData
 */
/* eslint-disable eqeqeq */
// @ts-ignore
export const getCouponsByProperty = (property: keyof CouponStatusData, value: any) =>
  createSelector(getAllCoupons, (coupons: CouponStatusData[]) => coupons.filter((coupon: CouponStatusData) => coupon[property] == value));

/**
 * Get coupon which does have arnk segment as any array filtered by ticket number
 */
export const getNonArnkCouponsByTicketNumber = (ticketNumber: string) =>
  createSelector(getAllCoupons, (coupons: CouponStatusData[]) =>
    coupons.filter((coupon) => coupon.ticketNumber === ticketNumber && coupon.flightNumber !== 'VOID')
  );

/**
 * Get the entire Ticket Service slice of state
 */
export const getVcrState = createSelector(getTicketServiceState, (state) => state);

/**
 * Get the loading status of the VCR lookup call
 */
export const getVcrLoading = createSelector(getTicketServiceState, (state) => state.vcrLoading);

/**
 * Get ticket search results as an array
 */
export const getAllTicketSearchResults = createSelector(getTicketServiceState, (state): TicketSearchResultRow[] =>
  selectAllSearchResults(state.searchResults)
);

/**
 * Get the status enum of the ticket search call, represents the current network status of the ticket search
 */
export const getTicketSearchStatus = createSelector(getTicketServiceState, (state) => state.searchStatus);

/**
 * Get the error enum of the ticket search call
 */
export const getTicketSearchError = createSelector(getTicketServiceState, (state) => state.searchError);

/**
 * Get the start time for the ticket search form for manual assocciation
 */
export const getTicketSearchStartTime = createSelector(getTicketServiceState, (state) => state.searchStartTime);

/**
 * Get ticket search results where pinned is true
 */
export const getAllPinnedTicketSearchResults = createSelector(getAllTicketSearchResults, (ticketSearchResults): TicketSearchResultRow[] =>
  ticketSearchResults.filter((result) => result.pinned)
);

/**
 * Get the number of ticket search results where pinned is true
 */
export const getPinnedTicketSearchResultCount = createSelector(
  getAllPinnedTicketSearchResults,
  (pinnedTicketSearchResults): number => pinnedTicketSearchResults?.length ?? 0
);

/**
 * Get ticket search results where pinned is false
 */
export const getAllUnPinnedTicketSearchResults = createSelector(getAllTicketSearchResults, (ticketSearchResults): TicketSearchResultRow[] =>
  ticketSearchResults.filter((result) => !result.pinned)
);

/**
 * Get ticket search results where selected is true
 */
export const getAllSelectedTicketSearchResults = createSelector(getAllTicketSearchResults, (ticketSearchResults): TicketSearchResultRow[] =>
  ticketSearchResults.filter((result) => result.selected)
);

/**
 * Get all ticket numbers that belong to a ticket search result row that has been selected
 */
export const getAllSelectedTicketNumbers = createSelector(getAllSelectedTicketSearchResults, (selectedTicketSearchResults): string[] =>
  selectedTicketSearchResults.map((result) => result.eTicketNumber)
);

/**
 * Get all coupons that belong to a ticket number that belongs to a ticket search result row that has been selected
 */
export const getCouponsForSelectedTicketSearchResults = createSelector(
  getAllCoupons,
  getAllSelectedTicketNumbers,
  (coupons: CouponStatusData[], selectedTicketNumbers: string[]) =>
    coupons.filter((coupon) => selectedTicketNumbers.includes(coupon.ticketNumber))
);

/**
 * Get coupons that are non arnk segments (segments which have flight number as void and coupon status void)
 */
export const getNonArnkCouponsForSelectedTicketNumbers = createSelector(
  getCouponsForSelectedTicketSearchResults,
  (couponsForSelectedTicketSearchResults): CouponStatusData[] =>
    couponsForSelectedTicketSearchResults.filter((result) => result.flightNumber !== 'VOID')
);

/**
 * Get array of coupon statuses that belong to the selected tickets
 */
export const getNonArnkCouponStatusForSelectedTicketNumbers = createSelector(
  getNonArnkCouponsForSelectedTicketNumbers,
  (coupons: CouponStatusData[]) => coupons.map((coupon) => coupon.status)
);

/**
 * Get unique confirmation codes that are attached to selected ticket search rows
 */
export const getUniqueSelectedConfirmationCodes = createSelector(
  getAllSelectedTicketSearchResults,
  (selectedTicketSearchResults): string[] => [
    ...new Set(
      selectedTicketSearchResults
        .filter((ticketSearchResult) => ticketSearchResult.recordLocator)
        .map((ticketSearchResult) => ticketSearchResult.recordLocator)
    ),
  ]
);

/**
 * Get unique confirmation codes that are still active and attached to selected ticket search rows
 */
export const getUniqueSelectedActiveConfirmationCodes = createSelector(
  getAllSelectedTicketSearchResults,
  (selectedTicketSearchResults): string[] => [
    ...new Set(
      selectedTicketSearchResults
        .filter((ticketSearchResult) => ticketSearchResult.recordLocator && ticketSearchResult.isPurged === false)
        .map((ticketSearchResult) => ticketSearchResult.recordLocator)
    ),
  ]
);

/**
 * Get the number of ticket search results where selected is true
 */
export const getSelectedTicketSearchResultCount = createSelector(
  getAllSelectedTicketSearchResults,
  (selectedTicketSearchResults): number => selectedTicketSearchResults?.length ?? 0
);

/**
 * Get selected ticket search results that are not pinned as an array of ticket search results
 */
export const getAllSelectedUnpinnedTicketSearchResults = createSelector(
  getAllSelectedTicketSearchResults,
  (selectedTicketSearchResults): TicketSearchResultRow[] => selectedTicketSearchResults.filter((result) => !result.pinned)
);

/**
 * Get selected ticket search results that are not pinned as an array of ticket numbers
 */
export const getAllSelectedUnpinnedTicketNumbers = createSelector(
  getAllSelectedUnpinnedTicketSearchResults,
  (selectedUnpinnedTicketSearchResults): string[] => selectedUnpinnedTicketSearchResults.map((result) => result.eTicketNumber)
);

/**
 * Get the number of ticket search results where pinned is false and selected is true
 */
export const getUnpinnedSelectedTicketSearchResultCount = createSelector(
  getAllSelectedUnpinnedTicketSearchResults,
  (selectedUnpinnedTicketSearchResults): number => selectedUnpinnedTicketSearchResults?.length ?? 0
);

/**
 * Get coupons that belong to the specifically selected segment for the search results.
 * @example `A search result might have a single segment SEA -> LAX, but when you look up the coupons for that ticket number you might
 *  get back 5 coupons (1 for the segment you search for, plus 4 for other segments on the reservation).`
 */
export const getCouponsForSelectedSegments = createSelector(
  getCouponsForSelectedTicketSearchResults,
  getAllSelectedTicketSearchResults,
  (coupons: CouponStatusData[], selectedResults: TicketSearchResultRow[]) => {

    const result: CouponStatusData[] = [];

    selectedResults.forEach((selectedResult) => {
      let identifiedFirstOkCoupon = false;

      selectedResult.segments.forEach((segment) => {
        if (segment.currentStatus === 'OK' && !identifiedFirstOkCoupon) {

          identifiedFirstOkCoupon = true;

          coupons.forEach((coupon) => {
            if (selectedResult.eTicketNumber === coupon.ticketNumber &&
              segment.marketingFlightNumber === coupon.flightNumber &&
              segment.marketingAirlineCode === coupon.airlineCode &&
              segment.originAirportCode === coupon.departureAirport &&
              segment.destinationAirportCode === coupon.arrivalAirport &&
              segment.classOfService === coupon.classOfService &&
              segment.currentStatus === coupon.status) {

              result.push(coupon);
            }
          });
        }
      });
    });

    return result;
  }
);

/**
 * Get coupons that belong to the specifically selected segment for the search results that match the search criteria.
 * @example `A search result might have a single segment SEA -> LAX, but when you look up the coupons for that ticket number you might
 *  get back 5 coupons (1 for the segment you search for, plus 4 for other segments on the reservation).`
 * This will return the coupons that match the search critera, so if you search SEA -> LAX, you will only get coupons for SEA -> LAX.
 */
export const getCouponsToAssociateForSelectedSegments = createSelector(getCouponsForSelectedSegments, (coupons: CouponStatusData[]) => {
  const result: CouponStatusData[] = [];
  coupons.forEach((coupon) => {
    if (
      coupon.flightNumber === coupons[0].flightNumber &&
      coupon.airlineCode === coupons[0].airlineCode &&
      coupon.departureAirport === coupons[0].departureAirport &&
      coupon.arrivalAirport === coupons[0].arrivalAirport &&
      coupon.classOfService === coupons[0].classOfService &&
      coupon.status === coupons[0].status
    ) {
      result.push(coupon);
    }
  });
  return result;
});

/**
 * Boolean value as to whether the ticket contains partially used coupons
 */
export const getTicketHasPartiallyUsedCoupons = createSelector(getCouponsForSelectedTicketSearchResults,
  getAllSelectedTicketSearchResults,
  (coupons: CouponStatusData[], selectedResults: TicketSearchResultRow[]) => {
    const result: CouponStatusData[] = [];

    let isUsedCouponFound = false;
    let isOkCouponFound = false;
    let isOtherStatusCouponFound = false;

    selectedResults.forEach((selectedResult) =>
      selectedResult.segments.forEach((segment) =>
        coupons.forEach((coupon) => {
          if (coupon.status === 'USED' && selectedResult.eTicketNumber === coupon.ticketNumber && !isUsedCouponFound) {
            isUsedCouponFound = true;
          }
          if (coupon.status === 'OK' && selectedResult.eTicketNumber === coupon.ticketNumber && !isOkCouponFound) {
            isOkCouponFound = true;
          }
          if (coupon.status !== 'OK' && coupon.status !== 'USED' && selectedResult.eTicketNumber === coupon.ticketNumber) {
            isOtherStatusCouponFound = true;
          }
        })
      )
    );

    return isUsedCouponFound && isOkCouponFound && !isOtherStatusCouponFound;
  }
);

/**
 * Get all EMDs as a dictionary with the key being the ticket number
 */
export const getAllEMDEntities = createSelector(
  getTicketServiceState,
  (state): Dictionary<EMDTicketDetail> => selectEMDEntities(state.EMDs)
);

/**
 * Get EMD coupon status
 */
export const getEMDCouponStatus = (
  feeTicketNumber: string,
  travelTicketNumber: string,
  departureAirport: string,
  arrivalAirport: string,
  marketingFlightNumber: string,
  marketingAirlineCode: string
) =>
  createSelector(getAllEMDEntities, (emdTicketDetails: Dictionary<EMDTicketDetail>): string => {
    let result: string = null;
    emdTicketDetails[feeTicketNumber]?.emd?.coupons?.forEach((coupon) => {
      result =
        coupon.serviceCoupon?.find(
          (serviceCoupon) =>
            coupon.associatedDocNumber.value === travelTicketNumber &&
            serviceCoupon.startLocation === departureAirport &&
            serviceCoupon.endLocation === arrivalAirport &&
            serviceCoupon.marketingFlightNumber === marketingFlightNumber &&
            serviceCoupon.marketingProvider?.value === marketingAirlineCode
        )?.currentStatus ?? result;
    });
    return result;
  });

/**
 * Get all ticket details as an array
 */
export const getAllTicketDetailsResults = createSelector(getTicketServiceState, (state): GDSTicketsCloudModelsResponsesTicketDetail[] =>
  selectAllTicketDetails(state?.ticketDetails)
);

/**
 * Get ticket details by ticket number
 */
export const getTicketDetailsByTicketNumber = (ticketNumber: string) =>
  createSelector(
    getAllTicketDetailsResults,
    (tickets: GDSTicketsCloudModelsResponsesTicketDetail[]): GDSTicketsCloudModelsResponsesTicketDetail[] =>
    tickets.filter((ticket) => ticket.ticket.number === ticketNumber)
);

/**
 * Get all fareCalc as an array
 */
export const getAllFareCalcs = createSelector(
  getAllTicketDetailsResults,
  (tickets: GDSTicketsCloudModelsResponsesTicketDetail[]) =>
    tickets
      ?.filter(
        (ticket) =>
          ticket.ticket && ticket.ticket.fareCalculation && ticket.ticket.fareCalculation.new && ticket.ticket.fareCalculation.new.value
      )
      .map((ticket) => ticket.ticket.fareCalculation.new.value) ?? []
);

export const getNextUpcomingCoupons = createSelector(
  getAllTicketDetailsResults,
  getAllCoupons,
  (tickets: GDSTicketsCloudModelsResponsesTicketDetail[], coupons: CouponStatusData[]): CouponStatusData[] => {
    const nextUpcomingCoupons: CouponStatusData[] = [];

    // Grab the next upcoming coupon for each ticket, and store those coupons in an array
    tickets.forEach((ticket) => {
      const nextUpcomingGdsCoupon =
        ticket.ticket?.serviceCoupon.find((coupon) => {
          const utcNowAsDate = new Date(new Date().toUTCString());
          const matchingCoupon = coupons.find((c) => c.couponNumber === coupon.coupon && c.ticketNumber === ticket.ticket.number);
          if (matchingCoupon) {
            const departureUtcAsDate = new Date(matchingCoupon.departureDateUtc);
            return departureUtcAsDate >= utcNowAsDate;
          }
          return null;
        }) ?? ticket.ticket?.serviceCoupon[ticket.ticket.serviceCoupon.length - 1];
      const nextUpcomingCoupon = coupons.find(
        (coupon) => coupon.couponNumber === nextUpcomingGdsCoupon.coupon && coupon.ticketNumber === ticket.ticket.number
      );
      if (nextUpcomingCoupon) {
        nextUpcomingCoupons.push(nextUpcomingCoupon);
      }
    });

    return nextUpcomingCoupons;
  }
);

/**
 * List of coupon statuses in order for which the tickets should be dislayed based on the next coupon status
 */
const couponStatusSequenceForTicketOrdering: string[] = ['OK', 'CKIN', 'LFTD', 'CTRL', 'USED', 'IROP', 'PRT', 'PRTX', 'RPRT', 'EXCH', 'RFND', 'PRFD', 'NOGO', 'UTL', 'NS', 'VOID'];

/**
 * Get coupons with unknown statuses for which we are currently not expecting and not tracked
 */
export const getCouponsWithUnknownStatuses = createSelector(
  getNextUpcomingCoupons,
  (nextUpcomingCoupons: CouponStatusData[]): CouponStatusData[] => {
    const couponsWithUnknownStatuses: CouponStatusData[] = [];
    const nextUpcomingCouponsNotTracked = nextUpcomingCoupons.filter(
      coupon => !couponStatusSequenceForTicketOrdering.includes(coupon.status));
    nextUpcomingCouponsNotTracked.forEach(coupon => couponsWithUnknownStatuses.push(coupon));
    return couponsWithUnknownStatuses;
  }
);

/**
 * Get ticket details sorted by first coupon status
 */
export const getAllTicketDetailsResultsSortedByStatus = createSelector(
  getAllTicketDetailsResults,
  getNextUpcomingCoupons,
  getCouponsWithUnknownStatuses,
  (
    tickets: GDSTicketsCloudModelsResponsesTicketDetail[],
    nextUpcomingCoupons: CouponStatusData[],
    couponsWithUnknownStatuses: CouponStatusData[]
  ): GDSTicketsCloudModelsResponsesTicketDetail[] => {
    const sortedTicketNumbers: string[] = [];
    const ticketsSortedByCoupon: GDSTicketsCloudModelsResponsesTicketDetail[] = [];

    // Retreive any missing coupon statuses for which we are currently not expecting and not tracked
    // Add them to the end of the statusOrder array for inclusiveness so that they are displayed
    couponsWithUnknownStatuses.forEach(coupon => couponStatusSequenceForTicketOrdering.push(coupon.status));

    // Take the light of nextUpcomingCoupons and find the highest priority coupon for each ticket
    // For each priority status, push the ticket number to the sortedTicketNumbers array
    couponStatusSequenceForTicketOrdering.forEach((status) => {
      const filteredCoupons = nextUpcomingCoupons.filter(
        (coupon) => coupon?.status === status && !sortedTicketNumbers.includes(coupon.ticketNumber)
      );
      filteredCoupons.forEach((coupon) => {
        if (!sortedTicketNumbers.includes(coupon.ticketNumber)) {
          sortedTicketNumbers.push(coupon.ticketNumber);
        }
      });
    });

    // Build your return list of tickets sorted by coupon status
    sortedTicketNumbers.forEach((ticketNumber) => {
      const ticket = tickets.find((t) => t.ticket.number === ticketNumber);
      if (ticket) {
        ticketsSortedByCoupon.push(ticket);
      }
    });

    return ticketsSortedByCoupon;
  }
);

/**
 * Get's all ticket details as an input and converts them into coupons array
 */
export const convertTicketDetailsToCoupons = createSelector(getAllTicketDetailsResults, (tickets) =>
  tickets.flatMap((ticket) => CouponMapper.getCouponsFromTicketDetail(ticket))
);

/**
 * Get all coupons as an array
 */
export const getAllCouponsFromTicketDetails = createSelector(convertTicketDetailsToCoupons, (coupons) => coupons);

/**
 * Get all ticket details as an array
 */
export const getTicketDetailsEntities = createSelector(
  getTicketServiceState,
  (state): Dictionary<GDSTicketsCloudModelsResponsesTicketDetail> => selectTicketDetailsEntities(state.ticketDetails)
);

/**
 * Get the status enum of the ticket details call, represents the current network status of the ticket details
 */
export const getTicketDetailsStatus = createSelector(getTicketServiceState, (state) => state.ticketDetailsStatus);

/**
 * Get the error enum of the ticket details call
 */
export const getTicketDetailsError = createSelector(getTicketServiceState, (state) => state.ticketDetailsError);

/**
 * Returns ticketDetails sorted by localIssueDateTime
 */
export const getTicketDetailsSortedByDate = createSelector(
  getAllTicketDetailsResults,
  (tickets): GDSTicketsCloudModelsResponsesTicketDetail[] => {
    tickets?.sort(
      (a, b) =>
        new Date(b.ticket?.details?.localIssueDateTime?.value).getTime() - new Date(a.ticket?.details?.localIssueDateTime?.value).getTime()
    );
    return tickets ?? [];
  }
);

/**
 * Returns an array of TicketDetails, where each item is the most recent ticket for a passenger.
 */
export const getMostRecentlyIssuedTicketDetailsForGuests = createSelector(
  getTicketDetailsSortedByDate,
  getPassengersAndExtraSeatPassengers,
  getIsDividedReservation,
  (
    sortedTicketDetails: GDSTicketsCloudModelsResponsesTicketDetail[],
    passengersAndExtraSeatPassengers: Passenger[],
    isDividedReservation: boolean
  ): GDSTicketsCloudModelsResponsesTicketDetail[] => {
    const mostRecentTickets =
      passengersAndExtraSeatPassengers?.map((passenger, index) =>
        sortedTicketDetails.find((ticketDetail) => {
          // Divided reservations can't map passenger nameId with index. Match their name instead
          if (isDividedReservation) {
            return (
              ticketDetail?.ticket?.customer?.traveler?.firstName?.replace(' ', '') === passenger.firstName?.replace(' ', '') &&
              ticketDetail?.ticket?.customer?.traveler?.lastName?.replace(' ', '') === passenger.lastName?.replace(' ', '')
            );
          } else {
            // nameId is 1-based
            return parseInt(ticketDetail?.ticket?.customer?.traveler?.nameId, 10) - 1 === index;
          }
        })
      ) ?? [];
    return mostRecentTickets.length > 0 && !mostRecentTickets[0] ? [] : mostRecentTickets;
  }
);

/**
 * Gets all recent coupons as an array
 * WILL RETURN VOID coupons for ARNK
 *         Happens when agents ticket reservations after adding the ARNK (they shouldn't be doing this, but they do)
 */
export const getAllRecentCoupons = createSelector(getMostRecentlyIssuedTicketDetailsForGuests, (tickets): CouponStatusData[] => {
  const result = tickets
    .map((ticket) => ticket.ticket.serviceCoupon)
    .reduce((aggregatedCoupons, coupons) => {
      return aggregatedCoupons.concat(coupons);
    }, []);
  return result.map((coupon) => ({
    ...coupon,
    arrivalAirport: coupon.endLocation,
    departureAirport: coupon.startLocation,
    status: coupon.currentStatus,
  })) as CouponStatusData[];
});

/**
 * Returns true if the most recently issued travel ticket for each passenger has OK coupons, false otherwise
 * Checks for ARNK coupons, does not return false if it has an ARNK ticket (flight number is VOID and status is VOID)
 * This is used in scenarios like reval/reissue
 *    where we won't pass the ARNK segments to the service anyways so we want to ignore them when checking if the coupon statuses are all ok
 */
export const getMostRecentlyIssuedTicketAllCouponsOKIgnoreArnk = createSelector(
  getPassengersAndExtraSeatPassengersCount,
  getMostRecentlyIssuedTicketDetailsForGuests,
  getTicketDetailsStatus,
  (count: number, ticketDetailsPerGuest: GDSTicketsCloudModelsResponsesTicketDetail[], status: Status): boolean => {
    if (status === Status.STABLE) {
      return (
        count === ticketDetailsPerGuest?.length &&
        ticketDetailsPerGuest.every((ticketDetail) =>
          ticketDetail?.ticket?.serviceCoupon?.every(
            (coupon) =>
              coupon.currentStatus === 'OK' ||
              // USED statuses account for segments that have already flown
              coupon.currentStatus === 'USED' ||
              // To handle ARNK segments
              (coupon.currentStatus === 'VOID' && (coupon.marketingFlightNumber === 'VOID' || coupon.operatingFlightNumber === 'VOID'))
          )
        )
      );
    }
    return null;
  }
);

/**
 * Extract the raw service coupons from ticket details
 */
export const getAllServiceCoupons = createSelector(
  getAllTicketDetailsResults,
  (serviceCoupons): GDSTicketsCloudModelsResponsesServiceCouponTicket[] =>
    serviceCoupons.flatMap((result) => result.ticket?.serviceCoupon).filter((coupons) => !!coupons)
);

/**
 * Extract the coupons associated with the selected search results from ticket details
 */
export const getAllServiceCouponsForSelectedSearchResults = createSelector(
  getAllTicketDetailsResults,
  getAllSelectedTicketNumbers,
  (serviceCoupons, selectedTicketNumbers): GDSTicketsCloudModelsResponsesServiceCouponTicket[] =>
    serviceCoupons
      .filter((coupon) => selectedTicketNumbers.includes(coupon.ticket?.number))
      .flatMap((result) => result.ticket.serviceCoupon)
      .filter((coupon) => !!coupon)
);

/**
 * Get's all fare types (eg: Companion, Bereavement, Award, etc) from ticket details
 */
export const getAllFareTypes = createSelector(getAllServiceCoupons, (serviceCoupons): FareType[] =>
  serviceCoupons.flatMap((coupon) => CouponMapper.convertFareTypes(coupon))
);

/**
 * Get all segment statuses from service coupons
 */
export const getHasStandBySegmentStatus = createSelector(
  getAllServiceCouponsForSelectedSearchResults,
  (serviceCoupons: GDSTicketsCloudModelsResponsesServiceCouponTicket[]): string[] =>
    serviceCoupons.map((coupon) => coupon.bookingStatus).filter((bookingStatus) => !!bookingStatus)
);

/**
 * Returns true if all tickets have a nameNumber field that is not null or empty, false otherwise
 */
export const getAllTicketsHaveValidNameNumber = createSelector(
  getAllTicketDetailsResults,
  (tickets): boolean => tickets?.every((ticket) => ticket.ticket.customer.traveler.nameId?.length > 0) ?? true
);

/**
 * Returns the names of the passengers with missing name number fields in an array. Each field will be in
 * 'firstName lastName' format. Example: ['John Smith', 'Jane Smith']
 */
export const getNamesOfMissingNameNumbers = createSelector(getAllTicketDetailsResults, (tickets): string[] => {
  const names: string[] = [];
  tickets?.forEach((ticket) => {
    const traveler = ticket.ticket.customer.traveler;
    if (!traveler.nameId) {
      names.push(traveler.firstName + ' ' + traveler.lastName);
    }
  });
  return names;
});

/**
 * Returns a list of matching service coupons for a given ticket number and coupon number
 */
export const getMatchingServiceCoupons = (ticketNumber: string, couponNumber: string) =>
  createSelector(getAllTicketDetailsResults, (tickets): GDSTicketsCloudModelsResponsesServiceCouponTicket[] => {
    return tickets
      .filter((t) => t.ticket.number === ticketNumber)
      .map((detail) => detail.ticket.serviceCoupon.find((sc) => sc.coupon === couponNumber));
  });

/**
 * Returns a list of linked/related documents for the given ticket number
 */
export const getOriginalTicketNumbers = (ticketNumber: string) =>
  createSelector(getAllTicketDetailsResults, (tickets): string[] => {
    return tickets
      .filter((t) => t.ticket.number === ticketNumber)
      .flatMap((t) => t.ticket?.relatedDocument?.exchange?.map((e) => e.number));
  });

/**
 * Returns true if all passenger names match on both VCR and PNR, false otherwise
 */
export const getAllNamesMatchPnrAndVcr = createSelector(
  getMostRecentlyIssuedTicketDetailsForGuests,
  getPassengers,
  (tickets, passengers): boolean => {
    let result = true;
    tickets?.forEach((ticket) => {
      const traveler = ticket?.ticket?.customer?.traveler;
      if (!traveler || !passengers.find((pax) => pax.firstName === traveler.firstName && pax.lastName === traveler.lastName)) {
        // Check extra seat passengers
        const found = passengers.find((pax) =>
          pax.extraSeatRefs?.find(
            (extraSeatRef) => extraSeatRef.firstName === traveler?.firstName && extraSeatRef.lastName === traveler?.lastName
          )
        );
        // eslint-disable-next-line no-unused-expressions
        if (!found) {
          result = false;
        } else {
          result = true;
        }
      }
    });
    return result;
  }
);

/**
 * Returns the confirmation code and ticket numbers of a reservation that contains tickets with null/empty nameNumber field
 */
export const getTicketDetailsForNoNameNumberTickets = createSelector(
  getAllTicketDetailsResults,
  (
    tickets
  ): {
    confCode: string;
    ticketNumbers: string[];
  } => {
    const ticketNumbers: string[] = [];
    let confCode = null;
    tickets?.forEach((ticket) => {
      if (!ticket.ticket.customer.traveler.nameId || ticket.ticket.customer.traveler.nameId.length === 0) {
        confCode = ticket.ticket.details.reservation.sabre.value;
        ticketNumbers.push(ticket.ticket.number);
      }
    });
    return { confCode, ticketNumbers };
  }
);

/*
 * Gets all ticket numbers. Note that this may contain duplicates
 */
export const getAllOkTicketNumbers = createSelector(getAllOKCoupons, (serviceCoupons: CouponStatusData[]): string[] =>
  serviceCoupons.map((coupon) => coupon.ticketNumber)
);

/*
 * Gets all ticket numbers that are in OK and CKIN status. Note that this may contain duplicates
 */
export const getAllOkAndCKINTicketNumbers = createSelector(getAllOKAndCKINCoupons, (serviceCoupons: CouponStatusData[]): string[] =>
  serviceCoupons.map((coupon) => coupon.ticketNumber)
);

/*
 * Gets all ticket numbers in OK status. Note that this may contain duplicates
 */
export const getAllOnlyOkUsedTicketNumbers = createSelector(getOnlyOKUsedCoupons, (serviceCoupons: CouponStatusData[]): string[] =>
  serviceCoupons.map((coupon) => coupon.ticketNumber)
);

/**
 * Gets all unique ticket numbers
 */
export const getOkUniqueTicketNumbers = createSelector(getAllOkTicketNumbers, (ticketNumbers: string[]): string[] => [
  ...new Set(ticketNumbers),
]);

/**
 * Gets all unique ticket numbers that are in OK or CKIN status
 */
export const getOkAndCKINUniqueTicketNumbers = createSelector(getAllOkAndCKINTicketNumbers, (ticketNumbers: string[]): string[] => [
  ...new Set(ticketNumbers),
]);

/**
 * Gets all unique ticket numbers in OK/USED status
 */
export const getOnlyOkUsedUniqueTicketNumbers = createSelector(getAllOnlyOkUsedTicketNumbers, (ticketNumbers: string[]): string[] => [
  ...new Set(ticketNumbers),
]);

/**
 * Returns true if the schedule change disabled reasons should be shown, false otherwise
 */
export const getSCDisabledReasonsEligibility = createSelector(
  getMostRecentlyIssuedTicketAllCouponsOKIgnoreArnk,
  getAllTicketsHaveValidNameNumber,
  getAllNamesMatchPnrAndVcr,
  getIsTicketIssuedByOtherAirline,
  getHasValidPhoneField,
  (
    allCouponsOK: boolean,
    validNameNumber: boolean,
    namesMatchVcrAndPnr: boolean,
    hasTicketIssuedByOA: boolean,
    hasValidPhoneField: boolean
  ): boolean =>
    (allCouponsOK === false && validNameNumber) || !validNameNumber || !namesMatchVcrAndPnr || hasTicketIssuedByOA || !hasValidPhoneField
);

/**
 * Returns true if the irrop disabled reasons should be shown, false otherwise
 */
export const getIrropDisabledReasonsEligibility = createSelector(
  getMostRecentlyIssuedTicketAllCouponsOKIgnoreArnk,
  getAllTicketsHaveValidNameNumber,
  getInvoluntaryChangeSegmentsGreaterThanSixDaysDeparture,
  getHasItinerary,
  getAllNamesMatchPnrAndVcr,
  getHasValidPhoneField,
  (
    allCouponsOK: boolean,
    validNameNumber: boolean,
    greaterThanSixDaysDeparture: boolean,
    hasItinerary: boolean,
    namesMatchVcrAndPnr: boolean,
    hasValidPhoneField: boolean
  ): boolean =>
    (allCouponsOK === false && validNameNumber) ||
    !validNameNumber ||
    (greaterThanSixDaysDeparture && hasItinerary) ||
    !namesMatchVcrAndPnr ||
    !hasValidPhoneField
);

/**
 * Returns true if the irrop disabled reasons should be shown, false otherwise
 */
export const getIrropDisabledReasonsEligibilityV2 = createSelector(
  getMostRecentlyIssuedTicketAllCouponsOKIgnoreArnk,
  getAllTicketsHaveValidNameNumber,
  getInvoluntaryChangeSegmentsGreaterThanSixDaysDepartureV2,
  getHasItineraryV2,
  getAllNamesMatchPnrAndVcr,
  getHasValidPhoneField,
  (
    allCouponsOK: boolean,
    validNameNumber: boolean,
    greaterThanSixDaysDeparture: boolean,
    hasItinerary: boolean,
    namesMatchVcrAndPnr: boolean,
    hasValidPhoneField: boolean
  ): boolean =>
    (allCouponsOK === false && validNameNumber) ||
    !validNameNumber ||
    (greaterThanSixDaysDeparture && hasItinerary) ||
    !namesMatchVcrAndPnr ||
    !hasValidPhoneField
);

/**
 * Returns a boolean based on whether the routing has changed by comparing
 *     the current active flights routing with the coupons routing
 * This is used to check whether revalidation can occur
 */
export const getActiveFlightsRoutingMatchesCouponsRouting = createSelector(
  getConfirmedAndPastDatedSegments,
  getAllRecentCoupons,
  (segments: Segment[], coupons: CouponStatusData[]): boolean =>
    coupons.every(
      (coupon) =>
        // We only care about current active flights
        coupon.status === 'USED' ||
        // Ignore VOID coupons, these could be a result of an ARNK segment added incorrectly
        coupon.status === 'VOID' ||
        segments.some(
          (segment) =>
            coupon.arrivalAirport === segment.arrivalAirport &&
            coupon.departureAirport === segment.departureAirport &&
            coupon.classOfService === (segment.originalClassOfService ?? segment.serviceClassCode)
        )
    )
);

/**
 * Reval eligibility check - Check if the reservation has no tickets
 */
export const getRevalEligibilityTypeCheckTicketNumbers = createSelector(
  getTicketNumbersNoVoid,
  (ticketNumbers: string[]): ReservationRevalEligibilityType => {
    return ticketNumbers?.length > 0 ? null : ReservationRevalEligibilityType.NO_TICKETS;
  }
);

/**
 * Reval eligibility check - Check if any of the coupons are not OK status
 */
export const getRevalEligibilityTypeCheckAllCouponsOk = createSelector(
  getTicketDetailsStatus,
  getRevalEligibilityTypeCheckTicketNumbers,
  getMostRecentlyIssuedTicketAllCouponsOKIgnoreArnk,
  (ticketDetailsStatus: Status, hasTickets: ReservationRevalEligibilityType, allCouponsOk: boolean): ReservationRevalEligibilityType => {
    if (ticketDetailsStatus == Status.STABLE && hasTickets != ReservationRevalEligibilityType.NO_TICKETS && !allCouponsOk) {
      return ReservationRevalEligibilityType.COUPONS_NOT_OK;
    }
    return null;
  }
);

/**
 * Reval eligibility check - Check if a flight is operated by OA
 */
export const getRevalEligibilityTypeCheckOperatedByOa = createSelector(
  getAnySegmentOperatedByNonAirgroupAirline,
  (operatedByOa: boolean): ReservationRevalEligibilityType => {
    return operatedByOa ? ReservationRevalEligibilityType.OPERATED_BY_OA : null;
  }
);

/**
 * Reval eligibility check - Check if there has been a change of routing
 */
export const getRevalEligibilityTypeCheckChangeOfRouting = createSelector(
  getActiveFlightsRoutingMatchesCouponsRouting,
  (routesMatch: boolean): ReservationRevalEligibilityType => {
    return routesMatch ? null : ReservationRevalEligibilityType.CHANGE_OF_ROUTING;
  }
);

/**
 * Reval eligibility checks - check all reval eligibility factors and return the correct type
 */
export const getReservationRevalEligiblility = createSelector(
  getRevalEligibilityTypeCheckTicketNumbers,
  getRevalEligibilityTypeCheckAllCouponsOk,
  getRevalEligibilityTypeCheckOperatedByOa,
  getRevalEligibilityTypeCheckChangeOfRouting,
  (
    noTickets: ReservationRevalEligibilityType,
    couponsNotOk: ReservationRevalEligibilityType,
    operatedByOa: ReservationRevalEligibilityType,
    changeOfRouting: ReservationRevalEligibilityType
  ): ReservationRevalEligibilityType => {
    if (noTickets) {
      return noTickets;
    }
    if (couponsNotOk) {
      return couponsNotOk;
    }
    if (operatedByOa) {
      return operatedByOa;
    }
    if (changeOfRouting) {
      return changeOfRouting;
    } else {
      return ReservationRevalEligibilityType.ELIGIBLE;
    }
  }
);

/**
 * Get all ancillary ticket details as an array
 */
export const getAllAncillaryTicketDetailsResults = createSelector(
  getTicketServiceState,
  (state): GDSTicketsCloudModelsResponsesTicketDetail[] => selectAllAncillaryTicketDetails(state.ancillaryTicketDetails)
);

/**
 * Get Ancillary Ticket Details as a dictionary with the key being the ticket number
 */
export const getAncillaryTicketDetailsEntities = createSelector(
  getTicketServiceState,
  (state): Dictionary<GDSTicketsCloudModelsResponsesTicketDetail> => selectAncillaryTicketDetailsEntities(state.ancillaryTicketDetails)
);

/**
 * Get Ancillary Ticket coupon status
 */
export const getAncillaryTicketCouponStatus = (
  feeTicketNumber: string,
  travelTicketNumber: string,
  departureAirport: string,
  arrivalAirport: string,
  marketingFlightNumber: string,
  marketingAirlineCode: string
) =>
  createSelector(
    getAncillaryTicketDetailsEntities,
    (ancillaryTicketDetails: Dictionary<GDSTicketsCloudModelsResponsesTicketDetail>): string => {
      let result: string = null;
      if (ancillaryTicketDetails[feeTicketNumber]?.emd) {
        ancillaryTicketDetails[feeTicketNumber]?.emd?.coupons?.forEach((coupon) => {
          result =
            coupon.serviceCoupon?.find(
              (serviceCoupon) =>
                coupon.associatedDocNumber.value === travelTicketNumber &&
                serviceCoupon.startLocation === departureAirport &&
                serviceCoupon.endLocation === arrivalAirport &&
                serviceCoupon.marketingFlightNumber === marketingFlightNumber &&
                serviceCoupon.marketingProvider?.value === marketingAirlineCode
            )?.currentStatus ?? result;
        });
      } else if (ancillaryTicketDetails[feeTicketNumber]?.ticket) {
        result =
          ancillaryTicketDetails[feeTicketNumber]?.ticket?.serviceCoupon?.find(
            (serviceCoupon) =>
              serviceCoupon.startLocation === departureAirport &&
              serviceCoupon.endLocation === arrivalAirport &&
              serviceCoupon.marketingFlightNumber === marketingFlightNumber &&
              serviceCoupon.marketingProvider?.value === marketingAirlineCode
          )?.bookingStatus ?? result;
      }
      return result;
    }
  );

/**
 * Get the status enum of the ancillary ticket details call, represents the current network status of the ticket details
 */
export const getAncillaryTicketDetailsStatus = createSelector(getTicketServiceState, (state) => state.ancillaryTicketDetailsStatus);

/**
 * Get the error enum of the ancillary ticket details call
 */
export const getAncillaryTicketDetailsError = createSelector(getTicketServiceState, (state) => state.ancillaryTicketDetailsError);

/**
 * Get all ancillary ticket details entites as an array
 */
export const getAllAncillaryTicketDetailsEmds = createSelector(
  getAllAncillaryTicketDetailsResults,
  (results): GDSTicketsCloudModelsResponsesElectronicMiscDocument[] =>
    results ? results.filter((result) => result.emd).map((filteredResult) => filteredResult.emd) : []
);

/**
 * Get ancillary ticket fees details by traveler
 */
export const getAncillaryTicketFeeDetailsFromEmds = createSelector(getAllAncillaryTicketDetailsEmds, (emds): AncillaryFeeDetails[] => {
  return (
    emds?.flatMap((emd) =>
      emd?.coupons?.map(
        (coupon) =>
          ({
            ticketNumber: emd?.number,
            travelTicketNumber: coupon?.associatedDocNumber?.value,
            description: coupon?.fee?.description,
            transactionSegments: getTransactionSegments(coupon?.serviceCoupon),
            saleTotal: coupon?.fee?.total?.amount?.value.toString(),
            saleCurrency: coupon?.fee?.total?.amount?.currencyCode,
            recordLocator: emd?.details?.reservation?.sabre?.value,
            firstName: emd?.customers[0]?.traveler?.firstName,
            lastName: emd?.customers[0]?.traveler?.lastName,
          } as AncillaryFeeDetails)
      )
    ) ?? []
  );
});

/**
 * Get all ancillary ticket details entites as an array
 */
export const getAllAncillaryTicketDetailsTickets = createSelector(
  getAllAncillaryTicketDetailsResults,
  (results): GDSTicketsCloudModelsResponsesTicket[] =>
    results ? results.filter((result) => result.ticket).map((filteredResult) => filteredResult.ticket) : []
);

/**
 * Get ancillary ticket fees details by traveler
 */
export const getAncillaryTicketFeeDetailsFromTickets = createSelector(
  getAllAncillaryTicketDetailsTickets,
  (tickets): AncillaryFeeDetails[] => {
    return (
      tickets?.flatMap((ticket) => {
        const traveler = ticket?.customer?.traveler;
        const transactionSegments: TransactionSegment[] = [];
        ticket?.serviceCoupon?.forEach((coupon) => {
          transactionSegments.push({
            departureAirport: coupon.startLocation,
            arrivalAirport: coupon.endLocation,
            scheduledDepartureDateStnLocal: new Date(coupon.startDateTime)?.toISOString().split('T')[0],
            marketingFlightNumber: coupon.marketingFlightNumber,
            marketingAirlineCode: coupon.marketingProvider?.value,
          });
        });
        return {
          ticketNumber: ticket?.number,
          description: getFeeDescription(ticket?.serviceCoupon[0]?.fareComponent?.associatedTicketDesignator),
          transactionSegments,
          saleTotal: ticket?.amounts?.new?.total?.text,
          saleCurrency: ticket?.amounts?.new?.total?.amount?.currencyCode,
          recordLocator: ticket?.details?.reservation?.sabre?.value,
          firstName: traveler?.firstName,
          lastName: traveler?.lastName,
        } as AncillaryFeeDetails;
      }) ?? []
    );
  }
);

export function getFeeDescription(fareCalculation: string): string {
  if (fareCalculation.includes('0GO')) {
    return 'Baggage fee';
  }
  if (fareCalculation.includes('SDC')) {
    return 'Same day change';
  }
  if (fareCalculation.includes('FEE')) {
    return 'Service fee';
  }
  if (fareCalculation.includes('CHG')) {
    return 'Change fee';
  }
  return 'Other fee';
}

export function getTransactionSegments(serviceCoupons: GDSTicketsCloudModelsResponsesServiceCouponTicket[]): TransactionSegment[] {
  const result: TransactionSegment[] = [];
  serviceCoupons?.forEach((serviceCoupon) => {
    result.push({
      departureAirport: serviceCoupon?.startLocation,
      arrivalAirport: serviceCoupon?.endLocation,
      scheduledDepartureDateStnLocal: new Date(serviceCoupon?.startDateTime).toISOString().slice(0, 10),
      marketingAirlineCode: serviceCoupon?.marketingProvider?.value,
      marketingFlightNumber: serviceCoupon?.marketingFlightNumber,
    } as TransactionSegment);
  });
  return result;
}

export const getAncillaryTicketFeesInfoByTraveler = createSelector(
  getAncillaryTicketFeeDetailsFromEmds,
  getAncillaryTicketFeeDetailsFromTickets,
  (feeDetailsFromEmds, feeDetailsFromTickets): AncillaryFeesInfo[] => {
    const result: AncillaryFeesInfo[] = [];
    const feeDetails = feeDetailsFromEmds.concat(feeDetailsFromTickets);
    feeDetails?.forEach((fee) => {
      const firstName = fee.firstName;
      const lastName = fee.lastName;
      if (result.length === 0) {
        result.push({ firstName, lastName, fees: [fee] });
      } else {
        if (result.find((data) => data.firstName === firstName && data.lastName === lastName)) {
          result[result.findIndex((data) => data.firstName === firstName && data.lastName === lastName)].fees.push(fee);
        } else {
          result.push({ firstName, lastName, fees: [fee] });
        }
      }
    });
    return result;
  }
);

/**
 * Get ancillary ticket fees details
 */
export const getAncillaryTicketFeesInfo = createSelector(
  getAncillaryTicketFeeDetailsFromEmds,
  getAncillaryTicketFeeDetailsFromTickets,
  (feeDetailsFromEmds, feeDetailsFromTickets): AncillaryFeeDetails[] => {
    return (feeDetailsFromEmds ?? []).concat(feeDetailsFromTickets ?? []);
  }
);

/**
 * Returns true if the following ticket conditions are met:
 * 1. Has coupons in OK status
 * 2. Ticket(s) with the OK status coupons ONLY have coupons in OK or USED status
 *
 * NOTE: this is meant to be used with other checks, this does not check specifics like if each passenger has tickets
 */
export const getActiveTicketCouponsAreOkUsedStatus = createSelector(
  getAllOKCoupons,
  getAllTicketDetailsResults,
  (okCoupons: CouponStatusData[], ticketDetails: GDSTicketsCloudModelsResponsesTicketDetail[]): boolean => {
    // If there are no coupons or tickets, return false
    if (!okCoupons || okCoupons.length === 0 || !ticketDetails || ticketDetails?.length === 0) {
      return false;
    }

    // Grab the unique tickets for the OK coupons
    const ticketNumbersWithOkCoupons = [... new Set(okCoupons.map((coupon) => coupon.ticketNumber))];
    // If there are no OK coupons, return false
    if (ticketNumbersWithOkCoupons?.length === 0) {
      return false;
    }

    // Check that the tickets with OK coupons only have coupons in OK or USED status
    const ticketNumbersWithOkUsedCouponsOnly = ticketDetails
      .filter((ticket) => ticketNumbersWithOkCoupons.includes(ticket.ticket?.number))
      .filter((ticket) => {
        // Filter out the ARNK coupons
        const filteredCoupons = ticket.ticket.serviceCoupon.filter((coupon) => coupon.marketingFlightNumber !== 'VOID');
        const couponStatuses = filteredCoupons.map((coupon) => coupon.currentStatus);
        // Check that all coupons are in OK or USED status
        return couponStatuses.every((status) => status === 'OK' || status === 'USED');
      });
    // If the lengths are the same, then the conditions were met
    return ticketNumbersWithOkCoupons.length === ticketNumbersWithOkUsedCouponsOnly.length;
  }
);

/**
 * Returns true if the reservation has a bulk/opaque fare designator
 */
export const getHasBulkOpaqueDesignator = createSelector(
  getAllTicketDesignators,
  getAllTicketDetailsResults,
  (ticketDesignators: string[], ticketDetails: GDSTicketsCloudModelsResponsesTicketDetail[]): boolean =>
    ticketDesignators.some((designator) => bulkOpaqueDesignators.includes(designator)) ||
    ticketDetails.some((ticket) => ticket.ticket?.fareCalculation?.new?.value?.includes(' BT '))
);

/**
 * Gets ticketNumber in the route. Used for testing since selectRouteNestedParam is un-testable, and un-mockable.
 */
export const getRoutedTicketNumber = createSelector(
  selectRouteNestedParam('ticketNumber'),
  (ticketNumber: string): string => ticketNumber
);
