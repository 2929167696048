import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FlightInformationResponse } from '../../../dtos/response/flight-status-response/flight-information-response';
import { RootState } from '../../../state/state';
import { Status } from 'src/app/models/status';

export const flightServiceFeatureKey = 'flightService';

export interface State extends RootState {
  flightService: FlightServiceState;
}

export interface FlightServiceState {
  flightStatus: FlightStatusEntity;
  status: Status;
}

export interface FlightStatusEntity extends EntityState<FlightInformationResponse> {}

export const flightStatusAdapter: EntityAdapter<FlightInformationResponse> = createEntityAdapter<FlightInformationResponse>({
  selectId: (flightStatus) =>
    flightStatus.flightNumber + flightStatus.departureDate,
});

export const initialFlightStatusState: FlightStatusEntity = flightStatusAdapter.getInitialState({});

export const initialFlightServiceState: FlightServiceState = {
  flightStatus: initialFlightStatusState,
  status: Status.INITIAL,
};
