import { Action, createReducer, on } from '@ngrx/store';
import {
  initializeModelState,
  setHasReviewedSummary,
  setHasSavedSessionLevelChanges,
  setPaymentLink,
  setPaymentReceived,
} from './model.actions';
import { initialModelState } from './model.state';

const featureReducer = createReducer(
  initialModelState,
  on(setPaymentLink, (state, { paymentLink }) => ({
    ...state,
    paymentLink,
  })),
  on(setPaymentReceived, (state, { paymentReceived }) => ({
    ...state,
    paymentReceived,
  })),
  on(setHasReviewedSummary, (state, { hasReviewedSummary }) => ({
    ...state,
    hasReviewedSummary,
  })),
  on(setHasSavedSessionLevelChanges, (state, { hasSavedSessionLevelChanges }) => ({
    ...state,
    hasSavedSessionLevelChanges,
  })),
  on(initializeModelState, (state) => ({ ...state, ...initialModelState }))
);

export function modelReducer(state = initialModelState, action: Action) {
  return featureReducer(state, action);
}
