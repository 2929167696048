export enum ActionCode {
  AF = 'AF',
  BB = 'BB',
  BK = 'BK',
  BL = 'BL',
  BR = 'BR',
  CG = 'CG',
  CH = 'CH',
  CL = 'CL',
  CN = 'CN',
  CQ = 'CQ',
  CR = 'CR',
  CS = 'CS',
  CW = 'CW',
  CX = 'CX',
  DK = 'DK',
  DL = 'DL',
  DS = 'DS',
  FG = 'FG',
  FS = 'FS',
  GK = 'GK',
  GL = 'GL',
  GN = 'GN',
  HK = 'HK',
  HL = 'HL',
  HN = 'HN',
  HS = 'HS',
  HQ = 'HQ',
  HX = 'HX',
  IC = 'IC',
  IG = 'IG',
  IK = 'IK',
  IN = 'IN',
  IS = 'IS',
  IX = 'IX',
  JL = 'JL',
  KK = 'KK',
  KL = 'KL',
  LK = 'LK',
  LL = 'LL',
  MM = 'MM',
  NN = 'NN',
  NO = 'NO',
  NR = 'NR',
  NS = 'NS',
  OB = 'OB',
  OS = 'OS',
  OX = 'OX',
  PK = 'PK',
  PN = 'PN',
  PS = 'PS',
  PU = 'PU',
  RM = 'RM',
  RQ = 'RQ',
  RR = 'RR',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SQ = 'SQ',
  SS = 'SS',
  TK = 'TK',
  TL = 'TL',
  TN = 'TN',
  UC = 'UC',
  UN = 'UN',
  US = 'US',
  UU = 'UU',
  VG = 'VG',
  WK = 'WK',
  WL = 'WL',
  WN = 'WN',
  XK = 'XK',
  XM = 'XM',
  YG = 'YG',
  YK = 'YK',
  ZH = 'ZH',
  ZK = 'ZK',
  ZL = 'ZL',
  ZN = 'ZN',
  ZS = 'ZS',
  ZW = 'ZW',
  ZX = 'ZX',
  ZY = 'ZY',
  ZZ = 'ZZ',
}
