import { createAction } from '@ngrx/store';
import { FormKey } from '../../../models/forms/form-key';
import { formsFeatureKey } from './forms.state';
import { Segment } from '../../../dtos/response/reservation-response/segment';

/**
 * Trigger the reducer to set the forms state to its initial values
 */
export const initializeFormsState = createAction(`[${formsFeatureKey}] Initialize`);

/**
 * Set the values for a form in the store
 */
export const setFormValue = createAction(`[${formsFeatureKey}] Set form value`, (formKey: FormKey, formValue: any) => ({
  formKey,
  formValue,
}));

/**
 * Set the segments for all passengers on the selectMultipleGuestsPetForm slice of state
 */
export const setMultipleGuestsPetFormSegments = createAction(
  `[${formsFeatureKey}] Set multiple guests pet form segments`,
  (flightSegments: Segment[]) => ({
    flightSegments,
  })
);

/**
 * Set the value to null for a form in the store
 */
export const resetFormValue = createAction(`[${formsFeatureKey}] Reset form value`, (formKey: FormKey) => ({
  formKey,
}));
